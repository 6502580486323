'use strict';

exports.__esModule = true;
exports.routes = exports.route = exports.components = exports.component = exports.history = undefined;
exports.falsy = falsy;

var _propTypes = require('prop-types');

function falsy(props, propName, componentName) {
  if (props[propName]) return new Error('<' + componentName + '> should not have a "' + propName + '" prop');
}

var history = exports.history = (0, _propTypes.shape)({
  listen: _propTypes.func.isRequired,
  push: _propTypes.func.isRequired,
  replace: _propTypes.func.isRequired,
  go: _propTypes.func.isRequired,
  goBack: _propTypes.func.isRequired,
  goForward: _propTypes.func.isRequired
});

var component = exports.component = _propTypes.elementType;
var components = exports.components = (0, _propTypes.oneOfType)([component, _propTypes.object]);
var route = exports.route = (0, _propTypes.oneOfType)([_propTypes.object, _propTypes.element]);
var routes = exports.routes = (0, _propTypes.oneOfType)([route, (0, _propTypes.arrayOf)(route)]);