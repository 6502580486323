import React from 'react';
import IBox from 'ui/components/iBox';
import CustomerApi from '../../../api/customer.jsx';
import {Link} from 'react-router';

class CustomerSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = { customer: null, inputCustomerID: null };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = e => {
    this.setState({inputCustomerID: e.target.value, customer: null});
    if(!e.target.value) return;
    CustomerApi.GetCustomerBasicInfo(e.target.value)
      .then(data => this.setState({customer: data}))
      .catch(e => console.error(e));
  }

  render() {
    let {customer, inputCustomerID} = this.state;
    let dealerLink = !!customer ? '/dealer/' + customer.DealerID : '';

    return <IBox title='Customer Search'>
      <span><b>Customer ID: </b></span><input type='number' onChange={this.handleChange} /><br/>
      {!!customer ? 
        <div style={{paddingTop: '20px'}}>
          <span><b>Name: </b>{customer.Name}</span><br/>
          <span><b>Address: </b>{customer.Address}</span><br/>
          <span><b>Dealer Link: </b><Link to={dealerLink} >{window.location.origin.concat(dealerLink)}</Link></span><br/>
        </div> :
        <div style={{width: '100%', padding: '20px', fontWeight: '700', textAlign: 'center'}}>
          {!!inputCustomerID ? 'No Customer found' : 'Enter a Customer ID'}
        </div>
      }
    </IBox>;
  }
}


export default CustomerSearch;
