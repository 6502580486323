import React from 'react';
import {FormattedHTMLMessage, FormattedMessage, injectIntl} from 'react-intl';
import {getImagePath} from '../../helpers/image-helper';
import {truBlueStatus} from '../../helpers/constants-helper.jsx';


const trublueKeys = {
  [truBlueStatus.Active]: 'report-viewer.trublue.active',
  [truBlueStatus.Inactive]: 'report-viewer.trublue.inactive',
  [truBlueStatus.Eligible]: 'report-viewer.trublue.inactive',
  [truBlueStatus.Opportunity]: 'report-viewer.trublue.inactive',
  [truBlueStatus.Ineligible]: 'report-viewer.trublue.ineligible',
};

const TruBlue = ({intl, data}) => {
  let truBlueMessage = trublueKeys[data.poolTruBlueStatus];

  return (
    <div
      className={`container trublue ${data.poolTruBlueStatus === truBlueStatus.Active ? 'trublue--active' : 'trublue--inactive'}`}>
      <div className="row">
        <div className="col-3 highlighted">
          <span className="trublue-raindrop">
            <img src={getImagePath({locale: intl.locale, folder: 'TruBlue',
              file: data.poolTruBlueStatus === truBlueStatus.Active ? 'trublue-drop-on.png' : 'trublue-drop-off.png'})}/>
          </span>
          <span className="trublue-brand">
            <img src={getImagePath({locale: intl.locale, folder: 'TruBlue', file: 'trublue-promise-logo.png'})}
                 alt='TruBlue Logo' className='trublue-logo'/>
          </span>
        </div>
        <div className="col-9 trublue-message">
          <FormattedHTMLMessage id={truBlueMessage} />
        </div>
      </div>
    </div>
  );
};

export default injectIntl(TruBlue);
