import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import notificationApi from '../../api/notification.jsx';
import NotificationModal from '../components/notificationModal.jsx';
import PageHeader from '../components/pageHeader.jsx';
import IBox from '../components/iBox.jsx';
import {browserHistory} from 'react-router';
import {notificationTypes} from 'ui/helpers/constants';
import {connectToToasts, TOAST_STATES} from '../components/toasts.jsx';

// Page object
class PageNotif extends React.Component {
  static contextTypes = {
    canUseNotifications: PropTypes.bool.isRequired,
  };

  state = {
    notifications: [],
    showNotifModalState: 'closed',
    modalNotif: null,
  };

  componentWillMount() {
    if (!this.context.canUseNotifications) {
      browserHistory.push('/');
    }
  }

  componentDidMount() {
    this.refresh();
  }

  onHideModel = () => {
    this.setState({showNotifModalState: 'closed'});
    this.refresh();
  };

  getRead = (notif, index) => {
    notificationApi.getReadNotifications(notif.ID)
      .then(readNotifs => {
        let notifs = this.state.notifications;

        notifs[index].numRead = readNotifs;
        this.setState({notifications: notifs});
      });
  };

  getSent = (notif, index) => {
    notificationApi.getSentNotifications(notif.ID)
      .then(sentNotifs => {
        let notifs = this.state.notifications;

        notifs[index].numSent = sentNotifs;
        this.setState({notifications: notifs});
      });
  };

  editNotif = (notif) => {
    this.setState({
      notificationToEdit: notif,
      showNotifModalState: 'edit',
    });
  };

  deleteNotif = (notif) => {
    let notifList = this.state.notifications;
    let index = notifList.findIndex(n => n.ID === notif.ID);

    if (index > -1) {
      this.setState({
        notifications: [
          ...notifList.slice(0, index),
          ...notifList.slice(index + 1),
        ],
      });
    }

    notificationApi.deleteNotification(notif.ID).then(res => {
      this.props.createToast({
        id: 'delete-notification-success',
        clears: 'delete-notification',
        message: 'Deleted notification success.',
        ttl: 4000,
        state: TOAST_STATES.SUCCESS,
      });
    })
    .catch(err => {
      this.setState({
        notifications: [
          ...notifList
        ],
      });

      this.props.createToast({
        id: 'delete-notification-failure',
        message: err.message,
        state: TOAST_STATES.ERROR,
        ttl: 6000,
      });
    });
  };

  createNotif = () => {
    this.setState({showNotifModalState: 'create'});
  };

  refresh = () => {
    // Get notifications from db to repopulate list
    notificationApi.getNotifications()
    .then(notifs => this.setState({notifications: notifs}));
  };

  render() {
    // Populate our notification history
    // Body and URLLink are mutually exclusive. (ie if one doesn't exist the other should)
    let notifRows;

    try {
      // Retrieve data from db to populate UI
      notifRows = this.state.notifications.map((notif, index) => {
        let sentDate = moment(notif.Sent).format('MM-DD-YYYY');

        return (
        <tr key={index}>
            <td>{notif.NotificationTitle || notif.NotificationTitleFr}</td>
            {(() => {
              if (notif.Body || notif.BodyFr) {
                return <td className='body-td'><p>{notif.Body || notif.BodyFr}</p></td>;
              }
              return <td>{notif.URLLink}</td>;
            })()}
            <td>{notificationTypes[notif.Type]}</td>
            <td>{sentDate}</td>
            <td>{notif.Creator}</td>
            {(() => {
              // Define either a button or fields to show if user has requested to see sent/read
              if (notif.numSent !== undefined) {
                return <td>{notif.numSent}</td>;
              }
              return <td><button className='btn btn-secondary' onClick={this.getSent.bind(this, notif, index)}>Show Sent</button></td>;
            })()}
            {(() => {
              if (notif.numRead !== undefined) {
                return <td>{notif.numRead}</td>;
              }
              return <td><button className='btn btn-secondary' onClick={this.getRead.bind(this, notif, index)}>Show Read</button></td>;
            })()}
            <td><button className='btn btn-secondary' onClick={this.editNotif.bind(this, notif)}>Edit</button></td>
            <td><button className='btn btn-danger' onClick={this.deleteNotif.bind(this, notif)}>Delete</button></td>
          </tr>
        );
      });
    } catch (e) {
      console.error(e);
    }
    // Create UI
    return (
      <div>
        <PageHeader title='Notifications' icon='fa fa-flag' breadcrumbs={[{title: 'Notifications', path: '/notifications'}]} />
        <div className='wrapper wrapper-content'>
          <div className='row' id='page-dealers'>
            <div className='col-xs-12'>
              <IBox title='Dealers'>
                <button className='btn btn-primary' onClick={this.createNotif}>Create Notification</button>
                <table className='table table-striped user-table notification-table'>
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Body/Link</th>
                      <th>Type</th>
                      <th>Created</th>
                      <th>Created By</th>
                      <th>Sent</th>
                      <th>Read</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {notifRows}
                  </tbody>
                </table>
              </IBox>
            </div>
          </div>
        </div>
        <NotificationModal
        showState={this.state.showNotifModalState}
        hideModal={this.onHideModel}
        notificationToEdit={this.state.notificationToEdit}/>
      </div>
    );
  }
}

export default connectToToasts(PageNotif);
