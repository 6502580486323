import qwest from 'qwest';

import TruBlueConfigModel from '../models/truBlueConfig.jsx';

let TruBlueConfigApi = {
  getTruBlueConfig() {
    return new Promise((resolve, reject) => {
      qwest.get('/api/TruBlueConfig')
      .then((xhr, response) => {
        if (response.success) {
          try {
            let data = response.data;

            if (!data) {
              data = {};
            }
            if (typeof data === 'string') {
              data = TruBlueConfigModel.fromApiFormat(data);
            }
            resolve(data);
          } catch (e) {
            console.error(e);
            reject(response);
          }
        } else {
          reject(response);
        }
      });
    });
  },

  saveTruBlueConfig(config) {
    return new Promise((resolve, reject) => {
      qwest.post('/api/TruBlueConfig', {config: config.toApiFormat()})
      .then((xhr, response) => {
        if (response.success) {
          let data = response.data;

          if (!data) {
            data = {};
          }
          if (typeof data === 'string') {
            data = TruBlueConfigModel.fromApiFormat(data);
          }
          resolve(data);
        } else {
          reject(response);
        }
      });
    });
  },
};

export default TruBlueConfigApi;
