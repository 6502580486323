import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage, injectIntl, intlShape} from 'react-intl';
import {connectToTranslations} from '../IntlWrapper';
import {getImagePath} from '../../helpers/image-helper';
import Highlight from '../highlight/highlight.jsx';
import SpecialCharacterHelper from '../../helpers/special-character-helper.jsx';

const _MaintenanceRow = ({step, intl, formatPrintFile, country}) => <div className='col'>
  <Highlight imageUrl={getImagePath({locale: intl.locale, folder: `StepImages/${country}`, file: step.stepImg})}
             title={SpecialCharacterHelper.fixSpecialCharactersJSX(step.item)}>
    <ul>
      {step && step.note &&
      (formatPrintFile(step.note) || '')
        .split('|')
        .map((currentNote, j) =>
          <li key={j}>{SpecialCharacterHelper.fixSpecialCharactersJSX(currentNote)}</li>
        )}
    </ul>
  </Highlight>
</div>;

_MaintenanceRow.propTypes = {
  step: PropTypes.object,
  intl: intlShape,
  formatPrintFile: PropTypes.func.isRequired,
  country: PropTypes.string.isRequired,
};

const MaintenanceRow = connectToTranslations(injectIntl(_MaintenanceRow));

const RegularMaintenance = ({data, chemicalBaseTypeID, country, formatPrintFile}) => {
  if (!data || !data.length) {
    return null;
  }
  data = data.filter(step => step && (formatPrintFile(step.note) || '') !== '');
  let maintenanceRecords = data.length < 6 ? data : data.slice(0, 3);
  let maintenanceRecordsExtraRow = data.length < 6 ? null : data.slice(3, data.length);

  return <div className="container regular-maintenance">
    <div className="row dividers header-row">
      <div className="col title-cell">
        <h2><FormattedMessage id='report-viewer.regular-maintenance.title'/></h2>
      </div>
      <div className="col-8">
        <FormattedMessage id='report-viewer.regular-maintenance.disclaimer.first'/>
        <FormattedMessage id={[1, 6].includes(chemicalBaseTypeID) ?
          'report-viewer.regular-maintenance.disclaimer.bromine' :
          'report-viewer.regular-maintenance.disclaimer.chlorine'} />
      </div>
    </div>

    <div className="row highlights-row">
      {maintenanceRecords.map((step, i) => <MaintenanceRow step={step} key={'maint-row-1.' + i} country={country}/>)}
    </div>
    {maintenanceRecordsExtraRow ?
      <div className="row highlights-row">
        {maintenanceRecordsExtraRow.map((step, i) => <MaintenanceRow step={step} key={'maint-row-2.' + i} country={country}/>)}
      </div> :
      null}
  </div>;
};

RegularMaintenance.propTypes = {
  data: PropTypes.array,
  country: PropTypes.string,
  formatPrintFile: PropTypes.func.isRequired,
};

export default connectToTranslations(injectIntl(RegularMaintenance));
