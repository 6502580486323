import React from 'react';

import TokenManager from '../helpers/tokenManager.jsx';

import Spinner from '../components/spinner.jsx';

// Page object
class PageSignIn extends React.Component {
  componentDidMount() {
    setTimeout(() => {
      TokenManager.signinRedirect();
    }, 700);
  }

  render() {
    return (
      <div id='page-wrapper' className='gray-bg spinner-page'>
        <div className='centered-spinner'>
          <Spinner />
        </div>
      </div>
    );
  }
}

export default PageSignIn;
