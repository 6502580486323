import React from 'react';
import PropTypes from 'prop-types';

class JsonInput extends React.Component {
  static propTypes = {
    defaultValue: PropTypes.string,
  };

  state = {
    error: false,
  };

  get value() {
    if (!this.validate()) {
      this.setState({
        error: true,
      });
      return;
    }

    if (this.state.error) {
      this.setState({
        error: false,
      });
    }

    return JSON.parse(this.input.value);
  }

  validate = () => {
    if (!this.input) {
      return false;
    }

    try {
      let isJSON = JSON.parse(this.input.value);

      return true;
    } catch(err) {
      return false;
    }
  };

  render() {
    let {defaultValue, ...otherProps} = this.props;

    if (defaultValue && typeof defaultValue !== 'string') {
      defaultValue = JSON.stringify(defaultValue, null, 2);
    } else if (typeof defaultValue === 'string') {
      defaultValue = JSON.stringify(JSON.parse(defaultValue), null, 2);
    }


    return <div>
      <textarea ref={r => this.input = r}
                {...otherProps}
                defaultValue={defaultValue} />

      {this.state.error ? <p className='error'>Please enter valid json.</p> : null}
    </div>;
  }
}

export default JsonInput;
