// Subset of immutable.js list object, since actual immutable is massive.
// Should probably switch in the future if we need more of this.
// Should be directly compatible with immutable
class List {
  constructor(xs) {
    this.elements = xs || [];
  }

  get size() {
    return this.elements.length;
  }

  clear() {
    return new List();
  }

  get(i) {
    return this.elements.length >= i ? this.elements[i] : undefined;
  }

  includes(x) {
    return this.elements.includes(x);
  }

  has(i) {
    return this.elements.length >= i;
  }

  toArray() {
    return this.elements;
  }

  delete(i) {
    let xs = Object.assign([], this.elements);

    xs.splice(i, 1);
    return new List(xs);
  }

  insert(i, val) {
    let xs = Object.assign([], this.elements);

    xs.splice(i, 0, val);
    return new List(xs);
  }

  push(x) {
    let xs = Object.assign([], this.elements);

    xs.push(x);
    return new List(xs);
  }

  unshift(x) {
    let xs = Object.assign([], this.elements);

    xs.unshift(x);
    return new List(xs);
  }

  map(f) {
    let xs = this.elements.map(f);

    return new List(xs);
  }

  filter(f) {
    return new List(this.elements.filter(f));
  }

  reduce(...args) {
    return new List(this.elements.reduce(...args));
  }

  first() {
    return this.elements.length > 0 ? this.elements[0] : undefined;
  }

  last() {
    return this.elements.length > 0 ? this.elements[this.elements.length - 1] : undefined;
  }

  butLast() {
    return new List(this.elements.slice(0, this.elements.length - 1));
  }

  rest() {
    return new List(this.elements.slice(1));
  }

  pop() {
    return new List(this.elements.slice(0, this.elements.length - 1));
  }

  shift() {
    return new List(this.elements.slice(1));
  }

  sort(f) {
    return new List(this.elements.sort(f));
  }

  join(x) {
    return this.elements.join(x);
  }

  find(x) {
    return this.elements.find(x);
  }

  slice(...args) {
    return new List(this.elements.slice(...args));
  }

  concat(ys) {
    if (ys instanceof List) {
      return new List(this.elements.concat(ys.elements));
    }
    return new List(this.elements.concat(ys));
  }
}

export default List;
