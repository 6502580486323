import qwest from 'qwest';

export function searchTestsAndCustomers(query) {
  return qwest.post('/api/testing/search', {
    query,
  }, {timeout: 120 * 1000})
    .then((xhr, res) => res)
    .then(({success, code, data}) => {
      if (!success) {
        throw new Error('Failed searching for customers.');
      }

      return data;
    });
}

export function loadTestDataFromSystem(id) {
  return qwest.get('/api/testing/loadFromSystem?systemID=' + id, null, {timeout: 120 * 1000})
    .then((xhr, res) => res)
    .then(({success, code, data}) => {
      if (!success) {
        throw new Error('Failed to get test data.');
      }

      return data;
    });
}

export function loadTestDataFromAnalysis(id) {
  return qwest.get('/api/testing/loadFromAnalysis?analysisID=' + id, null, {timeout: 120 * 1000})
    .then((xhr, res) => res)
    .then(({success, code, data}) => {
      if (!success) {
        throw new Error('Failed to get test data.');
      }

      return data;
    });
}


export function runTestAnalysis({dealer, customer, pool, analysis, expected, fromTest, reportType}) {
  return qwest.post('/api/testing/runInput', {
    dealer,
    customer,
    pool,
    analysis,
    expected,
    fromTest,
    reportType,
  }, {timeout: 120 * 1000})
    .then((xhr, res) => res)
    .then(({code, success, data}) => {
      if (!success) {
        throw new Error('Failed to run analysis.');
      }

      return data;
    });
}

export function runComparison(dealer, customer, pool, analysis) {
  return qwest.post('/api/testing/RunComparison', {
    dealer,
    customer,
    pool,
    analysis,
  }, {timeout: 120 * 1000})
    .then((xhr, res) => res)
    .then(({code, success, data}) => {
      if (!success) {
        throw new Error('Failed to run analysis.');
      }

      return data;
    });
}

export function saveTest({dealer, customer, pool, analysis, name, description, expectedResult, id, saveAsTests, reportType}) {
  return qwest.post('/api/testing/save', {
    dealer,
    customer,
    id,
    pool,
    analysis,
    name,
    description,
    expectedResult,
    saveAsTests,
    reportType,
  }, {timeout: 120 * 1000})
    .then((xhr, res) => res)
    .then(({code, success, data}) => {
      if (!success) {
        throw new Error('Failed to save test.');
      }

      return data;
    });
}

export function getTest(id) {
  return qwest.get('/api/testing/gettest?id=' + id, null, {timeout: 120 * 1000})
    .then((xhr, res) => res)
    .then(({code, success, data}) => {
      if (!success) {
        throw new Error('Failed to get test');
      }

      return data;
    });
}

export function getTests() {
  return qwest.get('/api/testing/gettests', null, {timeout: 120 * 1000})
    .then((xhr, res) => res)
    .then(({code, success, data}) => {
      if (!success) {
        throw new Error('Failed to get tests.');
      }

      return data;
    });
}

export function getAllAnalysesWithComparations() {
  return qwest.get('/api/testing/GetAllAnalysesWithComparationsTests', null, {timeout: 120 * 1000})
    .then((xhr, res) => res)
    .then(({code, success, data}) => {
      if (!success) {
        throw new Error('Failed to get tests.');
      }

      return data;
    });
}

export function getTestByRuleAndID(rule, id) {
  return qwest.get(`/api/testing/GetTestByRuleAndID?rule=${rule}&id=${id}`, null, {timeout: 120 * 1000})
    .then((xhr, res) => res)
    .then(({code, success, data}) => {
      if (!success) {
        throw new Error('Failed to get test.');
      }

      return data;
    });
}

export function runTestSuite() {
  return qwest.post('/api/testing/run', null, {timeout: 7200 * 1000}) //Two hours timeout
    .then((xhr, res) => res)
    .then(({code, success, data}) => {
      if (!success) {
        throw new Error('Failed to run test suite.');
      }

      return data;
    });
}

export function getStats() {
  return qwest.get('/api/testing/stats', null, {timeout: 120 * 1000})
    .then((xhr, res) => res)
    .then(({code, success, data}) => {
      if (!success) {
        throw new Error('Faled to get stats.');
      }

      return data;
    });
}

export function getComparisonStats() {
  return qwest.get('/api/testing/GetComparisonStats', null, {timeout: 120 * 1000})
    .then((xhr, res) => res)
    .then(({code, success, data}) => {
      if (!success) {
        throw new Error('Faled to get stats.');
      }

      return data;
    });
}

export function deleteTestByRuleAndID(rule, id, fromHistory) {
  return qwest.delete(`/api/testing/DeleteTestByRuleAndID?rule=${rule}&id=${id}&fromHistory=${fromHistory}`, null, {timeout: 120 * 1000})
    .then((xhr, res) => res)
    .then(({code, success, data}) => {
      if (!success) {
        throw new Error('Failed to get test.');
      }

      return data;
    });
}

export function getRule(id) {
  return qwest.get(`/api/testing/GetRule?id=${id}`, null, {timeout: 120 * 1000})
    .then((xhr, res) => res)
    .then(({code, success, data}) => {
      if (!success) {
        throw new Error('Failed to get test.');
      }

      return data;
    });
}

export function getForceGraph() {
  return qwest.get(`/api/testing/GetForceGraph`, null, {timeout: 120 * 1000})
    .then((xhr, res) => res)
    .then(({code, success, data}) => {
      if (!success) {
        throw new Error('Failed to get test.');
      }

      return data;
    });
}
