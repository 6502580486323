import React from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {getImagePath} from '../../helpers/image-helper';
import moment from 'moment';
import {reportTypes, systemTypes, finishTypesFormat, groundLevelsFormat, systemTypeNamesFormat} from '../../helpers/constants-helper';

function hideNode(e) {
  if (window.displayBrokenImages === 'False') {
    e.target.style.display = 'none';
  }
}

const asH1 = txt => <h1>{txt}</h1>;

const Header = ({data, intl}) => {
  const volumeUnit = data.country === 'CA' ? 'L' : 'gals';
  const {formatMessage} = intl;
  const optLabel = data.country === 'CA' ? formatMessage({id: 'general.optimizer.plus'}) : formatMessage({id: 'general.optimizer'});

  return <div className="container header">
    <div className="row title-row">
      <div className='col-2'>
        {data.poolType ===  systemTypes.spa.toString() ?
          <img src={getImagePath({locale: intl.locale, folder: '',file: 'spaguard-icon.png'})} id="headerLogo" width={'100%'}/> :
          <img src={getImagePath({locale: intl.locale, folder: '',file: 'bioguard-icon.png'})} id="headerLogo" height={60} width={'100%'}/>
        }
      </div>
      <div className='col-8'>
        {data.ReportType === reportTypes.seasonsUsage || data.ReportType === reportTypes.qcSeasonsUsage ?
          <FormattedMessage id='report-viewer.titles.seasons-usage'>{asH1}</FormattedMessage> :
          data.ReportType === reportTypes.systemConversion ?
            <FormattedMessage id='report-viewer.titles.conversion'
                              values={{date: moment().format('l')}}>{asH1}</FormattedMessage> :
            data.ReportType === reportTypes.optimizerPlus ?
              <FormattedMessage id={data.country === 'CA' ? 'report-viewer.titles.optimizer-plus.ca' : 'report-viewer.titles.optimizer-plus.us'}
                                values={{date: moment().format('l')}}>{asH1}</FormattedMessage> :
              <FormattedMessage id='report-viewer.titles.analysis'
                                values={{name: data.custName}}>{asH1}</FormattedMessage>}
      </div>
      <div className="col-2 dealer-logo">
        <img onError={hideNode}
             src={data.branchLogo}
             height={60}/>
      </div>
    </div>

    {data.ReportType === reportTypes.optimizerPlus ?
      <OptimizerHeader volume={data.poolVolume}
                       volumeUnit={volumeUnit}
                       optimizerPlus={data.Borates && data.Borates.value}
                       optLabel={optLabel}/> :
      data.ReportType === reportTypes.systemConversion ?
        <ConversionsHeader volume={data.poolVolume}
                           volumeUnit={volumeUnit}
                           finish={data.finish}
                           system={data.poolType}
                           conversion={data.testerName}
                           customer={data.custName}/> :
        data.ReportType === reportTypes.qcSeasonsUsage ?
          <QCSeasonsUsageHeader volume={data.poolVolume}
                                volumeUnit={volumeUnit}
                                finish={data.finish}
                                poolType={data.poolType}
                                groundLevel={data.groundLevelType}
                                testDate={data.testDate}
                                period={data.suPeriod}/> :
          <div className="row info-row dividers">
            <div className="col">
              <div><FormattedMessage id='report-viewer.data.account'
                                     values={{account: data.custAccountNo}}/></div>
              {data.ReportType === reportTypes.seasonsUsage  ?
                <div><FormattedMessage id='report-viewer.data.customer'
                                       values={{customer: data.custName}}/></div> :
                <div><FormattedMessage id='report-viewer.data.tester'
                                       values={{tester: data.testerName}}/></div>
              }
            </div>
            <div className="col">
              <div><FormattedMessage id='report-viewer.data.volume'
                                     values={{
                                       volume: data.poolVolume,
                                       unit: volumeUnit,
                                     }}/></div>
              <div>{systemTypeNamesFormat(formatMessage)[data.poolType]}: {data.groundLevelType ?
                groundLevelsFormat(formatMessage)[data.groundLevelType] + '/' : data.groundLevelType}{data.finish ?
                finishTypesFormat(formatMessage)[data.finish] : data.finish}</div>
            </div>
            {(data.ReportType === reportTypes.regular || data.ReportType === reportTypes.winterClosing) && data.season && data.poolType !== systemTypes.spa.toString() ?
              <div className="col">
                <div><FormattedMessage id='report-viewer.data.season'
                                     values={{season: formatMessage({id: `general.season.${data.season}`})}}/></div>
              </div> :
            null}
            {data.ReportType === reportTypes.seasonsUsage ?
              <div className="col">
                <div><FormattedMessage id='report-viewer.data.date'
                                       values={{date: moment(data.testDate).format('l')}}/></div>
                <div><FormattedMessage id='report-viewer.data.period'
                                       values={{period: data.suPeriod}}/></div>
              </div> :
              <div className="col">
                <div><FormattedMessage id='report-viewer.data.analysis-date'
                                       values={{date: moment(data.testDate).format('l')}}/></div>
                <div><b><FormattedMessage id='report-viewer.data.next-analysis'
                                          values={{date: moment(data.nextAnalysis).format('l')}}/></b></div>
              </div>}
          </div>}
  </div>;
};

const ConversionsHeader = injectIntl(({volume, volumeUnit, system, customer, finish, conversion, intl}) => {
  if (customer && system) {
    return <div className='row info-row dividers'>
      <div className='col'>
        <div><FormattedMessage id='report-viewer.data.customer' values={{customer}} /></div>
        <div><FormattedMessage id='report-viewer.data.system' values={{system}} /></div>
      </div>
      <div className='col'>
        <div><FormattedMessage id='report-viewer.data.finish' values={{finish}} /></div>
        <div><FormattedMessage id='report-viewer.data.volume' values={{volume, unit: volumeUnit}} /></div>
      </div>
      <div className='col'>
        <div><FormattedMessage id='report-viewer.data.conversion' values={{conversion}} /></div>
      </div>
    </div>;
  }

  return <div className='row info-row dividers'>
    <div className='col'>
      <div><FormattedMessage id='report-viewer.data.finish' values={{finish}} /></div>
      <div><FormattedMessage id='report-viewer.data.volume' values={{volume, unit: volumeUnit}} /></div>
    </div>
    <div className='col-9'>
      <div><FormattedMessage id='report-viewer.data.conversion' values={{conversion}} /></div>
    </div>
  </div>;
});

const QCSeasonsUsageHeader = injectIntl(({volume, volumeUnit, finish, groundLevel, poolType, period, testDate, intl}) => {
  const {formatMessage} = intl;

  return <div className="row info-row dividers">
    <div className="col">
      <div><FormattedMessage id='report-viewer.data.volume'
                             values={{volume, unit: volumeUnit}}/></div>
      <div>{poolType ?
        systemTypeNamesFormat(formatMessage)[poolType] :
        formatMessage({id: 'general.pool'})}: {groundLevel ?
        groundLevelsFormat(formatMessage)[groundLevel] + '/' :
        ''}{finish ? finishTypesFormat(formatMessage)[finish] : ''}</div>
    </div>
    <div className="col col-9">
      <div><FormattedMessage id='report-viewer.data.date'
                             values={{date: moment(testDate).format('l')}}/></div>
      <div><FormattedMessage id='report-viewer.data.period'
                             values={{period}}/></div>
    </div>
  </div>;
});

const OptimizerHeader = injectIntl(({volume, volumeUnit, optimizerPlus, optLabel, intl}) =>
  <div className='row info-row dividers'>
    <div className='col'>
      <div><FormattedMessage id='report-viewer.data.volume' values={{volume, unit: volumeUnit}} /></div>
    </div>
    <div className='col-9'>
      <div><FormattedMessage id='report-viewer.data.optimizer-plus' values={{amount: optimizerPlus,  title: optLabel}} /></div>
    </div>
  </div>);



export default injectIntl(Header);
