import PropTypes from 'prop-types';
import React from 'react';

import EditableTextArea from './editableTextArea.jsx';

class LayoutTextArea extends React.PureComponent {
  static displayName = 'LayoutTextArea';
  static propTypes = {
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
    style: PropTypes.object,
  };

  render() {
    return <div className='form-group'>
      <label>{this.props.label}{this.props.required && this.props.editMode ? <span className='error' style={{marginLeft: 2}}>*</span> : null}</label>
      <EditableTextArea {...this.props} />
    </div>;
  }
}

export default LayoutTextArea;
