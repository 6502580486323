import PropTypes from 'prop-types';
import React from 'react';

import EditableTextField from './editableTextField.jsx';

class LayoutInput extends React.Component {
  static defaultProps = {
    condition: true,
    disabled: false,
    label: '',
    dataKey: '',
    unit: null,
  };

  static propTypes = {
    condition: PropTypes.bool,
    dataChanged: PropTypes.func.isRequired,
    dataKey: PropTypes.string.isRequired,
    disabled: PropTypes.any,
    editMode: PropTypes.bool.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    unit: PropTypes.string,
    validate: PropTypes.func,
    validateMessage: PropTypes.string,
    value: PropTypes.any,
    required: PropTypes.bool,
  };

  shouldComponentUpdate(nextProps) {
    let props = this.props;

    if (props.condition !== nextProps.condition || props.disabled !== nextProps.disabled ||
        props.editMode !== nextProps.editMode || props.label !== nextProps.label || props.placeholder !== nextProps.placeholder ||
        props.value !== nextProps.value) {
      return true;
    }
    return false;
  }

  render() {
    let disabled = this.props.disabled;

    if (disabled instanceof Array) {
      disabled = disabled.indexOf(this.props.dataKey) !== -1;
    }
    if (this.props.condition) {
      return <div className='form-group'>
        <label>{this.props.label}{this.props.required && this.props.editMode ? <span className='error' style={{marginLeft: 2}}>*</span> : null}</label>
        <EditableTextField
          onChange={this.props.dataChanged}
          editMode={this.props.editMode}
          value={this.props.value}
          type={this.props.type}
          unit={this.props.unit}
          dataKey={this.props.dataKey}
          placeholder={this.props.placeholder}
          validate={this.props.validate}
          validateMessage={this.props.validateMessage}
          disabled={disabled} />
      </div>;
    }

    return null;
  }
}

export default LayoutInput;
