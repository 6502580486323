import qwest from 'qwest';

let AssetApi = {
  list() {
    return qwest.get('/api/Asset/List')
      .then((xhr, response) => {
        if (response.success) {
          return response.data;
        }
        throw response;
      });
  },

  upload(asset) {
    let formData = new FormData();

    formData.append('asset', asset);
    return new Promise((resolve, reject) => {
      qwest.post(
        '/api/Asset/Upload',
        formData,
        {
          responseType: 'json',
          dataType: 'formdata',
        }
      )
      .then((xhr, response) => {
        if (response.success) {
          resolve(response.data);
        }
        throw response;
      })
      .catch(err => reject(err));
    });
  },

  delete(filename) {
    return qwest.delete(`/api/Asset/Delete/${filename}`)
      .then((xhr, response) => {
        if (response.success) {
          return response.data;
        }
        throw response;
      });
  },
};

export default AssetApi;
