import React from 'react';
import {findDOMNode} from 'react-dom';
import PropTypes from 'prop-types';
import dealerApi from '../../api/dealer.jsx';
import DealerInfoModal from '../components/dealerInfoModal.jsx';
import ErrorList from '../components/errorList.jsx';
import PageHeader from '../components/pageHeader.jsx';
import IBox from '../components/iBox.jsx';
import Pagination from '../components/Pagination.jsx';
import {browserHistory} from 'react-router';
import {languages, countriesNames} from '../helpers/constants.jsx';
import {getLanguageFromLocale} from '../helpers/languageHelper';
import {connectToToasts, TOAST_STATES} from '../components/toasts.jsx';


function uploadFile(before, success, failure, importFileInput) {
  return function() {
    // eslint-disable-next-line babel/no-invalid-this
    let file = this.files[0];

    const reader = new FileReader();

    reader.onload = function(e) {
      let data = e.target.result;

      if(data) {
        before();

        dealerApi.import(data)
          .then(x => {
            if(x && x.success) {
              success();
              importFileInput.value = null;
            } else {
              console.error(x.message);
              failure();
              importFileInput.value = null;
            }
          })
          .catch(err => {
            console.error(err);
            failure();
            importFileInput.value = null;
          });
      }
    }
    ;
    reader.readAsText(file);
  };
}

// Page object
class PageDealers extends React.Component {
  static contextTypes = {
    token: PropTypes.any.isRequired,
  };
  static propTypes = {
    createToast: PropTypes.func.isRequired,
  };

  state = {
    dealers: [],
    showDealerInfoModalState: 'closed',
    dealerInfo: null,
    firstPage: 1,
    activePage: 1,
    pageSize: 10,
    pages() {
      return Math.ceil(this.dealers.length / this.pageSize);
    },
  };

  componentDidMount() {
    if (this.importFileInput) {
      // eslint-disable-next-line react/no-find-dom-node
      let input = findDOMNode(this.importFileInput);

      input.addEventListener('change', uploadFile(this.beforeUpload, this.successfulUpload, this.failedUpload, this.importFileInput));
    }

    this.refreshDealers();
  }

  componentWillUnmount() {
    if (this.importFileInput) {
      // eslint-disable-next-line react/no-find-dom-node
      let input = findDOMNode(this.importFileInput);

      input.removeEventListener('change', uploadFile(this.beforeUpload, this.successfulUpload, this.failedUpload, this.importFileInput));
    }
  }

  onCancelDealerInfoModal = () => {
    this.setState({showDealerInfoModalState: 'closed', modalDealer: null});
    this.clearErrors();
  };

  onSubmitDealerInfoModal = newDealer => {
    ErrorList.clearErrors();
    dealerApi
      .createDealer(newDealer)
      .then(() => {
        this.refreshDealers();

        this.setState({
          showDealerInfoModalState: 'closed',
          modalDealer: null,
        });
        this.clearErrors();
      })
      .catch(this.handleErrors);
  };

  changePage = page => {
    this.setState({
      activePage: page,
    });
  };

  clearErrors = () => {
    ErrorList.clearErrors();
  };

  goToDealer = dealer => {
    browserHistory.push(`/dealer/${dealer.ID}`);
  };

  handleErrors = error => {
    ErrorList.publishErrors(error.message.split('\n'));
  };

  refreshDealers = () => {
    dealerApi.getDealers().then(dealer => {
      this.setState({dealers: dealer});
    });
  };

  getExportCSV = () => {
    dealerApi.getExportCSV(encodeURIComponent(this.context.token['id_token']));
  };

  beforeUpload = () => {
    this.props.createToast({
      id: 'import-dealer-editable-report',
      message: 'Importing...',
      ttl: 4000,
    });
  };

  failedUpload = () => {
    this.props.createToast({
      id: 'import-dealer-editable-report-failure',
      message: 'Failed to import dealers.',
      ttl: 6000,
      state: TOAST_STATES.ERROR,
      clears: 'import-dealer-editable-report',
    });
  };

  successfulUpload = () => {
    this.props.createToast({
      id: 'import-dealer-editable-report-success',
      message: 'Finished importing.',
      ttl: 4000,
      state: TOAST_STATES.SUCCESS,
      clears: 'import-dealer-editable-report',
    });
    this.refreshDealers();
  };


  triggerUpload = () => {
    if (this.importFileInput) {
      // eslint-disable-next-line react/no-find-dom-node
      let file = findDOMNode(this.importFileInput);

      file.click();
    }
  };

  render() {
    let sliceFrom = (this.state.activePage - 1) * this.state.pageSize;
    let sliceTo = this.state.activePage * this.state.pageSize;
    let dealerList = this.state.dealers.slice(sliceFrom, sliceTo).map(dealer => {
      let status = dealer.IsDefunct ? ' (Defunct)' : dealer.IsDemo ? ' (Demo)' : '';

      return (
        <tr key={dealer.ID} onClick={this.goToDealer.bind(this, dealer)}>
          <td>
            {dealer.Name}
            {status}
          </td>
          <td>{dealer.Account}</td>
          <td>{dealer.PlatinumStatus}</td>
          <td>{countriesNames[dealer.Country || 'US']}</td>
          <td>{languages[getLanguageFromLocale(dealer.DefaultLocale)]}</td>
        </tr>
      );
    });

    return (
      <div>
        <input type='file' ref={r => this.importFileInput = r} style={{display: 'none'}} accept=".csv"/>
        <PageHeader title="Dealers" icon="fa fa-truck" breadcrumbs={[{title: 'Dealers', path: '/dealers'}]} />
        <div className="wrapper wrapper-content">
          <div className="row" id="page-dealers">
            <div className="col-xs-12">
              <IBox title="Dealers">
                <div className="row">
                  <div className="col-md-12">
                    <button className='btn btn-default' onClick={this.triggerUpload}>Import CSV</button>
                    <button className='btn btn-primary' onClick={this.getExportCSV}>Export CSV</button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sx-12">
                    <table className="table table-striped table-hover hoverClick">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Account</th>
                          <th>Platinum Status</th>
                          <th>Country</th>
                          <th>Preferred Language</th>
                        </tr>
                      </thead>
                      <tbody>{dealerList}</tbody>
                    </table>
                    {(() => {
                      if (this.state.pages() > 1) {
                        return (
                          <Pagination
                            onPageChange={this.changePage}
                            page={this.state.activePage}
                            count={this.state.pageSize}
                            total={this.state.dealers.length}
                            maxButtons={10}
                          />
                        );
                      }
                      return '';
                    })()}
                  </div>
                </div>
              </IBox>
            </div>
          </div>
          <DealerInfoModal
            showState={this.state.showDealerInfoModalState}
            dealer={this.state.modalDealer}
            onCancel={this.onCancelDealerInfoModal}
            onSubmit={this.onSubmitDealerInfoModal}
          />
        </div>
      </div>
    );
  }
}

export default connectToToasts(PageDealers);
