import React from 'react';

import TokenManager from '../helpers/tokenManager.jsx';

import {setToken} from '../helpers/tokens.jsx';

import {browserHistory} from 'react-router';


import Spinner from '../components/spinner.jsx';

class Token extends React.Component {
  render() {
    TokenManager.signinRedirectCallback().then(x => {
      setToken(x);
      browserHistory.push('/');
    }).catch(err => console.error(err));
    return <div id='page-wrapper' className='gray-bg spinner-page'>
      <div className='centered-spinner'>
        <Spinner />
      </div>
    </div>;
  }
}

export default Token;
