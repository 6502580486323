import PropTypes from 'prop-types';

import React from 'react';

class Collapsible extends React.Component {
  static defaultProps = {
    initiallyCollapsed: false,
  };

  static propTypes = {
    initiallyCollapsed: PropTypes.bool,
    children: PropTypes.any,
  };

  state = {
    collapsed: this.props.initiallyCollapsed,
  };

  toggle = (event) => {
    event.preventDefault();
    this.setState({collapsed: !this.state.collapsed});
  };

  render() {
    return (
      <div>
        <a href='#' onClick={this.toggle}>{this.state.collapsed ? 'Show' : 'Hide'} all</a>
        {this.state.collapsed ? null : this.props.children}
      </div>
    );
  }
}

export default Collapsible;
