import moment from 'moment';

import cloneDeep from 'lodash/cloneDeep';

import CustomerContact from './customerContact.jsx';

import Pool from './poolSummary.jsx';

import {truBlueStatus as truBlueStatusConstants} from '../ui/helpers/constants.jsx';


class Customer {
  constructor({
    id, dealerId, branchID, accountNum, organization, address1, address2, city, state, zip, addressVerified, contact, classification,
    other1, other2, other3, other4, other5, prodNamePrintout, inactive, modified, created, imported, deleted, favourite,
    customerContacts, pools, customerType, sourceConcern, flag, country, truBlueStatus, truBlueMemberID, POSTotalSales, POSNonBioguardSales, POSVendorID, storeAssociateTBSignUp,
  }) {
    this.id = id;
    this.dealerId = dealerId;
    this.branchID = branchID;
    this.accountNum = accountNum;
    this.organization = organization;
    this.address1 = address1;
    this.address2 = address2;
    this.city = city;
    this.state = state;
    this.zip = zip;
    this.addressVerified = addressVerified;
    this.contact = contact;
    this.country = country;
    this.classification = classification;
    this.other1 = other1;
    this.other2 = other2;
    this.other3 = other3;
    this.other4 = other4;
    this.other5 = other5;
    this.prodNamePrintout = prodNamePrintout;
    this.inactive = inactive;
    this.modified = modified;
    this.created = created;
    this.imported = imported;
    this.deleted = deleted;
    this.favourite = favourite;
    this.customerContacts = customerContacts || [];
    this.pools = pools;
    this.customerType = customerType || 'Residential';
    this.sourceConcern = sourceConcern;
    this.flag = flag;
    this.truBlueStatus = truBlueStatus;
    this.truBlueMemberID = truBlueMemberID;
    this.POSTotalSales = POSTotalSales;
    this.POSNonBioguardSales = POSNonBioguardSales;
    this.POSVendorID = POSVendorID;
    this.storeAssociateTBSignUp = storeAssociateTBSignUp;

    this.dirtied = {};
  }

  clone() {
    return cloneDeep(this);
  }

  get isTruBlueEligible() {
    return this.truBlueStatus === truBlueStatusConstants.Eligible ||
             this.truBlueStatus === truBlueStatusConstants.Active ||
              this.truBlueStatus === truBlueStatusConstants.Inactive;
  }

  get isTruBlueActive() {
    return this.truBlueStatus === truBlueStatusConstants.Active;
  }

  get isTruBlueInactive() {
    return this.truBlueStatus === truBlueStatusConstants.Inactive;
  }
  get isTruBlueIneligible() {
    return this.truBlueStatus === truBlueStatusConstants.Ineligible;
  }

  toApiFormat() {
    return {
      ID: this.id,
      DealerID: this.dealerId,
      BranchID: this.branchID,
      AccountNo: this.accountNum,
      Organization: this.organization,
      Address1: this.address1,
      Address2: this.address2,
      Country: this.country,
      City: this.city,
      State: this.state,
      Zip: this.zip,
      AddressVerified: this.addressVerified,
      Contact: this.contact,
      Classification: this.classification,
      Other1: this.other1,
      Other2: this.other2,
      Other3: this.other3,
      Other4: this.other4,
      Other5: this.other5,
      ProdNamePrintout: this.prodNamePrintout,
      Created: this.created && this.created.format('YYYY-MM-DD HH:mm:ss'),
      Imported: this.imported && this.imported.format('YYYY-MM-DD HH:mm:ss'),
      Deleted: this.deleted,
      Favourite: this.favourite,
      Inactive: this.inactive,
      CustomerContacts: (this.customerContacts || []).map(cc => cc.toApiFormat()),
      Pools: (this.pools || []).map(p => p.toApiFormat()),
      CustomerType: this.customerType,
      SourceConcern: this.sourceConcern,
      Flag: this.flag,
      TruBlueStatus: this.truBlueStatus,
      TruBlueMemberID: this.truBlueMemberID,
      POSTotalSales: this.POSTotalSales,
      POSNonBioguardSales: this.POSNonBioguardSales,
      POSVendorID: this.POSVendorID,
      StoreAssociateTBSignUp: this.storeAssociateTBSignUp,
    };
  }

  static fromApiFormat(api) {
    return new Customer({
      id: api.ID,
      dealerId: api.DealerID,
      branchID: api.BranchID,
      accountNum: api.AccountNo,
      organization: api.Organization,
      address1: api.Address1,
      address2: api.Address2,
      country: api.Country,
      city: api.City,
      state: api.State,
      zip: api.Zip,
      addressVerified: api.AddressVerified,
      contact: api.Contact,
      classification: api.Classification,
      other1: api.Other1,
      other2: api.Other2,
      other3: api.Other3,
      other4: api.Other4,
      other5: api.Other5,
      prodNamePrintout: api.ProdNamePrintout,
      inactive: api.Inactive,
      modified: api.Modified && moment(api.Modified),
      created: api.Created && moment(api.Created),
      imported: api.Imported && moment(api.Imported),
      deleted: api.Deleted,
      favourite: api.Favourite,
      customerContacts: (api.CustomerContacts || []).map(cc => CustomerContact.fromApiFormat(cc)),
      pools: (api.Pools || []).map(p => Pool.fromApiFormat(p)),
      customerType: api.CustomerType,
      sourceConcern: api.SourceConcern,
      flag: api.Flag,
      truBlueStatus: api.TruBlueStatus,
      truBlueMemberID: api.TruBlueMemberID,
      POSTotalSales: api.POSTotalSales,
      POSNonBioguardSales: api.POSNonBioguardSales,
      POSVendorID: api.POSVendorID,
      storeAssociateTBSignUp: api.StoreAssociateTBSignUp,
    });
  }
}

export default Customer;
