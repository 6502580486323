import PropTypes from 'prop-types';

import React from 'react';

// Page object
class Dealer extends React.Component {
  static propTypes = {
    record: PropTypes.object,
  };

  render() {

    return (
      <div className='search-result'>
        <h3><a href={'/dealer/' + this.props.record.Source.iD}>{this.props.record.Source.name}</a></h3>
        <a href={'/dealer/' + this.props.record.Source.iD} className='search-link'>/dealer/{this.props.record.Source.iD}</a>
        <p>{this.props.record.Source.soldTo} - {this.props.record.Source.ownerName}</p>
      </div>);

  }
}

export default Dealer;
