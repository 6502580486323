class ContactEmailPreference {
  constructor({
    accountUpdate,
    customerEmail,
    emailLanguagePreference,
    id,
    latestFromBioguard,
    newsFromExpert,
    CASL,
  }) {
    this.dirtied = false;

    this._accountUpdate = accountUpdate;
    this.customerEmail = customerEmail;
    this._emailLanguagePreference = emailLanguagePreference;
    this.id = id;
    this._latestFromBioguard = latestFromBioguard;
    this._newsFromExpert = newsFromExpert;
    this._CASL = CASL;
  }

  get accountUpdate() {
    return this._accountUpdate;
  }

  set accountUpdate(value) {
    this._accountUpdate = value;
    this.dirtied = true;
    return this._accountUpdate;
  }

  get latestFromBioguard() {
    return this._latestFromBioguard;
  }

  set latestFromBioguard(value) {
    this._latestFromBioguard = value;
    this.dirtied = true;
    return this._latestFromBioguard;
  }

  get newsFromExpert() {
    return this._newsFromExpert;
  }

  set newsFromExpert(value) {
    this._newsFromExpert = value;
    this.dirtied = true;
    return this._newsFromExpert;
  }

  get emailLanguagePreference() {
    return this._emailLanguagePreference;
  }

  set emailLanguagePreference(value) {
    this._emailLanguagePreference = value;
    this.dirtied = true;
    return this._emailLanguagePreference;
  }

  get CASL() {
    return this._CASL;
  }

  toApiFormat() {
    if (!this.dirtied) {
      return undefined;
    }

    return {
      AccountUpdate: this.accountUpdate,
      CustomerEmail: this.customerEmail,
      EmailLanguagePreference: this.emailLanguagePreference,
      ID: this.id,
      LatestFromBioguard: this.latestFromBioguard,
      NewsFromExpert: this.newsFromExpert,
      CASL: this.CASL,
    };
  }

  static fromApiFormat(api) {
    if(api) {
      return new ContactEmailPreference({
        accountUpdate: api.AccountUpdate,
        customerEmail: api.CustomerEmail,
        emailLanguagePreference: api.EmailLanguagePreference,
        id: api.ID,
        latestFromBioguard: api.LatestFromBioguard,
        newsFromExpert: api.NewsFromExpert,
        CASL: api.CASL,
      });
    }
  }
}

export default ContactEmailPreference;
