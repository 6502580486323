import * as d3 from 'd3';
import {cloneDeep} from 'lodash';
import classNames from 'classnames';

import {LINK_TYPES} from './models/Link';

const zoom = d3.zoom();

export const CLASS_NAMES_BY_LINK_TYPE = {
  [LINK_TYPES.ENTRY_POINT]: 'entry-point',
  [LINK_TYPES.BASIC]: 'basic',
  [LINK_TYPES.ITERATIVE]: 'iterative',
  [LINK_TYPES.ASYNC_ACTION]: 'async-action',
  [LINK_TYPES.ITERATIVE_ASYNC_ACTION]: 'iterative-async-action',
};

export class ForceGraph {
  constructor(containerElem, svgElem, graph) {
    this.containerElem = containerElem;
    this.svgElem = svgElem;
    this.container = d3.select(this.containerElem);
    this.svg = d3.select(this.svgElem);

    this.simulation = d3.forceSimulation()
      .force('link', d3.forceLink().id(d => d.id).distance(65))
      .force('charge', d3.forceManyBody().strength(-75))
      .force('center', d3.forceCenter());

    if (graph) {
      this.setGraph(graph);
    }
  }

  setGraph = (graph, ranMap = new Set()) => {
    this.svg.selectAll('*').remove();

    // Deep copy the data since d3 mutates it; using lodash not deep-copy library to preserve prototypes
    const links = graph.links.map(link => cloneDeep(link));
    const nodes = graph.nodes.map(node => cloneDeep(node));

    this.initLinks(links);
    this.initNodes(nodes, ranMap);

    this.svg.call(zoom
      .scaleExtent([1 / 4, 4])
      .on('zoom', () => {
        const e = d3.event.transform;

        this.svg.selectAll('g').attr('transform', e);
        this.simulationTicked();
      }));

    this.startSimulation(links, nodes);
  };

  initLinks = (links) => {
    this.links = this.svg.append('g')
      .attr('class', 'links')
      .selectAll('line')
      .data(links)
      .enter().append('line')
      .attr('class', data => `${CLASS_NAMES_BY_LINK_TYPE[data.linkType]} ${data.isParent ? 'parent' : ''}`);
  };

  initNodes = (nodes, ranMap = new Set()) => {
    this.nodes = this.svg.append('g')
      .attr('class', 'nodes')
      .selectAll('.node')
      .data(nodes)
      .enter()
      .append('g')
      .attr('class', data => classNames('node', {
        'entry-point': data.isEntryPoint,
        'did-run': ranMap.has(data.id),
      }))
      .attr('id', data => data.id)
      .call(d3.drag()
        .on('start', this.nodeDragStarted)
        .on('drag', this.nodeDragged)
        .on('end', this.nodeDragEnded));

    this.nodes.append('circle').attr('r', 24);

    this.nodes.append('text')
      .text(data => data.id.slice(0, 2))
      .attr('dx', '-6px')
      .attr('dy', '-14px');

    this.nodes.append('text')
      .text(data => data.id.slice(2, 5))
      .attr('dx', '-8px')
      .attr('dy', '-4px');

    this.nodes.append('text')
      .text(data => data.id.slice(5, 9))
      .attr('dx', '-10px')
      .attr('dy', '6px');

    this.nodes.append('text')
      .text(data => data.id.slice(9))
      .attr('dx', '-11px')
      .attr('dy', '16px');

    this.nodes.append('title')
      .text(data => `${data.id}: ${data.description}`);
  };

  updateNodeStyles = ranMap => {
    d3.selectAll('.node')
      .attr('class', data => classNames('node', {
        'entry-point': data.isEntryPoint,
        'did-run': ranMap.has(data.id),
      }));
  };

  startSimulation = (links, nodes) => {
    this.simulation
      .nodes(nodes)
      .on('tick', this.simulationTicked);

    this.simulation
      .force('link')
      .links(links);

    const [width, height] = [this.containerElem.clientWidth, this.containerElem.clientHeight];

    this.svg
      .call(zoom.transform, d3.zoomIdentity.scale(1 / 4))
      .call(zoom.translateTo, width / 2, height / 2);

    // Fix rendering issues; first one is to avoid flash, second is to fix after it screws up the layoutgit
    setTimeout(() => {
      this.svg
        .call(zoom.transform, d3.zoomIdentity.scale(1 / 4))
        .call(zoom.translateTo, width / 2, height / 2);
    }, 0);
  };

  selectNode = (id) => {
    d3.selectAll('.node')
      .attr('selected', null);

    const node = d3.select(`.node#${id}`);

    node.attr('selected', 'true');

    const [x, y] = node.attr('transform').match(/[0-9.-]+/g).map(x => parseFloat(x));

    const t = d3.transition()
      .duration(250)
      .ease(d3.easeSinInOut);

    this.svg
      .transition(t)
      .call(zoom.translateTo, x, y);
  };

  nodeDragStarted = (data) => {
    if (!d3.event.active) {this.simulation.alphaTarget(0.3).restart();}
    data.fx = data.x;
    data.fy = data.y;
  };

  nodeDragged = (data) => {
    data.fx = d3.event.x;
    data.fy = d3.event.y;
  };

  nodeDragEnded = (data) => {
    if (!d3.event.active) {this.simulation.alphaTarget(0);}
    data.fx = null;
    data.fy = null;
  };

  simulationTicked = () => {
    this.links
      .attr('x1', function(data) { return data.source.x; })
      .attr('y1', function(data) { return data.source.y; })
      .attr('x2', function(data) { return data.target.x; })
      .attr('y2', function(data) { return data.target.y; });

    this.nodes
      .attr('transform', function(data) { return 'translate(' + data.x + ',' + data.y + ')'; });
  };

  onResize = (width, height) => {
    this.simulation.stop();
    this.simulation.force('center', d3.forceCenter(width / 2, height / 2));
    this.simulation.restart();
  };
}
