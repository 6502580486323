import React, {Component} from 'react';
import moment from 'moment';

//Components
import events from '../../../lib/events.js';
import PageHeader from '../../components/pageHeader.jsx';
import _ from 'lodash';
import POSReportFilter from '../../components/posReportFilter.jsx';
import SummaryStatics from './summaryStatics.jsx';
import AccountSummaryStatics from './accountSummaryStatics.jsx';
import SystemSummaryStatics from './systemSummaryStatics.jsx';
import EligibleProducts from './eligibleProducts.jsx';
import requirePermissions from '../../helpers/requirePermissions';

// API
import {connectToPerformanceFile, performanceKeys} from 'helpers/performanceFile';

class PageTruBlueDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loadedReports: [],
      initalStart: moment().add(-1, 'month'),
      initalEnd: moment(),
      filters: {
        startDate: moment().add(-1, 'month'),
        endDate: moment(),
        platinumStatus: [],
        dsm: [],
        rm: [],
        states: [],
      },
      refs: [],
    };
  }

  componentDidMount() {
    this.filters = null;
    this.listener = events.subscribe('trublue-block-loaded', this.loadNextReport);
    this.loadFirstReport();
  };

  componentWillUnmount() {
    if (this.listener) {
      this.listener.remove();
    }
  };

  reports = {};

  loadFirstReport = () => {
    this.loadedReports = [];

    Object.values(this.reports)
      .map(x => x.getUnderlying())
      .filter(x => !!x && !!x.setLoading)
      .forEach(rep => {
        rep.setLoading();
      });

    const firstReportKey = Object.keys(this.reports)[0];

    if (!firstReportKey || !this.reports[firstReportKey]) {
      return;
    }

    this.reports[firstReportKey].getUnderlying().loadReport(this.state.filters);
  };

  loadNextReport = reportLoaded => {// Will be called when the first report finish to load
    this.loadedReports.push(reportLoaded);
    const keys = Object.keys(this.reports).filter(key => this.loadedReports.indexOf(key) < 0);

    if (!keys || !keys.length) {
      return;
    }

    const nextReport = this.reports[keys[0]].getUnderlying();

    if (nextReport) {
      nextReport.loadReport(this.state.filters);
    }
  };

  updateReport = filters => {
    this.setState({
      filters: filters,
    }, () => {
      this.loadFirstReport();
    });
  };


  render() {
    return (
      <div>
        <PageHeader title='Tru•Blue Dashboard' icon='fa fa-tachometer' breadcrumbs={[]}/>
        <POSReportFilter showPlatinumStatus={true}
                         start={this.state.initalStart}
                         onUpdate={this.updateReport}
                         buttonText={'Update'}
                         showCustomerLoyalty={true}/>
        <div className='row' id='page-dashboard'>
          <div className='col-md-6 col-lg-4'>
            <SummaryStatics ref={r => this.reports.summaryStatics = r} refName='summaryStatics'/>
            <AccountSummaryStatics ref={r => this.reports.accountSummaryStatics = r} refName='accountSummaryStatics'/>
          </div>
          <div className='col-md-6 col-lg-4'>
            <SystemSummaryStatics ref={r => this.reports.systemSummaryStatics = r} refName='systemSummaryStatics'/>
          </div>
          <div className='col-md-6 col-lg-4'>
            <EligibleProducts ref={r => this.reports.eligilbleProducts = r} refName='eligilbleProducts'/>
          </div>
        </div>
      </div>
    );
  }
}

let PageTruBlueDashboardWrapper = requirePermissions('canViewTruBlueDashboard')(PageTruBlueDashboard);

export default connectToPerformanceFile(performanceKeys.dashboard, PageTruBlueDashboardWrapper);

