import PropTypes from 'prop-types';

import React from 'react';

import SimpleSpinner from './simpleSpinner.jsx';

// Row object
class LoadingBtn extends React.Component {
  static defaultProps = {
    disabled: false,
    loading: false,
    className: '',
  };

  static propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    onClick: PropTypes.func,
    type: PropTypes.any,
  };

  render() {
    let loading;

    if (this.props.loading) {
      loading =
        <div style={{position: 'relative', top: '-4px'}} className='pull-left'>
          <SimpleSpinner scale={0.4} color='#FFF' />
        </div>
      ;
    }

    let defaultClass = 'btn ';

    if (this.props.disabled) {
      defaultClass += 'disabled ';
    }

    return (
      <button className={defaultClass + this.props.className} type={this.props.type} onClick={this.props.onClick} disabled={this.props.disabled}>
        {loading}
        {this.props.children}
      </button>
    );
  }
}

export default LoadingBtn;
