import {clearToken, getToken} from 'helpers/tokens';
import md5 from 'blueimp-md5';
import PropTypes from 'prop-types';
import React from 'react';
import Spinner from '../spinner.jsx';

class Header extends React.Component {
  state = {
    user: null,
    showMenu: false,
  };

  componentWillMount() {
    let token = getToken();

    if (token) {
      this.setState({
        user: {
          email: token.profile.email,
          name: token.profile.name,
        },
      });
    }
  }

  logout = () => {
    clearToken();
    window.location.href = window.idp + '/connect/endsession';
  };

  changeMenuState = () => {
    this.setState({showMenu: !this.state.showMenu});
  };

  render() {
    let emailHash = this.state.user && md5(this.state.user.email.toLowerCase().replace(' ', ''));

    return (
      <div className='container-fluid header'>
        <div className='row vertical-align'>
          <div className='col-md-6 left-panel'>
            <img src="https://ssoadmin-preprod.mybioguard.com/Content/logos/dealers_ALEX_admin.png"/>
          </div>
          <div className='col-md-6 right-panel'>
            <span>{this.state.user ?
              <img className='img-circle'
                           src={`//www.gravatar.com/avatar/${emailHash}.jpg?s=30&d=identicon`}/> :
                      null}</span>
            <span className='user-name' onClick={this.changeMenuState}>{this.state.user ? this.state.user.name : <Spinner/>}</span>
            <div className='popover-menu'>
              <i className='fa fa-chevron-down' onClick={this.changeMenuState}/>
              <div className={'menu-content-container' + (this.state.showMenu ? ' open' : '')}>
                <div className='arrow-up'/>
                <span className='item' onClick={this.logout}><i className='fa fa-sign-out'/>Logout</span>
              </div>
            </div>
            <div id='app-select' className='app-select' />
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
