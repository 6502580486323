import PropTypes from 'prop-types';

import React from 'react';

import Spinner from './spinner.jsx';

let Loader = ({
  loaded,
  children,
}) => {
  return loaded ? children : <Spinner />;
};

Loader.propTypes = {
  loaded: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default Loader;
