import React from 'react';
import {injectIntl} from 'react-intl';

const Announcements = ({data, intl}) => {
  const {locale} = intl;
  if (data) {
    return (
      <div>
        {
          locale === 'en-US' && data.Announcements[0] ?
          <div className='container'><img className='img-announcement' src={data.Announcements[0]} alt='Announcement Image' /></div>
          : locale === 'fr-CA' && data.Announcements[1] ?
          <div className='container'><img className='img-announcement' src={data.Announcements[1]} alt='Announcement Image' /></div>
          : null
        } 
      </div>
    );
  }
  else {
    return null;
  }
}

export default injectIntl(Announcements);
