import events from '../lib/events.js';

import {getToken} from '../ui/helpers/tokens.jsx';

export function requireAuth(nextState, replace, callback) {
  let token = getToken();

  if (token) {
    console.log('Token valid.');
    callback();
  } else {
    console.log(JSON.stringify(token));
    console.log('Invalid token redirecting.');
    replace('/signIn');
    events.publish('warn.user', {message: 'Sign in required'});
    callback();
  }
}


