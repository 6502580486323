import PropTypes from 'prop-types';
import React from 'react';
import healthApi from '../../api/health.jsx';
import PageHeader from '../components/pageHeader.jsx';
import ObjectTable from '../components/objectTable.jsx';
import Collapsible from '../components/collapsible.jsx';
import DatabaseStatistics from '../components/health/databaseStatistics.jsx';
import {browserHistory} from 'react-router';
import ClusterSummary from '../components/health/clusterSummary.jsx';

// Page object
class PageHealth extends React.Component {
  static contextTypes = {
    canViewHealth: PropTypes.bool.isRequired,
  };

  state = {
    clusterHealth: null,
    clusterStats: null,
    indexStats: null,
  };

  componentWillMount() {
    if (!this.context.canViewHealth) {
      browserHistory.push('/');
    }
  }

  componentDidMount() {
    healthApi.getClusterHealth().then((clusterHealth) => {
      this.setState({clusterHealth});
    });
    healthApi.getClusterStats().then((clusterStats) => {
      this.setState({clusterStats});
    });
    healthApi.getIndexStats().then((indexStats) => {
      this.setState({indexStats});
    });
  }

  renderIndexStatistics = (indexStats) => {
    if (!indexStats) {
      return <div>Not Loaded</div>;
    }

    let indices = indexStats.indices;

    return (
      <div className='row'>
        {Object.keys(indices).map((indexName, i, indicesArray) => {
          let index = indices[indexName];

          return (
            <div key={indexName} className={'col-sm-' + (indicesArray.length > 4 ? 12 : 12 / indicesArray.length)}>
              <h3>{indexName}</h3>
              <table className='table table-striped'>
                <tbody>
                  <tr>
                    <td>
                      <button type='button' className='btn btn-success m-r-sm'>{index.total.docs.count}</button>
                      Records
                    </td>
                    <td/>
                  </tr>
                  <tr>
                    <td>
                      <button type='button' className='btn btn-success m-r-sm'>{index.total.docs.deleted}</button>
                      Records Deleted
                    </td>
                    <td/>
                  </tr>
                  <tr>
                    <td>
                      <button type='button' className='btn btn-success m-r-sm'>{index.total.store.size_in_bytes}</button>
                      Size
                    </td>
                    <td/>
                  </tr>
                  <tr>
                    <td>
                      <button type='button' className='btn btn-success m-r-sm'>{index.total.search.query_total}</button>
                      Search Queries
                    </td>
                    <td/>
                  </tr>
                </tbody>
              </table>
            </div>
          );
        })}
      </div>
    );
  };

  renderMemoryUsage = (clusterStats) => {
    let used = clusterStats.nodes.jvm.mem.heap_used_in_bytes;
    let total = clusterStats.nodes.jvm.mem.heap_max_in_bytes;
    let usedHuman = clusterStats.nodes.jvm.mem.heap_used.toUpperCase();
    let totalHuman = clusterStats.nodes.jvm.mem.heap_max.toUpperCase();

    return this.renderPercentage(used, total, usedHuman, totalHuman);
  };

  renderPercentage = (used, total, usedHuman, totalHuman) => {
    let percent = Math.round(used / total * 100 * 100) / 100;

    return (
      <span>
        {percent}%<br />
        <small>({usedHuman} / {totalHuman})</small>
      </span>
    );
  };

  renderSummary = () => {
    let clusterHealth = this.state.clusterHealth;
    let clusterStats = this.state.clusterStats;

    if (!clusterHealth || !clusterStats) {
      return <div />;
    }
    return (
      <div className='summary'>
        <div className='row'>
          <div className='col-sm-6'>
            <strong>Cluster Name:</strong>
            {clusterHealth.cluster_name}
          </div>
          <div className={'col-sm-6 cluster-status ' + clusterHealth.status}>
            <strong>Status:</strong>
            {clusterHealth.status}
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-6'>
            <strong>Shards (Total: {clusterStats.indices.shards.total})</strong>
            Primaries: {clusterStats.indices.shards.primaries}<br />
            Replication: {clusterStats.indices.shards.replication}
          </div>
          <div className='col-sm-6'>
            <strong>Nodes (Total: {clusterStats.nodes.count.total})</strong>
            <div className='row'>
              <div className='col-sm-offset-2 col-sm-4'>
                Master Only: {clusterStats.nodes.count.master_only}
              </div>
              <div className='col-sm-4'>
                Data Only: {clusterStats.nodes.count.data_only}
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-offset-2 col-sm-4'>
                Master Data: {clusterStats.nodes.count.master_data}
              </div>
              <div className='col-sm-4'>
                Client: {clusterStats.nodes.count.client}
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-4'>
            <strong>CPU Usage:</strong>
            {clusterStats.nodes.process.cpu.percent}%
          </div>
          <div className='col-sm-4'>
            <strong>Memory Usage:</strong>
            {this.renderMemoryUsage(clusterStats)}
          </div>
          <div className='col-sm-4'>
            <strong>Disk Usage:</strong>
            <div className='row'>
              <div className='col-sm-offset-2 col-sm-4'>
                Total: {clusterStats.nodes.fs.total.toUpperCase()}
              </div>
              <div className='col-sm-4'>
                Available: {clusterStats.nodes.fs.available.toUpperCase()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    let clusterHealth = this.state.clusterHealth || {};
    let clusterStats = this.state.clusterStats || {};

    return (
      <div id='page-health'>
        <PageHeader title='Health &amp; Statistics' icon='fa fa-line-chart' breadcrumbs={[{title: 'Health', path: '/health'}]}/>
        <p>{clusterStats.timestamp ? <small>(Last Updated: {clusterStats.timestamp})</small> : null}</p>

        {/*ALEX ADMIN 2019 update [AADMIN-22] - Move Cluster Summary to health page*/}
        <ClusterSummary/>

        <h2>Summary</h2>
        {this.renderSummary()}

        <DatabaseStatistics />

        <h2>Individual Index Statistics</h2>
        {this.renderIndexStatistics(this.state.indexStats)}

        <h2>Detailed Cluster Health</h2>
        <Collapsible initiallyCollapsed={true}>
          <ObjectTable obj={clusterHealth} />
        </Collapsible>

        <h2>Detailed Cluster Stats</h2>
        <Collapsible initiallyCollapsed={true}>
          <ObjectTable obj={clusterStats} />
        </Collapsible>

        <h2>Detailed Index Stats</h2>
        <Collapsible initiallyCollapsed={true}>
          <ObjectTable obj={this.state.indexStats} />
        </Collapsible>
      </div>
    );
  }
}

export default PageHealth;
