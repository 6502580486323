import React from 'react';
import PropTypes from 'prop-types';
import TruBlueApi from 'api/truBlueDashboard';
import Loader from 'components/loader';
import events from 'lib/events';
import {connectToToasts, TOAST_STATES} from 'components/toasts';

class EligibleProducts extends React.Component {
  static propTypes = {
    createToast: PropTypes.func.isRequired,
    refName: PropTypes.string.isRequired,
  };

  state = {
    loaded: true,
  };

  setLoading = () => {
    this.setState({loaded: false});
  };

  loadReport = (filters) => {
    this.setLoading();
    TruBlueApi
      .getProducts(filters.startDate, filters.endDate, filters.platinumStatus, filters.dsm, filters.rm, filters.states)
      .then(data => {
        this.setState({loaded: true, data});
        events.publish('trublue-block-loaded', this.props.refName);
      })
      .catch(e => {
        console.log(e);
        this.props.createToast({
          id: 'trublue-dashboard-error',
          state: TOAST_STATES.ERROR,
          message: 'Failed to load data for report',
          ttl: 4000,
        });
      });
  };

  render() {
    const {data} = this.state;

    let rows;

    if(data) {
      rows = data.map((el, idx) => {
        let brand = 'BioGuard';

        if (el.ProGuard) {
          brand = 'ProGuard';
        } else if (el.SpaGuard) {
          brand = 'SpaGuard';
        }

        return <tr key={idx}>
          <td>
            <h5>{el.Product}</h5>
          </td>
          <td>
            {brand}
          </td>
          <td>
            {el.Category}
          </td>
          <td>
            {el.ActiveSytemsTruBlue}
          </td>
        </tr>;
      });
    }

    return (
      <div>
        <div className='widget style1 navy-bg'>
          <div className='row'>
            <div className='col-xs-4'>
              <i className='fa fa-wrench fa-5x'/>
            </div>
            <div className='col-xs-8 text-right'>
              <h2 className='font-bold'>Eligible Products
              </h2>
            </div>
          </div>
        </div>
        <Loader loaded={this.state.loaded}>
          <div  className='ibox-content-e-margins'>
            <table className='table table-hover no-margins'>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Brand</th>
                  <th>Category</th>
                  <th>Active Systems</th>
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </table>
          </div>
        </Loader>
      </div>
    );
  }
}

export default connectToToasts(EligibleProducts);
