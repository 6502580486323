import PropTypes from 'prop-types';

import React from 'react';

import _ from 'lodash';

import LoadingBtn from './loadingBtn.jsx';

class TestSidebar extends React.Component {
  static propTypes = {
    addTest: PropTypes.func.isRequired,
    editTest: PropTypes.func.isRequired,
    outputs: PropTypes.array.isRequired,
    runAllTests: PropTypes.func.isRequired,
    runningAllTests: PropTypes.bool.isRequired,
    tests: PropTypes.array.isRequired,
  };

  shouldComponentUpdate(nextProps) {
    if (!_.isEqual(this.props.outputs, nextProps.outputs) || !_.isEqual(this.props.tests, nextProps.tests) ||
        nextProps.runningAllTests !== this.props.runningAllTests) {
      return true;
    }
    return false;
  }

  render() {
    let tests = this.props.tests.map(test => {
      let testStatus = 'test-unknown';
      let outputs = this.props.outputs.filter(x => x.testID === test.id);

      if (outputs.length > 0) {
        let output = outputs[0];

        if (!output.expectedWaterClarityMatch || !output.expectedSwimmerComfortMatch || !output.expectedSurfaceEquipmentProtectionMatch) {
          testStatus = 'test-error';
        } else {
          testStatus = 'test-success';
        }
      }
      return <div className={'test-summary ' + testStatus} onClick={this.props.editTest(test.id)} key={'test-summary' + test.id}>
        <h4><i className={testStatus === 'test-error' ? 'fa fa-times' : testStatus === 'test-success' ? 'fa fa-check' : ''} />
          &nbsp;{test.description ? test.description : '(no test description)'}
        </h4>
      </div>;
    });

    return <div className='test-sidebar'>
      <h2>Tests</h2>
      <LoadingBtn className='btn btn-primary' onClick={this.props.runAllTests} loading={this.props.runningAllTests}>Run All Tests</LoadingBtn>
      <button className='btn btn-default' onClick={this.props.addTest}>Add Test</button>

      {tests}
    </div>;
  }
}

export default TestSidebar;
