import ForceGraph from './ForceGraph';
import PathLogger from './PathLogger';
import PlayerControls from './PlayerControls';
import PropTypes from 'prop-types';
import React from 'react';
import {getRule, getForceGraph} from 'api/testing';
class Trace extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
  };

  state = {
    currentStep: 0,
    rulesGraph: undefined,
    rulesMap: undefined,
    runResults: undefined,
  };

  componentWillMount() {
    const gfg = getForceGraph();
    const gr = getRule(this.props.id);

    Promise.all([gr, gfg])
    .then(results => {
      this.setState({
        rulesGraph: results[1],
        rulesMap: results[1].nodes,
        runResults: results[0].output,
      });
    })
    .catch(err => console.log(err));
  }

  update = changes => this.setState(changes);

  setStep = step => this.setState({currentStep: step});

  render() {
    let fg = null;
    let pc = null;
    let pl = null;
    let {runResults, rulesGraph} = this.state;

    if(runResults && rulesGraph) {
      fg = <ForceGraph update={this.update} {...this.state} />;
      pc = <PlayerControls connectedState={this.state} setStep={this.setStep} />;
      pl = <PathLogger update={this.update} {...this.state} />;
    }

    return <div className='container-fluid trace-container'>
      <div className='row'>
        <div className='col-md-12'>
          {fg}
          {pc}
        </div>
      </div>
      <div className='row'>
        <div className='col-md-12 path-logger-container'>
          {pl}
        </div>
      </div>
    </div>;
  }
}

export default Trace;
