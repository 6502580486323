import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export class TabContainer extends React.Component {
  static childContextTypes = {
    registerTab: PropTypes.func.isRequired,
    unregisterTab: PropTypes.func.isRequired,
    isIBoxTab: PropTypes.bool.isRequired,
    setDisabled: PropTypes.func.isRequired,
  };

  static propTypes = {
    children: PropTypes.any,
    ibox: PropTypes.bool,
    right: PropTypes.any,
    style: PropTypes.object,
  };

  state = {
    tabs: [],
    activeKey: undefined,
    disabled: {},
  };

  getChildContext() {
    return {
      registerTab: this.registerTab,
      unregisterTab: this.unregisterTab,
      isIBoxTab: this.props.ibox,
      setDisabled: this.setDisabled,
    };
  }

  lock = false; // Prevent multiple tabs on first load from setting initial tab incorrectly due to batching of updates in react

  setDisabled = (key, d) => {
    const disabled = this.state.disabled;

    disabled[key] = d;

    this.setState({disabled});
  };

  registerTab = (ref, key, label) => {
    let {tabs, activeKey, disabled} = this.state;

    tabs.push({ref, key, label});

    disabled[key] = ref.props.disabled;

    if (!this.lock) {
      this.lock = true;
      activeKey = activeKey || key;

      this.setState({tabs, activeKey, disabled}, this.updateVisible);
      return;
    }

    this.setState({tabs, disabled});
  };

  unregisterTab = key => {
    const tabs = this.state.tabs.filter(x => x.key !== key);
    let activeKey = this.state.activeKey;

    if (activeKey === key) {
      activeKey = this.state.tabs.length ? this.state.tabs[0].key : undefined;
    }

    this.setState({tabs, activeKey});
  };

  updateVisible = () => {
    const {tabs, activeKey} = this.state;

    for (let tab of tabs) {
      if (!tab || !tab.ref) {
        return;
      }

      if (tab.key === activeKey) {
        tab.ref.show();
      } else {
        tab.ref.hide();
      }
    }
  };

  getActive = () => this.state.activeKey;

  select = key => () => {
    if (this.state.tabs.find(x => x.key === key).ref.props.disabled) {
      return;
    }

    this.setState({activeKey: key},
      () => this.updateVisible());
  };

  render() {
    return <div className='tabs-container' style={this.props.style}>
      <ul className='nav nav-tabs'>
        {this.state.tabs.map(tab =>
          <li className={classNames({
            'active': tab.key === this.state.activeKey,
            'disabled': this.state.disabled[tab.key],
          })}
              key={tab.key}
              onClick={this.select(tab.key)}><a>{tab.label}</a></li>)}

        {this.props.right ? <div style={{float: 'right'}}>{this.props.right}</div> : null}
      </ul>

      <div className='tab-content'>
        {this.props.children}
      </div>
    </div>;
  }
}

export class TabItem extends React.Component {
  static propTypes = {
    tabKey: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    children: PropTypes.any.isRequired,
    disabled: PropTypes.bool,
  };

  static contextTypes = {
    registerTab: PropTypes.func.isRequired,
    unregisterTab: PropTypes.func.isRequired,
    isIBoxTab: PropTypes.bool.isRequired,
    setDisabled: PropTypes.func.isRequired,
  };

  state = {
    visible: false,
  };

  componentDidMount() {
    this.context.registerTab(this, this.props.tabKey, this.props.label);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.disabled !== this.props.disabled) {
      this.context.setDisabled(nextProps.tabKey, nextProps.disabled);
    }
  }

  componentWillUnmount() {
    this.context.unregisterTab(this.props.tabKey);
  }

  show = () => {
    this.setState({visible: true});
  };

  hide = () => {
    this.setState({visible: false});
  };

  render() {
    if (!this.state.visible) {
      return null;
    }

    return <div className='tab-pane active'>
      <div className='panel-body' style={this.context.isIBoxTab ? {padding: 0, background: 'initial'} : {}}>
        {this.props.children}
      </div>
    </div>;
  }
}
