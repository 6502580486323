import React from 'react';
import {FormattedMessage} from 'react-intl';
import moment from 'moment';

const Congratulations = ({data}) =>
  <div className="container congratulations">
    <div className="row">
      <div className="col">
        <h2 className="header"><FormattedMessage id='report-viewer.perfect-water.title' /></h2>
        <div className="paraRow">
          <FormattedMessage id='report-viewer.perfect-water.body' />
        </div>
        <div className="nextDateRow">
          <FormattedMessage id='report-viewer.perfect-water.next-analysis' values={{date: moment(data.nextAnalysis).format('l')}} />
        </div>
      </div>
    </div>
  </div>;

export default Congratulations;
