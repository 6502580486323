import PropTypes from 'prop-types';

import React from 'react';

import _ from 'lodash';

import {ParameterCutoffs} from '../../models/poolScoreParameters.jsx';

import {categoryColours} from '../page/poolScore/scoreData.jsx';


import EditableTextField from './form/editableTextField.jsx';

class ParameterInput extends React.Component {
  static defaultProps = {
    editMode: true,
  };

  static propTypes = {
    category: PropTypes.string.isRequired,
    data: PropTypes.instanceOf(ParameterCutoffs).isRequired,
    dataKey: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    editMode: PropTypes.bool,
    scoreChanged: PropTypes.func.isRequired,
  };

  shouldComponentUpdate(nextProps) {
    if (!_.isEqual(this.props.data, nextProps.data)) {
      return true;
    }
    return false;
  }

  render() {
    let data = this.props.data;
    let key = this.props.dataKey;
    let idealHigh;
    let idealLow;
    let ideal = '';

    for (let i = 0; i < data.highCutoffs.length; ++i) {
      if (data.highCutoffs[i] || data.highCutoffs[i] === 0) {
        idealHigh = data.highCutoffs[i];
        break;
      }
    }

    for (let j = 0; j < data.lowCutoffs.length; ++j) {
      if (data.lowCutoffs[j] || data.lowCutoffs[j] === 0) {
        idealLow = data.lowCutoffs[j];
        break;
      }
    }

    if (idealLow !== undefined && idealHigh !== undefined) {
      ideal = idealLow + ' - ' + idealHigh;
    } else if (idealLow === undefined && idealHigh !== undefined) {
      ideal = '<' + idealHigh;
    } else if (idealLow !== undefined && idealHigh === undefined) {
      ideal = '>' + idealLow;
    }

    return <tr className='parameter-input'>
      <td className='score-category' style={{backgroundColor: categoryColours[this.props.category]}}>{this.props.category}</td>
      <td style={{width: 200}}>{this.props.label || key}</td>
      <td className='data-cell'>
        <EditableTextField value={data.lowCutoffs[5]} editMode={this.props.editMode} dataKey={key + '|lowCutoffs|5'} key={key + '|lowCutoffs|5'}
          type='number' onChange={this.props.scoreChanged} />
      </td>
      <td className='data-cell'>
        <EditableTextField value={data.lowCutoffs[4]} editMode={this.props.editMode} dataKey={key + '|lowCutoffs|4'} key={key + '|lowCutoffs|4'}
          type='number' onChange={this.props.scoreChanged} />
      </td>
      <td className='data-cell'>
        <EditableTextField value={data.lowCutoffs[3]} editMode={this.props.editMode} dataKey={key + '|lowCutoffs|3'} key={key + '|lowCutoffs|3'}
          type='number' onChange={this.props.scoreChanged} />
      </td>
      <td className='data-cell'>
        <EditableTextField value={data.lowCutoffs[2]} editMode={this.props.editMode} dataKey={key + '|lowCutoffs|2'} key={key + '|lowCutoffs|2'}
          type='number' onChange={this.props.scoreChanged} />
      </td>
      <td className='data-cell'>
        <EditableTextField value={data.lowCutoffs[1]} editMode={this.props.editMode} dataKey={key + '|lowCutoffs|1'} key={key + '|lowCutoffs|1'}
          type='number' onChange={this.props.scoreChanged} />
      </td>

      <td style={{minWidth: 75}}>{ideal}</td>

      <td className='data-cell'>
        <EditableTextField value={data.highCutoffs[1]} editMode={this.props.editMode} dataKey={key + '|highCutoffs|1'} key={key + '|highCutoffs|1'}
          type='number' onChange={this.props.scoreChanged} />
      </td>
      <td className='data-cell'>
        <EditableTextField value={data.highCutoffs[2]} editMode={this.props.editMode} dataKey={key + '|highCutoffs|2'} key={key + '|highCutoffs|2'}
          type='number' onChange={this.props.scoreChanged} />
      </td>
      <td className='data-cell'>
        <EditableTextField value={data.highCutoffs[3]} editMode={this.props.editMode}
        dataKey={key + '|highCutoffs|3'} key={key + '|highCutoffs|3'}
          type='number' onChange={this.props.scoreChanged} />
      </td>
      <td className='data-cell'>
        <EditableTextField value={data.highCutoffs[4]} editMode={this.props.editMode} dataKey={key + '|highCutoffs|4'} key={key + '|highCutoffs|4'}
          type='number' onChange={this.props.scoreChanged} />
      </td>
      <td className='data-cell'>
        <EditableTextField value={data.highCutoffs[5]} editMode={this.props.editMode} dataKey={key + '|highCutoffs|5'} key={key + '|highCutoffs|5'}
          type='number' onChange={this.props.scoreChanged} />
      </td>
    </tr>;
  }
}

export default ParameterInput;
