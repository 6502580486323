import PropTypes from 'prop-types';
import React from 'react';

import EditableSelectField from './editableSelectField.jsx';

class LayoutSelect extends React.Component {
  static defaultProps = {
    condition: true,
    keys: [],
    label: '',
  };

  static propTypes = {
    condition: PropTypes.bool,
    dataChanged: PropTypes.func.isRequired,
    dataKey: PropTypes.string.isRequired,
    editMode: PropTypes.bool.isRequired,
    keys: PropTypes.array,
    label: PropTypes.string,
    options: PropTypes.array,
    optionsChange: PropTypes.bool,
    value: PropTypes.any,
    required: PropTypes.bool,
  };

  render() {
    let output = null;

    if (this.props.condition) {
      output =
        <div className='form-group'>
          <label>{this.props.label}{this.props.required && this.props.editMode ? <span className='error' style={{marginLeft: 2}}>*</span> : null}</label>
          <EditableSelectField
            onChange={this.props.dataChanged}
            editMode={this.props.editMode}
            value={this.props.value ? this.props.value : ''}
            dataKey={this.props.dataKey}
            options={this.props.options}
            optionsChange={this.props.optionsChange}
            data-event-category={this.props['data-event-category']}
            data-event-action={this.props['data-event-action']}
            keys={this.props.keys} />
        </div>
      ;
    }
    return output;
  }
}

export default LayoutSelect;
