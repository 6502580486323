import React from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import Highlight from '../highlight/highlight.jsx';
import KeyParameters from '../key-parameters/key-parameters.jsx';
import KeyParametersRanges from '../key-parameters-ranges/key-parameters-ranges.jsx';
import StarRating from '../star-rating/star-rating.jsx';
import SectionHelper from '../../helpers/section-helper.jsx';
import {getImagePath} from '../../helpers/image-helper.jsx';

const WaterQualityScore = ({data, intl}) =>
  <div className="container water-quality-score">
    {SectionHelper.showPoolScores(data.ReportType) && data.ShowPoolScores &&
    (data.SwimmerComfortScore !== null || data.WaterClarityScore !== null || data.SurfaceEquipmentProtectionScore !== null) &&
    <div className="row title-row">
      <div className="col">
        <h2><FormattedMessage id='report-viewer.wqs.title'/></h2>
      </div>
    </div>}

    {SectionHelper.showPoolScores(data.ReportType) && data.ShowPoolScores &&
    (data.SwimmerComfortScore !== null || data.WaterClarityScore !== null || data.SurfaceEquipmentProtectionScore !== null) &&
    <div className="row scores-row">

      {
        data.SwimmerComfortScore !== undefined && data.SwimmerComfortScore !== null &&
        <div className="col-4">
          <Highlight imageUrl={getImagePath({locale: intl.locale, folder: 'PoolScores', file: 'comfort.png'})}
                     title={intl.formatMessage({id: 'report-viewer.wqs.comfort'})}>
            <StarRating rating={data.SwimmerComfortScore} of="5"/>
          </Highlight>
        </div>
      }

      {
        data.WaterClarityScore !== undefined && data.WaterClarityScore !== null &&
        <div className="col-4">
          <Highlight imageUrl={getImagePath({locale: intl.locale, folder: 'PoolScores', file: 'clarity.png'})}
                     title={intl.formatMessage({id: 'report-viewer.wqs.clarity'})}>
            <StarRating rating={data.WaterClarityScore} of="5"/>
          </Highlight>
        </div>
      }

      {
        data.SurfaceEquipmentProtectionScore !== undefined && data.SurfaceEquipmentProtectionScore !== null &&
        <div className="col-4">
          <Highlight last={true}
                     imageUrl={getImagePath({locale: intl.locale, folder: 'PoolScores', file: 'protection.png'})}
                     title={intl.formatMessage({id: 'report-viewer.wqs.protection'})}>
            <StarRating rating={data.SurfaceEquipmentProtectionScore} of="5"/>
          </Highlight>
        </div>
      }

    </div>}

    <div className="row key-parameters-row">
      <div className="col">
        {data.ShowKeyParameterRangesSection ? <KeyParametersRanges data={data.keyParameters}/> : <KeyParameters data={data.keyParameters}/>}
      </div>
    </div>
  </div>;

export default injectIntl(WaterQualityScore);
