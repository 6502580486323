import React from 'react';

import healthApi from '../../../api/health.jsx';

import Spinner from '../spinner.jsx';

class ClusterSummary extends React.Component {
  state = {
    clusterHealth: null,
    clusterStats: null,
    indexStats: null,
  };

  componentDidMount() {
    healthApi.getClusterHealth().then((clusterHealth) => {
      this.setState({clusterHealth});
    });
    healthApi.getClusterStats().then((clusterStats) => {
      this.setState({clusterStats});
    });
    healthApi.getIndexStats().then((indexStats) => {
      this.setState({indexStats});
    });
  }

  toggle = (event) => {
    event.preventDefault();
    this.setState({collapsed: !this.state.collapsed});
  };

  render() {
    const {clusterHealth, clusterStats, indexStats} = this.state;

    if (!clusterHealth || !clusterStats || !indexStats) {
      return (
        <Spinner />
      );
    }

    let searchCount = Object.keys(indexStats.indices).map((indexName) => {
      let index = indexStats.indices[indexName];

      return index.total.search.query_total;
    }).reduce((previousValue, currentValue) => {
      return previousValue + currentValue;
    });

    let statusClass = 'red-bg';

    if (clusterHealth.status === 'yellow') {
      statusClass = 'yellow-bg';
    } else if (clusterHealth.status === 'green') {
      statusClass = 'navy-bg';
    }

    let memused = clusterStats.nodes.jvm.mem.heap_used_in_bytes;
    let memtotal = clusterStats.nodes.jvm.mem.heap_max_in_bytes;
    let mempercent = Math.round(memused / memtotal * 100 * 100) / 100;

    let diskfree = clusterStats.nodes.fs.free_in_bytes;
    let disktotal = clusterStats.nodes.fs.total_in_bytes;
    let diskpercent = 100 - Math.round(diskfree / disktotal * 100 * 100) / 100;

    return (
      <div className='row'>
        <div className='col-xs-12 col-lg-6' style={{padding: 0}}>
          <div className='col-xs-12'>
            <div className={'widget style1 ' + statusClass}>
              <div className='row'>
                <div className='col-xs-4'>
                  <i className='fa fa-search fa-5x'/>
                </div>
                <div className='col-xs-8 text-right'>
                  <span>{clusterHealth.cluster_name}</span>
                  <h2 className='font-bold'>{clusterHealth.status}</h2>
                </div>
              </div>
            </div>
          </div>
          <div className='col-xs-12'>
          <table className='table'>
            <tbody>
              <tr>
                <td>
                  <button type='button' className='btn btn-success m-r-sm'>{clusterStats.indices.shards.total}</button>
                    Shards
                </td>
                <td>
                  <button type='button' className='btn btn-success m-r-sm'>{clusterStats.nodes.count.total}</button>
                    Nodes
                </td>
              </tr>
              <tr>
                <td>
                  <button type='button' className='btn btn-info m-r-sm'>{clusterStats.indices.shards.replication}</button>
                    Replication
                </td>
                <td/>
              </tr>
            </tbody>
          </table>
        </div>
        </div>
        <div className='col-xs-12 col-lg-6' style={{padding: 0}}>
          <div className='col-xs-12'>
            <div className='widget style1 lazur-bg'>
              <div className='row'>
                <div className='col-xs-4'>
                  <i className='fa fa-search fa-5x'/>
                </div>
                <div className='col-xs-8 text-right'>
                  <span>Searches Performed</span>
                  <h2 className='font-bold'>{searchCount}</h2>
                </div>
              </div>
            </div>
          </div>
          <div className='col-xs-12'>
          <div className='ibox-content'>
            <div>
              <span>CPU</span>
              <small className='pull-right'>{clusterStats.nodes.process.cpu.percent + '%'}</small>
            </div>
            <div className='progress progress-small'>
              <div style={{width: clusterStats.nodes.process.cpu.percent + '%'}} className='progress-bar'/>
            </div>
            <div>
              <span>Memory</span>
              <small className='pull-right'>{clusterStats.nodes.jvm.mem.heap_used}/{clusterStats.nodes.jvm.mem.heap_max}</small>
            </div>
            <div className='progress progress-small'>
              <div style={{width: mempercent + '%'}} className='progress-bar'/>
            </div>
            <div>
              <span>Disk Usage</span>
              <small className='pull-right'>{clusterStats.nodes.fs.available}/{clusterStats.nodes.fs.total}</small>
            </div>
            <div className='progress progress-small'>
              <div style={{width: diskpercent + '%'}} className='progress-bar'/>
            </div>
          </div>
        </div>
        </div>
      </div>
    );
  }
}

export default ClusterSummary;
