import qwest from 'qwest';

import Dealer from '../../../Canopy/Javascript/models/dealer.jsx';

let DealerApi = {
  getDealers() {
    return qwest.get('/api/Dealer/Index')
      .then((xhr, response) => {
        if (response.success) {
          return response.data;
        }
        throw response;
      });
  },

  getDealer(id) {
    return qwest.get(`/api/Dealer/Index/${id}`)
      .then((xhr, response) => {
        if (response.success) {
          return Dealer.fromApiFormat(response.data);
        }
        throw response;
      });
  },

  createDealer(dealer) {
    let apiDealer = dealer.toApiFormat();

    return qwest.post('/api/Dealer/Index', apiDealer)
      .then((xhr, response) => {
        if (response.success) {
          return Dealer.fromApiFormat(response.data);
        }
        throw response;
      });
  },

  saveDealer(dealer) {
    let apiDealer = dealer.toApiFormat();

    return qwest.post(`/api/Dealer/Index/${dealer.id}`, apiDealer)
      .then((xhr, response) => {
        if (response.success) {
          return Dealer.fromApiFormat(response.data);
        }
        throw response;
      });
  },

  getExportCSV(token) {
    window.open(`/api/Dealer/ExportToCSV?token=${token}`);
  },

  import(data) {
    return qwest.post('/api/Dealer/Import', {
      csv: data,
    })
      .then((xhr, res) => res);
  },

};

export default DealerApi;
