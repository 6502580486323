import React from 'react';
import {findDOMNode} from 'react-dom';
import PropTypes from 'prop-types';
import qwest from 'qwest';
import printfileApi from '../../api/printfile.jsx';
import PageHeader from '../components/pageHeader.jsx';
import IBox from '../components/iBox.jsx';
import {connectToToasts, TOAST_STATES} from '../components/toasts.jsx';
import {browserHistory} from 'react-router';


function uploadFile(before, success, failure) {
  return function() {
    // eslint-disable-next-line babel/no-invalid-this
    let file = this.files[0];

    const reader = new FileReader();

    reader.onload = function(e) {
      let data = e.target.result;

      before();

      printfileApi.import(data)
        .then(x => {
          success();
        })
        .catch(err => {
          console.error(err);
          failure();
        });
    };
    reader.readAsText(file);
  };
}

// Page object
class PagePrintFiles extends React.Component {
  static contextTypes = {
    canViewPrintfiles: PropTypes.bool.isRequired,
    canEditPrintfiles: PropTypes.bool.isRequired,
    token: PropTypes.any.isRequired,
  };

  static propTypes = {
    createToast: PropTypes.func.isRequired,
  };

  state = {
    files: [],
  };

  componentWillMount() {
    if (!this.context.canViewPrintfiles) {
      browserHistory.push('/');
    }
  }

  componentDidMount() {
    printfileApi.getFiles()
      .then(data => { if(this.mounted) this.setState({files: data.data}); });

    if (this.importFileInput) {
// eslint-disable-next-line react/no-find-dom-node
      let input = findDOMNode(this.importFileInput);

      input.addEventListener('change', uploadFile(this.beforeUpload, this.successfulUpload, this.failedUpload));
      this.mounted = true;
    }

    this.getPrintAllLink();
  }

  componentWillUnmount() {
    if (this.importFileInput) {
// eslint-disable-next-line react/no-find-dom-node
      let input = findDOMNode(this.importFileInput);

      input.removeEventListener('change', uploadFile(this.beforeUpload, this.successfulUpload, this.failedUpload));
      this.mounted = false;
    }
  }

  getExport = () => {
    printfileApi.getExportJSON(encodeURIComponent(this.context.token['id_token']));
  };

  getExportCSV = () => {
    printfileApi.getExportCSV(encodeURIComponent(this.context.token['id_token']));
  };

  getPrintAllLink = () => {
    const url = 'api/PrintLink/PrintAllLink';

    return qwest.get(url).then(function(xhr, response) {
      if(this.mounted) this.setState({ reportURL: response });
    }.bind(this));
  };

  beforeUpload = () => {
    this.props.createToast({
      id: 'import-print-files',
      message: 'Importing...',
      ttl: 4000,
    });
  };

  failedUpload = () => {
    this.props.createToast({
      id: 'import-print-files-failure',
      message: 'Failed to import printfiles.',
      ttl: 6000,
      state: TOAST_STATES.ERROR,
      clears: 'import-print-files',
    });
  };

  printAll = () => {
    window.open(this.state.reportURL);
  };

  successfulUpload = () => {
    this.props.createToast({
      id: 'import-print-files-success',
      message: 'Finished importing.',
      ttl: 4000,
      state: TOAST_STATES.SUCCESS,
      clears: 'import-print-files',
    });
  };

  triggerUpload = () => {
    if (this.importFileInput) {
// eslint-disable-next-line react/no-find-dom-node
      let file = findDOMNode(this.importFileInput);

      file.click();
    }
  };

  newPrintFile = () => {
    browserHistory.push('/print-file/new');
  }

  render() {
    let printfileRows;

    try {
      printfileRows = this.state.files.map((file, i) => {
        return (
          <tr key={file.ReferenceNumber + '.' + i} onClick={() => browserHistory.push(`/print-file/${file.ReferenceNumber}/${file.Country}`)}>
            <td>{!!file.Flagged && <i className='fa fa-flag' />}</td>
            <td>{file.Country}</td>
            <td>{file.ReferenceNumber}</td>
            <td>{file.Problem.Title}</td>
            <td>{file.Problem.Description}</td>
            <td>{file.Remedy.Title}</td>
            <td className='hide-tablet'>{file.Remedy.Instructions}</td>
          </tr>
        );
      });
    } catch (e) {
      console.error(e);
    }

    return (
      <div>
        <input type='file' ref={r => this.importFileInput = r} style={{display: 'none'}} />
        <PageHeader title='Print Files' icon='fa fa-print' breadcrumbs={[]} />
        <div className='wrapper wrapper-content'>
          <div className='row' id='page-print-files'>
            <div className='col-xs-12'>
              <IBox title='Print Files'>
                {this.context.canEditPrintfiles ? <div className='btn-group-padding'>
                  <button className='btn btn-default' onClick={this.triggerUpload}>Import</button>
                  <button className='btn btn-primary' onClick={this.getExport}>Export JSON</button>
                  <button className='btn btn-primary' onClick={this.getExportCSV}>Export CSV</button>
                  <button className='btn btn-primary' onClick={this.printAll}>Print All</button>
                  <button className='btn btn-primary' onClick={this.newPrintFile}>New Print File</button>
                </div> : <div className='btn-group-padding'><button className='btn btn-primary' onClick={this.printAll}>Print All</button></div>}

                <div className='alert alert-warning'>
                  <span><i className='fa fa-flag print-files-legend' />Print files that are marked as candidates to be removed in the future. This feature does not affect any existing functionality.</span>
                </div>

                <div className='row'>
                  <div className='print-files-list col-sm-12'>
                    <table className='table table-striped table-hover hoverClick'>
                      <thead>
                        <tr>
                          <th></th>
                          <th>ID</th>
                          <th>Problem Title</th>
                          <th>Problem Description</th>
                          <th>Remedy Title</th>
                          <th className='hide-tablet'>Remedy Instructions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {printfileRows}
                      </tbody>
                    </table>
                  </div>
                </div>
              </IBox>
            </div>
          </div>
        </div>
      </div>);
  }
}

export default connectToToasts(PagePrintFiles);
