import moment from 'moment';

import cloneDeep from 'lodash/cloneDeep';

class AnalysisModel {
  constructor({
    cdDrain, chlorineDemandOnly, clarifierDemand, cleanAndDrain, copperFromProduct, copperStaining, deleted, foaming, freshFill, hazy, ironStaining,
    manganese, mustardAlgae, organicStaining, partialDrain, partialDrainCYARemover, plasterLT12Months, plasterLT30Days, printoutShowMaint, possibleCD, scaleBuildup, season,
    slimeMold, spaAlgae, spaFilterCleaned6Weeks, spaSentryStartup, startup, swampy, temperatureLT32, trioStartup, unknownStaining, waterBuildup,
    created, filterCleanedDate, imported, modified, mSCellCleanedDate, nextAnalysisDate, adjustedTA, copper, freeChlorine, iron, pH, saturationIndex,
    silken, stabilizer, totalBromine, totalChlorine, alkalinity, cDDistStandard, cDSample, cYA, dD, hardness, id, minerals, optimizerPlusBorate, poolID, quat,
    salt, scanSettingsID, scanTypeID, sSKSanitizerResidual, sSKShockResidual, sSWB, sSWC, tDS, temperature, version, algaeProduct, blackAlgae, cDProduct,
    clarifier, cloudy, cloudyProductID, greenAlgae, sourceWaterType, testType, waterTester,
    comfortScore, clarityScore, protectionScore, lastCleanedDrained, branchID, branchName,
    suggestedProducts, initiatorLevel, chemicalBaseTypeID, poolTypeID, displayInputTemperature, phosphate, phosphateLevel, contactId, optimizerProductID,
    swampyProduct, pollen, pollenProductID, severeWeatherProduct, prePartyProduct, postPartyProduct, skipCleanAndDrain
  }) {

    this.cdDrain = cdDrain;
    this.chlorineDemandOnly = chlorineDemandOnly;
    this.clarifierDemand = clarifierDemand;
    this.cleanAndDrain = cleanAndDrain;
    this.cloudy = cloudy;
    this.cloudyProductID = cloudyProductID;
    this.copperFromProduct = copperFromProduct;
    this.copperStaining = copperStaining;
    this.deleted = deleted;
    this.foaming = foaming;
    this.freshFill = freshFill;
    this.hazy = hazy;
    this.ironStaining = ironStaining;
    this.manganese = manganese;
    this.mustardAlgae = mustardAlgae;
    this.organicStaining = organicStaining;
    this.partialDrain = partialDrain;
    this.partialDrainCYARemover = partialDrainCYARemover;
    this.plasterLT12Months = plasterLT12Months;
    this.plasterLT30Days = plasterLT30Days;
    this.pollen = pollen;
    this.printoutShowMaint = printoutShowMaint;
    this.possibleCD = possibleCD;
    this.scaleBuildup = scaleBuildup;
    this.season = season;
    this.slimeMold = slimeMold;
    this.spaAlgae = spaAlgae;
    this.spaFilterCleaned6Weeks = spaFilterCleaned6Weeks;
    this.spaSentryStartup = spaSentryStartup;
    this.startup = startup;
    this.swampy = swampy;
    this.temperatureLT32 = temperatureLT32;
    this.trioStartup = trioStartup;
    this.unknownStaining = unknownStaining;
    this.waterBuildup = waterBuildup;

    this.created = created;
    this.filterCleanedDate = filterCleanedDate ? moment(filterCleanedDate) : filterCleanedDate;
    this.imported = imported;
    this.modified = modified;
    this.mSCellCleanedDate = mSCellCleanedDate ? moment(mSCellCleanedDate) : mSCellCleanedDate;
    this.nextAnalysisDate = nextAnalysisDate;

    this.adjustedTA = adjustedTA;
    this.copper = copper;
    this.freeChlorine = freeChlorine;
    this.iron = iron;
    this.pH = pH;
    this.saturationIndex = saturationIndex;
    this.silken = silken;
    this.stabilizer = stabilizer;
    this.totalBromine = totalBromine;
    this.totalChlorine = totalChlorine;

    this.alkalinity = alkalinity;
    this.cDDistStandard = cDDistStandard;
    this.cDSample = cDSample;
    this.cYA = cYA;
    this.dD = dD;
    this.hardness = hardness;
    this.id = id;
    this.minerals = minerals;
    this.optimizerPlusBorate = optimizerPlusBorate;
    this.pollenProductID = pollenProductID;
    this.poolID = poolID;
    this.postPartyProduct = postPartyProduct;
    this.prePartyProduct = prePartyProduct;
    this.quat = quat;
    this.salt = salt;
    this.scanSettingsID = scanSettingsID;
    this.scanTypeID = scanTypeID;
    this.severeWeatherProduct = severeWeatherProduct;
    this.sSKSanitizerResidual = sSKSanitizerResidual;
    this.sSKShockResidual = sSKShockResidual;
    this.sSWB = sSWB;
    this.sSWC = sSWC;
    this.tDS = tDS;
    this.temperature = temperature;
    this.version = version;

    this.algaeProduct = algaeProduct;
    this.blackAlgae = blackAlgae;
    this.cDProduct = cDProduct;
    this.clarifier = clarifier;
    this.greenAlgae = greenAlgae;
    this.sourceWaterType = sourceWaterType;
    this.testType = testType;
    this.waterTester = waterTester;

    this.comfortScore = comfortScore;
    this.clarityScore = clarityScore;
    this.protectionScore = protectionScore;

    this.lastCleanedDrained = lastCleanedDrained ? moment(lastCleanedDrained) : lastCleanedDrained;

    this.branchID = branchID;
    this.branchName = branchName;

    this.suggestedProducts = suggestedProducts;

    this.initiatorLevel = initiatorLevel;
    this.phosphate = phosphate;
    this.phosphateLevel = phosphateLevel;
    this.chemicalBaseTypeID = chemicalBaseTypeID;
    this.poolTypeID = poolTypeID;
    this.displayInputTemperature = displayInputTemperature;
    this.contactId = contactId;
    this.optimizerProductID = optimizerProductID;
    this.swampyProduct = swampyProduct;
    this.skipCleanAndDrain = skipCleanAndDrain;

  }

  clone() {
    return cloneDeep(this);
  }

  toApiFormat() {
    return {
      CDDrain: this.cdDrain,
      ChlorineDemandOnly: this.chlorineDemandOnly,
      ClarifierDemand: this.clarifierDemand,
      CleanAndDrain: this.cleanAndDrain,
      Cloudy: this.cloudy,
      CloudyProductID: this.cloudyProductID,
      CopperFromProduct: this.copperFromProduct,
      CopperStaining: this.copperStaining,
      Deleted: this.deleted,
      Foaming: this.foaming,
      FreshFill: this.freshFill,
      Hazy: this.hazy,
      IronStaining: this.ironStaining,
      Manganese: this.manganese,
      MustardAlgae: this.mustardAlgae,
      OrganicStaining: this.organicStaining,
      PartialDrain: this.partialDrain,
      PartialDrainCYARemover: this.partialDrainCYARemover,
      PlasterLT12Months: this.plasterLT12Months,
      PlasterLT30Days: this.plasterLT30Days,
      Pollen: this.pollen,
      PrintoutShowMaint: this.printoutShowMaint,
      PossibleCD: this.possibleCD,
      ScaleBuildup: this.scaleBuildup,
      Season: this.season,
      SlimeMold: this.slimeMold,
      SpaAlgae: this.spaAlgae,
      SpaFilterCleaned6Weeks: this.spaFilterCleaned6Weeks,
      SpaSentryStartup: this.spaSentryStartup,
      SpringOpening: this.springOpening,
      Startup: this.startup,
      Swampy: this.swampy,
      TemperatureLT32: this.temperatureLT32,
      TRIOStartup: this.trioStartup,
      UnknownStaining: this.unknownStaining,
      WaterBuildup: this.waterBuildup,

      Created: moment(this.created),
      FilterCleanedDate: moment(this.filterCleanedDate),
      Modified: moment(this.modified),
      MSCellCleanedDate: moment(this.mSCellCleanedDate),
      NextAnalysisdate: moment(this.nextAnalysisDate),

      AdjustedTA: this.adjustedTA,
      Copper: this.copper,
      FreeChlorine: this.freeChlorine,
      Iron: this.iron,
      pH: this.pH,
      SaturationIndex: this.saturationIndex,
      Silken: this.silken,
      Stabilizer: this.stabilizer,
      TotalBromine: this.totalBromine,
      TotalChlorine: this.totalChlorine,

      Alkalinity: this.alkalinity,
      CDDistStandard: this.cDDistStandard,
      CDSample: this.cDSample,
      CYA: this.cYA,
      DD: this.dD,
      DiskType: this.diskType,
      Hardness: this.hardness,
      ID: this.id,
      Minerals: this.minerals,
      OptimizerPlusBorate: this.optimizerPlusBorate,
      PollenProductID: this.pollenProductID,
      PoolID: this.poolID,
      PostPartyProduct: this.postPartyProduct,
      PrePartyProduct: this.prePartyProduct,
      Quat: this.quat,
      Salt: this.salt,
      ScanSettingsID: this.scanSettingsID,
      ScanTypeID: this.scanTypeID,
      SevereWeatherProduct: this.severeWeatherProduct,
      SSKSanitizerResidual: this.sSKSanitizerResidual,
      SSKShockResidual: this.sSKShockResidual,
      SSWB: this.sSWB,
      SSWC: this.sSWC,
      TDS: this.tDS,
      Temperature: this.temperature,
      Version: this.version,

      AlgaeProduct: this.algaeProduct,
      BlackAlgae: this.blackAlgae,
      CDProduct: this.cDProduct,
      Clarifier: this.clarifier,
      GreenAlgae: this.greenAlgae,
      SourceWaterType: this.sourceWaterType,
      TestType: this.testType,
      WaterTester: this.waterTester,

      ComfortScore: this.comfortScore,
      ClarityScore: this.clarityScore,
      ProtectionScore: this.protectionScore,

      LastCleanedDrained: this.lastCleanedDrained ? moment(this.lastCleanedDrained) : this.lastCleanedDrained,

      BranchID: this.branchID,

      SuggestedProducts: this.suggestedProducts,

      InitiatorLevel: this.initiatorLevel,
      Phosphate: this.phosphate,
      PhosphateLevel: this.phosphateLevel,
      ChemicalBaseTypeID: this.chemicalBaseTypeID,
      DisplayInputTemperature: this.displayInputTemperature,
      ContactId: this.contactId,
      SwampyProduct: this.swampyProduct,
      SkipCleanAndDrain: this.skipCleanAndDrain,
    };
  }

  static fromApiFormat(api) {
    return new AnalysisModel({
      cdDrain: api.CDDrain,
      chlorineDemandOnly: api.ChlorineDemandOnly,
      clarifierDemand: api.ClarifierDemand,
      cleanAndDrain: api.CleanAndDrain,
      cloudy: api.Cloudy,
      cloudyProductID: api.CloudyProductID,
      copperFromProduct: api.CopperFromProduct,
      copperStaining: api.CopperStaining,
      deleted: api.Deleted,
      foaming: api.Foaming,
      freshFill: api.FreshFill,
      hazy: api.Hazy,
      ironStaining: api.IronStaining,
      manganese: api.Manganese,
      mustardAlgae: api.MustardAlgae,
      organicStaining: api.OrganicStaining,
      partialDrain: api.PartialDrain,
      partialDrainCYARemover: api.PartialDrainCYARemover,
      plasterLT12Months: api.PlasterLT12Months,
      plasterLT30Days: api.PlasterLT30Days,
      pollen: api.Pollen,
      printoutShowMaint: api.PrintoutShowMaint,
      possibleCD: api.PossibleCD,
      scaleBuildup: api.ScaleBuildup,
      season: api.Season,
      slimeMold: api.SlimeMold,
      spaAlgae: api.SpaAlgae,
      spaFilterCleaned6Weeks: api.SpaFilterCleaned6Weeks,
      spaSentryStartup: api.SpaSentryStartup,
      startup: api.Startup,
      swampy: api.Swampy,
      temperatureLT32: api.TemperatureLT32,
      trioStartup: api.TRIOStartup,
      unknownStaining: api.UnknownStaining,
      waterBuildup: api.WaterBuildup,

      created: api.Created,
      filterCleanedDate: api.FilterCleanedDate,
      imported: api.Imported,
      modified: api.Modified,
      mSCellCleanedDate: api.MSCellCleanedDate,
      nextAnalysisDate: api.NextAnalysisDate,

      adjustedTA: api.AdjustedTA,
      copper: api.Copper,
      freeChlorine: api.FreeChlorine,
      iron: api.Iron,
      pH: api.pH,
      saturationIndex: api.SaturationIndex,
      silken: api.Silken,
      stabilizer: api.Stabilizer,
      totalBromine: api.TotalBromine,
      totalChlorine: api.TotalChlorine,

      alkalinity: api.Alkalinity,
      cDDistStandard: api.CDDistStandard,
      cDSample: api.CDSample,
      cYA: api.CYA,
      dD: api.DD,
      diskType: this.DiskType,
      hardness: api.Hardness,
      id: api.ID,
      minerals: api.Minerals,
      optimizerPlusBorate: api.OptimizerPlusBorate,
      pollenProductID: api.PollenProductID,
      poolID: api.PoolID,
      postPartyProduct: api.PostPartyProduct,
      prePartyProduct: api.PrePartyProduct,
      quat: api.Quat,
      salt: api.Salt,
      scanSettingsID: api.ScanSettingsID,
      scanTypeID: api.ScanTypeID,
      severeWeatherProduct: api.SevereWeatherProduct,
      sSKSanitizerResidual: api.SSKSanitizerResidual,
      sSKShockResidual: api.SSKShockResidual,
      sSWB: api.SSWB,
      sSWC: api.SSWC,
      tDS: api.TDS,
      temperature: api.Temperature,
      version: api.Version,

      algaeProduct: api.AlgaeProduct,
      blackAlgae: api.BlackAlgae,
      cDProduct: api.CDProduct,
      clarifier: api.Clarifier,
      greenAlgae: api.GreenAlgae,
      sourceWaterType: api.SourceWaterType,
      testType: api.TestType,
      waterTester: api.WaterTester,

      clarityScore: api.ClarityScore,
      comfortScore: api.ComfortScore,
      protectionScore: api.ProtectionScore,

      lastCleanedDrained: api.LastCleanedDrained,
      branchID: api.BranchID,
      branchName: api.BranchName,

      suggestedProducts: api.SuggestedProducts,
      initiatorLevel: api.InitiatorLevel,
      phosphate: api.Phosphate,
      phosphateLevel: api.PhosphateLevel,
      chemicalBaseTypeID: api.ChemicalBaseTypeID,
      poolTypeID: api.PoolTypeID,
      displayInputTemperature: api.DisplayInputTemperature,
      contactId: api.ContactId,
      optimizerProductID: api.OptimizerProductID,
      swampyProduct: api.SwampyProduct,
      skipCleanAndDrain: api.SkipCleanAndDrain,
    });
  }
}

export default AnalysisModel;
