import _ from 'lodash';
import events from '../../lib/events.js';
import IBox from '../components/iBox.jsx';
import Loader from '../components/loader.jsx';
import makeCancelable from '../helpers/promiseHelper.jsx';
import PageHeader from '../components/pageHeader.jsx';
import poolScoreApi from '../../api/poolScoreApi.jsx';
import PoolScoreTest from '../../models/poolScoreTest.jsx';
import PropTypes from 'prop-types';
import React from 'react';
import RulesEntry from './poolScore/rulesEntry.jsx';
import ScoreTest from './poolScore/scoreTest.jsx';
import {browserHistory} from 'react-router';
import {PoolScoreWrapper} from '../../models/poolScoreParameters.jsx';


class PagePoolScore extends React.Component {
  static propTypes = {
    params: PropTypes.object.isRequired,
  };

  state = {
    currentTest: new PoolScoreTest({}),
    loaded: false,
    data: {
      chemicalBaseTypeID: 2,
      sanitizer: 0,
      rules: new PoolScoreWrapper({}),
      score: 0,
    },
    tests: [],
    testBackups: [],
    outputs: [],
    savingRules: false,
    runningAllTests: false,
    runningTest: {},
    deletingTest: {},
    savingTest: false,
  };

  componentWillMount() {
    this.fetchData = makeCancelable(
      Promise.all([
        poolScoreApi.getPoolScores(),
        poolScoreApi.getTests(),
      ])
    );

    this.fetchData.promise()
      .then(([scores, tests]) => {
        let data = _.cloneDeep(this.state.data);

        data.rules = scores;
        this.setState({
          currentTest: tests[0] || new PoolScoreTest({}),
          data: data,
          loaded: true,
          testBackups: tests.map(x => new PoolScoreTest(x)),
          tests: tests,
        });
      })
      .catch(err => {
        console.error(err);
      });
  }

  componentWillUnmount() {
    this.fetchData.cancel();
  }

  getEditPage = () => {
    return <RulesEntry
      data={this.state.data}
      dataChanged={this.dataChanged}
      save={this.save}
      scoreChanged={this.scoreChanged}
      savingRules={this.state.savingRules}
      toggleEdit={this.toggleEdit}
    />;
  };

  getTestingPage = () => {
    return <ScoreTest
      addTest={this.addTest}
      currentTest={this.state.currentTest}
      dataChanged={this.updateCurrentTest}
      deleteTest={this.deleteTest}
      deletingTest={this.state.deletingTest}
      editTest={this.editTest}
      outputs={this.state.outputs}
      runAllTests={this.runAllTests}
      runningAllTests={this.state.runningAllTests}
      runningTest={this.state.runningTest}
      runTest={this.runTest}
      saveTest={this.saveTest}
      savingTest={this.state.savingTest}
      testChanged={this.testChanged}
      tests={this.state.tests}
      toggleEdit={this.toggleEdit}
    />;
  };

  addTest = () => {
    var newTest = _.find(this.state.tests, test => !test.id);

    if (!newTest) {
      newTest = new PoolScoreTest({});
    }
    this.state.tests.push(newTest);
    this.state.testBackups.push(new PoolScoreTest(newTest));
    this.setState({
      currentTest: newTest,
      tests: this.state.tests,
      testBackups: this.state.testBackups,
    });
  };

  dataChanged = (e) => {
    let data = _.cloneDeep(this.state.data);

    data[e.key] = e.value;
    this.setState({
      data: data,
    });
  };

  deleteTest = (testID) => {
    return () => {
      let deletingTest = _.cloneDeep(this.state.deletingTest);

      deletingTest[testID] = true;
      this.setState({
        deletingTest: this.state.deletingTest,
      });
      poolScoreApi.deleteTest(testID)
        .then(() => {
          let tests = this.state.tests.filter(x => x.id !== testID);
          let testBackups = this.state.testBackups.filter(x => x.id !== testID);

          delete this.state.deletingTest[testID];
          this.setState({
            tests: tests,
            testBackups: testBackups,
            deletingTest: this.state.deletingTest,
            outputs: this.state.outputs.filter(x => x.testID !== testID),
            currentTest: new PoolScoreTest(tests[0] || {}), // Both escapes empty list and clones object
          });
        })
        .catch(e => {
          console.error(e);
          deletingTest[testID] = false;
          this.setState({
            deletingTest: this.state.deletingTest,
          });
        });
    };
  };

  editTest = (testID) => {
    return () => {
      let newTest = _.cloneDeep(this.state.tests.filter(x => x.id === testID)[0]);

      this.setState({
        currentTest: newTest || new PoolScoreTest({}),
      });
    };
  };

  isEditting = () => {
    return this.props.params.mode === 'edit';
  };

  runAllTests = () => {
    this.setState({
      runningAllTests: true,
    });
    poolScoreApi.runTests()
      .then(results => {
        this.setState({
          outputs: results,
          runningAllTests: false,
        });
      })
      .catch(e => {
        console.error(e);
        this.setState({
          runningAllTests: false,
        });
      });
  };

  runTest = (testID) => {
    return () => {
      let runningTest = _.cloneDeep(this.state.runningTest);

      runningTest[testID] = true;
      this.setState({
        runningTest: runningTest,
      });
      poolScoreApi.runTest(testID)
        .then(result => {
          let outputs = _.cloneDeep(this.state.outputs);

          outputs = outputs.filter(x => x.testID !== result.testID);
          outputs.push(result);

          runningTest[testID] = false;
          this.setState({
            outputs: outputs,
            runningTest: runningTest,
          });
        })
        .catch(e => {
          console.error(e);

          runningTest[testID] = false;
          this.setState({
            runningTest: runningTest,
          });
        });
    };
  };

  save = () => {
    this.setState({
      savingRules: true,
    });
    poolScoreApi.savePoolScores(this.state.data.rules)
      .then(scores => {
        let data = _.cloneDeep(this.state.data);

        data.rules = scores;
        this.setState({
          data: data,
          savingRules: false,
        });
      })
      .catch(err => {
        console.error(err);
        this.setState({
          savingRules: false,
        });
        events.publish('error.user', {message: 'Error saving pool scores.'});
      });
  };

  saveTest = () => {
    this.setState({
      savingTest: true,
    });
    poolScoreApi.saveTest(this.state.currentTest)
      .then(test => {
        let tests = _.cloneDeep(this.state.tests);

        tests = tests.filter(x => x.id !== test.id);
        tests.push(_.cloneDeep(test));

        let testBackups = this.state.testBackups.filter(x => x.id !== test.id);

        testBackups.push(_.cloneDeep(test));

        this.setState({
          currentTest: test,
          outputs: this.state.outputs.filter(x => x.testID !== test.id),
          savingTest: false,
          testBackups: testBackups,
          tests: tests,
        });
      })
      .catch(err => {
        console.error(err);
        this.setState({
          savingTest: false,
        });
      });
  };

  scoreChanged = (e) => {
    let keys = e.key.split('|');
    let data = _.cloneDeep(this.state.data);
    let obj = data.rules.scores[this.state.data.chemicalBaseTypeID + '|' + this.state.data.score];

    let exists = true;

    for (let i = 0; i < keys.length - 1; ++i) {
      let key = keys[i];

      if (obj[key]) {
        obj = obj[key];
      } else {
        exists = false;
      }
    }

    if (exists) {
      obj[keys[keys.length - 1]] = e.value;
      this.setState({
        data: data,
      });
    }
  };

  testChanged = (testID) => {
    let test = this.state.currentTest;

    if (test.id !== testID) {
      test = _.find(this.state.tests, x => x.id === testID);
    }
    let equal = _.isEqual(test, _.find(this.state.testBackups, x => x.id === testID));

    return !equal;
  };

  toggleEdit = () => {
    if (!this.isEditting()) {
      browserHistory.push('/pool-score/edit');
    } else {
      browserHistory.push('/pool-score/test');
    }
  };

  updateCurrentTest = (e) => {
    if (e.key) {
      let currentTest = _.cloneDeep(this.state.currentTest);

      currentTest[e.key] = e.value;
      this.setState({
        currentTest: currentTest,
      });
    }
  };

  render() {
    let subTitle = this.isEditting() ? 'Edit Calculation Parameters' : 'Test Calculations';
    let finalBread = {title: 'Test Calculations', path: '/pool-score/test'};

    if (!this.isEditting()) {
      finalBread = {title: 'Edit Calculation Parameters', path: '/pool-score/edit'};
    }
    return (
      <div className='pool-score-page'>
        <PageHeader title='Pool Score' icon='fa fa-calculator' breadcrumbs={[finalBread]}/>
        <IBox title={subTitle}>
          <Loader loaded={this.state.loaded}>
            {this.isEditting() ? this.getEditPage() : this.getTestingPage()}
          </Loader>
        </IBox>
      </div>
    );
  }
}

export default PagePoolScore;
