import React from 'react';
import PropTypes from 'prop-types';
import {cloneDeep, isEqual, get} from 'lodash';
import {ForceGraph as D3ForceGraph} from './forceGraphBase';

class ForceGraph extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    currentStep: PropTypes.number,
    rulesGraph: PropTypes.object,
    runResults: PropTypes.object,
  };

  state = {
    height: 300,
    width: 400,
  };

  componentDidMount() {
    const {
      currentStep,
      rulesGraph,
      runResults: {path},
    } = this.props;

    this.d3ForceGraph = new D3ForceGraph(
      this.container,
      this.svg
    );

    const fatherContainer = document.getElementsByClassName('trace-container');

    this.setState({
      height: fatherContainer[0].clientHeight / 2,
      width: fatherContainer[0].clientWidth,
    });

    let ranMap = new Set();

    if (path) {
      ranMap = path
        .reduce((acc, x) => {
          if (x.didRun) {
            acc.add(x.id);
          }

          return acc;
        }, new Set());
    }

    if (rulesGraph) {
      this.d3ForceGraph.setGraph(this.getNodesAndLinks(), ranMap);
    }

    if (path.length && currentStep >= 0) {
      setTimeout(() => this.d3ForceGraph.selectNode(path[currentStep].id), 0);
    }
  }

  componentDidUpdate = (prevProps) => {
    const {
      currentStep,
      rulesGraph,
      runResults: {path},
    } = this.props;

    let ranMap = new Set();

    if (path) {
      ranMap = path
        .reduce((acc, x) => {
          if (x.didRun) {
            acc.add(x.id);
          }

          return acc;
        }, new Set());
    }

    if (rulesGraph && !isEqual(prevProps.rulesGraph, rulesGraph)) {
      this.d3ForceGraph.setGraph(this.getNodesAndLinks(), ranMap);

      this.onResize();
    } else if (!isEqual(prevProps.runResults.path, path)) {
      this.d3ForceGraph.updateNodeStyles(ranMap);
    }

    if (path.length && prevProps.currentStep !== currentStep) {
      this.d3ForceGraph.selectNode(path[currentStep].id);
    }
  };

  componentWillUnmount() {
    this.container = null;
    this.d3ForceGraph = null;
    this.svg = null;
  }

  container = null;
  d3ForceGraph = null;
  svg = null;

  getNodesAndLinks() {
    let {rulesGraph} = this.props;

    let p = new Map();
    let map = new Map();

    let entryPoints = new Set();
    let entryOffset = 0;

    for (let node of rulesGraph.nodes) {
      if (node.isEntryPoint) {
        entryPoints.add(node);
      }
      map.set(node.id, node);
    }

    for (let link of rulesGraph.links) {
      if (!p.has(link.source)) {
        p.set(link.source, []);
      }

      p.get(link.source).push(link.target);
    }

    let finalNodes = [];
    let finalNodesIDs = new Set();
    let finalLinks = [];

    for (let ep of entryPoints) {
      finalNodes.push({
        id: ep.id,
        x: entryOffset * 1000,
        y: 0,
        description: ep.description,
        isEntryPoint: true,
      });

      finalNodesIDs.add(ep.id);

      this._addNode({finalNodes, finalNodesIDs, finalLinks, path: p, map, node: ep,
        baseX: entryOffset * 1000, baseY: 0, maxDegrees: 360, baseAngle: 0});
    }

    return {nodes: finalNodes, links: finalLinks};
  }

  _addNode({finalNodes, finalNodesIDs, finalLinks, path, map, node, baseX, baseY, maxDegrees, baseAngle}) {
    let children = (path.get(node.id) || []).map(x => map.get(x)).filter(x => !!x);

    let subLevel = 0;

    maxDegrees = Math.max(maxDegrees, 60);

    let degreesPerChild = maxDegrees / children.length;

    for (let child of children) {
      if(!finalNodesIDs.has(child.id)) {
        let angle = baseAngle + subLevel * degreesPerChild - maxDegrees / 2;
        let radians = angle / 360 * 2 * Math.PI;

        let xOffset = Math.cos(radians) * 100;
        let yOffset = Math.sin(radians) * 100;

        let x = baseX + xOffset;
        let y = baseY + yOffset;

        finalNodes.push({
          id: child.id,
          y,
          x,
          description: child.description,
        });

        finalNodesIDs.add(child.id);

        finalLinks.push({
          id: `${node.id}-${child.id}`,
          source: node.id,
          target: child.id,
        });

        subLevel += 1;

        this._addNode({path, map, baseX: x, baseY: y, finalNodes, finalNodesIDs, finalLinks,
          baseAngle: angle, maxDegrees: degreesPerChild,
          node: child});
      } else {
        // if node was already added to final nodes only create link for it and update it position
        finalLinks.push({
          id: `${node.id}-${child.id}`,
          source: node.id,
          target: child.id,
        });
      }
    }
  }


  onResize = () => {
    const rect = this.container.getBoundingClientRect();
    const {width, height} = rect;

    this.setState({width, height});
    this.d3ForceGraph.onResize(width, height);
  };

  render() {
    const {width, height} = this.state;

    return <div
      className={`force-graph ${this.props.className}`}
      ref={(elem) => {
        this.container = elem;
      }}
    >
      <svg
        className='force-graph-svg'
        viewBox={`0 0 ${width} ${height}`}
        preserveAspectRatio='xMidYMid meet'
        ref={elem => this.svg = elem}
      />
    </div>;
  }
}

export default ForceGraph;
