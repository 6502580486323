import React from 'react';

export const debugComponent = C => {
  return class Wrapped extends React.Component {
    static displayName = `debugComponent(${C.displayName || C.name})`;

    state = {
      error: false,
    };

    componentDidCatch(err) {
      console.error('Component caught', err);
      this.setState({error: true});
    }

    render() {
      if (this.state.error) {
        return <div>Report failed to generate. Please try again.</div>;
      }

      return <C {...this.props} />;
    }
  };
};
