import React from 'react';
import {ArrayToCSV, ArrayToXLSX} from '../../helpers/fileConvertions.jsx';

import AmChart from './amchart.jsx';

import 'amcharts3/amcharts/serial';

/**
 * StackedChart
 * @param {Array} data - [{name: something, value: something else}, ...] - Each item in the array represents a bar
 * @param {String} [titleField='name'] - the field used to title each bar
 * @param {[String]} [valueFields=['value']] - the fields used to get the numerical value of each bar in each stack
 * @returns {ReactElement} React HTML
 */
let StackedChart = ({
  data,
  height,
  fileName = 'stacked-barchart',
  titleField = 'name',
  valueFields = ['value'],
  valueTitles = ['Value'],
  verticalAxisTitle = '',
  type,
  exportFileData,
  valueAxisLabelFunction,
  zoomScrollBar = false,
}) =>
  <AmChart height={height} config={{
    type: 'serial',
    theme: 'light',
    zoomOutButtonImage: '',
    dataProvider: data,
    categoryField: titleField,
    dataDateFormat: 'MM/DD/YYYY',
    categoryAxis: {
      labelRotation: 30,
    },
    export: {
      enabled: true,
      fileName: fileName,
      menu: [
        {
          class: 'export-main',
          menu: [
            {
              label: 'Download as ...',
              menu: ['PNG', 'JPG', 'SVG'],
            },
            {
              label: 'Save as ...',
              menu: [
                {
                  label: 'CSV',
                  click: function() {
                    this.download(ArrayToCSV(exportFileData != null ? exportFileData : data),
                      this.defaults.formats.CSV.mimeType, fileName + '.csv');
                  },
                },
                {
                  label: 'XLSX',
                  click: function() {
                    saveAs(ArrayToXLSX(exportFileData != null ? exportFileData : data, fileName), fileName + '.xlsx');
                  }},
                {
                  label: 'JSON',
                  click: function() {
                    this.download(JSON.stringify(exportFileData != null ? exportFileData : data, undefined, '\t'),
                      this.defaults.formats.JSON.mimeType, fileName + '.json');
                  },
                },
              ],
            },
            {
              label: 'Annotate ...',
              action: 'draw',
            },
            {
              label: 'Print',
              action: 'PRINT',
            },
          ],
        },
      ],
    },
    graphs: valueFields.map((valueField, i) => {
      return {
        type: type != null ? type : 'line',
        valueField: valueField,
        fillAlphas: 1,
        lineAlpha: 0,
        balloonText: `<span style='font-size:14px'>${valueTitles[i]}:<b>[[value]]</b></span>`,
      };
    }),
    chartCursor: {
      categoryBalloonEnabled: false,
      cursorAlpha: 0,
      zoomable: zoomScrollBar,
    },
    chartScrollbar: zoomScrollBar ? {
      dragIcon: 'https://www.amcharts.com/lib/3/images/dragIconRoundBig.svg',
    } : undefined,
    valueAxes: [
      {
        stackType: 'regular',
        axisAlpha: 0,
        position: 'left',
        labelFunction: valueAxisLabelFunction,
        title: verticalAxisTitle,
      },
    ],
    startDuration: 0,
  }} />

;

export default StackedChart;
