import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Select, TextArea} from '../form/index.jsx';
import IBox from '../iBox.jsx';
import {connectToToasts, TOAST_STATES} from '../toasts.jsx';

import ToolsApi from '../../../api/tools.jsx';

const indices = [
  'Select...',
  'article',
  'branch',
  'contact',
  'dealer',
  'printfile',
  'user',
];

const keys = indices.slice(1);

keys.unshift('');

class DeleteFromElasticSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      index: undefined,
      ids: [],
    };
  }

  changeIDs = ({value}) => {
    this.setState({
      ids: value,
    });
  };

  changeState = ({value}) => {
    this.setState({
      index: value,
    });
  };

  delete = () => {
    let ids = this.state.ids;
    let index = this.state.index;
    let {createToast} = this.props;

    createToast({
      id: 'elasticsearch-delete',
      message: 'Deleting...',
      state: TOAST_STATES.INFO,
    });

    ToolsApi.elasticSearchDelete(index, ids)
      .then(x => {
        if (x.success) {
          this.setState({
            index: '',
            ids: [],
          });

          createToast({
            id: 'elasticsearch-delete-success',
            message: 'Finished deleting.',
            clears: 'elasticsearch-delete',
            state: TOAST_STATES.SUCCESS,
            ttl: 4000,
          });
        } else {
          throw new Error('Deletion failed.');
        }
      })
      .catch(err => {
        console.error(err);
        createToast({
          id: 'elasticsearch-delete-failed',
          message: 'Failed to delete items.',
          clears: 'elasticsearch-delete',
          state: TOAST_STATES.ERROR,
          ttl: 6000,
        });
      });
  };

  render() {
    return <IBox title='Delete From ElasticSearch' style={{marginTop: 10}}
        tools={<div>
          <a onClick={this.delete}><i className='fa fa-trash fa-lg' /></a>
        </div>}>
      <div>
        <Select value={this.state.index} label='Index' options={indices} keys={keys} editMode={true} dataChanged={this.changeState} />
        <TextArea label='IDs' editMode={true} style={{height: 88}} value={this.state.ids} onChange={this.changeIDs} />
      </div>
    </IBox>;
  }
}
DeleteFromElasticSearch.displayName = 'DeleteFromElasticSearch';
DeleteFromElasticSearch.propTypes = {
  createToast: PropTypes.func.isRequired,
};

export default connectToToasts(DeleteFromElasticSearch);
