import React from 'react';
import {FormattedMessage} from 'react-intl';
import SpecialCharacterHelper from '../../helpers/special-character-helper.jsx';
import {systemTypeNames} from '../../helpers/constants-helper.jsx';
import {connectToTranslations} from '../IntlWrapper';


const ShoppingList = ({data, formatPrintFile}) =>
  <div className="container shopping-list">
    <div className="row">
      <div className="col">
        <h2><FormattedMessage id='report-viewer.shopping-list.title' /></h2>
      </div>
    </div>

    <div className="row">
      <div className="col product-checklist">
        <div className="row">
          {data.ShoppingList && data.ShoppingList.map((product, i) =>
            <div className="col-6 product" key={i}>
              <h3>{SpecialCharacterHelper.fixSpecialCharacters(product.product)}</h3>
              <div>{formatPrintFile(product.instructions)}</div>
            </div>
          )}
        </div>
      </div>

      {data.poolType === systemTypeNames.spa ?
        <div className="col product-disclaimer">
          <p><FormattedMessage id='report-viewer.shopping-list.disclaimer.spa' /></p>
        </div> :
        <div className="col product-disclaimer">
          <p><FormattedMessage id='report-viewer.shopping-list.disclaimer.pool' /></p>
        </div>
      }
    </div>

    {data.poolType !== systemTypeNames.spa &&  data.country !== 'CA' ?
      <div className="row">
        <div className="col">
          <FormattedMessage id='report-viewer.shopping-list.disclaimer.chlorinating-product' />
        </div>
      </div> :
    null}
  </div>;

export default connectToTranslations(ShoppingList);
