import PropTypes from 'prop-types';

import React from 'react';

// Page object
class AdminPage extends React.Component {
  static propTypes = {
    record: PropTypes.object,
  };

  render() {

    return (
      <div className='search-result'>
        <h3><a href={this.props.record.Source.uRL}>{this.props.record.Source.title}</a></h3>
        <a href={this.props.record.Source.uRL} className='search-link'>{this.props.record.Source.uRL}</a>
        <p>{this.props.record.Source.description}</p>
      </div>);

  }
}

export default AdminPage;
