import React from 'react';

const helper = {
  fixSpecialCharacters(text = '') {
    let output = text;

    //Registration Marks
    output = output.replace(new RegExp('®', 'g'), '\u00AE');
    output = output.replace(new RegExp('™', 'g'), '\u2122');
    output = output.replace(/{registered}/g, '\u00AE');
    output = output.replace(/&reg;/g, '\u00AE');
    output = output.replace(/{trademark}|{trademarked}/g, '\u2122');
    output = output.replace(/&trade;/g, '\u2122');

    //Bolding
    output = output.replace(/\*(.*?)\*/g, '<span class=\'bold\'>$1</span>');

    return output;
  },

  fixSpecialCharactersJSX(text = '', inline = true) {
    let output = text
      .replace(new RegExp('®', 'g'), '\u00AE')
      .replace(new RegExp('™', 'g'), '\u2122')
      .replace(/{registered}/g, '\u00AE')
      .replace(/&reg;/g, '\u00AE')
      .replace(/{trademark}|{trademarked}/g, '\u2122')
      .replace(/&trade;/g, '\u2122');

    return React.createElement(inline ? 'span' : 'div', null, output
      .split('*')
      .map((x, i) => i % 2 ?
        <span className='bold'>{x}</span> :
        x));
  },

  formatBullets(xs) {
    const split = xs.split('||');

    return <ul>
      {split.map((x, i) => <li key={i}><span>{x.replace(/-/g, '\u2011')}</span></li>)}
    </ul>;
  },
};

export default helper;
