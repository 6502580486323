import {getProductNames} from 'api/product';
import {cacheAndInjectData} from 'helpers/cacheAndInjectData';
import {rawResultAsString} from 'page/Testing/RawResult';
import React from 'react';
import {diffChars} from 'diff';
import PropTypes from 'prop-types';

function ensureArray(x) {
  return x instanceof Array ? x : x !== undefined && x !== null ? [x] : [];
}

export class Diff extends React.Component {
  static propTypes = {
    old: PropTypes.string,
    new: PropTypes.string,
  };

  render() {
    let parts = diffChars(this.props.old || '', this.props.new || '');

    const text = '<p>' + parts
      .map(p => p.added ?
        `<span style="color: green">${p.value}</span>` :
        p.removed ?
          `<span style="color: red; text-decoration: line-through">${p.value}</span>` :
          `<span>${p.value}</span>`)
      .join('')
      .replace(/\n\n/g, '</p><p>')
      .replace(/\n/g, '</br>') + '</p>';

    return <span dangerouslySetInnerHTML={{__html: text}} />;
  }
}

class DiffResults extends React.Component {
  static propTypes = {
    productNames: PropTypes.object.isRequired,
    alexMill: PropTypes.array.isRequired,
    warden: PropTypes.array.isRequired,
  };

  render() {
    let newIndex = 0;

    let oldResults = this.props.alexMill
      .map(x => ({
        printFile: x.printFile,
        body: ensureArray(x.product).reduce((ac2, y, i) => {
          ac2 += rawResultAsString({...x, p: y, productNames: this.props.productNames, i});
          ac2 += '\n';
          return ac2;
        }, ''),
      }));

    let newResults = this.props.warden
      .map(x => ({
        printFile: x.printFile,
        body: ensureArray(x.product).reduce((ac2, y, i) => {
          ac2 += rawResultAsString({...x, p: y, productNames: this.props.productNames, i});
          ac2 += '\n';
          return ac2;
        }, ''),
      }));

    let changeLog = [];

    if (!newResults.length) {
      changeLog =  oldResults.map(x => ({o: x, n: undefined, change: -1}));
      oldResults = [];
    }

    for (let i = 0; i < oldResults.length; ++i) {
      let o = oldResults[i];
      let n = newResults[newIndex];

      if (o.printFile === n.printFile) {
        changeLog.push({o, n, change: 0});
        newIndex += 1;
        continue;
      }

      let nIndex = newResults.findIndex(x => x.printFile === o.printFile);

      if (nIndex !== -1) {
        changeLog = changeLog.concat(
          newResults
            .slice(newIndex, nIndex)
            .map(x => ({n: x, o: undefined, change: 1}))
        );

        changeLog.push({o, n: newResults[nIndex], change: 0});

        newIndex = nIndex + 1;

        continue;
      }

      changeLog.push({o, n, change: -1});
    }

    if (newIndex < newResults.length - 1) {
      changeLog = changeLog.concat(
        newResults
          .slice(newIndex)
          .map(x => ({n: x, o: undefined, change: 1}))
      );
    }


    return <div>
      {changeLog.map(({o,n,change}) => {
        if (change === -1) {
          return <div style={{color: 'red', textDecoration: 'line-through', marginBottom: 15}}>
            <strong>{o.printFile}</strong>
            {o.body ? <p style={{marginBottom: 0}}>{o.body}</p> : null}
          </div>;
        }

        if (change === 1) {
          return <div style={{color: 'green', marginBottom: 15}}>
            <strong>{n.printFile}</strong>
            {n.body ? <p style={{marginBottom: 0}}>{n.body}</p> : null}
          </div>;
        }

        if (change === 0) {
          return <div style={{marginBottom: 15}}>
            <strong>{o.printFile}</strong>
            {o.body || n.body ? <p style={{marginBottom: 0}}><Diff old={o.body} new={n.body} /></p> : null}
          </div>;
        }
      })}
    </div>;
  }
}

export default cacheAndInjectData({
  productNames() {
    return getProductNames();
  },
})(DiffResults);
