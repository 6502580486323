import qwest from 'qwest';

import {PoolScoreWrapper} from '../models/poolScoreParameters.jsx';


import PoolScoreTest from '../models/poolScoreTest.jsx';

import PoolScoreOutput from '../models/poolScoreOutput.jsx';

let PoolScoreApi = {
  getPoolScores() {
    return new Promise((resolve, reject) => {
      qwest.get('/api/PoolScore')
      .then((xhr, response) => {
        if (response.success) {
          try {
            let data = response.data;

            if (!data) {
              data = {};
            }
            if (typeof data === 'string') {
              data = JSON.parse(data);
            }
            data = new PoolScoreWrapper(data);
            resolve(data);
          } catch (e) {
            console.error(e);
            reject(response);
          }
        } else {
          reject(response);
        }
      });
    });
  },

  savePoolScores(data) {
    return new Promise((resolve, reject) => {
      qwest.post('/api/PoolScore', {data: JSON.stringify(data)})
      .then((xhr, response) => {
        if (response.success) {
          let data = response.data;

          if (!data) {
            data = {};
          }
          if (typeof data === 'string') {
            data = JSON.parse(data);
          }
          data = new PoolScoreWrapper(data);
          resolve(data);
        } else {
          reject(response);
        }
      });
    });
  },

  getTests() {
    return new Promise((resolve, reject) => {
      qwest.get('/api/PoolScore/Tests')
      .then((xhr, response) => {
        if (response.success) {
          resolve(response.data.map(x => PoolScoreTest.fromApiFormat(x)));
        } else {
          reject(response);
        }
      });
    });
  },

  deleteTest(testID) {
    return new Promise((resolve, reject) => {
      qwest.delete(`/api/PoolScore/Test?testID=${testID}`)
      .then((xhr, response) => {
        if (response.success) {
          resolve(true);
        } else {
          reject(response);
        }
      });
    });
  },

  saveTest(test) {
    return new Promise((resolve, reject) => {
      qwest.post('/api/PoolScore/Test', test.toApiFormat())
      .then((xhr, response) => {
        if (response.success) {
          resolve(PoolScoreTest.fromApiFormat(response.data));
        } else {
          reject(response);
        }
      });
    });
  },

  runTest(testID) {
    return new Promise((resolve, reject) => {
      qwest.get(`/api/PoolScore/Run?testID=${testID}`)
      .then((xhr, response) => {
        if (response.success) {
          resolve(new PoolScoreOutput(response.data));
        } else {
          reject(response);
        }
      });
    });
  },

  runTests() {
    return new Promise((resolve, reject) => {
      qwest.get('/api/PoolScore/RunAll')
      .then((xhr, response) => {
        if (response.success) {
          resolve(response.data.map(x => new PoolScoreOutput(x)));
        } else {
          reject(response);
        }
      });
    });
  },
};

export default PoolScoreApi;
