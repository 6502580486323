import IBox from '../components/iBox.jsx';
import PageHeader from '../components/pageHeader.jsx';
import printfileApi from '../../api/printfile.jsx';
import PropTypes from 'prop-types';
import React from 'react';
import Spinner from '../components/spinner.jsx';
import {browserHistory} from 'react-router';
import {FormControl, FormGroup} from 'react-bootstrap';
import {printFilesLanguages} from '../helpers/constants.jsx';
import {connectToToasts, TOAST_STATES} from '../components/toasts.jsx';



// Page object
class PagePrintFile extends React.Component {
  static contextTypes = {
    canViewPrintfiles: PropTypes.bool.isRequired,
    canEditPrintfiles: PropTypes.bool.isRequired,
  };

  static propTypes = {
    params: PropTypes.object,
    createToast: PropTypes.func.isRequired,
  };

  state = {
    file: null,
    language: 'English',
    languages: [],
    temporaryFile: {Problem: {}, Remedy: {}},
    linkedFiles: null,
    isEditMode: true, //true -> editing, false -> creating new printfile
  };

  componentWillMount() {
    // You have to have edit permissions to create a new print file.
    // We are creating a new print file when no referenceNumber (id) is passed.
    if (this.props.params.id === undefined || this.props.params.id === null){
      if(this.context.canEditPrintfiles){
        this.setState({isEditMode: false});
      }else{
        browserHistory.push('/');
      }
    }

    if (!this.context.canViewPrintfiles && !this.context.canEditPrintfiles) {
      browserHistory.push('/');
    } else {
      let languagesArray = Object.keys(printFilesLanguages).map((lang, idx) => {
        return <option key={idx} value={lang}>{printFilesLanguages[lang]}</option>;
      });

      this.setState({languages: languagesArray});
    }
  }

  componentDidMount() {
    if (this.state.isEditMode){
      this.getFile(this.props.params.id, this.props.params.country);
    }else{
      this.setState({
        file: {
          Country: "US",
          Version: 1,
          ReferenceNumber: '',
          Problem: {},
          Remedy: {},
        },
      });
    }
  }

  getFile = (refNumber, country) => {
    printfileApi.getFile(refNumber, country)
      .then(data => {
        this.setState({file: data.data}, () => this.changeLanguage());
        this.getLinkedFiles();
      });
  }

  handleFileChange = (key, value) => {
    let file = this.state.file;

    file[key] = value;
    this.setState({file: file});
  }

  handleProblemChange = (key, value) => {
    let file = this.state.temporaryFile;

    file.Problem[key] = value;
    this.setState({temporaryFile: file});
  };

  handleRemedyChange = (key, value) => {
    let file = this.state.temporaryFile;

    file.Remedy[key] = value;
    this.setState({temporaryFile: file});
  };

  create = e => {
    e.preventDefault();

    //Ensure all required fields are completed.
    let getDataToCreate = this.getDataToCreate();
    const requiredFields = ['ReferenceNumber', 'Country', 'Version', 'ProblemTitle', 'ProblemTitleFr',
      'ProblemDescription', 'ProblemDescriptionFr', 'RemedyTitle', 'RemedyTitleFr'];

    for (let requiredField of requiredFields){
      if(getDataToCreate[requiredField] == null || getDataToCreate[requiredField] === ""){

        this.props.createToast({
          id: 'create-printfile-failure',
          message: requiredField + ' is a required field. Please fill it out before submitting.',
          state: TOAST_STATES.ERROR,
          ttl: 6000,
        });

        return;
      }
    }

    printfileApi.create(getDataToCreate)
      .then(response => {
        // printfileApi.create throws if response.success is false.
        // so we can safely assume the print file was created here
        // and redirect to the print file list.
        browserHistory.push('/print-files');
      })
      .catch(response => {
        if(response.message != null){
          this.props.createToast({
            id: 'create-printfile-failure',
            message: response.message,
            state: TOAST_STATES.ERROR,
            ttl: 6000,
          });
          return;
        }

        this.props.createToast({
          id: 'create-printfile-failure',
          message: 'Unable to create print file.',
          state: TOAST_STATES.ERROR,
          ttl: 6000,
        });
      });
  };

  save = e => {
    e.preventDefault();
    printfileApi.save(this.getDataToSave())
      .then(response => {
        if (response.success) {
          browserHistory.push('/print-files');
        } else {
          console.error('Unable to save print file');
        }
      });
  };

  getLinkedFiles = () => {
    printfileApi.getLinkedFiles(this.state.file.Problem.ID, this.state.file.Remedy.ID, this.props.params.country)
        .then(files => {
          this.setState({linkedFiles: files.data})
        console.log(data)
      });
  };

  getDataToCreate = () => {
    this.updateOriginalFile(this.getSelectedLanguage() === 'fr-CA' ? 'Fr' : '');
    return {
      ReferenceNumber: this.state.file.ReferenceNumber,
      Country: this.state.file.Country,
      Version: this.state.file.Version,


      ProblemTitle: this.state.file.Problem.Title,
      ProblemSubTitle: this.state.file.Problem.SubTitle,
      ProblemDescription: this.state.file.Problem.Description,
      ProblemVariable: this.state.file.Problem.Variable,
      ProblemImages: this.state.file.Problem.Images,
      ProblemTitleImages: this.state.file.Problem.TitleImages,

      ProblemTitleFr: this.state.file.Problem.TitleFr,
      ProblemSubTitleFr: this.state.file.Problem.SubTitleFr,
      ProblemDescriptionFr: this.state.file.Problem.DescriptionFr,
      ProblemVariableFr: this.state.file.Problem.VariableFr,


      RemedyTitle: this.state.file.Remedy.Title,
      RemedyInstructions: this.state.file.Remedy.Instructions,
      RemedyBullets: this.state.file.Remedy.Bullets,
      RemedyImages: this.state.file.Remedy.Images,

      RemedyTitleFr: this.state.file.Remedy.TitleFr,
      RemedyInstructionsFr: this.state.file.Remedy.InstructionsFr,
      RemedyBulletsFr: this.state.file.Remedy.BulletsFr,
    };
  };

  getDataToSave = () => {
    this.updateOriginalFile(this.getSelectedLanguage() === 'fr-CA' ? 'Fr' : '');
    return {
      ProblemID: this.state.file.Problem.ID,
      ProblemTitle: this.state.file.Problem.Title,
      ProblemSubTitle: this.state.file.Problem.SubTitle,
      ProblemDescription: this.state.file.Problem.Description,
      ProblemVariable: this.state.file.Problem.Variable,
      ProblemImages: this.state.file.Problem.Images,
      ProblemTitleImages: this.state.file.Problem.TitleImages,

      ProblemTitleFr: this.state.file.Problem.TitleFr,
      ProblemSubTitleFr: this.state.file.Problem.SubTitleFr,
      ProblemDescriptionFr: this.state.file.Problem.DescriptionFr,
      ProblemVariableFr: this.state.file.Problem.VariableFr,

      RemedyID: this.state.file.Remedy.ID,
      RemedyTitle: this.state.file.Remedy.Title,
      RemedyInstructions: this.state.file.Remedy.Instructions,
      RemedyBullets: this.state.file.Remedy.Bullets,
      RemedyImages: this.state.file.Remedy.Images,

      RemedyTitleFr: this.state.file.Remedy.TitleFr,
      RemedyInstructionsFr: this.state.file.Remedy.InstructionsFr,
      RemedyBulletsFr: this.state.file.Remedy.BulletsFr,
    };
  };

  changeLanguage = () => {

    let language = this.getSelectedLanguage();

    this.setState({language: printFilesLanguages[language]});

    if (language === 'fr-CA') {
      this.updateOriginalFile();
      this.updateTemporaryData('Fr');
    } else {
      this.updateOriginalFile('Fr');
      this.updateTemporaryData();
    }
  };

  updateTemporaryData = suffix => {
    let problem = this.state.file.Problem;
    let remedy = this.state.file.Remedy;
    let arr = this.state.temporaryFile;

    suffix = suffix ? suffix : '';
    arr.Problem.ID = problem.ID;
    arr.Remedy.ID = remedy.ID;

    // change the values of temporal file to new language
    arr.Problem.Description = problem['Description' + suffix];
    arr.Problem.Images = problem['Images'];
    arr.Problem.TitleImages = problem['TitleImages'];
    arr.Problem.SubTitle = problem['SubTitle' + suffix];
    arr.Problem.Title = problem['Title' + suffix];
    arr.Problem.Variable = problem['Variable' + suffix];

    arr.Remedy.Bullets = remedy['Bullets' + suffix];
    arr.Remedy.Images = remedy['Images'];
    arr.Remedy.Instructions = remedy['Instructions' + suffix];
    arr.Remedy.Title = remedy['Title' + suffix];

    this.setState({temporaryFile: arr});
  };

  updateOriginalFile = (suffix) => {

    let problem = this.state.file.Problem;
    let remedy = this.state.file.Remedy;
    let arr = this.state.temporaryFile;

    suffix = suffix ? suffix : '';

    // set the inputs into original file
    problem['Title' + suffix] = arr.Problem.Title && arr.Problem.Title.length > 0 ? arr.Problem.Title : problem['Title' + suffix];
    problem['SubTitle' + suffix] = arr.Problem.SubTitle && arr.Problem.SubTitle.length > 0 ? arr.Problem.SubTitle : problem['SubTitle' + suffix];
    problem['Description' + suffix] = arr.Problem.Description && arr.Problem.Description.length > 0 ? arr.Problem.Description : problem['Description' + suffix];
    problem['Variable' + suffix] = arr.Problem.Variable && arr.Problem.Variable.length > 0 ? arr.Problem.Variable : problem['Variable' + suffix];
    problem['Images'] = arr.Problem.Images && arr.Problem.Images.length > 0 ? arr.Problem.Images : problem['Images'];
    problem['TitleImages'] = arr.Problem.TitleImages && arr.Problem.TitleImages.length > 0 ? arr.Problem.TitleImages : problem['TitleImages'];

    remedy['Title' + suffix] = arr.Remedy.Title && arr.Remedy.Title.length > 0 ? arr.Remedy.Title : remedy['Title' + suffix];
    remedy['Instructions' + suffix] = arr.Remedy.Instructions && arr.Remedy.Instructions.length > 0 ? arr.Remedy.Instructions : remedy['Instructions' + suffix];
    remedy['Bullets' + suffix] = arr.Remedy.Bullets && arr.Remedy.Bullets.length > 0 ? arr.Remedy.Bullets : remedy['Bullets' + suffix];
    remedy['Images'] = arr.Remedy.Images && arr.Remedy.Images.length > 0 ? arr.Remedy.Images : remedy['Images'];

    let newFile = this.state.file;

    newFile.Problem = problem;
    newFile.Remedy = remedy;

    this.setState({
      file: newFile,
    });
  };

  getSelectedLanguage = () => {
    let selectedLanguage = document.getElementById('languagesSelect');
    let language = selectedLanguage.options[selectedLanguage.selectedIndex];

    return language.value;
  };

  splitRemedy = problemRemedyID => {
    // TODO: Split remedy into a new one 
  }

  splitProblem = problemRemedyID => {
    // TODO: Split problem into a new one 
  }

  goToFile = ref => {
    if(ref !== this.state.file.ReferenceNumber) {
      browserHistory.push(`/print-file/${ref}/${this.state.file.Country}`);
      this.getFile(ref, this.state.file.Country);
    }
  }

  toggleFlag = () => {
    const flagged = !!this.state.file.Flagged;

    printfileApi.toggleFlag(this.state.file.ID)
    .then(response => {
      if (response.success) {
        let newState = Object.assign({}, this.state.file);

        newState.Flagged = !flagged;

        this.setState({
          file: newState
        });
      } else {
        console.error('Unable to toggle flag for print file');
      }
    });
  }

  render() {
    if (!this.state.file) {
      return <div>
        <PageHeader title={'Print File'} icon='fa fa-print' breadcrumbs={[]} />
        <Spinner />
      </div>;
    }

    let languageSelect = <div className="languagesSelect">
      <label>Language: </label>
      <select ref={r => this.media = r} id='languagesSelect' className='form-control' onChange={this.changeLanguage}>
        {this.state.languages}
      </select>
    </div>;

    let linkedRemedies;
    let linkedProblems;
    let iconAfterTitle = !!this.state.file.Flagged ? 'flagged-print-file fa fa-flag' : '';

    if(this.state.linkedFiles) {
      linkedRemedies = this.state.linkedFiles.Remedies.map((f, i) => {
        return (
          <tr key={i} onClick={() => this.goToFile(f.ReferenceNumber)}>
            <td>{f.RemedyID}</td>
            <td>{f.RemedyTitle}</td>
            <td>{f.ReferenceNumber}</td>
            {/* <td>{f.RemedyID != this.state.file.Remedy.ID || f.ReferenceNumber != this.state.file.ReferenceNumber ? 
              <div className='btn btn-primary' onClick={() => this.splitRemedy(f.ID)}>Split</div> : null}</td> */}
          </tr>
        );
      });

      linkedProblems = this.state.linkedFiles.Problems.map((f, i) => {
        return (
          <tr key={i} onClick={() => this.goToFile(f.ReferenceNumber)}>
            <td>{f.ProblemID}</td>
            <td>{f.ProblemTitle}</td>
            <td>{f.ReferenceNumber}</td>
            {/* <td>{f.ProblemID != this.state.file.Problem.ID || f.ReferenceNumber != this.state.file.ReferenceNumber ? 
              <div className='btn btn-primary' onClick={() => this.splitProblem(f.ID)}>Split</div> : null}</td> */}
          </tr>
        );
      }); 
    }

    let title = this.state.isEditMode ? `Print File  - ${this.state.file.ReferenceNumber} (${this.state.file.Country})` : "New Print File";
    let editPrintFileSection = this.state.isEditMode ? null : <div className='row' id='page-print-files'>
          <div className='col-xs-12 printfile-form'>
            <IBox>
            <div className="col-sm-4">
                <FormGroup>
                  <h3>Reference Number*</h3>
                  <FormControl type='text'
                    placeholder='Reference Number'
                    value={this.state.file.ReferenceNumber}
                    readOnly={!this.context.canEditPrintfiles}
                    onChange={event => this.handleFileChange('ReferenceNumber', event.target.value)}
                    required />
                </FormGroup>
              </div>
              <div className="col-sm-4">
                <FormGroup>
                  <h3>Country*</h3>
                  <FormControl componentClass='select'
                    value={this.state.file.Country}
                    readOnly={!this.context.canEditPrintfiles}
                    onChange={event => this.handleFileChange('Country', event.target.value)}
                    required>
                    <option value="CA">CA</option>
                    <option value="US">US</option>
                  </FormControl>
                </FormGroup>
              </div>
              <div className="col-sm-4">
                <FormGroup>
                  <h3>Version*</h3>
                  <FormControl type='number'
                    value={this.state.file.Version}
                    readOnly={!this.context.canEditPrintfiles}
                    onChange={event => this.handleFileChange('Version', event.target.value)}
                    required />
                </FormGroup>
              </div>
            </IBox>
          </div>
        </div>;

    return <form onSubmit={this.state.isEditMode ? this.save : this.create}>
      <PageHeader title={title} icon='fa fa-print' iconAfter={iconAfterTitle} breadcrumbs={[]}>
        {this.state.isEditMode && <button className='btn-flag btn btn-danger' type='button' onClick={() => this.toggleFlag()}>{this.state.file.Flagged ? 'Unflag' : 'Flag'}</button>}
      </PageHeader>
      {this.state.isEditMode && <div className="alert alert-warning flag-legend">The Flag/Unflag feature does not affect any existing functionality. It marks print files as candidates to be removed in the future.</div>}
      <div className='wrapper wrapper-content'>
        {editPrintFileSection}
        <div className='row' id='page-print-files'>
          <div className='col-xs-6'>
            <IBox title='Problem' tools={languageSelect}>
              <div className='row'>
                <div className='print-file col-sm-12'>
                  {this.state.isEditMode && <h3>ID</h3>}
                  {this.state.isEditMode && <p>{this.state.temporaryFile.Problem.ID}</p>}
                  <FormGroup>
                    <h3>Title{!this.state.isEditMode ? "*" : ""}</h3>
                    <FormControl type='text'
                      placeholder='Problem Title'
                      value={this.state.temporaryFile.Problem.Title || ''}
                      readOnly={!this.context.canEditPrintfiles}
                      onChange={event => this.handleProblemChange('Title', event.target.value)}
                      required />
                  </FormGroup>
                  <FormGroup>
                    <h3>SubTitle</h3>
                    <FormControl type='text'
                      placeholder='Problem SubTitle'
                      value={this.state.temporaryFile.Problem.SubTitle || ''}
                      readOnly={!this.context.canEditPrintfiles}
                      onChange={event => this.handleProblemChange('SubTitle', event.target.value)} />
                  </FormGroup>
                  <FormGroup>
                    <h3>Description{!this.state.isEditMode ? "*" : ""}</h3>
                    <input type='text'
                      placeholder='Problem Description'
                      className='form-control'
                      required
                      value={this.state.temporaryFile.Problem.Description || ''}
                      readOnly={!this.context.canEditPrintfiles}
                      onChange={event => this.handleProblemChange('Description', event.target.value)}
                      required />
                  </FormGroup>
                  <FormGroup>
                    <h3>Variable</h3>
                    <FormControl type='text'
                      placeholder='Problem Variable'
                      readOnly={!this.context.canEditPrintfiles}
                      value={this.state.temporaryFile.Problem.Variable || ''}
                      onChange={event => this.handleProblemChange('Variable', event.target.value)} />
                  </FormGroup>
                  <FormGroup>
                    <h3>Images</h3>
                    <FormControl type='text'
                      placeholder='Problem Images'
                      readOnly={!this.context.canEditPrintfiles}
                      value={this.state.temporaryFile.Problem.Images || ''}
                      onChange={event => this.handleProblemChange('Images', event.target.value)} />
                      <span className='legend'>Multiple images can be added. They should be separated by a pipe '|'.</span>
                  </FormGroup>
                  <FormGroup>
                    <h3>Title Images</h3>
                    <FormControl type='text'
                                 placeholder='Problem Title Images'
                                 readOnly={!this.context.canEditPrintfiles}
                                 value={this.state.temporaryFile.Problem.TitleImages || ''}
                                 onChange={event => this.handleProblemChange('TitleImages', event.target.value)} />
                    <span className='legend'>Multiple images can be added to use next to the title. They should be separated by a pipe '|'.</span>
                  </FormGroup>
                </div>
              </div>
            </IBox>
            {linkedRemedies && Object.keys(linkedRemedies).length ? 
              <IBox title={'This problem will affect these remedies' } >
                <table className='table table-striped table-hover hoverClick'>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Title</th>
                      <th>Reference Number</th>
                      {/* <th>{Object.keys(linkedRemedies).length > 1 ? 
                        <td><div className='btn btn-primary' onClick={this.splitRemedy} type='submit'>Split All</div></td> : null}</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {linkedRemedies}
                  </tbody>
                </table>
              </IBox>
              : null}
          </div>
          <div className='col-xs-6'>
            <IBox title='Remedy' rightTitle={'Language: ' + this.state.language}>
              <div className='row'>
                <div className='print-file col-sm-12'>
                  {this.state.isEditMode && <h3>ID</h3>}
                  {this.state.isEditMode && <p>{this.state.temporaryFile.Remedy.ID}</p>}
                  <FormGroup>
                    <h3>Title{!this.state.isEditMode ? "*" : ""}</h3>
                    <FormControl type='text'
                      placeholder='Remedy Title'
                      value={this.state.temporaryFile.Remedy.Title || ''}
                      readOnly={!this.context.canEditPrintfiles}
                      onChange={event => this.handleRemedyChange('Title', event.target.value)}
                      required/>
                      <span className='legend'>Multiple remedies can be added. They should be separated by a pipe '|' and a connector 'AND' or 'OR', for instance: RemedyOne|AND|RemedyTwo.</span>
                  </FormGroup>
                  <FormGroup>
                    <h3>Instructions</h3>
                    <FormControl type='text'
                      placeholder='Remedy Instructions'
                      value={this.state.temporaryFile.Remedy.Instructions || ''}
                      readOnly={!this.context.canEditPrintfiles}
                      onChange={event => this.handleRemedyChange('Instructions', event.target.value)} />
                      <span className='legend'>Multiple text lines can be added to be used as instructions. They should be separated by a pipe '|'.<br/>Images can be added to be next to the instruction, the image and text must be separated with a tilde '~'.</span>
                  </FormGroup>
                  <FormGroup>
                    <h3>Bullets</h3>
                    <FormControl
                      componentClass='textarea'
                      placeholder='Remedy Bullets'
                      value={this.state.temporaryFile.Remedy.Bullets || ''}
                      readOnly={!this.context.canEditPrintfiles}
                      onChange={event => this.handleRemedyChange('Bullets', event.target.value)} />
                      <span className='legend'>Multiple text lines can be added to be used as bullets. They should be separated by a pipe '|'.</span>
                  </FormGroup>
                  <FormGroup>
                    <h3>Images</h3>
                    <FormControl type='text'
                      placeholder='Remedy Images'
                      value={this.state.temporaryFile.Remedy.Images || ''}
                      readOnly={!this.context.canEditPrintfiles}
                      onChange={event => this.handleRemedyChange('Images', event.target.value)} />
                      <span className='legend'>Multiple images can be added to use next to the title. They should be separated by a pipe '|'.</span>
                  </FormGroup>
                </div>
              </div>
            </IBox>
            {linkedProblems && Object.keys(linkedProblems).length ? 
              <IBox title={'This remedy will affect these problems' } >
                <table className='table table-striped table-hover hoverClick'>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Title</th>
                      <th>Reference Number</th>
                      {/* <th>{Object.keys(linkedProblems).length > 1 ? 
                        <td><div className='btn btn-primary' onClick={this.splitProblem} type='submit'>Split All</div></td> : null}</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {linkedProblems}
                  </tbody>
                </table>
              </IBox>
              : null}
          </div>
        </div>
        {this.context.canEditPrintfiles ? <div className='row'>
          <div className='col-xs-6'>
            <button className='btn btn-primary' type='submit'>Save</button>
            <button className='btn btn-danger' type='button' onClick={() => {browserHistory.push('/print-files');}}>Cancel</button>
          </div>
        </div> : null}
      </div>
    </form>;
  };
}

export default connectToToasts(PagePrintFile);
