import PropTypes from 'prop-types';

import React from 'react';

import ReactDOM from 'react-dom';

import Spinner from 'spin.js';

// Row object
class LoadingBtn extends React.Component {
  static propTypes = {
    component: PropTypes.any,
    loaded: PropTypes.bool,
    options: PropTypes.object,
    scale: PropTypes.number,
    lines: PropTypes.number,
    length: PropTypes.number,
    width: PropTypes.number,
    radius: PropTypes.number,
    corners: PropTypes.number,
    rotate: PropTypes.number,
    direction: PropTypes.oneOf([1, -1]),
    color: PropTypes.string,
    speed: PropTypes.number,
    trail: PropTypes.number,
    shadow: PropTypes.bool,
    hwaccell: PropTypes.bool,
    className: PropTypes.string,
    zIndex: PropTypes.number,
    top: PropTypes.string,
    left: PropTypes.string,
  };

  componentDidMount() {
    this.spin();
  }

  spin = () => {
    let self = this;
    let allowedOptions = Object.keys(this.constructor.propTypes);
    let options = {position: 'relative'};

    allowedOptions.forEach(key => {
      if (key in self.props) {
        options[key] = self.props[key];
      }
    });

    let spinner = new Spinner(options);
    let target =  ReactDOM.findDOMNode(this.spn);

    // Clear out any other spinners from previous renders
    target.innerHTML = '';
    spinner.spin(target);
  };

  render() {
    return (
      <span ref={r => this.spn = r} style={{display: 'inline-block'}}/>
    );
  }
}

export default LoadingBtn;
