import cloneDeep from 'lodash/cloneDeep';

import moment from 'moment';

class PoolSummary {
  constructor({
    id, location, finish, volume, flag, typeID, prevAnalysisDate, nextAnalysisDate,
    sanitizerType, softSoakTRIO, groundLevel, truBlue, truBlueExpiry,truBlueStatus, scanCount, nickName, isInMaintenance,
  }) {
    this.id = id;
    this.location = location;
    this.finish = finish;
    this.volume = volume;
    this.lastAnalysis = prevAnalysisDate;
    this.prevAnalysisDate = prevAnalysisDate;
    this.nextAnalysisDate = nextAnalysisDate;
    this.flag = flag;
    this.typeID = typeID;
    this.sanitizerType = sanitizerType;
    this.softSoakTRIO = softSoakTRIO;
    this.groundLevel = groundLevel;
    this.truBlue = truBlue;
    this.truBlueExpiry = truBlueExpiry;
    this.truBlueStatus = truBlueStatus;
    this.scanCount = scanCount;
    this.nickName = nickName;
    this.isInMaintenance = isInMaintenance;
  }

  clone() {
    return cloneDeep(this);
  }

  toApiFormat() {
    return {
      ID: this.id,
      Location: this.location,
      Finish: this.finish,
      Volume: this.volume,
      LastAnalysis: this.lastAnalysis,
      PrevAnalysisDate: this.PrevAnalysisDate,
      NextAnalysisDate: this.NextAnalysisDate,
      Flag: this.flag,
      TypeID: this.typeID,
      SanitizerType: this.sanitizerType,
      SoftSoakTRIO: this.softSoakTRIO,
      GroundLevel: this.groundLevel,
      TruBlue: this.truBlue ? this.truBlue.format() : undefined,
      TruBlueExpiry: this.truBlueExpiry ? this.truBlueExpiry.format() : undefined,
      TruBlueStatus: this.truBlueStatus,
      ScanCount: this.scanCount,
      NickName: this.nickName,
      IsInMaintenance: this.isInMaintenance,
    };
  }

  static fromApiFormat(api) {
    return new PoolSummary({
      id: api.ID,
      location: api.Location,
      finish: api.Finish,
      volume: api.Volume,
      lastAnalysis: api.PrevAnalysisDate,
      prevAnalysisDate: api.PrevAnalysisDate,
      nextAnalysisDate: api.NextAnalysisDate,
      flag: api.Flag,
      typeID: api.TypeID,
      sanitizerType: api.SanitizerType,
      softSoakTRIO: api.SoftSoakTRIO,
      groundLevel: api.GroundLevel,
      truBlue: api.TruBlue ? moment(api.TruBlue) : undefined,
      truBlueExpiry: api.TruBlueExpiry ? moment(api.TruBlueExpiry) : undefined,
      truBlueStatus: api.TruBlueStatus,
      scanCount: api.ScanCount,
      nickName: api.NickName,
      isInMaintenance: api.IsInMaintenance,
    });
  }
}

export default PoolSummary;
