import qwest from 'qwest';

export function getProductList() {
  return qwest.get('/api/product/list')
    .then((xhr, res) => res)
    .then(({code, success, data}) => {
      if (!success) {
        throw new Error('Failed to retrieve product list.');
      }

      return data;
    });
}

export function getProductNames() {
  return qwest.get('/api/testing/productnames')
    .then((xhr, res) => res)
    .then(({code, success, data}) => {
      if (!success) {
        throw new Error('Failed to retrieve product name list.');
      }

      return (data || []).reduce((acc, x) => {
        acc[x.WardenID] = x.Name;
        return acc;
      }, {});
    });
}
