import 'babel-polyfill';
import './util/configureQwest.jsx';

import ReactDOM from 'react-dom';
import {browserHistory} from 'react-router';

import createRouter from './router.jsx';

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(createRouter(browserHistory), document.getElementById('body'));
});
