import React from 'react';
import {findDOMNode, createPortal} from 'react-dom';
import PropTypes from 'prop-types';
import events from 'lib/events';
import qwest from 'qwest';
import {FormattedMessage} from 'react-intl';

/**
 * Injects report into shadow dom using react 16 portals
 */
class ChildLoader extends React.Component {
  static propTypes = {
    loaded: PropTypes.bool.isRequired,
    portal: PropTypes.any,
    children: PropTypes.any,
  };

  shouldComponentUpdate(nextProps) {
    return nextProps.loaded !== this.props.loaded || nextProps.portal !== this.props.portal || nextProps.children !== this.props.children;
  }

  render() {
    const {children, loaded, portal} = this.props;

    if (!loaded) {return null;}

    return createPortal(children, portal);
  }
}

/**
 * Wraps report in shadow dom and adds in stylesheet
 */
class ReportWrapper extends React.Component {
  static propTypes = {
    children: PropTypes.any,
  };

  state = {
    loaded: false,
    portal: undefined,
    error: false,
  };

  componentDidMount() {
    const node = findDOMNode(this.wrapper); // eslint-disable-line react/no-find-dom-node
    const root = node.attachShadow({mode: 'open'}); // Create shadow dom
    const inner = document.createElement('div'); // Add dom element inside shadow root so can render portal into it

    this.root = root;

    root.appendChild(inner);
    this.setState({loaded: false, portal: inner});

    const stylesheet = document.createElement('link');

    const cssURL = '/Content/ReportViewerAssets/css/base.css';

    stylesheet.rel = 'stylesheet';
    stylesheet.type = 'text/css';
    stylesheet.href = cssURL;

    root.appendChild(stylesheet); // Add stylesheet to shadow root

    qwest.get(cssURL)
      .then(() => this.setState({loaded: true}))
      .catch(err => {
        console.error(err);
        console.error('Could not retrieve css for report viewer.');

        events.publish('Could not retrieve report.');
      });
  }

  componentDidCatch(err) {
    this.setState({error: true});
  }

  render() {
    return <div>
      <div ref={r => this.wrapper = r}/>
      {!this.state.error ?
        <ChildLoader loaded={this.state.loaded} portal={this.state.portal}>
          {this.props.children}
        </ChildLoader> :
        <div><FormattedMessage id='report.could.not.load' /></div>}
    </div>;
  }
}

export default ReportWrapper;
