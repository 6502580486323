import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import {FormControl} from 'react-bootstrap';
import ImageUpload from './fileUpload.jsx';

class AssetList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchFilter: '',
      fileTypeFilter: ['Images'],
    };
  }

  static propTypes = {
    addLink: PropTypes.func,
    addAsset: PropTypes.func,
    fileSelected: PropTypes.string,
    title: PropTypes.string,
    assets: PropTypes.array,
    imageHash: PropTypes.number,
  };

  getAssetURL = (filename) => {
    let CDN = document.getElementById('body').dataset.cdn;
    let url = CDN + filename;

    return url;
  };

  appendAssetUrl = (filename) => {
    this.setState({assetSelected: this.getAssetURL(filename)});
  };

  extensionFilter = (fileExtension) => {
    let extFilters = {
      Images: ['jpg', 'jpeg', 'png', 'gif','JPG', 'JPEG', 'PNG', 'GIF'],
      Music: ['mp3'],
      Videos: ['webm', 'mkv', 'mp4', 'avi'],
    };

    extFilters.Files = extFilters.Images + extFilters.Data + extFilters.Music + extFilters.Videos;

    for (let filterName of this.state.fileTypeFilter) {
      if (filterName === 'Files') {
        if (extFilters[filterName].indexOf(fileExtension) === -1) {
          return true;
        }
      } else if (extFilters[filterName].indexOf(fileExtension) > -1) {
        return true;
      }
    }

    return false;
  };

  handleClick = (e, index, name, extension) => {
    this.props.addLink(name, ['jpg', 'jpeg', 'png', 'gif','JPG', 'JPEG', 'PNG', 'GIF'].indexOf(extension) > -1);
  };

  getAssetURL = (filename) => {
    let CDN = document.getElementById('body').dataset.cdn;
    let url = CDN + filename;

    return url;
  };

  fileBox = (item, index) => {
    let date = moment(item.substring(0, 10), 'MM-DD-YYYY').format('MMM Do, YY');
    let name = item.substring(11, item.length);
    let extension = name.substring(name.lastIndexOf('.') + 1);

    // Apply search filter
    if (name.indexOf(this.state.searchFilter) === -1) {
      return null;
    }

    // Apply file extension filter
    if (!this.extensionFilter(extension)) {
      return null;
    }

    return (
      <div key={item} className='file-box'>
        <div className={`file ${ this.getAssetURL(item) === this.props.fileSelected ? 'file-selected' : '' }`} onClick={(e) => this.handleClick(e, index, item, extension)}>
          {this.fileIcon(extension, item)}
          <div className='file-name'>
            <span>{name}</span>
            <br />
            <small>Added: {date}</small>
          </div>
        </div>
      </div>
    );
  };

  fileIcon = (extension, assetName) => {
    let charts = ['csv', 'xls', 'xlsx', 'ods'];
    let images = ['jpg', 'jpeg', 'png', 'gif','JPG', 'JPEG', 'PNG', 'GIF'];
    let music = ['mp3'];
    let pdf = ['pdf'];
    let powerpoint = ['ppt', 'pptx'];
    let videos = ['webm', 'mkv', 'mp4', 'avi'];
    let word = ['doc', 'docx'];
    let zip = ['rar', 'zip'];

    if (images.indexOf(extension) > -1) {
      let CDN = document.getElementById('body').dataset.cdn;

      return (
        <div className='image'>
          <img alt='image' className='img-responsive' src={`${CDN + assetName}?${this.props.imageHash}`} />
        </div>
      );
    }
    let icon;

    if (word.indexOf(extension) > -1) {
      icon = <i className='fa fa-word-o'/>;
    } else if (charts.indexOf(extension) > -1) {
      icon = <i className='fa fa-excel-o'/>;
    } else if (pdf.indexOf(extension) > -1) {
      icon = <i className='fa fa-pdf-o'/>;
    } else if (zip.indexOf(extension) > -1) {
      icon = <i className='fa fa-zip-o'/>;
    } else if (powerpoint.indexOf(extension) > -1) {
      icon = <i className='fa fa-powerpoint-o'/>;
    } else if (music.indexOf(extension) > -1) {
      icon = <i className='fa fa-audio-o'/>;
    } else if (videos.indexOf(extension) > -1) {
      icon = <i className='fa fa-video-o'/>;
    } else {
      icon = <i className='fa fa-file-o'/>;
    }
    return (
      <div className='icon'>
        {icon}
      </div>
    );
  };

  uploadAsset = (file) => {
    this.props.addAsset(file);
  };

  render() {
    return (
      <div className='row'>
        <div className='col-xs-12'>
          <div className='form-group'>
            <label>{this.props.title}</label>
            <label className='pull-right'>{'(Recommended image size of 1120 x 300)'}</label>
            <FormControl
              placeholder='Search images...'
              value={this.state.searchFilter}
              onChange={e => this.setState({searchFilter: e.target.value})} />
          </div>
        </div>
        <div className='col-xs-12'>
          <div className='file-container'>
            <div className='file-box'>
              <div className='file'>
                <ImageUpload upload={this.uploadAsset} />
              </div>
            </div>
            { this.props.assets.map((asset, index) => this.fileBox(asset, index)) }
          </div>
        </div>
      </div>
    );
  }
}

export default AssetList;
