import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import {FormattedMessage, FormattedNumber, injectIntl} from 'react-intl';
import {connectToTranslations} from '../IntlWrapper';
import {getImagePath} from '../../helpers/image-helper.jsx';
import SpecialCharacterHelper from '../../helpers/special-character-helper.jsx';

const canvasConstants = {
  colors: {
    gray: '#7b8791',
    lightBlue: '#52b9e9',
    red: '#d2242a',
    blue: '#043673',
  },
  height: 25,
  width: 80,
  limitHeight: 10,
  valueHeight: 15,
};

class IdealRangeParameter extends React.Component {
  static propTypes = {
    ideal: PropTypes.array,
    valid: PropTypes.bool,
    value: PropTypes.number,
    scale: PropTypes.array,
    greatherThan: PropTypes.bool,
    lessThan: PropTypes.bool,
  };

  componentDidMount() {
    this.updateCanvas();
  }

  getValuePercentageInScale = (value) => {
    const {scale} = this.props;
    const [min, max] = scale;

    return  100 * Math.abs(value - min) / (max - min);
  }

  getPointFromPercerntage =  (percentage) => {
    return percentage * canvasConstants.width / 100;
  }

  getIdealRangeCoordinates = () => {
    const {ideal, lessThan, greatherThan} = this.props;
    const [min, max] = ideal;
    let start = this.getPointFromPercerntage(lessThan ? 0 : this.getValuePercentageInScale(min || 0));
    let width = this.getPointFromPercerntage(greatherThan ? 100 : this.getValuePercentageInScale(max || 0)) - start;

    return {start, width};
  }

  getValueLinePosition = () => {
    const {value, scale} = this.props;
    const [min, max] = scale;

    const valueToDraw = value <= min ? min : value >= max ? max : value;

    return this.getPointFromPercerntage(this.getValuePercentageInScale(valueToDraw));
  }

  updateCanvas() {
    const {valid, value} = this.props;
    const ctx = this.canvas.getContext('2d');

    //center line (scale)
    ctx.beginPath();
    ctx.moveTo(0, this.canvas.height / 2);
    ctx.lineTo(this.canvas.width, this.canvas.height / 2);
    ctx.strokeStyle = canvasConstants.colors.gray;
    ctx.stroke();

    //left limit
    ctx.beginPath();
    ctx.moveTo(0, this.canvas.height / 2 - canvasConstants.limitHeight / 2);
    ctx.lineTo(0, this.canvas.height / 2 + canvasConstants.limitHeight / 2);
    ctx.strokeStyle = canvasConstants.colors.gray;
    ctx.stroke();

    //right limit
    ctx.beginPath();
    ctx.moveTo(this.canvas.width, this.canvas.height / 2 - canvasConstants.limitHeight / 2);
    ctx.lineTo(this.canvas.width, this.canvas.height / 2 + canvasConstants.limitHeight / 2);
    ctx.strokeStyle = canvasConstants.colors.gray;
    ctx.stroke();

    //ideal range
    const idealRange = this.getIdealRangeCoordinates();

    ctx.fillStyle = canvasConstants.colors.lightBlue;
    ctx.fillRect(idealRange.start,
                  this.canvas.height / 2 - 4,
                  idealRange.width,
                  8);
    ctx.stroke();

    const valuePosition = this.getValueLinePosition();

    ctx.beginPath();
    ctx.moveTo(valuePosition, this.canvas.height / 2 - canvasConstants.valueHeight / 2);
    ctx.lineTo(valuePosition, this.canvas.height / 2 + canvasConstants.valueHeight / 2);
    ctx.strokeStyle = valid ? canvasConstants.colors.blue : canvasConstants.colors.red;
    ctx.stroke();
  }

  getValueDisplay = () =>{
    const {ideal, lessThan, greatherThan} = this.props;
    const [min, max] = ideal;
    if(greatherThan){
      return <div> &gt; {min}</div>
    }
    if(lessThan){
      return <div> &lt; {max} </div>
    }
    return <div>{min || 0}-{max || 0}</div>;
  }

  render() {
    const idealRange = this.getIdealRangeCoordinates();
    return (
      <div className="ideal-range-parameter">
        {this.getValueDisplay()}
        <canvas ref={c => this.canvas = c} width={canvasConstants.width} height={canvasConstants.height}/>
      </div>
    );
  }
}

export default IdealRangeParameter;
