import PropTypes from 'prop-types';
import Loader from '../../components/loader.jsx';
import requirePermissions from '../../helpers/requirePermissions';

import ReportFilter from '../../components/reportFilter.jsx';
import IBoxes from '../../components/IBoxes.jsx';
import PageHeader from '../../components/pageHeader.jsx';
import reportApi from '../../../api/report.jsx';
import React from 'react';

function sortRows(a, b) {
  if (a[1] > b[1]) {
    return -1;
  }
  if (a[1] === b[1]) {
    return a[0] < b[0] ? -1 : 1;
  }
  return 1;
}

class EmailsReportPage extends React.Component {
  state = {
    data: {},
    loaded: true,
    showNoDataMessage: false,
  };

  updateReport = (filters) => {
    this.setState({
      data: [],
      loaded: false,
    });
    reportApi.getEmailsReport(
      filters.startDate,
      filters.endDate,
      filters.platinumStatus,
      filters.dsm,
      filters.rm,
      filters.states
    )
    .then(data => {
      if(data && Object.keys(data).length) {
        this.setState({
          data: data,
          showNoDataMessage: false,
        });
      } else {
        this.setState({
          showNoDataMessage: true,
        });
      }
      this.setState({loaded: true});
    })
    .catch(e => {
      this.setState({
        loaded: true,
        showNoDataMessage: true,
      });
      console.error(e);
    });
  };

  render() {
    let hasReportData = Object.keys(this.state.data).length;

    return (
      <div>
        <PageHeader title='Emails Sent' breadcrumbs={[]} />
        <div className='alert alert-warning'>
          <p>Warning! Do not run during peak hours. This report puts a lot of strain on the server.</p>
        </div>
        <div className='wrapper wrapper-content'>
          <ReportFilter onUpdate={this.updateReport} buttonText={hasReportData ? 'Update' : 'Run Report'} />
          <Loader loaded={this.state.loaded}>
            <div>
              {
                hasReportData ?
                  <EmailsReport {...this.state} /> :
                this.state.showNoDataMessage ?
                  <IBoxes title=''>
                    <label className="no-data-report">No Emails found under those parameters</label>
                  </IBoxes> :
                  ''
              }
            </div>
          </Loader>
        </div>
      </div>
    );
  }
}

class EmailsReport extends React.Component {
  static displayName = 'EmailsReport';

  static propTypes = {
    data: PropTypes.any,
    loaded: PropTypes.bool,
  };

  render() {
    let data = this.props.data.summary;

    let rows = [['Welcome', data.welcome, data.welcomeCTR],
      ['Inactive', data.inactive, data.inactiveCTR],
      ['Past Due', data.pastDue, data.pastDueCTR],
      ['Problem Follow-Up', data.problemFollowUp, data.problemFollowUpCTR],
      ['Pool Closing', data.poolClosing, data.poolClosingCTR],
      ['Product Reminder', data.productReminder, data.productReminderCTR],
      ['Season Opening', data.seasonsOpening, data.seasonsOpeningCTR],
      ['Calendar Reminder', data.calendarReminder, data.calendarReminderCTR],
    ]
      .sort(sortRows)
      .map(x => <tr key={x[0]}><td>{x[0]}</td><td>{x[1]}</td>{/* <{td>{x[2] * 100 || 0}</td> */}</tr>);

    data = this.props.data;

    let calendarRows = [['Fourth Of July', data.calendar.fourthJuly, data.calendar.fourthJulyCTR],
      ['Labour Day', data.calendar.labourDay, data.calendar.labourDayCTR],
      ['Memorial Day', data.calendar.memorialDay, data.calendar.memorialDayCTR],
    ].sort(sortRows).map(x => <tr key={x[0]} style={{backgroundColor: 'grey', color: 'white'}}><td>{x[0]}</td><td>{x[1]}</td>{/* <td>{x[2] * 100 || 0}</td> */}</tr>);

    let index = rows.findIndex(x => x.key === 'Calendar Reminder');

    rows.splice(index + 1, 0, ...calendarRows);

    let problemRows = [
      ['Black Algae', data.problems.blackAlgae, data.problems.blackAlgaeCTR],
      ['Chlorine Demand', data.problems.chlorineDemand, data.problems.chlorineDemandCTR],
      ['Cloudy/Hazy', data.problems.cloudyHazy, data.problems.cloudyHazyCTR],
      ['Foaming', data.problems.foaming, data.problems.foamingCTR],
      ['Green Algae', data.problems.greenAlgae, data.problems.greenAlgaeCTR],
      ['Metal Staining', data.problems.metalStaining, data.problems.metalStainingCTR],
      ['Mustard Algae', data.problems.mustardAlgae, data.problems.mustardAlgaeCTR],
      ['Other Staining', data.problems.otherStaining, data.problems.otherStainingCTR],
      ['Scale Buildup', data.problems.scale, data.problems.scaleCTR],
      ['Slime/Mold', data.problems.slimeMold, data.problems.slimeMoldCTR],
      ['Spa Algae', data.problems.spaAlgae, data.problems.spaAlgaeCTR],
      ['Swampy', data.problems.swampy, data.problems.swampyCTR],
      ['Waterline Buildup', data.problems.waterlineBuildup, data.problems.waterlineBuildupCTR],
    ]
      .sort(sortRows)
      .map(x => <tr key={x[0]} style={{backgroundColor: 'grey', color: 'white'}}><td>{x[0]}</td><td>{x[1]}</td>{/* <td>{x[2] * 100 || 0}</td> */}</tr>);

    index = rows.findIndex(x => x.key === 'Problem Follow-Up');

    rows.splice(index + 1, 0, ...problemRows);

    return <div>
      <IBoxes title='Results'>
        <div>
          <h3>Customer Statistics</h3>

          <p>Total: {data.summary.welcome + data.summary.inactive + data.summary.pastDue + data.summary.problemFollowUp +
          data.summary.poolClosing + data.summary.productReminder + data.summary.seasonsOpening + data.summary.calendarReminder}</p>

          <div className="alert alert-info">
            <p>Email tracking is a useful way of knowing if the recipient actually received the email or interacted with any of the links from the email, it is not however an accurate way of knowing if the actual message was opened or read by the recipient (the recipient might have simply interacted with one of the links instead of reading the rest of the email).</p>
          </div>

          <table className='table table-striped'>
            <thead>
              <tr>
                <th>Email Type</th>
                <th>Number Sent</th>
                {/* <th>Click Through Rate (%)</th> */}
              </tr>
            </thead>
            <tbody>
              {rows}
            </tbody>
          </table>
        </div>
      </IBoxes>
    </div>;
  }
}

export default requirePermissions('canViewReports')(EmailsReportPage);
