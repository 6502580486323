import {getStats, getComparisonStats} from 'api/testing';
import {connectToToasts} from 'components/toasts';
import React from 'react';
import EditableTextField from 'components/form/editableTextField';
import {browserHistory, Link} from 'react-router';
import IBox from 'components/iBox';
import moment from 'moment';
import PropTypes from 'prop-types';
import Loader from 'components/loader';

class Testing extends React.Component {
  static propTypes = {
    createToast: PropTypes.func.isRequired,
  };

  state = {
    searchValue: '',
    loaded: false,
    count: 0,
    totalComparisonHistory: 0,
    totalComparisonHistoryPassed: 0,
    lastRun: {
      completed: undefined,
      id: undefined,
      total: 0,
      passed: 0,
      failed: 0,
      entrypoint: undefined,
    },
  };

  componentWillMount() {
    let gs = getStats();
    // let gcs = getComparisonStats();

    Promise.all([gs /*gcs*/])
      .then(data => {
        let {count, lastRun} = data[0];
        // let {pass, total} = data[1];

        lastRun = lastRun || {};
        lastRun.completed = lastRun.completed ? moment(lastRun.completed) : lastRun.completed;

        this.setState({
          loaded: true,
          count,
          lastRun,
          // totalComparisonHistory: total || 0,
          // totalComparisonHistoryPassed: pass || 0,
        });
      })
      .catch(err => {
        console.error(err);
        this.props.createToast({
          id: 'testing.stats.error',
          message: 'Failed to load stats.',
          ttl: 4000,
        });
      });
  }

  search = () => {
    browserHistory.push('/testing/search/' + encodeURIComponent(this.state.searchValue || '~'));
  };

  render() {
    const {lastRun, count, loaded, totalComparisonHistory, totalComparisonHistoryPassed} = this.state;

    return <div className='testing-view'>
      <div className='row'>
        <div className='col-md-6'>
          <IBox title='Automated Unit Tests'
                rightTitle={<div>
                  <Link className='btn btn-secondary' to={'/testing/new'}>Create Test</Link>
                  <Link className='btn btn-primary' to={'/testing/test'}>View &amp; Run Tests</Link>
                </div>}>
            <div style={{minHeight: 139, display: 'flex', flexDirection: 'column'}}>
              <Loader loaded={loaded}>
                <p style={{flexGrow: 2}}>
                  <strong>Number Tests: </strong> {count}<br/>
                  <strong>Last Run: </strong> {moment(lastRun.completed).format('LLL')}<br/>
                  <strong>Last Status: </strong> {lastRun.passed}/{lastRun.total} passing
                  ({Math.round(lastRun.passed / lastRun.total * 100)}%)
                </p>
              </Loader>
            </div>
          </IBox>
        </div>

        <div className='col-md-6'>
          <IBox title='Search'>
            <div className='testing-view__search'
                 style={{minHeight: 150, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
              <form style={{display: 'flex'}} onSubmit={this.search}>
                <EditableTextField dataKey='searchValue'
                                   editMode={true}
                                   autoFocus
                                   style={{flexGrow: 2}}
                                   value={this.state.searchValue}
                                   placeholder='Search by URL or customer name'
                                   onChange={({key, value}) => this.setState({[key]: value})}/>

                <button type='submit' style={{marginLeft: 20, minWidth: 75}}
                        className='btn btn-primary pull-right'>Search
                </button>
              </form>
            </div>
          </IBox>
        </div>

        <div className='col-md-6'>
          <IBox title='Historical Engine Comparison Data'
                rightTitle={<Link className='btn btn-primary' to='/testing/historical'>View</Link>}>
            <div>
              <p>
                <strong>Number Tests Verified: </strong> {totalComparisonHistory}<br/>
                <strong>Passing: </strong> {totalComparisonHistoryPassed}/{totalComparisonHistory} ({totalComparisonHistory ?
                Math.round(totalComparisonHistoryPassed / totalComparisonHistory * 100) :
                0}%)<br/>
                <strong>Failed: </strong> {
                  totalComparisonHistory - totalComparisonHistoryPassed}/{totalComparisonHistory} ({totalComparisonHistory &&
              totalComparisonHistoryPassed && totalComparisonHistory - totalComparisonHistoryPassed > 0 ?
                Math.round(totalComparisonHistory - totalComparisonHistoryPassed / totalComparisonHistory * 100) :
                0}%)
              </p>
            </div>
          </IBox>
        </div>
      </div>
    </div>;
  }
}

export default connectToToasts(Testing);
