import qwest from 'qwest';

let SearchAPI = {

  search(query, page) {
    return qwest.post('/api/Search/', {q: query, page: page}, {dataType: 'post'}).then(this._process);
  },

  _process(xhr, response) {
    return response;
  },

};

export default SearchAPI;
