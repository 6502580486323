import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import taskApi from '../../../api/task.jsx';

const progressBarColours = {
  progress: '',
  completed: 'progress-bar-success',
  cancelled: 'progress-bar-danger',
  failed: 'progress-bar-warning',
};

const iconColors = {
  progress: 'navy-bg',
  completed: 'blue-bg',
  cancelled: 'red-bg',
  failed: 'yellow-bg',
};

const btnColors = {
  progress: 'btn-primary',
  completed: 'btn-success',
  cancelled: 'btn-danger',
  failed: 'btn-warning',
};

function parseDealerLink(href) {
  if (!href || typeof href !== 'string') {
    return '';
  }

  let split = href.split(':');

  if (split.length <= 1) {
    return href;
  }

  return split.slice(1).join(':');
}

class Timeline extends React.Component {
  static defaultProps = {includeTypes: null, excludeTypes: null};

  static propTypes = {
    includeTypes: PropTypes.array,
    excludeTypes: PropTypes.array,
  };

  state = {
    tasks: [],
  };

  componentDidMount() {
    const {includeTypes, excludeTypes} = this.props;

    taskApi.getCompletedTasks(includeTypes, excludeTypes).then((tasks) => {
      this.setState({tasks});
    });
  }

  render() {
    return (
      <div id='vertical-timeline' className='vertical-container light-timeline no-margins'>
        {this.state.tasks.map((task) => {
          let completionStatus = task.metaData.completionStatus;

          return (
            <div className='vertical-timeline-block' key={task.id}>
              <div className={`vertical-timeline-icon ${iconColors[completionStatus]}`}>
                <i className={`fa ${task.metaData.icon}`} />
              </div>

              <div className='vertical-timeline-content'>
                <h2>{task.metaData.title}</h2>
                {task.type === 16 ? <h5>{task.notificationTitle}</h5> : null}
                {task.type !== 7 ?
                  <p>{task.body}</p> :
                  null}
                <div className={`progress progress-small ${completionStatus === 'progress' ? 'progress-striped active' : ''}`}>
                  <div className={`progress-bar ${progressBarColours[completionStatus]}`}
                       style={{width: `${Math.abs(task.status)}%`}}>
                  </div>
                </div>

                {parseDealerLink(task.link).length ?
                  <a href={parseDealerLink(task.link)}
                     className={`btn btn-sm ${btnColors[completionStatus]}`}>Download
                  </a> :
                  null}

                <span className='vertical-date'>
                  {moment.utc(task.created).fromNow()} <br/>
                  <small>{moment.utc(task.created).local().format('Do MMMM YYYY hh:mm a')}</small> <br/>
                </span>

              </div>
            </div>);
        })}
      </div>
    );
  }
}

export default Timeline;
