import React from 'react';
import {browserHistory} from 'react-router';

export default function requirePermissions(...permissions) {
  return Element => {
    class AuthedElement extends React.Component {
      static contextTypes = permissions.reduce((acc, x) => {
        acc[x] = true;
        return acc;
      }, {});

      componentWillMount() {
        for (let perm of permissions) {
          if (!this.context[perm]) {
            browserHistory.push('/');
            console.warn('Not authed.');
          }
        }
      }

      render() {
        return <Element {...this.props} />;
      }
    }

    return AuthedElement;
  };
}

