import React from "react";
import moment from "moment";
import { systemTypes, KeyParameterTypes } from "./constants-helper";

var service = {
  getHeaderData(data) {
    console.log(data);
    return {
      Borates: data.Borates,
      custName: data.custName,
      custAccountNo: data.custAccountNo,
      poolVolume: data.poolVolume,
      testerName: data.testerName,
      poolType: data.poolType,
      branchLogo:
        "data:image/png;base64," +
        (data.BranchLogoBase64 || data.BranchLogoBytes),
      finish: data.finish,
      groundLevelType: data.groundLevelType,
      testDate: moment(data.testDate),
      nextAnalysis: moment(data.nextAnalysis),
      suPeriod: data.suPeriod,
      ReportType: data.ReportType,
      country: data.country,
      season: data.season
    };
  },
  GetShoppingListData(data) {
    return {
      ReportType: data.ReportType,
      ShoppingList: data.ShoppingList,
      poolType: data.poolType,
      country: data.country
    };
  },
  getMaintenanceData(data) {
    return {
      data: [
        data.maintOne,
        data.maintTwo,
        data.maintThree,
        data.maintFour
      ].concat(data.additionalMaintItems),
      chemicalBaseTypeID: data.chemicalBaseTypeID
    };
  },
  getWaterQualityScoreData(data) {
    let wqs = {
      ReportType: data.ReportType,
      ShowPoolScores: data.ShowPoolScores,
      SwimmerComfortScore: data.SwimmerComfortScore,
      WaterClarityScore: data.WaterClarityScore,
      SurfaceEquipmentProtectionScore: data.SurfaceEquipmentProtectionScore,
      keyParameters: [
        {
          kpType: KeyParameterTypes.SaturationIndex,
          ...data.SI
        },
        {
          kpType: KeyParameterTypes.SaturationCopy,
          value: data.saturationIndexText
        },
        {
          kpType: KeyParameterTypes.TDS,
          ...data.TDS
        },
        {
          kpType: KeyParameterTypes.Salt,
          ...data.Salt
        },
        {
          kpType: KeyParameterTypes.pH,
          ...data.pH
        },
        {
          kpType: KeyParameterTypes.TotalAlkalinity,
          ...data.TotalAlkalinity
        },
        {
          kpType: KeyParameterTypes.TotalHardness,
          ...data.TotalHardness
        },
        {
          kpType: KeyParameterTypes.Optimizer,
          ...data.Borates
        },
        {
          kpType: KeyParameterTypes.FreeChlorine,
          ...data.MainParam
        },
        {
          kpType: KeyParameterTypes.TotalChlorine,
          ...data.MainParam2
        },
        {
          kpType: KeyParameterTypes.CYA,
          ...data.CYA
        },
        {
          kpType: KeyParameterTypes.Phosphate,
          ...data.Phosphate
        },
        {
          kpType: KeyParameterTypes.TotalBromine,
          ...data.TotalBromine
        }
      ],
      ShowKeyParameterRangesSection: data.showKeyParameterRangesSection
    };

    const isUSSpa =
      data.country === "US" && parseInt(data.poolType) === systemTypes.spa;

    if (isUSSpa) {
      wqs.keyParameters = wqs.keyParameters.map(keyParameter => {
        if (
          keyParameter.kpType !== undefined &&
          keyParameter.kpType !== null &&
          keyParameter.kpType === KeyParameterTypes.Optimizer
        ) {
          // Hide "Optimizer Plus" (Borates) from key parameters for US based SPAs leaving a blank space
          return {
            kpType: KeyParameterTypes.Optimizer,
            showBlank: true
          };
        } else {
          return keyParameter;
        }
      });
    }

    wqs.keyParameters = wqs.keyParameters.filter(
      keyParameter => Object.keys(keyParameter).length > 1
    );

    return wqs;
  },
  getCongratulationsData(data) {
    return {
      nextAnalysis: moment(data.nextAnalysis)
    };
  },
  getProblemAreas(data) {
    return {
      ProblemAreas: data.problemAreas,
      ReportType: data.ReportType,
      ShowKeyParameterRangesSection: data.showKeyParameterRangesSection
    };
  },
  getAnnouncements(data) {
    return {
      Announcements: data.announcements
    };
  },
  getFooterData(data) {
    return {
      country: data.country,
      storeAddress: data.storeAddress,
      storeTelephone: data.storeTelephone,
      storeWebsite: data.storeWebsite,
      storeEmail: data.storeEmail,
      storeHours: data.storeHours,
      established: data.established,
      branchLogo:
        "data:image/png;base64," +
        (data.BranchLogoBase64 || data.BranchLogoBytes)
    };
  },
  getTruBlueData(data) {
    return {
      isDealerTruBlue: data.isDealerTruBlue,
      poolTruBlueStatus: data.poolTruBlueStatus,
      hideTruBlueBanner: data.hideTruBlueBanner
    };
  },
  getSuggestedProducts(data) {
    return {
      country: data.country,
      chemicalBaseTypeID: data.chemicalBaseTypeID,
      suggestedProducts: data.suggestedProducts,
      version: data.version,
      volume:
        data.poolType === "Pool" ? data.poolVolume / 1000 : data.poolVolume
    };
  }
};

export default service;
