import React from 'react';
import ReactDOM from 'react-dom';
import {FormattedMessage, FormattedNumber, injectIntl} from 'react-intl';
import {connectToTranslations} from '../IntlWrapper';
import {getImagePath} from '../../helpers/image-helper.jsx';
import SpecialCharacterHelper from '../../helpers/special-character-helper.jsx';

function hideParentNode(e) {
  if (window.displayBrokenImages === 'False') {
    ReactDOM.findDOMNode(e.target).parentNode.style.display = 'none';
  }
}

function hideNode(e) {
  if (window.displayBrokenImages === 'False') {
    e.target.style.display = 'none';
  }
}

const ProblemsSolutions = ({data, formatPrintFile, intl, country}) => {
  return (
    <div className="container problems-solutions">

      <div className="solution-heading-text">
        <div><FormattedMessage id='report-viewer.instructions' /></div>
      </div>

      <table>
        <thead>
          <tr className="dividers">
            <th className="table-col-4">
              <h2><FormattedMessage id='report-viewer.problem.title' /></h2>
            </th>
            <th className="table-col">
              <h2><FormattedMessage id='report-viewer.solution.title' /></h2>
            </th>
            <th className="table-col-2 product-image-col">
              <h2><FormattedMessage id='report-viewer.product.title' /></h2>
            </th>
          </tr>
        </thead>

        <tbody>
          {data.ProblemAreas.map((row, i) =>
            <tr className="dividers problem-solution-row" key={i}>
              <td className="table-col-4">
                <div>
                  <h4 className={row.problemTypeImages && row.problemTypeImages.length > 0 ? 'images' : ''}>
                    {i + 1 + '.'} {formatPrintFile(row.problemTitle)}{formatPrintFile(row.problemType)}

                    {row.problemTypeImages && row.problemTypeImages.map((image, j) =>
                      <img className="problem-image"
                           key={j}
                           src={getImagePath({locale: intl.locale, file: image, folder: 'TitleImages'})}
                           onError={hideNode} />
                    )}
                  </h4>
                </div>
                <ul className="title-list">
                  <li dangerouslySetInnerHTML={{__html: SpecialCharacterHelper.fixSpecialCharacters(formatPrintFile(row.problemTypeDescription))}} />
                </ul>

                {row.problemTypeGraph && <div className={`problem-graph${!!data.ShowKeyParameterRangesSection ? '-new-ranges' : ''}`}>
                  <img src={getImagePath({file: row.problemTypeGraph, folder: !!data.ShowKeyParameterRangesSection ? 'ProblemGraphsNewRanges' : 'ProblemGraphs', locale: intl.locale})}
                       onError={hideParentNode} />
                  <div><FormattedNumber value={row.problemTypeCurrent}>{x => x}</FormattedNumber></div>
                </div>}
              </td>

              <td className="table-col">
                {row.problemSolutions && row.problemSolutions.map((solution, j) =>
                  <div key={j}>
                    {solution.problemSolutionHeadings && (solution.problemSolutionHeadings || [])
                      .map((heading, k) =>
                        <p key={k} dangerouslySetInnerHTML={{
                          __html: SpecialCharacterHelper.fixSpecialCharacters(formatPrintFile(heading)),
                        }} />
                      )}
                    <ul>
                      {solution.problemSolutionBullets && (solution.problemSolutionBullets || [])
                        .filter(x => !!formatPrintFile(x))
                        .map((bullet, k) => <li key={k}
                                                dangerouslySetInnerHTML={{__html: SpecialCharacterHelper.fixSpecialCharacters(formatPrintFile(bullet))}} />
                      )}
                    </ul>

                    {solution.problemSolutionNotes && solution.problemSolutionNotes.map((note, k) =>
                      <div key={k} className="solution-notes">
                        <img className="note-image"
                             src={getImagePath({file: note.imgName, folder: 'SpecialInstructions', locale: intl.locale})} />
                        {formatPrintFile(note.note)}
                      </div>
                    )}
                  </div>
                )}
              </td>

              <td className="table-col-2 product-image-col">
                {row.recommendedProducts.map((product, j) =>
                  <img onError={hideNode}
                       src={getImagePath({file: product, folder: `Products/${country}`, locale: intl.locale})}
                       key={j} />
                )}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default connectToTranslations(injectIntl(ProblemsSolutions));
