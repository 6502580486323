class Notification {
  constructor({
    id = 0,
    notificationTitle = '',
    body = '',
    link = '',
    embeddedMedia = '',
    type = '',
    userType = '',
    sendEmail = false,
  } = {}) {
    this.id = id;
    this.notificationTitle = notificationTitle;
    this.body = body;
    this.link = link;
    this.embeddedMedia = embeddedMedia;
    this.type = type;
    this.userType = userType;
    this.sendEmail = sendEmail;
  }

  clone() {
    return new Notification(this);
  }

  toApiFormat() {
    return {
      ID: this.id,
      NotificationTitle: this.notificationTitle,
      Body: this.body,
      URLLink: this.link,
      EmbeddedMedia: this.embeddedMedia,
      Type: this.type,
      // Set UserType to a JSON because it is returned as an array from the site
      UserType: JSON.stringify(this.userType),
      SendEmail: this.sendEmail,
    };
  }

  static fromApiFormat(api) {
    return new Notification({
      id: api.ID,
      notificationTitle: api.NotificationTitle,
      body: api.Body,
      link: api.URLLink,
      embeddedMedia: api.EmbeddedMedia,
      type: api.Type,
      userType: api.UserType,
      sendEmail: api.SendEmail,
    });
  }
}

export default Notification;
