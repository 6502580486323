import React from 'react';

export function ArrayToCSV(objArray) {
  const items = objArray;
  const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
  const header = Object.keys(items[0]);
  let csv = items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));

  csv.unshift(header.join(','));
  csv = csv.join('\r\n');

  return csv;
}

export function ArrayToXLSX(objArray, fileName) {
  var wopts = {bookType: 'xlsx', bookSST: false, type: 'binary'};
  var sheet = json_to_sheet(objArray);
  var wb = {
    SheetNames: [],
    Sheets: {},
  };

  wb.SheetNames.push(fileName);
  wb.Sheets[fileName] = sheet;
  var wbout = XLSX.write(wb,wopts);

  return new Blob([s2ab(wbout)],{type: 'application/octet-stream'});
}

function json_to_sheet(js/*:Array<any>*/, opts)/*:Worksheet*/ {
  var o = opts || {};
  var ws = {}/*:any*/;
  var range/*:Range*/ = {s: {c: 0, r: 0}, e: {c: 0, r: js.length}}/*:any*/;
  var hdr = o.header || [], C = 0;

  for(var R = 0; R != js.length; ++R) {
    Object.keys(js[R]).filter(function(x) {
      return js[R].hasOwnProperty(x);
    }).forEach(function(k) {
      if((C = hdr.indexOf(k)) == -1) {
        hdr[C = hdr.length] = k;
      }
      var v = js[R][k];
      var t = 'z';

      if(typeof v === 'number') {
        t = 'n';
      }  else if(typeof v === 'boolean') {
        t = 'b';
      }
      ws[XLSX.utils.encode_cell({c: C,r: R + 1})] = {t: t, v: v};
    });
  }
  range.e.c = hdr.length - 1;
  for(C = 0; C < hdr.length; ++C) {
    ws[XLSX.utils.encode_col(C) + '1'] = {t: 's', v: hdr[C]};
  }
  ws['!ref'] = XLSX.utils.encode_range(range);
  return ws;
}

function s2ab(s) {
  var buf = new ArrayBuffer(s.length);
  var view = new Uint8Array(buf);

  for (var i = 0; i != s.length; ++i) {
    view[i] = s.charCodeAt(i) & 0xFF;
  }
  return buf;
}
