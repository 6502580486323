import PropTypes from 'prop-types';
import React, {Component} from 'react';
import IBox from '../iBox.jsx';
import {connectToToasts, TOAST_STATES} from '../toasts.jsx';
import EditableCheckbox from '../form/editableCheckbox.jsx';

import ToolsApi from '../../../api/tools.jsx';

class PerformanceFileEditor extends Component {
  static displayName = 'PerformanceFileEditor';
  static propTypes = {
    createToast: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      adminReports: true,
      canEdit: false,
      dashboard: true,
      reports: true,
    };
  }

  componentWillMount() {
    this.getState();
  }

  getState = () => {
    const {createToast} = this.props;

    ToolsApi.getPerformanceFileVariables()
      .then(x => {
        if (x.success) {
          this.setState({
            adminReports: x.data.AdminReports,
            canEdit: true,
            dashboard: x.data.Dashboard,
            reports: x.data.Reports,
          });
        } else {
          createToast({
            id: 'perfomance-file-get-failed',
            message: 'Couldn\'t get the Performance File.',
            clears: 'perfomance-file-get',
            ttl: 6000,
            state: TOAST_STATES.ERROR,
          });
        }
      })
      .catch(err => {
        console.error(err);
        createToast({
          id: 'perfomance-file-get-failed',
          message: 'Couldn\'t get the Performance File.',
          clears: 'perfomance-file-get',
          ttl: 6000,
          state: TOAST_STATES.ERROR,
        });
      });
  };

  saveState = () => {
    const {adminReports, dashboard, reports} = this.state;
    let {createToast} = this.props;

    ToolsApi.setPerformanceFileVariables(adminReports, dashboard, reports)
      .then(x => {
        if (x.success) {
          createToast({
            id: 'perfomance-file-update-succeed',
            message: 'Performance File saved successfully.',
            clears: 'perfomance-file-update',
            ttl: 6000,
            state: TOAST_STATES.SUCCESS,
          });
        } else {
          createToast({
            id: 'perfomance-file-update-failed',
            message: 'Failed to save Performance File.',
            clears: 'perfomance-file-update',
            ttl: 6000,
            state: TOAST_STATES.ERROR,
          });
        }
      })
      .catch(err => {
        console.error(err);
        createToast({
          id: 'perfomance-file-update-failed',
          message: 'Failed to save Performance File.',
          clears: 'perfomance-file-update',
          ttl: 6000,
          state: TOAST_STATES.ERROR,
        });
      });
  };

  render() {
    return <IBox title='Performance File' style={{marginTop: 10}}
                 tools={<div><button className='btn btn-primary' onClick={this.saveState}>Save</button></div>}
            >
      <div>
        <EditableCheckbox
            checked={this.state.adminReports}
            dataKey={'adminReports'}
            editMode={this.state.canEdit}
            label={'Set Admin Reports State as Available'}
            onChange={({value}) => {
              this.setState({adminReports: value});
            }}
            ref={r => this.adminReports = r}
            value={this.state.adminReports}
        />

        <EditableCheckbox
            checked={this.state.dashboard}
            dataKey={'dashboard'}
            editMode={this.state.canEdit}
            label={'Set Dashboard State as Available'}
            onChange={({value}) => {
              this.setState({dashboard: value});
            }}
            ref={r => this.dashboard = r}
            value={this.state.dashboard}
        />

        <EditableCheckbox
            checked={this.state.reports}
            dataKey={'reports'}
            editMode={this.state.canEdit}
            label={'Set Reports State as Available'}
            onChange={({value}) => {
              this.setState({reports: value});
            }}
            ref={r => this.reports = r}
            value={this.state.reports}
        />
      </div>
    </IBox>;
  }
}

export default connectToToasts(PerformanceFileEditor);
