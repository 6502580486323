import IBox from 'components/iBox';
import {TabContainer, TabItem} from 'components/tabs';
import {getToken} from 'helpers/tokens';
import moment from 'moment';
import DiffResults from 'page/Testing/Diff';
import TestInput from 'page/Testing/TestInput';
import React from 'react';
import {Link} from 'react-router';
import Collapsible from 'components/collapsible';
import {connectToToasts, TOAST_STATES} from 'components/toasts';
import {deleteTestByRuleAndID, getTest, getTests, runTestAnalysis, runTestSuite, saveTest} from 'api/testing';
import PropTypes from 'prop-types';
import {browserHistory} from 'react-router';
import PoolModel from '../../../../../Canopy/Javascript/models/pool';
import CustomerModel from '../../../../../Canopy/Javascript/models/customer';
import DealerModel from '../../../../../Canopy/Javascript/models/dealer';
import AnalysisModel from '../../../../../Canopy/Javascript/models/analysis';
import ReportWrapper from '../../../../../Canopy/Javascript/ui/components/ReportWrapper';
import ReportViewer from '../../../../../ReportViewer/layout';
import JsonInput from './JsonInput';
import Trace from 'page/Testing/Trace';

const ListSummary = ({subset, total, label}) => {
  const percent = total.length === 0 ? 0 : Math.round(subset.length / total.length * 100);

  return <div>
    <h3>{label} ({subset.length} of {total.length} / {percent}%)</h3>
    <Collapsible>
      <ul>
        {subset.map(t => <li key={t.id}><Link to={`/testing/test/${t.id}`}>{t.name}</Link></li>)}
      </ul>
    </Collapsible>
  </div>;
};

ListSummary.propTypes = {
  subset: PropTypes.array,
  total: PropTypes.array,
  label: PropTypes.string,
};
class Test extends React.PureComponent {
  static propTypes = {
    createToast: PropTypes.func.isRequired,
    location: PropTypes.string.isRequired,
    params: PropTypes.object,
  };

  state = {
    tests: [],
    running: true,
    filter: '',
    ran: false,
    raw: {
      warden: [],
      alexMill: [],
    },
    logId: '',
  };

  componentWillMount() {
    this.fetchData();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.location !== nextProps.location) {
      let id = this.props.params.id;

      this.fetchData(nextProps, (id => {
        if (id !== nextProps.params.id && id && this.tabs) {
          this.tabs.select('input')();
        }
      }).bind(null, id));
    }
  }

  isCanada = (dealer = this.state.dealer) => dealer && dealer.country && dealer.country === 'CA';

  fetchData = (props = this.props, cb) => {
    if(props.params.id) {
      getTest(props.params.id)
        .then(({raw: test, customer, dealer, analysis, pool}) => {
          let input = test.input;

          if (typeof input === 'string') {
            input = JSON.parse(input);
          }

          let {name, description} = input;

          customer = CustomerModel.fromApiFormat(customer);
          customer.created = moment(customer.created);
          customer.pools = customer.pools || [];

          dealer = DealerModel.fromApiFormat(dealer);
          analysis = AnalysisModel.fromApiFormat(analysis);
          pool = PoolModel.fromApiFormat(pool);

          let reportType = 0;

          if (input && input.type === '1') {
            reportType = 1;
          }

          if (pool.volume && pool.volume < 100) {
            pool.volume = pool.volume * 1000;
          }


          //ensure previous constants get map correct - fix for old stored testcases
          if(analysis.season === 'closing') {
            analysis.season = 'winterclosing';
          }

          if(analysis.season === 'opening') {
            analysis.season = 'springopening';
          }

          this.setState({test, customer, dealer, analysis, pool, name, description, reportType}, cb);
        })
        .catch(err => {
          console.error(err);

          props.createToast({
            id: 'test.case.error',
            message: 'Failed to load test case.',
            ttl: 4000,
          });
        });
    }

    return getTests()
      .then(data => {
        this.setState({
          tests: data,
          running: false,
        });
      })
      .catch(err => {
        console.error(err);

        props.createToast({
          id: 'tests-load-error',
          message: 'Failed to load test',
          state: TOAST_STATES.INFO,
          ttl: 4000,
        });
      });
  };

  runAll = () => {
    this.setState({
      running: true,
    });

    runTestSuite()
      .then(() => this.fetchData(this.props))
      .catch(err => {
        console.error(err);

        this.props.createToast({
          id: 'run-test-suite-error',
          message: 'Failed to initialize test suite.',
          ttl: 4000,
          state: TOAST_STATES.ERROR,
        });
      });
  };

  deleteTest = () => {
    const {id, name} = this.state.test;

    deleteTestByRuleAndID(name, id)
      .then(() => {
        browserHistory.push('/testing/test');

        setTimeout(() => {
          this.fetchData(this.props);
        }, 100);

        this.props.createToast({
          id: 'delete-test',
          message: 'Deleted test.',
          ttl: 4000,
          state: TOAST_STATES.SUCCESS,
        });
      })
      .catch(err => {
        console.error(err);

        this.props.createToast({
          id: 'delete-test',
          message: 'Failed to delete test.',
          ttl: 4000,
          state: TOAST_STATES.ERROR,
        });
      });
  };

  run = () => {
    let {analysis, dealer, pool, customer, reportType} = this.input.getUnderlying().data;

    let expected = this.state.test.output;

    if (typeof expected === 'string') {
      expected = JSON.parse(expected);
    }

    expected = expected.recommendations;

    customer = new CustomerModel(customer || {}).toApiFormat();
    dealer = new DealerModel(dealer || {}).toApiFormat();
    analysis = new AnalysisModel(analysis || {}).toApiFormat();
    pool = new PoolModel(pool || {}).toApiFormat();

    runTestAnalysis({
      dealer,
      customer,
      pool,
      analysis,
      expected,
      reportType,
      fromTest: true,
    })
      .then(({alexMill = {}, warden, expected, raw}) => {
        dealer = DealerModel.fromApiFormat(dealer);

        this.setState({
          ran: true,
          alexMillAnalysisObj: this.isCanada(dealer) ? null : alexMill.analysisObj,
          wardenAnalysisObj: warden.analysisObj,
          expectedAnalysisObj: expected.analysisObj,
          raw,
          logId: warden.wardenOutput.id,
          reportType,
        }, () => {
          this.tabs.select('printouts')();
        });
      })
      .catch(err => {
        console.error(err);

        this.props.createToast({
          id: 'run-analysis-error',
          message: 'Failed to run analysis.',
          ttl: 4000,
        });
      });
  };

  update = (expectedResult) => {
    const {id, output} = this.state.test;
    let dealer, customer, pool, analysis, name, description, reportType;

    if (this.input) {
      ({dealer, customer, pool, analysis, name, description, reportType} = this.input.getUnderlying().data);
    } else if (!this.input || !name) {
      ({dealer, customer, pool, analysis, reportType} = this.state);
      ({name, description} = this.state.test);
    }

    customer = new CustomerModel(customer || {}).toApiFormat();
    dealer = new DealerModel(dealer || {}).toApiFormat();
    analysis = new AnalysisModel(analysis || {}).toApiFormat();
    pool = new PoolModel(pool || {}).toApiFormat();

    saveTest({dealer, customer, analysis, pool, name, reportType, description, id, expectedResult: expectedResult || output})
      .then(() => {
        browserHistory.push('/testing/test');

        this.setState({
          customer: undefined,
          test: undefined,
          dealer: undefined,
          name: undefined,
          description: undefined,
          pool: undefined,
          analysis: undefined,
          reportType: 0,
        }, this.fetchData);
      })
      .catch(err => {
        console.error(err);

        this.props.createToast({
          id: 'update-test-error',
          message: 'Failed saving test.',
          ttl: 4000,
          state: TOAST_STATES.ERROR,
        });
      });
  };

  saveAsExpected = () => {
    const key = this.printoutTabs.getActive();

    let expected = this.state.raw[key];

    return this.update(expected);
  };

  saveRawExpected = () => {
    if (!this.jsonInput) {
      return;
    }

    const expected = this.jsonInput.value;

    return this.update(expected);
  };

  export = () => {
    window.open('/api/testing/exporttests?token=' + getToken()['id_token']);
  };

  render() {
    const totalTests = this.state.tests;
    const filter = this.state.filter;

    const listTests = !filter ? totalTests : totalTests.filter(x => x && x.name && x.name.toLowerCase().includes((filter || '').toLowerCase()));

    const neverRan = listTests.filter(x => x.lastresult !== 'pass' && x.lastresult !== 'fail');
    const passed = listTests.filter(x => x.lastresult === 'pass');
    const failed = listTests.filter(x => x.lastresult === 'fail');

    return <div style={{display: 'flex'}}>
      <style>
        {`#page-wrapper {
          min-height: 0 !important;
        }`}
      </style>
      <div className='warden-test__sidebar'
           style={{
             borderRight: '1px solid lightgrey',
             backgroundColor: 'white',
             width: 350,
             flexGrow: 0,
             flexShrink: 0,
             marginRight: 20,
             display: 'flex',
             padding: '0 20px 30px',
             flexDirection: 'column',
             height: 'calc(100vh - 61px)',
           }}>
        <h2>Tests</h2>
        <input type='text' className='form-control' placeholder='Filter' onChange={e => this.setState({filter: e.target.value})} style={{marginBottom: 30}} />
        <div style={{
          maxHeight: 'calc(100vh - 276px)',
          overflowY: 'auto',
          marginBottom: 30,
          flexGrow: 2,
        }}>
          <div>
            <ListSummary subset={failed} total={listTests} label='Failed' />
            <ListSummary subset={neverRan} total={listTests} label='Never Ran' />
            <ListSummary subset={passed} total={listTests} label='Passed' />
          </div>
        </div>
        <button disabled={this.state.running} onClick={this.runAll} className='btn btn-primary'>{this.state.running ? '...' : 'Run All'}</button>
        <button style={{margin: '15px 0 0'}} disabled={!failed.length && !passed.length} onClick={this.export} className='btn btn-secondary'>Export</button>
      </div>

      {this.state.test ?
        <TabContainer ibox ref={r => this.tabs = r} style={{flexGrow: 2}}>
          <TabItem tabKey='input' label='Input'>
            <IBox title='Input' rightTitle={
              <div>
                <button className='btn btn-warn' onClick={this.deleteTest}>Delete</button>
                <button className='btn btn-default' onClick={this.run}>Run</button>
                <button className='btn btn-primary' onClick={() => this.update()}>Update & Save</button>
              </div>}>
              <TestInput {...this.state} fromApi={false} name={this.state.test.name} ref={r => this.input = r} />
            </IBox>
          </TabItem>

          <TabItem disabled={!this.state.ran} tabKey='printouts' label='Printouts'>
            <div className='row'>
              <div className='col-md-6'>
                <IBox title='Expected'>
                  <ReportWrapper><ReportViewer showControls={false} data={this.state.expectedAnalysisObj} /></ReportWrapper>
                </IBox>
              </div>
              <div className='col-md-6'>
                <TabContainer ref={r => this.printoutTabs = r}
                              right={<button className='btn btn-primary' onClick={this.saveAsExpected}>Save As Expected</button>}>

                  <TabItem tabKey='warden' label='Alex Pro Rules'>
                    <ReportWrapper><ReportViewer showControls={false} data={this.state.wardenAnalysisObj} /></ReportWrapper>
                  </TabItem>

                  {!this.isCanada() ? <TabItem tabKey='alexMill' label='Alex Mill Rules'>
                    <ReportWrapper><ReportViewer showControls={false} data={this.state.alexMillAnalysisObj} /></ReportWrapper>
                  </TabItem> : null}

                </TabContainer>
              </div>
            </div>
          </TabItem>

          <TabItem tabKey='expected' label='Expected' disabled={!this.state.ran}>
            <IBox title='Expected' rightTitle={
              <button className='btn btn-primary' onClick={this.saveRawExpected}>
                Update &amp; Save
              </button>
            }>
              <JsonInput defaultValue={this.state.raw.expected}
                         ref={r => this.jsonInput = r}
                         placeholder='Expected result (JSON)'
                         style={{width: '100%', height: 400}} />
            </IBox>
          </TabItem>

          {!this.isCanada() ? <TabItem disabled={!this.state.ran} tabKey='diff' label='Diff'>
            <DiffResults alexMill={this.state.raw.expected || []} warden={this.state.raw.warden || []} />
          </TabItem> : null}

          <TabItem disabled={!this.state.ran || !this.state.logId.length} tabKey='trace' label='Trace'>
            <Trace id={this.state.logId}/>
          </TabItem>
        </TabContainer> :
        null}

    </div>;
  }
}

export default connectToToasts(Test);
