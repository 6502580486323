import PropTypes from 'prop-types';
import React, {Component} from 'react';
import IBox from 'components/iBox';
import {Input, Checkbox} from 'components/form';
import {containerType, mediaType, salesProductCategory, unitsOfMeasure, countriesNames} from 'helpers/constants';
import SalesProduct from 'models/salesProduct';
import {browserHistory} from 'react-router';

const editableColumnClass = 'col-md-4 ';
const nonEditableColumnClass = 'col-md-4 hide-element';

const EditableField = ({children}) => {
  return <div className={editableColumnClass}>
    {children}
  </div>;
};

EditableField.propTypes = {
  children: PropTypes.any,
};

const NonEditableField = ({children, updating}) => {
  return <div className={updating ? nonEditableColumnClass : editableColumnClass}>
    {children}
  </div>;
};

NonEditableField.propTypes = {
  children: PropTypes.any,
  updating: PropTypes.bool,
};


class EditSalesProduct extends Component {
  constructor(props) {
    super(props);

    this.state = {
      product: new SalesProduct(),
    };
  }

  componentWillMount() {
    if (this.props.product) {
      this.setState({
        product: this.props.product,
      });
    }

    if (!this.context.canEditProducts) {
      browserHistory.push('/');
    }
  }

  handleProductChange = e => {
    let state = this.state;

    if (e.key) {
      state.product[e.key] = e.value;
    } else {
      state.product['unitOfMeasure'] = this.unitOfMeasure.value;
      state.product['salesCategory'] = this.category.value;
      state.product['containerType'] = this.container.value;
      state.product['mediaType'] = this.media.value;
      state.product['country'] = this.country.value;
    }

    this.setState(state);
  };

  save = () => {
    return this.props.save(this.state.product);
  };

  update = () => {
    return this.props.update(this.state.product);
  };

  render() {
    let measureUnits = Object.keys(unitsOfMeasure)
      .map(key => <option key={key} value={unitsOfMeasure[key]}>{unitsOfMeasure[key]}</option>);

    measureUnits.unshift(<option key={0} />);

    let categories = Object.keys(salesProductCategory)
      .map(key => <option key={key} value={salesProductCategory[key]}>{salesProductCategory[key]}</option>);

    categories.unshift(<option key={0} />);

    let containers = Object.keys(containerType)
      .map(key => <option key={key} value={containerType[key]}>{containerType[key]}</option>);

    containers.unshift(<option key={0} />);

    let medias = Object.keys(mediaType)
      .map(key => <option key={key} value={mediaType[key]}>{mediaType[key]}</option>);

    medias.unshift(<option key={0} />);

    let countries = Object.keys(countriesNames)
    .map(key => <option key={key} value={key}>{countriesNames[key]}</option>);

    const updating = this.props.updateProductMode;

    return <IBox title={updating ? `Editing: ${this.state.product.name}` : 'Create'}>
      <div className='row'>
        <NonEditableField updating={updating}>
          <Input value={this.state.product.name}
                 label='Name'
                 dataKey='name'
                 editMode={true}
                 type='string'
                 dataChanged={this.handleProductChange} />
        </NonEditableField>

        <EditableField>
          <Input value={this.state.product.msrp}
                 label='MSRP'
                 dataKey='msrp'
                 editMode={true}
                 type='number'
                 dataChanged={this.handleProductChange} />
        </EditableField>

        <NonEditableField updating={updating}>
          <Input value={this.state.product.amount}
                 label='Amount'
                 dataKey='amount'
                 editMode={true}
                 type='number'
                 dataChanged={this.handleProductChange} />
        </NonEditableField>

        <NonEditableField updating={updating}>
          <div className='form-group'>
            <label>Unit of Measure</label>
            <select ref={r => this.unitOfMeasure = r}
                    className='form-control'
                    onChange={this.handleProductChange}>
              {measureUnits}
            </select>
          </div>
        </NonEditableField>

        <NonEditableField updating={updating}>
          <Input value={this.state.product.caseUPC}
                 label='Case UPC'
                 dataKey='caseUPC'
                 editMode={true}
                 type='string'
                 dataChanged={this.handleProductChange} />
        </NonEditableField>

        <NonEditableField updating={updating}>
          <div className='form-group'>
            <label>Sales Category</label>
            <select ref={r => this.category = r}
                    className='form-control'
                    onChange={this.handleProductChange}>
              {categories}
            </select>
          </div>
        </NonEditableField>

        <NonEditableField updating={updating}>
          <Input value={this.state.product.sku}
                 label='SKU'
                 dataKey='sku'
                 editMode={true}
                 type='string'
                 dataChanged={this.handleProductChange} />
        </NonEditableField>

        <NonEditableField updating={updating}>
          <Input value={this.state.product.productUPC}
                 label='Product UPC'
                 dataKey='productUPC'
                 editMode={true}
                 type='string'
                 dataChanged={this.handleProductChange} />
        </NonEditableField>

        <NonEditableField updating={updating}>
          <Input value={this.state.product.unitsPerCase}
                 label='Units / Case'
                 dataKey='unitsPerCase'
                 editMode={true}
                 type='number'
                 dataChanged={this.handleProductChange} />
        </NonEditableField>

        <NonEditableField updating={updating}>
          <div className='form-group'>
            <label>Container Type</label>
            <select ref={r => this.container = r}
                    className='form-control'
                    onChange={this.handleProductChange}>
              {containers}
            </select>
          </div>
        </NonEditableField>

        <NonEditableField updating={updating}>
          <div className='form-group'>
            <label>Media Type</label>
            <select ref={r => this.media = r}
                    className='form-control'
                    onChange={this.handleProductChange}>
              {medias}
            </select>
          </div>
        </NonEditableField>

        <NonEditableField updating={updating}>
          <div className='form-group'>
            <label>Country</label>
            <select ref={r => this.country = r}
                    className='form-control'
                    onChange={this.handleProductChange}>
              {countries}
            </select>
          </div>
        </NonEditableField>

        <NonEditableField updating={updating}>
          <Checkbox value={this.state.product.isThreeStepProduct}
                    label='Is three step product (maintenance)'
                    dataKey='isThreeStepProduct'
                    topLabel=' '
                    editMode={true}
                    dataChanged={this.handleProductChange} />
        </NonEditableField>

        {this.state.product.isThreeStepProduct ?
          <NonEditableField updating={updating}>
            <Input value={this.state.product.stepNumber}
                   label='Step number'
                   dataKey='stepNumber'
                   editMode={true}
                   type='number'
                   dataChanged={this.handleProductChange} />
          </NonEditableField> :
          null}

        <EditableField>
          <Checkbox value={this.state.product.isActive}
                    checked={this.state.product.isActive}
                    label='Is Active'
                    dataKey='isActive'
                    topLabel=' '
                    editMode={true}
                    dataChanged={this.handleProductChange} />
        </EditableField>

        <EditableField>
          <Checkbox value={this.state.product.isTruBlue}
                    label='Is TrueBlue Product'
                    dataKey='isTruBlue'
                    topLabel=' '
                    editMode={true}
                    dataChanged={this.handleProductChange} />
        </EditableField>

      </div>

      <div className='row'>
        <div className='col-md-12'>
          <div className='pull-right'>
            <button className='btn btn-cancel'
                    onClick={this.props.cancel}
                    style={{marginRight: 15}}>
              Cancel
            </button>
            <button className='btn btn-primary'
                    onClick={this.props.updateProductMode ? this.update : this.save}>
              Save
            </button>
          </div>
        </div>
      </div>
    </IBox>;
  }
}
EditSalesProduct.propTypes = {
  cancel: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  updateProductMode: PropTypes.bool,
  product: PropTypes.instanceOf(SalesProduct),
};

EditSalesProduct.contextTypes = {
  canEditProducts: PropTypes.bool.isRequired,
};

export default EditSalesProduct;
