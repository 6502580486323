import EditableTextField from '../../components/form/editableTextField.jsx';
import LayoutSelect from '../../components/form/layoutSelect.jsx';
import LoadingBtn from '../../components/loadingBtn.jsx';
import ParameterInput from '../../components/parameterInput.jsx';
import PropTypes from 'prop-types';
import React from 'react';
import {categories, categoryColours, labels} from './scoreData.jsx';
import {ParameterCutoffs} from '../../../models/poolScoreParameters.jsx';


class RulesEntry extends React.Component {
  static contextTypes = {
    canEditPoolScores: PropTypes.bool.isRequired,
  };

  static propTypes = {
    data: PropTypes.object.isRequired,
    dataChanged: PropTypes.func.isRequired,
    save: PropTypes.func.isRequired,
    scoreChanged: PropTypes.func.isRequired,
    savingRules: PropTypes.bool.isRequired,
    toggleEdit: PropTypes.func.isRequired,
  };

  render() {
    let data = this.props.data;
    let currentRules = data.rules.scores[data.chemicalBaseTypeID + '|' + data.score];

    // Sort the keys by order of having parameters cutoffs, or just being a boolean
    let keys = Object.keys(currentRules);


    return <div>
      <div className='row' style={{marginBottom: 20}}>
        <div className='col-xs-12'>
          {this.context.canEditPoolScores ?
            <LoadingBtn className='btn btn-primary pull-left' loading={this.props.savingRules}
                        onClick={this.props.save}>Save</LoadingBtn> : null}

          <button className='btn btn-primary pull-right' onClick={this.props.toggleEdit}>
            Test Calculations
          </button>
        </div>
      </div>
      <div className='row' style={{marginBottom: 20}}>
        <div className='col-xs-4'>
          <LayoutSelect label='Sanitizer Type' dataKey='chemicalBaseTypeID' value={data.chemicalBaseTypeID}
                        options={['Bromine Pool', 'Chlorine Pool', 'SoftSwim Pool', 'Chlorine Spa', 'Bromine Spa', 'Mineral Springs/Salt', 'SoftSoak Spa']}
                        keys={[1, 2, 3, 5, 6, 7, 8]} editMode={true} dataChanged={this.props.dataChanged}/>
        </div>

        <div className='col-xs-4'>
          <LayoutSelect label='Score'
                        options={['Swimmer Comfort', 'Water Clarity', 'Surface & Equipment Protection']}
                        keys={[0, 1, 2]}
                        editMode={true}
                        value={data.score}
                        dataKey='score'
                        dataChanged={this.props.dataChanged}/>
        </div>
      </div>

      <table className='table table-striped smaller-font-table'>
        <thead>
          <tr>
            <th>Category</th>
            <th>Property</th>
            <th colSpan='5'>Values less than these apply the corresponding decrease stars.</th>
            <th>Ideal</th>
            <th colSpan='5'>Values greater than these apply the corresponding decrease in stars.</th>
          </tr>
          <tr className='parameter-input'>
            <th/>
            <th/>
            <th>-5 Stars</th>
            <th>-4 Stars</th>
            <th>-3 Stars</th>
            <th>-2 Stars</th>
            <th>-1 Stars</th>
            <th/>
            <th>-1 Stars</th>
            <th>-2 Stars</th>
            <th>-3 Stars</th>
            <th>-4 Stars</th>
            <th>-5 Stars</th>
          </tr>
        </thead>
        <tbody>
          {keys.map(k => {
            if (currentRules[k] instanceof ParameterCutoffs) {
              return <ParameterInput data={currentRules[k]} key={k} dataKey={k} label={labels[k] || k}
                                   scoreChanged={this.props.scoreChanged}
                                   category={categories[k] || 'Other'} editMode={this.context.canEditPoolScores}/>;
            }
            return null;
          })}
        </tbody>
      </table>


      <table className='table table-striped'>
        <thead>
          <tr>
            <th>Category</th>
            <th>Property</th>
            <th>Change in Stars<span
            className='hide-tablet'>(Positive numbers add stars, negative numbers subtract stars)</span></th>
            <th>Notes</th>
          </tr>
        </thead>
        <tbody>
          {keys.sort((a, b) => categories[a] < categories[b] ? -1 : 1).map(j => {
            if (!(currentRules[j] instanceof ParameterCutoffs)) {
              return <tr className='fixed-input' key={j}>
                <td style={{backgroundColor: categoryColours[categories[j] || 'Other']}}
                  className='score-category'>{categories[j] || 'Other'}</td>
                <td>{labels[j] || j}</td>
                <td className='data-cell'>
                  <EditableTextField value={currentRules[j].change} editMode={this.context.canEditPoolScores}
                                   dataKey={j + '|change'}
                                   type='number' onChange={this.props.scoreChanged}/>
                </td>
                <td style={{width: 550}} className='data-cell'>
                  <EditableTextField value={currentRules[j].note}
                                   editMode={this.context.canEditPoolScores} dataKey={j + '|note'}
                                   onChange={this.props.scoreChanged}/>
                </td>
              </tr>;
            }
            return null;
          })}
        </tbody>
      </table>
    </div>;
  }
}

export default RulesEntry;
