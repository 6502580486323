import React from 'react';
import path from 'path';

function getLastSegment(xs = '') {
  const split = xs.split(/\/|\\/);

  if (split && split.length) {
    return split[split.length - 1];
  }

  return xs;
}

export function getImagePath({locale, folder, file}) {
  return `/Content/ReportViewerAssets/img/${locale}/${path.join(folder || '', getLastSegment(file))}`
    .replace('*', '');
}

export const imageFolders = {
  stepImages: 'StepImages',
  productImages: 'Products',
  problemTypeTitle: 'ProblemTypeTitleImages',
  problemGraphs: 'ProblemGraphs',
  specialInstructions: 'SpecialInstructions',
  problemSolutions: 'ProblemSolutions',
  truBlue: 'TruBlue',
};
