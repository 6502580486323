'use strict';

exports.__esModule = true;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _collapseStyles;

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _style = require('dom-helpers/style');

var _style2 = _interopRequireDefault(_style);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _Transition = require('react-transition-group/Transition');

var _Transition2 = _interopRequireDefault(_Transition);

var _capitalize = require('./utils/capitalize');

var _capitalize2 = _interopRequireDefault(_capitalize);

var _createChainedFunction = require('./utils/createChainedFunction');

var _createChainedFunction2 = _interopRequireDefault(_createChainedFunction);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var MARGINS = {
  height: ['marginTop', 'marginBottom'],
  width: ['marginLeft', 'marginRight']
};

// reading a dimension prop will cause the browser to recalculate,
// which will let our animations work
function triggerBrowserReflow(node) {
  node.offsetHeight; // eslint-disable-line no-unused-expressions
}

function getDimensionValue(dimension, elem) {
  var value = elem['offset' + (0, _capitalize2.default)(dimension)];
  var margins = MARGINS[dimension];

  return value + parseInt((0, _style2.default)(elem, margins[0]), 10) + parseInt((0, _style2.default)(elem, margins[1]), 10);
}

var collapseStyles = (_collapseStyles = {}, _collapseStyles[_Transition.EXITED] = 'collapse', _collapseStyles[_Transition.EXITING] = 'collapsing', _collapseStyles[_Transition.ENTERING] = 'collapsing', _collapseStyles[_Transition.ENTERED] = 'collapse in', _collapseStyles);

var propTypes = {
  /**
   * Show the component; triggers the expand or collapse animation
   */
  in: _propTypes2.default.bool,

  /**
   * Wait until the first "enter" transition to mount the component (add it to the DOM)
   */
  mountOnEnter: _propTypes2.default.bool,

  /**
   * Unmount the component (remove it from the DOM) when it is collapsed
   */
  unmountOnExit: _propTypes2.default.bool,

  /**
   * Run the expand animation when the component mounts, if it is initially
   * shown
   */
  appear: _propTypes2.default.bool,

  /**
   * Duration of the collapse animation in milliseconds, to ensure that
   * finishing callbacks are fired even if the original browser transition end
   * events are canceled
   */
  timeout: _propTypes2.default.number,

  /**
   * Callback fired before the component expands
   */
  onEnter: _propTypes2.default.func,
  /**
   * Callback fired after the component starts to expand
   */
  onEntering: _propTypes2.default.func,
  /**
   * Callback fired after the component has expanded
   */
  onEntered: _propTypes2.default.func,
  /**
   * Callback fired before the component collapses
   */
  onExit: _propTypes2.default.func,
  /**
   * Callback fired after the component starts to collapse
   */
  onExiting: _propTypes2.default.func,
  /**
   * Callback fired after the component has collapsed
   */
  onExited: _propTypes2.default.func,

  /**
   * The dimension used when collapsing, or a function that returns the
   * dimension
   *
   * _Note: Bootstrap only partially supports 'width'!
   * You will need to supply your own CSS animation for the `.width` CSS class._
   */
  dimension: _propTypes2.default.oneOfType([_propTypes2.default.oneOf(['height', 'width']), _propTypes2.default.func]),

  /**
   * Function that returns the height or width of the animating DOM node
   *
   * Allows for providing some custom logic for how much the Collapse component
   * should animate in its specified dimension. Called with the current
   * dimension prop value and the DOM node.
   */
  getDimensionValue: _propTypes2.default.func,

  /**
   * ARIA role of collapsible element
   */
  role: _propTypes2.default.string
};

var defaultProps = {
  in: false,
  timeout: 300,
  mountOnEnter: false,
  unmountOnExit: false,
  appear: false,

  dimension: 'height',
  getDimensionValue: getDimensionValue
};

var Collapse = function (_React$Component) {
  (0, _inherits3.default)(Collapse, _React$Component);

  function Collapse() {
    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, Collapse);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, _React$Component.call.apply(_React$Component, [this].concat(args))), _this), _this.handleEnter = function (elem) {
      elem.style[_this.getDimension()] = '0';
    }, _this.handleEntering = function (elem) {
      var dimension = _this.getDimension();
      elem.style[dimension] = _this._getScrollDimensionValue(elem, dimension);
    }, _this.handleEntered = function (elem) {
      elem.style[_this.getDimension()] = null;
    }, _this.handleExit = function (elem) {
      var dimension = _this.getDimension();
      elem.style[dimension] = _this.props.getDimensionValue(dimension, elem) + 'px';
      triggerBrowserReflow(elem);
    }, _this.handleExiting = function (elem) {
      elem.style[_this.getDimension()] = '0';
    }, _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  Collapse.prototype.getDimension = function getDimension() {
    return typeof this.props.dimension === 'function' ? this.props.dimension() : this.props.dimension;
  };

  // for testing


  Collapse.prototype._getScrollDimensionValue = function _getScrollDimensionValue(elem, dimension) {
    return elem['scroll' + (0, _capitalize2.default)(dimension)] + 'px';
  };

  /* -- Expanding -- */


  /* -- Collapsing -- */


  Collapse.prototype.render = function render() {
    var _this2 = this;

    var _props = this.props,
        onEnter = _props.onEnter,
        onEntering = _props.onEntering,
        onEntered = _props.onEntered,
        onExit = _props.onExit,
        onExiting = _props.onExiting,
        className = _props.className,
        children = _props.children,
        props = (0, _objectWithoutProperties3.default)(_props, ['onEnter', 'onEntering', 'onEntered', 'onExit', 'onExiting', 'className', 'children']);


    delete props.dimension;
    delete props.getDimensionValue;

    var handleEnter = (0, _createChainedFunction2.default)(this.handleEnter, onEnter);
    var handleEntering = (0, _createChainedFunction2.default)(this.handleEntering, onEntering);
    var handleEntered = (0, _createChainedFunction2.default)(this.handleEntered, onEntered);
    var handleExit = (0, _createChainedFunction2.default)(this.handleExit, onExit);
    var handleExiting = (0, _createChainedFunction2.default)(this.handleExiting, onExiting);

    return _react2.default.createElement(
      _Transition2.default,
      (0, _extends3.default)({}, props, {
        'aria-expanded': props.role ? props.in : null,
        onEnter: handleEnter,
        onEntering: handleEntering,
        onEntered: handleEntered,
        onExit: handleExit,
        onExiting: handleExiting
      }),
      function (state, innerProps) {
        return _react2.default.cloneElement(children, (0, _extends3.default)({}, innerProps, {
          className: (0, _classnames2.default)(className, children.props.className, collapseStyles[state], _this2.getDimension() === 'width' && 'width')
        }));
      }
    );
  };

  return Collapse;
}(_react2.default.Component);

Collapse.propTypes = propTypes;
Collapse.defaultProps = defaultProps;

exports.default = Collapse;
module.exports = exports['default'];