export class ParameterCutoffs {
  constructor({
    highCutoffs, lowCutoffs,
  }) {
    this.highCutoffs = Array.isArray(highCutoffs) ? highCutoffs : [];
    this.lowCutoffs = Array.isArray(lowCutoffs) ? lowCutoffs : [];
  }
}

export class ParameterSwitch {
  constructor({
    change, note,
  }) {
    this.change = change || '';
    this.note = note || '';
  }
}

export class PoolScoreParameters {
  constructor({
    pH, freeChlorine, combinedChlorine, hazy, cloudy, borates, saturationIndex, metals, blackAlgae, scaleBuildup, ironStaining, copperStaining,
    unknownStaining, greenAlgae, mustardAlgae, hardness, biguanide, peroxide, usingMaintClarifier, copper, iron, manganese, foaming, totalChlorine,
    waterlineBuildup, spaAlgae, borateGT30, maxCCAndFC, totalBromine, swampy, slimeMold, organicStaining,
  }) {
    this.saturationIndex = new ParameterCutoffs(saturationIndex || {});
    this.pH = new ParameterCutoffs(pH || {});
    this.hardness = new ParameterCutoffs(hardness || {});
    this.freeChlorine = new ParameterCutoffs(freeChlorine || {});
    this.totalChlorine = new ParameterCutoffs(totalChlorine || {});
    this.combinedChlorine = new ParameterCutoffs(combinedChlorine || {});
    this.biguanide = new ParameterCutoffs(biguanide || {});
    this.peroxide = new ParameterCutoffs(peroxide || {});
    this.borates = new ParameterCutoffs(borates || {});
    this.totalBromine = new ParameterCutoffs(totalBromine || {});

    this.copper = new ParameterSwitch(copper || {});
    this.iron = new ParameterSwitch(iron || {});
    this.manganese = new ParameterSwitch(manganese || {});
    this.usingMaintClarifier = new ParameterSwitch(usingMaintClarifier || {});

    this.hazy = new ParameterSwitch(hazy || {});
    this.cloudy = new ParameterSwitch(cloudy || {});
    this.foaming = new ParameterSwitch(foaming || {});

    this.greenAlgae = new ParameterSwitch(greenAlgae || {});
    this.mustardAlgae = new ParameterSwitch(mustardAlgae || {});
    this.blackAlgae = new ParameterSwitch(blackAlgae || {});
    this.spaAlgae = new ParameterSwitch(spaAlgae || {});
    this.swampy = new ParameterSwitch(swampy || {});
    this.slimeMold = new ParameterSwitch(slimeMold || {});

    this.scaleBuildup = new ParameterSwitch(scaleBuildup || {});
    this.waterlineBuildup = new ParameterSwitch(waterlineBuildup || {});
    this.copperStaining = new ParameterSwitch(copperStaining || {});
    this.ironStaining = new ParameterSwitch(ironStaining || {});
    this.unknownStaining = new ParameterSwitch(unknownStaining || {});
    this.organicStaining = new ParameterSwitch(organicStaining || {});

    this.metals = new ParameterSwitch(metals || {});

    this.borateGT30 = new ParameterSwitch(borateGT30 || {});
    this.maxCCAndFC = new ParameterSwitch(maxCCAndFC || {});
  }
}

export class PoolScoreWrapper {
  constructor({scores}) {
    // Storing this in a scores property to make easier to deserialize in C#
    this.scores = {};

    for (let key of Object.keys(scores || {})) {
      this.scores[key] = new PoolScoreParameters(scores[key] || {});
    }

    for (let chemType of [1, 2, 3, 5, 6, 7, 8]) {
      for (let calc of [0, 1, 2]) {
        if (!this.scores[chemType + '|' + calc]) {
          this.scores[chemType + '|' + calc] = new PoolScoreParameters({});
        }
      }
    }
  }
}
