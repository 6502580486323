import IBox from '../components/iBox.jsx';
import moment from 'moment';
import PageHeader from '../components/pageHeader.jsx';
import printfileApi from '../../api/printfile.jsx';
import PropTypes from 'prop-types';
import React from 'react';
import Spinner from '../components/spinner.jsx';
import {browserHistory} from 'react-router';
import {FormControl, FormGroup} from 'react-bootstrap';

import {getClaim, saveClaim} from '../../api/truBlueClaims.jsx';
import requirePermissions from '../helpers/requirePermissions';
import {trublueMemberIDEnabled} from 'features';


const trublueClaimStatuses = {
  0: 'Open',
  1: 'Submitted',
  2: 'Approved',
  3: 'More Info Needed',
  4: 'Dismissed',
  5: 'Denied',
};

// Page object
class TruBlueClaimItem extends React.Component {

  static propTypes = {
    params: PropTypes.object,
  };

  state = {
    claim: null,
    updated: false,
  };

  componentWillMount() {
    getClaim(this.props.params.id)
      .then((data) => {
        if (!data.success) {
          throw new Error('Failed to download claims.');
        }

        this.setState({claim: data.data});
      });
  }

  handleChange = (key, value) => {
    let update = this.state.claim;

    update[key] = value;

    this.setState({claim: update, updated: true});
  };

  save = (e) => {
    e.preventDefault();
    //do save here
    saveClaim(this.state.claim).then(() => {
      this.setState({updated: false});
      browserHistory.push('/claims');
    });
  };

  getDataToSave = () => {
    return {
      ProblemID: this.state.file.Problem.ID,
    };
  };

  render() {
    if(!this.state.claim) {
      return <div>Loading...</div>;
    }

    let cc = '';

    if(this.state.claim.Contact && this.state.claim.Contact.NameFirst) {
      cc = this.state.claim.Contact.NameFirst + ' ' + this.state.claim.Contact.NameLast;
    }
    if(this.state.claim.Contact && this.state.claim.Contact.ContactOrganization) {
      cc = this.state.claim.Contact.ContactOrganization;
    }

    const attachments = this.state.claim.Attachments.filter(a => a).map(a => {
      const attachment = a.split('|');

      return {
        link: attachment[0],
        name: attachment[1] || attachment[0],
      };
    });

    let statuses = Object.keys(trublueClaimStatuses).map(k => {
      return <option key={k} value={k} selected={this.state.claim.StatusID == k}>{trublueClaimStatuses[k]}</option>;
    });


    return <form onSubmit={this.save}>
      <PageHeader title={`Claim - ${this.props.params.id}`} icon='fa fa-print' breadcrumbs={[]} />
      <div className='wrapper wrapper-content'>
        <div className='row' id='page-claim'>
          <div className='col-xs-6'>
            <IBox title={`Claim Submitted By: ${this.state.claim.DealerName} on ${moment(this.state.claim.Created).format('LL')}`}>
              <div className='row'>
                <div className='claimitem col-sm-12'>
                  <h2>Customer Details</h2>
                </div>
                <div className='claimitem col-sm-6'>
                  <h3>Customer Name</h3>
                  <p>{cc || '-'}</p>
                  <h3>Alex ID</h3>
                  <p>{this.state.claim.CustomerID}</p>
                  <h3>Account Number</h3>
                  <p>{this.state.claim.AccountNo}</p>
                </div>
                <div className='claimitem col-sm-6'>
                  <h3>Dealer Name</h3>
                  <p>{this.state.claim.DealerName}</p>
                  {trublueMemberIDEnabled ?
                    <React.Fragment>
                      <h3>Tru•Blue Member ID</h3>
                      <p>{this.state.claim.TruBlueMemberID}</p>
                    </React.Fragment> :
                  null}
                </div>
                <div className='claimitem col-sm-12'>
                  <h2>System and Product Details</h2>
                </div>
                <div className='claimitem col-sm-6'>
                  <h3>System ID</h3>
                  <p>{this.state.claim.PoolID}</p>
                  <h3>Date of Prior test</h3>
                  <p>{moment(this.state.claim.PrevAnalysisCreated).format('LL')}</p>
                  <h3>Products Available to Claim</h3>
                  {this.state.claim.ProductDetails ?
                   this.state.claim.ProductDetails.split(', ').map(product =>
                     <p key={product}>{product}</p>
                  ) : <p>None</p>}
                </div>
                <div className='claimitem col-sm-6'>
                  <h3>Water Issue</h3>
                  <p>{this.state.claim.Problems}</p>
                  <h3>Date of Claim</h3>
                  <p>{moment(this.state.claim.Created).format('LL')}</p>
                  <h3>Notes</h3>
                  <pre>{this.state.claim.NotesDealer}</pre>
                </div>
                <div className='claimitem col-sm-12'>
                  <h2>Claim Approval</h2>
                </div>
                <div className='claimitem col-sm-12'>
                  <FormGroup>
                    <h3>Notes</h3>
                    <FormControl type='textarea' componentClass="textarea"
                      placeholder='Notes'
                      value={this.state.claim.NotesBiolab}
                      onChange={event => this.handleChange('NotesBiolab', event.target.value)} />
                  </FormGroup>
                  <FormGroup>
                    <h3>Status</h3>
                    <select ref={r => this.claimstatus = r}
                            className='form-control'
                            onChange={event => this.handleChange('StatusID', event.target.value)}>
                      {statuses}
                    </select>
                  </FormGroup>

                </div>
              </div>
            </IBox>
          </div>
          <div className='col-xs-6'>
            <h2>Water Test</h2>
            <iframe class="helitack-report-container" src={this.state.claim.AnalysisLink.replace("http://", "//")} scrolling="yes" style={{width: '99%', height: '600px'}} />
            <h2>Invoice</h2>
            <ul>
              {attachments.map((v, k) => {
                return <li key={k}><a href={v.link}>{v.name}</a></li>;
              })}
            </ul>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-6'>
            <button className={'btn' + (this.state.updated ? ' btn-primary' : ' btn-gray')} type='submit'>{this.state.updated ? 'Save' : 'Saved'}</button>
          </div>
        </div>
      </div>
    </form>;
  };
}

let TruBlueClaimItemWrapper = requirePermissions('canViewTruBlueClaims')(TruBlueClaimItem);

export default TruBlueClaimItem;
