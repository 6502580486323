// For the future developers who get here:
// https://github.com/ryanflorence/react-training/blob/gh-pages/lessons/05-wrapping-dom-libs.md

import _ from 'lodash';

import PropTypes from 'prop-types';

import React from 'react';

import ReactDOM from 'react-dom';

import 'amcharts3';
import 'amcharts3/amcharts/themes/light';
import 'amcharts3/amcharts/plugins/export/export';

let AmCharts = window.AmCharts;

/**
 * Wrapper around AmCharts.makeChart
 */
class AmChart extends React.Component {
  static defaultProps = {height: 300};

  static propTypes = {
    config: PropTypes.object.isRequired,
    className: PropTypes.string,
    height: PropTypes.number,
    noData: PropTypes.node,
  };

  componentDidMount() {
    this.node = ReactDOM.findDOMNode(this);

    this.updateNode();
    this._chart = AmCharts.makeChart(this.node, _.cloneDeep(this.props.config));
  }

  componentWillReceiveProps(nextProps) {
    this.updateNode(nextProps);
  }

  componentDidUpdate() {
    this.updateChart();
  }

  componentWillUnmount() {
    this._chart.clear();
    ReactDOM.unmountComponentAtNode(this.node);
  }

  updateChart = (props = this.props) => {
    Object.assign(this._chart, props.config);

    this._chart.validateNow(true);

    // Certain chart types don't have this
    if (this._chart.zoomOut) {
      this._chart.zoomOut();
    }
  };

  updateNode = (props = this.props) => {
    this.node.className = props.className || '';
    this.node.style.height = props.height + 'px';
  };

  render() {
    return <div />;
  }
}

export default AmChart;
