/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';

class Modal extends React.Component {
  static propTypes = {
    show: PropTypes.bool,
    onHide: PropTypes.func,
    children: PropTypes.any,
  };

  static childContextTypes = {
    hideModal: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      visible: props.show || false,
    };
  }

  getChildContext() {
    return {
      hideModal: this.hide,
    };
  }

  hide = () => {
    if (!this.state.visible) {
      return;
    }

    this.setState({visible: false}, () => {
      if (this.props.onHide) {
        this.props.onHide();
      }
    });
  };

  render() {
    if (!this.state.visible) {
      return null;
    }

    return <div className={`modal-backdrop`}>
      <div className='modal' style={{display: 'block', maxHeight: '85%'}}>
        <div className='modal-dialog' style={{position: 'relative', height: '100%'}}>
          <div className='modal-content' style={{maxHeight: '80%', overflowY: 'auto'}}>
            {this.props.children}
          </div>
        </div>
      </div>
    </div>;
  }
}


Modal.Header = ({children, closeButton}, {hideModal}) => <div className='modal-header'>
  {children}
  {closeButton ? <button onClick={hideModal} style={{position: 'absolute', right: 5, top: 5}}>X</button> : null}
</div>;

Modal.Header.propTypes = {
  children: PropTypes.any,
  closeButton: PropTypes.bool,
};

Modal.Header.contextTypes = {
  hideModal: PropTypes.func,
};

Modal.Title = ({children}) => <h2 style={{marginRight: 50}}>{children}</h2>;


Modal.Body = ({children}) => <div className='modal-body'>{children}</div>;

Modal.Body.propTypes = {
  children: PropTypes.any,
};

Modal.Footer = ({children}) => <div className='modal-footer'>{children}</div>;

Modal.Footer.propTypes = {
  children: PropTypes.any,
};


export default Modal;
