import React from 'react';
import ReactDOM from 'react-dom';
import {FormattedMessage, FormattedNumber, injectIntl} from 'react-intl';
import {connectToTranslations} from '../IntlWrapper';
import {getImagePath} from '../../helpers/image-helper.jsx';
import SpecialCharacterHelper from '../../helpers/special-character-helper.jsx';

function hideParentNode(e) {
  if (window.displayBrokenImages === 'False') {
    ReactDOM.findDOMNode(e.target).parentNode.style.display = 'none';
  }
}

const ProblemsSolutions = ({intl, data, formatPrintFile}) =>
  <div className="container maintenance-schedule">
    <table>
      <thead>
        <tr className="dividers">
          <th className="table-col-4">
            <h2><FormattedMessage id='report-viewer.maintenance-schedule.title'/></h2>
          </th>
          <th className="table-col"/>
        </tr>
      </thead>

      <tbody>
        {data.ProblemAreas.map((row, i) =>
          <tr className="dividers problem-solution-row" key={i}>
            <td className="table-col-4">
              <div>
                <h4 className={row.problemTypeImages && row.problemTypeImages.length > 0 ? 'images' : ''}>
                  {(formatPrintFile(row.problemTitle) + formatPrintFile(row.problemType))
                  .split('|')
                  .map((titlePart, tpIdx) => <div key={tpIdx}>
                    {SpecialCharacterHelper.fixSpecialCharactersJSX((tpIdx === 0 ? i + 1 + '. ' : '') + titlePart)}
                  </div>)}

                  {row.problemTypeImages && row.problemTypeImages.map((image, j) =>
                    <img className="problem-image" key={j}
                       src={getImagePath({file: image, locale: intl.locale, folder: 'ProblemTypes'})}/>
                )}
                </h4>
              </div>
              <ul className="title-list">
                <li>{formatPrintFile(row.problemTypeDescription)}</li>
              </ul>

              {row.problemTypeGraph && <div className="problem-graph">
                <img src={getImagePath({file: row.problemTypeGraph, locale: intl.locale, folder: !!data.ShowKeyParameterRangesSection ? 'ProblemGraphsNewRanges' : 'ProblemGraphs'})}
                   onError={hideParentNode}/>
                <div><FormattedNumber value={row.problemTypeCurrent}/></div>
              </div>}
            </td>

            <td className="table-col">
              {row.problemSolutions && row.problemSolutions.map((solution, j) =>
                <div key={j}>
                  {solution.problemSolutionHeadings &&
                  <p dangerouslySetInnerHTML={{
                    __html: SpecialCharacterHelper.fixSpecialCharacters(solution.problemSolutionHeadings.map(formatPrintFile).join(' ')),
                  }}/>}
                  <ul>
                    {solution.problemSolutionBullets && solution.problemSolutionBullets.map(formatPrintFile).map((bullet, k) =>
                      <li key={k}>{bullet}</li>)}
                  </ul>

                  {solution.problemSolutionNotes && solution.problemSolutionNotes.map((note, k) =>
                    <div key={k} className="solution-notes">
                      <img className="note-image"
                         src={getImagePath({file: note.imgName, locale: intl.locale, folder: 'SpecialInstructions'})}/>
                      {note.note}
                    </div>
                )}
                </div>
            )}
            </td>
          </tr>
      )}

        <tr className="fine-print">
          <td className="table-col" colSpan="3">
            <FormattedMessage id='report-viewer.maintenance-schedule.disclaimer'/>
          </td>
        </tr>
      </tbody>
    </table>
  </div>;

export default connectToTranslations(injectIntl(ProblemsSolutions));
