import createModel from './create';

export const LINK_TYPES = {
  ENTRY_POINT: 'ENTRY_POINT',
  BASIC: 'BASIC',
  ITERATIVE: 'ITERATIVE',
  ASYNC_ACTION: 'ASYNC_ACTION',
  ITERATIVE_ASYNC_ACTION: 'ITERATIVE_ASYNC_ACTION',
};

const Link = createModel({
  name: 'Link',
  properties: {
    group: undefined,
    index: undefined,
    source: undefined,
    target: undefined,
    type: undefined,
    value: undefined,
  },
});

export default Link;
