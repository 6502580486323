import PropTypes from 'prop-types';
import React from 'react';
import PageHeader from '../components/pageHeader.jsx';
import AdminPage from '../components/search/adminpage.jsx';
import Dealer from '../components/search/dealer.jsx';
import PrintFile from '../components/search/printfile.jsx';
import searchApi from '../../api/search.jsx';
import {browserHistory} from 'react-router';


// Page object
class PageSearch extends React.Component {
  static propTypes = {
    params: PropTypes.object,
  };

  state = {
    filter: '',
    resultCount: 0,
    resultTime: 0,
    suggest: '',
    results: [],
  };

  componentDidMount() {
    this.search(this.props.params.search);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.params.search !== this.state.filter) {
      this.search(nextProps.params.search);
    }
  }

  goSearch = (e) => {
    e.preventDefault();

    let searchStr = this.bigSearch.value;

    browserHistory.push('/search/' + searchStr);

    return false;
  };

  goSuggest = (e) => {
    e.preventDefault();

    browserHistory.push('/search/' + this.state.suggest);
    return false;
  };

  results = () => {
    let results = [];

    if (this.state.results.length === 0) {
      return '';
    }

    this.state.results.forEach((val, idx) => {
      if (val.Index.includes('dealer')) {
        results.push(<Dealer record={val} key={idx} />);
        results.push(<div key={idx + '.hr'} className='hr-line-dashed'/>);
      } else if (val.Index.includes('adminpage')) {
        results.push(<AdminPage record={val} key={idx} />);
        results.push(<div key={idx + '.hr'} className='hr-line-dashed'/>);
      } else if (val.Index.includes('printfile')) {
        results.push(<PrintFile record={val} key={idx} />);
        results.push(<div key={idx + '.hr'} className='hr-line-dashed'/>);
      }
    });

    return results;
  };

  search = (filter) => {
    searchApi.search(filter, 1).then((res) => {
      let suggest = '';

      try {
        suggest = res.data.suggest.suggest[0].Options.length > 0 &&
              res.data.suggest.suggest[0].Options[0].SuggestScore > 0.65 ? res.data.suggest.suggest[0].Options[0].Text : '';
      } catch (err) {
        suggest = '';
      }

      this.setState({
        resultTime: res.data.time / 1000,
        resultCount: res.data.total,
        results: res.data.hits,
        suggest: suggest,
      })
      .catch(err => {
        console.error(err);
      });
    });
  };

  render() {
    let sug = '';

    if (this.state.suggest) {
      sug = <h3><i>Did you mean “<a onClick={this.goSuggest}>{this.state.suggest}</a>”</i></h3>;
    }

    return (
      <div>
        <PageHeader title='Search Results' icon='fa fa-search' breadcrumbs={[{title: 'Search', path: '/search'}]} />
        <div className='wrapper wrapper-content'>
          <div className='row' id='page-search'>
            <div className='col-xs-12'>
              <div className='ibox float-e-margins'>
                <div className='ibox-content'>
                  <h2>{this.state.resultCount} results found for: <span className='text-navy'>“{this.props.params.search}”</span></h2>
                  {sug}
                  <small>Request time ({this.state.resultTime} seconds)</small>
                  <div className='search-form'>
                    <form method='get' onSubmit={this.goSearch}>
                      <div className='input-group'>
                        <input type='text'
                        ref={r => this.bigSearch = r}
                        placeholder='Search'
                        autoComplete='off'
                        defaultValue={this.props.params.search}
                        className='form-control input-lg' />
                        <div className='input-group-btn'>
                          <button className='btn btn-lg btn-primary' type='submit'>Search</button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className='hr-line-dashed'/>
                  {this.results()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>);
  }
}

export default PageSearch;
