import React from 'react';
import {browserHistory} from 'react-router';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

import PageHeader from '../../components/pageHeader.jsx';
import Loader from '../../components/loader.jsx';
import IBoxes from '../../components/iBoxes.jsx';
import Modal from '../../components/Modal.jsx';
import {connectToToasts, TOAST_STATES} from '../../components/toasts.jsx';

import DealerApi from '../../../api/dealer.jsx';
import BranchApi from '../../../api/branch.jsx';
import ToolsApi from '../../../api/tools.jsx';

const defaultOption = {value: '', label: 'Select a dealer'};
const defaultBranchOption = {value: '', label: 'Select a store'};

// Page object
class PageTransfer extends React.Component {
  static contextTypes = {
    canUseTools: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      dealerOptions: [],
      selected: {
        fromDealer: undefined,
        toDealers: {}, // key is the branch id which is to be transferred.
        toBranches: {},
      },
      branchOptions: [],
      branchOptionsForTransfer: {},
      loaded: false,
      showConfirmation: false,
    };
  }

  componentWillMount() {
    if (!this.context.canUseTools) {
      browserHistory.push('/');
    } else {
      this.loadDealers();
    }
  }

  loadDealers = () => {
    const {createToast} = this.props;

    DealerApi.getDealers()
      .then(dealers => {
        this.setState({
          dealerOptions: [defaultOption].concat(dealers.map((x) => ({
            label: x.Name + ' (' + x.Account + ')',
            value: x.ID,
          }))),
          selected: {
            fromDealer: defaultOption,
            toDealers: {}, // key is the branch id which is to be transferred.
            toBranches: {},
          },
          branchOptions: [],
          branchOptionsForTransfer: {},
          loaded: true,
          showConfirmation: false,
        });
      })
      .catch(error => {
        createToast({
          id: 'load-dealers',
          message: error.message || 'Couldn\'t load dealers.',
          state: TOAST_STATES.ERROR,
        });
        console.error(error);
      });
  }

  updateFromDealer = (selected) => {
    const {createToast} = this.props;

    if (selected === defaultOption) {
      this.setState(prevState => ({
        selected: {
          ...prevState.selected,
          fromDealer: selected,
        },
        branchOptions: [],
        branchOptionsForTransfer: {},
      }));
    } else {
      this.setState(prevState => ({
        selected: {
          ...prevState.selected,
          fromDealer: selected,
        },
        loaded: false,
      }), () => {
        BranchApi.getBranches(this.state.selected.fromDealer.value)
          .then(branches => {
            this.setState({
              branchOptions: branches.map(x => ({
                label: x.name,
                value: x.id,
              })),
              loaded: true,
            });
          })
          .catch(error => {
            createToast({
              id: 'load-branches',
              message: error.message || 'Couldn\'t load stores.',
              state: TOAST_STATES.ERROR,
            });
            console.error(error);
          });
      });
    }
  }

  updateToDealer = (key, selected) => {
    const {createToast} = this.props;

    this.setState(prevState => ({
      selected: {
        ...prevState.selected,
        toDealers: {
          ...prevState.selected.toDealers,
          [key]: selected !== defaultOption ? selected : undefined,
        },
        toBranches: {
          ...prevState.toBranches,
          [key]: undefined,
        },
      },
      loaded: !selected,
    }), () => {
      if (selected) {
        BranchApi.getBranches(selected.value)
          .then(branches => {
            this.setState(prevState => ({
              branchOptionsForTransfer: {
                ...prevState.branchOptionsForTransfer,
                [key]: branches.map(x => ({
                  label: x.name,
                  value: x.id,
                })),
              },
              loaded: true,
            }));
          })
          .catch(error => {
            createToast({
              id: 'load-branches',
              message: error.message || 'Couldn\'t load stores.',
              state: TOAST_STATES.ERROR,
            });
            console.error(error);
          });
      }
    });
  }

  updateToBranch = (key, selected) => {
    this.setState(prevState => ({
      selected: {
        ...prevState.selected,
        toBranches: {
          ...prevState.selected.toBranches,
          [key]: selected !== defaultBranchOption ? selected : undefined,
        },
      },
    }));
  }

  canTransfer = () => {
    const {
      selected, branchOptions,
    } = this.state;

    return selected.fromDealer !== defaultOption &&
      branchOptions.some(b => {
        return selected.toDealers[b.value] && selected.toBranches[b.value];
      }) &&
      branchOptions.every(b => {
        return (selected.toDealers[b.value] && selected.toBranches[b.value]) ||
          (!selected.toDealers[b.value] && !selected.toBranches[b.value]);
      });
  }

  confirmTransfer = () => {
    const {selected} = this.state;

    if (this.canTransfer()) {
      this.setState({
        showConfirmation: true,
      });
    }
  }

  transfer = () => {
    const {createToast} = this.props;

    this.setState({loaded: false});
    createToast({
      id: 'transfer-dealers',
      message: 'Transfering...',
      state: TOAST_STATES.INFO,
    });
    const transerRequests = Object.keys(this.state.selected.toDealers)
      .filter(branchID => this.state.selected.toDealers[branchID] && this.state.selected.toBranches[branchID])
      .map(branchID => {
        return {
          FromDealerID: this.state.selected.fromDealer.value,
          FromBranchID: branchID,
          ToDealerID: this.state.selected.toDealers[branchID].value,
          ToBranchID: this.state.selected.toBranches[branchID].value,
        };
      });

    ToolsApi.transferBranches(transerRequests)
      .then(res => {
        createToast({
          id: 'transfer-dealers',
          message: 'Success.',
          state: TOAST_STATES.SUCCESS,
          ttl: 4000,
        });
        this.setState({
          showConfirmation: false,
        });
        this.loadDealers();
      })
      .catch(error => {
        createToast({
          id: 'transfer-dealers',
          message: error.message || 'Couldn\'t transer stores.',
          state: TOAST_STATES.ERROR,
        });
        console.error(error);
      });
  }

  render() {
    const {
      dealerOptions, selected, branchOptions,
      loaded, branchesLoaded, showConfirmation,
      transerRequests, branchOptionsForTransfer,
    } = this.state;

    return (
      <div>
        <PageHeader title='Transfer' icon='fa fa-wrench' breadcrumbs={[{title: 'Developer Tools', path: '/tools'}, {title: 'Transfer', path: '/tools/transfer'}]} />
        <div className='alert alert-warning'>
          <p>Warning! Do not run during peak hours. This report puts a lot of strain on the server. May take up to half an hour per transfer.</p>
        </div>
        <div className='wrapper wrapper-content' id='page-tools-transfer'>
          <Loader loaded={loaded}>
            <IBoxes title='Transfer Stores'>
              <div className='row'>
                <div className='col-xs-6'>
                  <h4>Transfer From</h4>
                  <ReactSelect name='dealers'
                    options={dealerOptions}
                    value={selected.fromDealer || defaultOption}
                    onChange={(val) => this.updateFromDealer(val || defaultOption)}/>
                </div>
              </div>
              {branchOptions.length > 0 || selected.fromDealer === defaultOption ?
                branchOptions.map(b =>
                  <div key={b.value} className='row'>
                    <div className='col-xs-6'>
                      <h4>Store to Transfer</h4>
                      <span>{b.label}</span>
                    </div>
                    <div className='col-xs-6'>
                      <h4>Transfer To</h4>
                      <ReactSelect
                        className='margin-bottom'
                        name={`dealers-${b.value}`}
                        options={dealerOptions}
                        value={selected.toDealers[b.value] || defaultOption}
                        onChange={(val) => this.updateToDealer(b.value, val)}/>
                      <ReactSelect
                        name={`branches-${b.value}`}
                        options={branchOptionsForTransfer[b.value] && branchOptionsForTransfer[b.value].filter(d => d.value !== b.value)}
                        value={selected.toBranches[b.value] || defaultBranchOption}
                        onChange={(val) => this.updateToBranch(b.value, val)}/>
                    </div>
                  </div>
                ) :
                <div className='row'>
                  <div className='col-xs-12'>
                    This dealer has no stores.
                  </div>
                </div>
              }
              <div>
                <button onClick={this.confirmTransfer} disabled={!this.canTransfer()} className='btn btn-primary'>
                  Transfer
                </button>
              </div>
            </IBoxes>
          </Loader>
          {showConfirmation ?
            <Modal show={showConfirmation} onHide={() => this.setState({showConfirmation: false})}>
              <Modal.Header closeButton>
                <Modal.Title>Transfer Confirmation</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                You are about to perform the following actions:

                {branchOptions.filter(b => selected.toDealers[b.value] && selected.toBranches[b.value]).map(b =>
                  <div key={b.value}>Transfer store <span className='strong'>{b.label}</span> from the dealer <span className='strong'>{selected.fromDealer.label}</span> to the branch <span className='strong'>{selected.toBranches[b.value].label}</span> which is owned by dealer <span className='strong'>{selected.toDealers[b.value].label}.</span></div>
                )}
                <br />
                Transfering a store will transfer all store and customer related data.
              </Modal.Body>
              <Modal.Footer>
                <button onClick={this.transfer} className='btn btn-primary'>
                  Confirm Transfer(s)
                </button>
              </Modal.Footer>
            </Modal>
          : null}
        </div>
      </div>
    );
  }
}

export default connectToToasts(PageTransfer);
