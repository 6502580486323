import React from 'react';
import PropTypes from 'prop-types';
import {connectToPerformanceFile, performanceKeys} from 'ui/helpers/performanceFile';

const ReportsContainer = ({children, ...props}) => {
  return <div {...props}>{children}</div>;
};

ReportsContainer.propTypes = {
  children: PropTypes.any,
};

export const ReportContainer = connectToPerformanceFile(performanceKeys.adminReports, ReportsContainer);

