import PropTypes from 'prop-types';
import LayoutCheckbox from 'components/form/layoutCheckbox';
import LayoutSelect from 'components/form/layoutSelect';
import LayoutInput from 'components/form/layoutInput';
import React from 'react';

import TestSidebar from '../../components/testSidebar.jsx';

import LoadingBtn from '../../components/loadingBtn.jsx';

import {balanceColumns} from '../../helpers/fieldHelpers.jsx';


import PoolScoreTest from '../../../models/poolScoreTest.jsx';

import {labels} from './scoreData.jsx';


class ScoreTest extends React.Component {
  static contextTypes = {
    canEditPoolScores: PropTypes.bool.isRequired,
  };

  static propTypes = {
    addTest: PropTypes.func.isRequired,
    currentTest: PropTypes.instanceOf(PoolScoreTest).isRequired,
    dataChanged: PropTypes.func.isRequired,
    deleteTest: PropTypes.func.isRequired,
    deletingTest: PropTypes.object.isRequired,
    editTest: PropTypes.func.isRequired,
    outputs: PropTypes.array.isRequired,
    runAllTests: PropTypes.func.isRequired,
    runningAllTests: PropTypes.bool.isRequired,
    runningTest: PropTypes.object.isRequired,
    runTest: PropTypes.func.isRequired,
    saveTest: PropTypes.func.isRequired,
    savingTest: PropTypes.bool.isRequired,
    testChanged: PropTypes.func.isRequired,
    tests: PropTypes.array.isRequired,
    toggleEdit: PropTypes.func.isRequired,
  };

  getActualResults = () => {
    let score = this.props.outputs.filter(x => x.testID === this.props.currentTest.id)[0];

    return [<div className='row'>
      <div className='col-xs-4'>
        <div className='form-group'>
          <label>Swimmer Comfort</label>
          <div>{score ? score.swimmerComfort + ' stars' : '(test not run)'}</div>
          {this.getScoreIntermediates(score, 'swimmerComfortIntermediate')}
        </div>
      </div>
      <div className='col-xs-4'>
        <div className='form-group'>
          <label>Water Clarity</label>
          <div>{score ? score.waterClarity + ' stars' : '(test not run)'}</div>
          {this.getScoreIntermediates(score, 'waterClarityIntermediate')}
        </div>
      </div>
      <div className='col-xs-4'>
        <div className='form-group'>
          <label>Surface & Equipment Protection</label>
          <div>{score ? score.surfaceEquipmentProtection + ' stars' : '(test not run)'}</div>
          {this.getScoreIntermediates(score, 'surfaceProtectionIntermediate')}
        </div>
      </div>
    </div>,
      score && score.expectedWaterClarityMatch && score.expectedSwimmerComfortMatch && score.expectedSurfaceEquipmentProtectionMatch,
      !score,
    ];
  };

  getDescriptionRow = () => {
    let all = [];
    let test = this.props.currentTest;
    let dataChanged = this.props.dataChanged;

    all.push(<LayoutInput label='Test Description' value={test.description} key='description'
      dataKey='description' dataChanged={dataChanged} editMode={true} />);

    return balanceColumns(all, 1);
  };

  getExpectedResults = () => {
    let all = [];
    let data = this.props.currentTest;
    let dataChanged = this.props.dataChanged;

    all.push(<LayoutInput type='number' label='Expected Swimmer Comfort' dataKey='expectedSwimmerComfort'
      key='expectedSwimmerComfort' value={data.expectedSwimmerComfort}
      editMode={true} dataChanged={dataChanged} />);

    all.push(<LayoutInput type='number' label='Expected Water Clarity' dataKey='expectedWaterClarity'
      key='expectedWaterClarity' value={data.expectedWaterClarity}
      editMode={true} dataChanged={dataChanged} />);

    all.push(<LayoutInput type='number' label='Expected Surface & Equipment Protection'
      dataKey='expectedSurfaceEquipmentProtection' key='expectedSurfaceEquipmentProtection'
      value={data.expectedSurfaceEquipmentProtection} editMode={true} dataChanged={dataChanged} />);

    return balanceColumns(all, 3);
  };

  getScoreIntermediates = (scores, key) => {
    let output = null;

    if (scores && scores[key] && Object.keys(scores[key]).length > 0) {
      let keys = Object.keys(scores[key]);
      let rows = keys.map(variable => {
        return <tr key={key + variable}>
          <td>{labels[variable]}</td>
          <td>{scores[key][variable]}</td>
        </tr>;
      });

      output = <table className='table table-striped' style={{marginTop: 20}}>
        <thead>
          <tr>
            <th>Variable</th>
            <th>Change in Stars</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </table>;
    }

    return output;
  };

  getTestFields = () => {
    let all = [];
    let data = this.props.currentTest;
    let dataChanged = this.props.dataChanged;

    all.push(<LayoutSelect label='Sanitizer Type' dataKey='chemicalBaseTypeID' value={data.chemicalBaseTypeID}
      options={['Bromine Pool', 'Chlorine Pool', 'SoftSwim Pool', 'Chlorine Spa', 'Bromine Spa', 'Mineral Springs/Salt', 'SoftSoak Spa']}
      keys={[1, 2, 3, 5, 6, 7, 8]} editMode={true} dataChanged={dataChanged} />);

    all.push(<LayoutInput type='number' label='Temperature' dataKey='temperature' key='temperature' value={data.temperature} editMode={true}
      dataChanged={dataChanged} />);

    all.push(<LayoutInput type='number' label='TDS' dataKey='tds' key='tds' value={data.tds} editMode={true} dataChanged={dataChanged} />);

    all.push(<LayoutInput type='number' label='Free Chlorine' dataKey='freeChlorine' key='freeChlorine' value={data.freeChlorine}
      editMode={true} dataChanged={dataChanged} />);

    all.push(<LayoutInput type='number' label='Total Chlorine' dataKey='totalChlorine' key='totalChlorine' value={data.totalChlorine}
      editMode={true} dataChanged={dataChanged} />);

    all.push(<LayoutInput type='number' label='Total Bromine' dataKey='totalBromine' key='totalBromine' value={data.totalBromine}
      editMode={true} dataChanged={dataChanged} />);

    all.push(<LayoutInput type='number' label='Hardness' dataKey='hardness' key='hardness' value={data.hardness}
      editMode={true} dataChanged={dataChanged} />);

    all.push(<LayoutInput type='number' label='Borates' dataKey='optimizerPlusBorate' key='optimizerPlusBorate' value={data.optimizerPlusBorate}
      editMode={true} dataChanged={dataChanged} />);

    all.push(<LayoutInput type='number' label='Iron' dataKey='iron' key='iron' value={data.iron}
      editMode={true} dataChanged={dataChanged} />);

    all.push(<LayoutInput type='number' label='Copper' dataKey='copper' key='copper' value={data.copper}
      editMode={true} dataChanged={dataChanged} />);

    all.push(<LayoutInput type='number' label='CYA' dataKey='cya' key='cya' value={data.cya}
      editMode={true} dataChanged={dataChanged} />);

    all.push(<LayoutInput type='number' label='Biguanide' dataKey='biguanide' key='biguanide' value={data.biguanide}
      editMode={true} dataChanged={dataChanged} />);

    all.push(<LayoutInput type='number' label='Peroxide' dataKey='peroxide' key='peroxide' value={data.peroxide}
      editMode={true} dataChanged={dataChanged} />);

    all.push(<LayoutInput type='number' label='pH' dataKey='pH' key='pH' value={data.pH}
      editMode={true} dataChanged={dataChanged} />);

    all.push(<LayoutInput type='number' label='Alkalinity' dataKey='alkalinity' key='alkalinity' value={data.alkalinity}
      editMode={true} dataChanged={dataChanged} />);

    all.push(<LayoutCheckbox label='Manganese' dataKey='mangenese' key='mangenese' value={data.mangenese}
      editMode={true} dataChanged={dataChanged} />);

    all.push(<LayoutCheckbox label='Hazy' dataKey='hazy' key='hazy' value={data.hazy}
      editMode={true} dataChanged={dataChanged} />);

    all.push(<LayoutCheckbox label='Cloudy' dataKey='cloudy' key='cloudy' value={data.cloudy}
      editMode={true} dataChanged={dataChanged} />);

    all.push(<LayoutCheckbox label='Foaming' dataKey='foaming' key='foaming' value={data.foaming}
      editMode={true} dataChanged={dataChanged} />);

    all.push(<LayoutCheckbox label='Swampy' dataKey='swampy' key='swampy' value={data.swampy}
      editMode={true} dataChanged={dataChanged} />);

    all.push(<LayoutCheckbox label='Slime/Mold' dataKey='slimeMold' key='slimeMold' value={data.slimeMold}
      editMode={true} dataChanged={dataChanged} />);

    all.push(<LayoutCheckbox label='Green Algae' dataKey='greenAlgae' key='greenAlgae' value={data.greenAlgae}
      editMode={true} dataChanged={dataChanged} />);

    all.push(<LayoutCheckbox label='Black Algae' dataKey='blackAlgae' key='blackAlgae' value={data.blackAlgae}
      editMode={true} dataChanged={dataChanged} />);

    all.push(<LayoutCheckbox label='Mustard Algae' dataKey='mustardAlgae' key='mustardAlgae' value={data.mustardAlgae}
      editMode={true} dataChanged={dataChanged} />);

    all.push(<LayoutCheckbox label='Spa Algae' dataKey='spaAlgae' key='spaAlgae' value={data.spaAlgae}
      editMode={true} dataChanged={dataChanged} />);

    all.push(<LayoutCheckbox label='Scale Buildup' dataKey='scaleBuildup' key='scaleBuildup' value={data.scaleBuildup}
      editMode={true} dataChanged={dataChanged} />);

    all.push(<LayoutCheckbox label='Waterline Buildup' dataKey='waterlineBuildup' key='waterlineBuildup' value={data.waterlineBuildup}
      editMode={true} dataChanged={dataChanged} />);

    all.push(<LayoutCheckbox label='Iron Staining' dataKey='ironStaining' key='ironStaining' value={data.ironStaining}
      editMode={true} dataChanged={dataChanged} />);

    all.push(<LayoutCheckbox label='Copper Staining' dataKey='copperStaining' key='copperStaining' value={data.copperStaining}
      editMode={true} dataChanged={dataChanged} />);

    all.push(<LayoutCheckbox label='Organic Staining' dataKey='organicStaining' key='organicStaining' value={data.organicStaining}
      editMode={true} dataChanged={dataChanged} />);

    all.push(<LayoutCheckbox label='Unknown Staining' dataKey='unknownStaining' key='unknownStaining' value={data.unknownStaining}
      editMode={true} dataChanged={dataChanged} />);

    all.push(<LayoutCheckbox label='Using Maint Clarifier' dataKey='usingMaintClarifier' key='usingMaintClarifier' value={data.usingMaintClarifier}
      editMode={true} dataChanged={dataChanged} />);

    all.push(<LayoutCheckbox label='Scale Inhibitor Recommended' dataKey='scaleInhibitor' key='scaleInhibitor' value={data.scaleInhibitor}
      editMode={true} dataChanged={dataChanged} />);

    all.push(<LayoutCheckbox label='Using Vanishing Act' dataKey='vanishingAct' key='vanishingAct' value={data.vanishingAct}
      editMode={true} dataChanged={dataChanged} />);

    all.push(<LayoutCheckbox label='Using Spa Sentry' dataKey='spaSentry' key='spaSentry' value={data.spaSentry} editMode={true} dataChanged={dataChanged} />);

    return balanceColumns(all, 4);
  };

  render() {
    let expected = this.getExpectedResults();
    let fields = this.getTestFields();
    let [actual, passed, notRun] = this.getActualResults();
    let testID = this.props.currentTest.id;
    let desc = this.getDescriptionRow();

    let saveEnabled = (!testID || testID && this.props.testChanged(testID)) && this.context.canEditPoolScores;

    let testStatus = 'test-unknown';
    let outputs = this.props.outputs.filter(x => x.testID === this.props.currentTest.id);

    if (outputs.length > 0) {
      let output = outputs[0];

      if (!output.expectedWaterClarityMatch || !output.expectedSwimmerComfortMatch || !output.expectedSurfaceEquipmentProtectionMatch) {
        testStatus = 'test-error';
      } else {
        testStatus = 'test-success';
      }
    }

    return <div>
      <div className='row'>
        <div className='col-xs-8'>
          <div className='row' style={{marginBottom: 20}}>
            <div className='col-xs-12'>
              {testID && this.context.canEditPoolScores ?
                <LoadingBtn className='btn btn-default'
                    onClick={this.props.deleteTest(testID)}
                    loading={this.props.deletingTest[testID]}
                    disabled={!testID}>
                  Delete
                </LoadingBtn> :
                null}

              {saveEnabled ?
                <LoadingBtn className='btn btn-primary'
                    onClick={this.props.saveTest}
                    loading={this.props.savingTest}>
                  Save
                </LoadingBtn> :
                null}
              {!saveEnabled ?
                <LoadingBtn className='btn btn-primary'
                    onClick={this.props.runTest(testID)}
                    loading={this.props.runningTest[testID]}
                    title='You must save the test before running it.'
                    disabled={!testID || testID && this.props.testChanged(testID)}>
                  Run
                </LoadingBtn> :
              null}
              <button className='btn btn-primary pull-right' onClick={this.props.toggleEdit}>Change Pool Score Calculation Parameters</button>
            </div>
          </div>
          {desc}
          <h3 className={testStatus}>Test Results{passed ? ': PASSED' : notRun ? '' : ': FAILED'}</h3>
          {actual}
          <hr />
          <h3>Expected Results</h3>
          {expected}
          <hr />
          <h3>Inputs</h3>
          {fields}
        </div>
        <div className='col-xs-4'>
          <TestSidebar
            addTest={this.props.addTest}
            editTest={this.props.editTest}
            outputs={this.props.outputs}
            runAllTests={this.props.runAllTests}
            runningAllTests={this.props.runningAllTests}
            runningTest={this.props.runningTest}
            runTest={this.props.runTest}
            testChanged={this.props.testChanged}
            tests={this.props.tests.filter(x => x.id)
              .sort((a, b) => a.description && b.description ?
                a.description.toLowerCase() < b.description.toLowerCase() ? -1 : 1                :
                a.description < b.description ? -1 : 1)}
          />
        </div>
      </div>
    </div>;
  }
}

export default ScoreTest;
