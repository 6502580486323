export const labels = {
  saturationIndex: 'Saturation Index',
  saturationIndexValue: 'SI Value',
  pH: 'pH',
  hardness: 'Hardness',
  freeChlorine: 'Free Chlorine',
  totalChlorine: 'Total Chlorine',
  combinedChlorine: 'Combined Chlorine',
  biguanide: 'Biguanide',
  peroxide: 'Peroxide',
  borates: 'Borates',
  copper: 'Copper > 0.2',
  iron: 'Iron > 0.2',
  manganese: 'Manganese',
  usingMaintClarifier: 'Using Maint Clarifier',
  hazy: 'Hazy',
  cloudy: 'Cloudy',
  foaming: 'Foaming',
  greenAlgae: 'Green Algae',
  mustardAlgae: 'Mustard Algae',
  blackAlgae: 'Black Algae',
  spaAlgae: 'Spa Algae',
  scaleBuildup: 'Scale Buildup',
  waterlineBuildup: 'Waterline Buildup',
  copperStaining: 'Copper Staining',
  ironStaining: 'Iron Staining',
  unknownStaining: 'Unknown Staining',
  metals: 'Metals',
  borateGT30: 'Borates >= 30',
  maxCCAndFC: 'Max CC + FC',
  totalBromine: 'Total Bromine',
  swampy: 'Swampy',
  cappedMetals: 'Capped Metals',
  cappedProblems: 'Capped Water Problems',
  cappedCCAndFC: 'Capped CC + FC',
  cappedStaining: 'Capped Staining',
  slimeMold: 'Slime/Mold',
  organicStaining: 'Organic Staining',
};

export const categories = {
  saturationIndex: 'Water Balance',
  pH: 'Water Balance',
  hardness: 'Water Balance',
  freeChlorine: 'Chlorine',
  totalChlorine: 'Chlorine',
  combinedChlorine: 'Chlorine',
  biguanide: 'Biguanide/Peroxide',
  peroxide: 'Biguanide/Peroxide',
  borates: 'Other',
  copper: 'Metals',
  iron: 'Metals',
  manganese: 'Metals',
  usingMaintClarifier: 'Other',
  hazy: 'Clarity',
  cloudy: 'Clarity',
  foaming: 'Clarity',
  greenAlgae: 'Algae',
  mustardAlgae: 'Algae',
  blackAlgae: 'Algae',
  spaAlgae: 'Algae',
  swampy: 'Algae',
  slimeMold: 'Algae',
  scaleBuildup: 'Surface',
  waterlineBuildup: 'Surface',
  copperStaining: 'Surface',
  ironStaining: 'Surface',
  unknownStaining: 'Surface',
  metals: 'Metals',
  borateGT30: 'Other',
  maxCCAndFC: 'Other',
  totalBromine: 'Bromine',
  organicStaining: 'Surface',
};

export const categoryColours = {
  'Water Balance': '#E91E63',
  Chlorine: '#00BCD4',
  'Biguanide/Peroxide': '#4CAF50',
  Other: '#2196F3',
  Metals: '#FFC107',
  Clarity: '#9575CD',
  Algae: '#9E9E9E',
  Surface: '#CDDC39',
  Bromine: '#FFEB3B',
};
