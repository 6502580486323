import React from 'react';

import toolsApi from '../../../api/tools.jsx';

import Spinner from '../spinner.jsx';

// Page object
class ReindexCustomer extends React.Component {
  state = {
    status: 'ready',
    percent: 0,
  };

  componentDidMount() {
    this._checkStatus = setInterval(this.updateStats, 30000);
    this.updateStats();
  }

  componentWillUnmount() {
    clearInterval(this._checkStatus);
  }

  _checkStatus = null;

  cancel = () => {
    this.setState({
      status: 'loading',
      percent: 0,
    });

    toolsApi.reindexCustomerCancel();

  };

  reindex = () => {

    this.setState({
      status: 'loading',
      percent: 0,
    });

    toolsApi.reindexCustomer();

  };

  updateStats = () => {
    toolsApi.reindexCustomerStatus().then(function(res) {
      let status = 'ready';
      let percent = 0;

      if (res.data.count > 0) {
        status = 'running';
        percent = Math.floor(100 - res.data.count / res.data.total * 100);
      }
      this.setState({
        status: status,
        percent: percent,
      });
    });

  };

  render() {

    let action = '';

    if (this.state.status === 'loading') {
      action = <Spinner />;
    }else if (this.state.status === 'ready') {
      action = <div>
        <h4 className='media-heading'>Ready to Reindex</h4>
        <div className='tc'>
          <button className='btn btn-primary' onClick={this.reindex}>Reindex</button>
        </div>
      </div>;
    }else if (this.state.status === 'running') {
      action = <div>
        <h4 className='media-heading'>Currently Reindexing ({this.state.percent}%)</h4>
        <div className='progress progress-striped active'>
          <div style={{width: this.state.percent + '%'}} className='progress-bar'/>
        </div>
        <div className='tc'>
          <button className='btn btn-primary' onClick={this.cancel}>Cancel</button>
        </div>
      </div>;
    }

    return (
      <div>
        <div className='widget-head-color-box navy-bg p-lg text-center'>
          <div className='m-b-md'>
            <h2 className='font-bold no-margins'>Reindex Customers</h2>
            <small>This will take several hours</small>
          </div>
          <i className='fa fa-cloud fa-5x' />
        </div>
        <div className='widget-text-box'>
          {action}
        </div>
      </div>
    );
  }
}

export default ReindexCustomer;
