import React from 'react';
import {FormattedMessage} from 'react-intl';

const Established = ({date}) =>
  <div className="container established">
    <div className="row">
      <div className="col"><FormattedMessage id='report-viewer.established.date' values={{date}} /></div>
    </div>
  </div>;

export default Established;
