import qwest from 'qwest';

import reportOptions from 'helpers/reportOptions';

let resolveSuccessful = promise => {
  return new Promise((resolve, reject) => {
    promise
    .then((xhr, response) => {
      if (response.success) {
        resolve(response.data);
      } else {
        reject(response);
      }
    })
    .catch((e) => {
      resolve(e);
    });
  });
};

let ReportApi = {
  getPlatinumStatusList() {
    return new Promise(resolve => {
      resolve(['NA', 'PL', 'NP']);
    });
  },

  getDSMList() {
    return resolveSuccessful(qwest.get('/api/Report/DSMList', reportOptions));
  },

  getRMList() {
    return resolveSuccessful(qwest.get('/api/Report/RMList', reportOptions));
  },

  getStateList() {
    return resolveSuccessful(qwest.get('/api/Report/StateList', reportOptions));
  },

  getGeneralStats(startDate, endDate, platinumStatus, dsm, rm, state) {
    return resolveSuccessful(qwest.post('/api/Report/GeneralStats', {
      start: startDate,
      end: endDate,
      PlatinumStatus: platinumStatus,
      DSM: dsm,
      RM: rm,
      State: state,
    }, reportOptions));
  },

  getEmailsReport(startDate, endDate, platinumStatus, dsm, rm, state) {
    return resolveSuccessful(qwest.post('/api/Report/Emails', {
      start: startDate,
      end: endDate,
      PlatinumStatus: platinumStatus,
      DSM: dsm,
      RM: rm,
      State: state,
    }, reportOptions));
  },

  getNotificationsGraph(platinumStatus, dsm, rm, state) {
    return resolveSuccessful(qwest.post('/api/Report/NotificationsGraph', {
      PlatinumStatus: platinumStatus,
      DSM: dsm,
      RM: rm,
      State: state,
    }, reportOptions));
  },

};

export default ReportApi;
