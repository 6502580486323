import React from 'react';
import HealthApi from 'api/health';
import IBox from 'ui/components/iBox';
import moment from 'moment';

function formatStatus({Key, Start, Stop, Next}) {
  Start = Start ? moment(Start) : Start;
  Stop = Stop ? moment(Stop) : Stop;
  if (typeof Next !== undefined) {
    Next = Next ? moment(Next) : Next;
  }

  let nextLocal;

  if (typeof Next === undefined && Next < Start) {
    nextLocal = 'Unscheduled';
  } else {
    nextLocal = Next.format('LLL');
  }

  if (Start && Stop) {
    return <span><strong>{Key}</strong>: <time>{Start.format('LT')}</time> &ndash; <time>{Stop.format('LT')}</time>.
      <i> Ran for {Math.round(moment.duration(Stop.valueOf() - Start.valueOf(), 'ms').asMinutes())} minutes. </i>
      Next run at <time>{nextLocal}</time></span>;
  }

  if (Start && !Stop) {
    return <span><strong>{Key}</strong>: Started at <time>{Start.format('LT')}</time> Next run at <time>{nextLocal}</time></span>;
  }

  return <span><strong>{Key}</strong>: Never run. Next run at <time>{nextLocal}</time></span>;
}

class JobStatus extends React.Component {
  state = {
    statuses: [],
  };

  componentWillMount() {
    this.fetchData();
    this.interval = setInterval(this.fetchData, 60000);
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  fetchData = () => {
    HealthApi.getJobStatus()
      .then(data => {
        const statuses = data.data || [];

        this.setState({statuses});
      });
  };

  render() {
    return <IBox title='Job Status'>
      {this.state.statuses.length ?
        this.state.statuses.map(s => <p key={s.Key}>{formatStatus(s)}</p>) :
        <p>No jobs registered.</p>}
    </IBox>;
  }
}

export default JobStatus;
