import moment from 'moment';

import cloneDeep from 'lodash/cloneDeep';

class Product {
  constructor({
                id, name, description, created, modified, imported, deleted, productTypeID, chemicalBaseTypeID,
                poolTypeID, code, residentialDefault, inActiveInd,
                proGuardInd, minVolume, maxVolume, order, isThreeStepProduct, stepNumber, wardenID, country,
              }) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.created = created;
    this.modified = modified;
    this.imported = imported;
    this.deleted = deleted;
    this.productTypeID = productTypeID;
    this.chemicalBaseTypeID = chemicalBaseTypeID;
    this.poolTypeID = poolTypeID;
    this.code = code;
    this.residentialDefault = residentialDefault;
    this.inActiveInd = inActiveInd;
    this.proGuardInd = proGuardInd;
    this.minVolume = minVolume;
    this.maxVolume = maxVolume;
    this.order = order;
    this.isThreeStepProduct = isThreeStepProduct;
    this.stepNumber = stepNumber;
    this.wardenID = wardenID;
    this.country = country;
  }

  clone() {
    return cloneDeep(this);
  }

  toApiFormat() {
    return {
      ID: this.id,
      Name: this.name,
      Description: this.description,
      Created: moment(this.created),
      Modified: moment(this.modified),
      Imported: moment(this.imported),
      Deleted: this.deleted,
      ProductTypeID: this.productTypeID,
      ChemicalBaseTypeID: this.chemicalBaseTypeID,
      PoolTypeID: this.poolTypeID,
      Code: this.code,
      ResidentialDefault: this.residentialDefault,
      InActiveInd: this.inActiveInd,
      MaxVolume: this.maxVolume,
      MinVolume: this.minVolume,
      Order: this.order,
      IsThreeStepProduct: this.isThreeStepProduct,
      StepNumber: this.stepNumber,
      WardenID: this.wardenID,
    };
  }

  static fromApiFormat(api) {
    return new Product({
      id: api.ID,
      name: api.Name,
      description: api.Description,
      created: api.Created,
      modified: api.Modified,
      imported: api.Imported,
      deleted: api.Deleted,
      productTypeID: api.ProductTypeID,
      chemicalBaseTypeID: api.ChemicalBaseTypeID,
      poolTypeID: api.PoolTypeID,
      code: api.Code,
      residentialDefault: api.ResidentialDefault,
      inActiveInd: api.InActiveInd,
      proGuardInd: api.ProGuardInd,
      minVolume: api.MinVolume,
      maxVolume: api.MaxVolume,
      order: api.Order,
      isThreeStepProduct: api.IsThreeStepProduct,
      stepNumber: api.StepNumber,
      wardenID: api.WardenID,
      country: api.Country || 'US',
    });
  }
}

export default Product;
