import qwest from 'qwest';

import UserManager from './tokenManager.jsx';

import moment from 'moment';

const storageKey = 'helitack-token';

let startUpToken = getToken();

if (startUpToken) {
  qwest.setDefaultOptions({
    headers: {
      Authorization: startUpToken['id_token'],
    },
  });
}

export function setToken(token) {
  console.log('Setting token');
  localStorage.setItem(storageKey, JSON.stringify(token));
  qwest.setDefaultOptions({
    headers: {
      Authorization: token['id_token'],
    },
  });
}

export function isValid(token) {
  return token && token.profile && token.profile.exp && token.profile.exp > moment().unix();
}

export function getTokenFromCache() {
  let token = getToken();

  if (token) {
    console.log('Got token from storage');
    return;
  }

  UserManager.getUser().then(x => {
    if (x) {
      console.log('Got token from manager.');
      setToken(x);
    }
  });
}

export function getToken() {
  let rawToken = localStorage.getItem(storageKey);

  if (rawToken) {
    let token = JSON.parse(rawToken);

    if (token && isValid(token)) {
      console.log('Got token.');
      return token;
    }
  }
  return null;
}

export function clearToken() {
  localStorage.removeItem(storageKey);
}
