import React from 'react';
import qwest from 'qwest';
import jwtDecode from 'jwt-decode';

let _token;

function getToken() {
  if (_token) {
    return _token;
  }

  return _token = new URLSearchParams(window.location.search).get('token');
}

export function getTokenData() {
  const token = getToken();

  if (!token) {
    return {};
  }

  return jwtDecode(token);
}

export function getData() {
  const linkurl = window.URL.indexOf('http://') === 0 || window.URL.indexOf('https://') === 0 ? window.URL : '//' + window.URL ;

  const token = getToken();
  const url =  linkurl + '?token=' + token;

  return qwest.get(url);
}
