import moment from 'moment';

import cloneDeep from 'lodash/cloneDeep';

import ContactEmailPreference from './contactEmailPreference.jsx';

class CustomerContact {
  constructor({
    id, customerID, nameFirst = '', nameLast = '', homePhone = '', mobilePhone = '',
    workPhone = '', email = '', organization = '', contactMe, modified, created, imported, deleted,
    contactEmailPreferences,
  }) {
    this.id = id;
    this.customerID = customerID;
    this.nameFirst = nameFirst;
    this.nameLast = nameLast;
    this.homePhone = homePhone;
    this.mobilePhone = mobilePhone;
    this.workPhone = workPhone;
    this.email = email;
    this.organization = organization;
    this.contactMe = contactMe;
    this.modified = modified;
    this.created = created;
    this.imported = imported;
    this.deleted = deleted;
    this.contactEmailPreferences = contactEmailPreferences || new ContactEmailPreference({});
  }

  clone() {
    return cloneDeep(this);
  }

  get name() {
    return this.nameFirst + ' ' + this.nameLast;
  }

  toApiFormat() {
    return {
      ID: this.id,
      CustomerID: this.customerID,
      NameFirst: this.nameFirst,
      NameLast: this.nameLast,
      HomePhone: this.homePhone,
      MobilePhone: this.mobilePhone,
      WorkPhone: this.workPhone,
      Email: this.email,
      Organization: this.organization,
      ContactMe: this.ContactMe && moment(this.ContactMe),
      Deleted: this.deleted,
      ContactEmailPreferences: this.contactEmailPreferences && this.contactEmailPreferences.toApiFormat(),
    };
  }

  static fromApiFormat(api) {
    return new CustomerContact({
      id: api.ID,
      customerID: api.CustomerID,
      nameFirst: api.NameFirst,
      nameLast: api.NameLast,
      homePhone: api.HomePhone,
      mobilePhone: api.MobilePhone,
      workPhone: api.WorkPhone,
      email: api.Email,
      organization: api.Organization,
      contactMe: api.ContactMe && moment(api.ContactMe),
      modified: api.Modified && moment(api.Modified),
      created: api.Created && moment(api.Created),
      imported: api.Imported && moment(api.Imported),
      deleted: api.Deleted,
      contactEmailPreferences: ContactEmailPreference.fromApiFormat(api.ContactEmailPreferences),
    });
  }
}

export default CustomerContact;
