import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import reportApi from '../../api/report.jsx';
import dealerApi from '../../api/dealer.jsx';
import branchApi from '../../api/branch.jsx';
import EditableDatePicker from './form/editableDatePicker.jsx';
import ReactSelect from 'react-select';

import IBoxes from './iBoxes.jsx';

const ALL = {value: 'All', label: 'All'};

class ReportFilter extends React.Component {
  static defaultProps = {
    showDates: true,
    showDealers: false,
  };

  static propTypes = {
    export: PropTypes.object,
    onUpdate: PropTypes.func.isRequired,
    buttonText: PropTypes.string,
    showDates: PropTypes.bool,
    showDealers: PropTypes.bool,
    customFilters: PropTypes.element,
    updateCustomFilters: PropTypes.func,
    POS: PropTypes.bool,
  };

  state = {
    show: true,
    data: {},
    options: {
      platinumStatus: [],
      dsm: [],
      rm: [],
      states: [],
      dealers: [],
      stores: [],
      pos: [],
      customerType: [],
    },
    selected: {
      platinumStatus: [],
      dsm: [],
      states: [],
      dealers: [],
      stores: [],
      pos: [],
    },
    selectedRM: 'All',
    startDate: moment().subtract(1, 'years'),
    endDate: moment(),
    showStores: false,
  };

  componentWillMount() {
    let apis = [];

    apis.push(reportApi.getPlatinumStatusList());
    apis.push(reportApi.getDSMList());
    apis.push(reportApi.getRMList());
    apis.push(reportApi.getStateList());
    apis.push(dealerApi.getDealers());

    Promise.all(apis)
      .then(([platinumStatus, dsm, rm, states, dealers]) => {

        dsm = dsm.filter(x => x.DSMNumber);
        rm = rm.filter(x => x.RMNumber);

        let options = {
          platinumStatus: [ALL].concat(platinumStatus.map((x) => ({label: x, value: x}))),
          states: [ALL].concat(states.map((x) => ({label: x, value: x}))),
          dsm: [ALL].concat(dsm.map(x => ({
            label: x.DSMName ? x.DSMNumber + ' - ' + x.DSMName : x.DSMNumber,
            value: x.DSMNumber,
          }))),
          rm: [ALL].concat(rm.map(x => ({
            label: x.RMName ? x.RMName + ' (' + x.RMNumber + ')'  : x.RMNumber,
            value: x.RMNumber,
          }))),
          dealers: [ALL].concat(dealers.map((x) => ({
            label: x.Name + ' (' + x.Account + ')',
            value: x.ID,
          }))),
          pos: [ALL,{label: 'RB', value: 1},{label: 'Evosus', value: 2}],
          customerType: [ALL].concat({value: 'truBlue', label: 'Tru•Blue'}),
        };

        let selected = {
          platinumStatus: [ALL],
          dsm: [ALL],
          rm: ALL,
          states: [ALL],
          dealers: [ALL],
          stores: [ALL],
          pos: [ALL],
          customerType: ALL,
        };

        this.setState({options, selected});
      })
      .catch(err => console.error(err));
  }

  onUpdate = () => {
    let platinumStatus = this.state.selected.platinumStatus[0].value === 'All' ?
    [] : this.state.selected.platinumStatus.map(x => x.value);

    let dsm = this.state.selected.dsm[0].value === 'All' ?
    [] : this.state.selected.dsm.map(x => x.value);

    let rm = this.state.selected.rm.value === 'All' ?
    [] : this.state.selected.rm.value;

    let states = this.state.selected.states[0].value === 'All' ?
    [] : this.state.selected.states.map(x => x.value);

    let dealers = this.state.selected.dealers[0].value === 'All' ?
    [] : this.state.selected.dealers.map(x => x.value);

    let stores = this.state.selected.stores[0].value === 'All' ?
      [] : this.state.selected.stores.map(x => x.value);

    let customData;

    if (this.props.customFilters && this.props.updateCustomFilters) {
      customData = this.props.updateCustomFilters.bind(this)();
    }

    let pos = this.state.selected.pos[0] && this.state.selected.pos[0].value === 'All' ?
    [] : this.state.selected.pos.map(x => x.value);

    let customerType = this.state.selected.customerType.value === 'All' ?
    [] : this.state.selected.customerType.value;

    let filters = {
      platinumStatus,
      dsm,
      rm,
      states,
      dealers,
      stores,
      customData,
      pos,
      customerType,
    };

    if (this.props.showDates) {
      filters.startDate = this.state.startDate;
      filters.endDate = this.state.endDate;
    }

    this.props.onUpdate(filters);

  };

  dataChanged = (e) => {
    let state = Object.assign({}, this.state);

    state[e.key] = e.value;
    this.setState(state);
  };

  exportData = () => {
    let output = '';
    let arrays = [];
    let keys = Object.keys(this.props.export);

    for (let key of keys) {
      if (this.props.export[key] instanceof Array) {
        arrays.push(key);
      } else {
        output += key + ', ' + this.props.export[key] + '\n';
      }
    }

    for (let arr of arrays) {
      let data = this.props.export[arr];
      let dataKeys = Object.keys(data[0]);

      output += '\n' + arr + '\n';
      output += dataKeys.join(', ') + '\n';

      for (let row of data) {
        output += dataKeys.map(x => row[x]).join(', ') + '\n';
      }
    }

    let blob = new Blob([output], {type: 'text/utf8'});
    let url = URL.createObjectURL(blob);

    window.location.href = url;
  };

  updateFilter = (key) => {
    return selectedOptions => {
      // Ensure that 'All' cannot be selected along with other values in a multiselect
      if (Array.isArray(selectedOptions) && Array.isArray(this.state.selected[key])) {
        let prevIndexOfAll = this.state.selected[key].indexOf(ALL);
        let currentIdxOfAll = selectedOptions.indexOf(ALL);

        // If 'ALL' is being added to the selected options, then force it to be the only option selected
        if (currentIdxOfAll !== -1 && prevIndexOfAll === -1) {
          selectedOptions = [selectedOptions[currentIdxOfAll]];
        // Remove 'ALL', when the user is trying to add another option
        } else if (currentIdxOfAll !== -1 && selectedOptions.length > 1) {
          selectedOptions.splice(currentIdxOfAll, 1);
        }

      }

      let selected = Object.assign({}, this.state.selected);

      selected[key] = selectedOptions;
      this.setState({selected: selected});

      // If key is dealers; do a API call to get the stores and show
      if (key === 'dealers') {
        if (selectedOptions.indexOf(ALL) !== -1) {
          this.setState({showStores: false});
        } else {
          this.setState({showStores: true});
          let apis = [];
          let dealers = [];

          for (let dealer of selectedOptions) {
            dealers.push(dealer.value);
          }
          apis.push(branchApi.getBranchesByDealerIDs(dealers));
          Promise.all(apis)
          .then(([stores]) => {
            let options = {
              platinumStatus: this.state.options.platinumStatus,
              dsm: this.state.options.dsm,
              rm: this.state.options.rm,
              states: this.state.options.states,
              dealers: this.state.options.dealers,
              customerType: this.options.customerType,
              stores: [ALL].concat(stores.map((x) => ({
                label: x.name + ' (' + x.city + ')',
                value: x.id,
              }))),
            };

            let selected = {
              platinumStatus: this.state.selected.platinumStatus,
              dsm: this.state.selected.dsm,
              rm: this.state.selected.rm,
              states: this.state.selected.states,
              dealers: this.state.selected.dealers,
              stores: this.state.selected.stores,
              customerType: this.state.selected.customerType,
            };

            this.setState({options, selected});
          })
          .catch(err => console.error(err));
        }
      }
    };
  };

  render() {
    let dates = this.props.showDates ?
    [
      <div className='col-xs-3' key='startDate'>
        <h5>Start Date</h5>
        <EditableDatePicker value={this.state.startDate} label='Start Date' editMode={true}
          dateFormat='DD MMMM YYYY' onChange={this.dataChanged} dataKey='startDate'/>
      </div>,
      <div className='col-xs-3' key='endDate'>
        <h5>End Date</h5>
        <EditableDatePicker value={this.state.endDate} label='End Date' editMode={true}
          dateFormat='DD MMMM YYYY' onChange={this.dataChanged} dataKey='endDate'/>
      </div>,
    ] : null;

    let dealers = this.props.showDealers ?
    [
      <div className='col-xs-3' key={'dealers'} style={{padding: '15px'}}>
        <h4>Dealers</h4>
        <ReactSelect name='dealers' multi={true}
          options={this.state.options.dealers}
          value={this.state.selected.dealers}
          onChange={this.updateFilter('dealers')}/>
      </div>,
    ] : null;

    let stores = this.state.showStores ?
    [
      <div className='col-xs-3' key={'stores'} style={{padding: '15px'}}>
        <h4>Stores</h4>
        <ReactSelect name='stores' multi={true}
          options={this.state.options.stores}
          value={this.state.selected.stores}
          onChange={this.updateFilter('stores')}/>
      </div>,
    ] : null;

    let customerTypeOption = this.props.showCustomerType ?
    [
      <div className='col-xs-3' key={'customerType'} style={{padding: '15px'}}>
        <h4>Customer Type</h4>
        <ReactSelect name='customerType' multi={false}
          options={this.state.options.customerType}
          value={this.state.selected.customerType}
          onChange={this.updateFilter('customerType')}/>
      </div>,
    ] : null;

    return <div>
      <IBoxes title='Filters'>
        <div className='row'>
          <div className='col-xs-6' key='states'>
            <h4>States</h4>
            <ReactSelect name='states' multi={true}
              options={this.state.options.states}
              value={this.state.selected.states}
              onChange={this.updateFilter('states')} />
          </div>
          <div className='col-xs-6' key='dsm'>
            <h4>DSM</h4>
            <ReactSelect name='dsm' multi={true}
              options={this.state.options.dsm}
              value={this.state.selected.dsm}
              onChange={this.updateFilter('dsm')} />
          </div>
        </div>

        <div className='row'>
          {dates}
          <div className='col-xs-3' key='rm'>
            <h4>RM</h4>
            <ReactSelect name='rm'
              options={this.state.options.rm}
              value={this.state.selected.rm}
              onChange={this.updateFilter('rm')} />
          </div>

          <div className='col-xs-3' key='platinumStatus'>
            <h4>Platinum Status</h4>
            <ReactSelect name='platinumStatus' multi={true}
              options={this.state.options.platinumStatus}
              value={this.state.selected.platinumStatus}
              onChange={this.updateFilter('platinumStatus')} />
          </div>
          {dealers}
          {stores}
          {customerTypeOption}
        </div>
        {this.props.customFilters && this.props.updateCustomFilters ? this.props.customFilters : null}

        <div>
          <button onClick={this.onUpdate} className='btn btn-primary'>
            {this.props.buttonText || 'Run Report'}
          </button>
          {this.props.export && Object.keys(this.props.export).length > 0 ?
            <button onClick={this.exportData} className='btn btn-primary pull-right'>
              Export Data
            </button> :
            null}
        </div>
      </IBoxes>
    </div>;
  }
}

export default ReportFilter;
