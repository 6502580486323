import qwest from 'qwest';

export function getAll() {
  return qwest.get('/api/ZipCode/All')
    .then((xhr, res) => res);
}

export function getMissing() {
  return qwest.get('/api/ZipCode/Missing')
    .then((xhr, res) => res);
}

export function save(zipcode, latitude, longitude) {
  return qwest.post('/api/ZipCode', {
    zipcode,
    latitude,
    longitude,
  })
    .then((xhr, res) => res);
}
