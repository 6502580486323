import React from 'react';
import {searchTestsAndCustomers} from 'api/testing';
import EditableTextField from 'components/form/editableTextField';
import debounce from 'lodash/debounce';
import moment from 'moment';
import {chemicalBaseTypeNames} from 'helpers/constants';
import {connectToToasts, TOAST_STATES} from 'components/toasts';
import PropTypes from 'prop-types';
import {browserHistory} from 'react-router';

class Search extends React.Component {
  propTypes = {
    createToast: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      searchValue: props.params.query !== '~' ? decodeURIComponent(props.params.query) : '',
      page: 0,
      total: 0,
    };
  }

  componentWillMount() {
    if (this.props.params.query) {
      this.search();
    }
  }

  updateSearch = ({key, value}) => {
    this.setState({[key]: value}, this.search);
  };

  _search = () => {
    if(this.state.searchValue.toLowerCase().includes('/analysis/')){
      let vals = this.state.searchValue.toLowerCase().split('/');
      if(!isNaN(vals[vals.length - 1])){
        return this.selectAnalysis(vals[vals.length - 1])();
      }else if(!isNaN(vals[vals.length - 2])){
        return this.selectAnalysis(vals[vals.length - 2])();
      }else if(!isNaN(vals[vals.length - 3])){
        return this.selectAnalysis(vals[vals.length - 3])();
      }
    }
    searchTestsAndCustomers(this.state.searchValue)
      .then(({type, value, page, total}) => {
        if (type === 'analysis' && value.ID) {
          return this.selectAnalysis(value.ID)();
        }

        this.setState({
          data: value,
          page,
          total,
        });
      })
      .catch(err => {
        console.error(err);

        this.props.createToast({
          id: 'testing-search-error',
          type: TOAST_STATES.ERROR,
          message: 'Failed searching.',
          ttl: 4000,
        });
      });
  };

  search = debounce(this._search, 200, {leading: false, trailing: true});

  select = id => () => browserHistory.push('/testing/system/' + id);
  selectAnalysis = id => () => browserHistory.push('/testing/analysis/' + id);


  render() {
    return <div>
      <div style={{display: 'flex'}}>
        <EditableTextField dataKey='searchValue'
                           editMode={true}
                           style={{flexGrow: 2}}
                           value={this.state.searchValue}
                           placeholder='Search by URL or customer name'
                           onChange={this.updateSearch} />

        <button onClick={this.search} style={{marginLeft: 20, minWidth: 75}} className='btn btn-primary pull-right'>Search</button>
      </div>

      <table className='table table-striped table-hover'>
        <thead>
          <tr>
            <th>Contact</th>
            <th>System</th>
            <th>Dealer</th>
            <th>Sold To</th>
            <th>Location</th>
            <th>Last Test</th>
          </tr>
        </thead>
        <tbody>
          {this.state.data ? this.state.data.map(x => <tr key={x.poolID} style={{cursor: 'pointer'}} onClick={this.select(x.poolID)}>
            <td>{x.name}</td>
            <td>{x.nickname || chemicalBaseTypeNames[x.chemicalBaseType]}</td>
            <td>{x.dealerName}</td>
            <td>{x.soldTo}</td>
            <td>{[x.city, x.state].filter(x => !!x).reduce((acc, x) => acc ? acc + ` (${x})` : x, '')}</td>
            <td>{x.prevAnalysisDate ? moment(x.prevAnalysisDate).format('ll') : 'none'}</td>
          </tr>) : null}
        </tbody>
      </table>
    </div>;
  }
}

export default connectToToasts(Search);
