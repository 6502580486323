import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

import {connectToToasts, TOAST_STATES} from '../components/toasts.jsx';
import Loader from '../components/loader.jsx';
import PageHeader from '../components/pageHeader.jsx';
import IBox from '../components/iBox.jsx';
import EditableTextField from '../components/form/editableTextField.jsx';
import EditableDatePicker from '../components/form/editableDatePicker.jsx';

import requirePermissions from '../helpers/requirePermissions';

import TruBlueConfigApi from '../../api/truBlueConfig.jsx';

import TruBlueConfigModel from '../../models/truBlueConfig.jsx';

// Page object
class PageTruBlueConfig extends React.Component {
  static propTypes = {
    createToast: PropTypes.func.isRequired,
  };

  state = {
    config: new TruBlueConfigModel({}),
    loaded: false,
  };

  componentWillMount() {
    const {createToast} = this.props;

    TruBlueConfigApi.getTruBlueConfig()
      .then((config) => {
        this.setState({
          config: new TruBlueConfigModel(config),
          loaded: true,
        });
      })
      .catch(error => {
        createToast({
          id: 'load-tru-config',
          message: error.message || 'Couldn\'t load Tru•Blue Config.',
          state: TOAST_STATES.ERROR,
        });
        console.error(error);
      });
  }

  configChanged = (e) => {
    let config = Object.assign({}, this.state.config);

    config[e.key] = e.value;
    this.setState({
      config: new TruBlueConfigModel(config),
    });
  };

  numConfigChanged = (e) => {
    e.value = e.value ? parseInt(e.value) : undefined;
    this.configChanged(e);
  }

  save = () => {
    const {createToast} = this.props;

    createToast({
      id: 'save-tru-config',
      message: 'Saving...',
      state: TOAST_STATES.INFO,
    });
    this.setState({loaded: false}, () => {
      TruBlueConfigApi.saveTruBlueConfig(this.state.config)
        .then(res => {
          this.setState({loaded: true});
          createToast({
            id: 'save-tru-config',
            message: 'Saved.',
            state: TOAST_STATES.SUCCESS,
            ttl: 4000,
          });
        })
        .catch(error => {
          createToast({
            id: 'save-tru-config',
            message: error.message || 'Couldn\'t save Tru•Blue Config.',
            state: TOAST_STATES.ERROR,
          });
          console.error(error);
        });
    });

  }

  render() {
    const {config, loaded} = this.state;

    return (
      <div id='page-trublue-config'>
        <PageHeader title='Tru•Blue Config' icon='fa fa-cog' breadcrumbs={[]} />
        <div className='wrapper wrapper-content'>
          <Loader loaded={loaded}>
            <IBox title='Date Ranges'>
              <div className='row'>
                <div className='col-xs-3' key='regStartDate'>
                  <h4>Customer Registration Start Date</h4>
                  <EditableDatePicker
                    value={config.regStartDate}
                    label='Customer Registration Start Date'
                    editMode={true}
                    allowEmpty
                    dateFormat='DD MMMM YYYY'
                    onChange={this.configChanged}
                    dataKey='regStartDate'/>
                </div>
                <div className='col-xs-3' key='regEndDate'>
                  <h4>Customer Registration End Date</h4>
                  <EditableDatePicker
                    value={config.regEndDate}
                    label='Customer Registration End Date'
                    editMode={true}
                    allowEmpty
                    dateFormat='DD MMMM YYYY'
                    onChange={this.configChanged}
                    dataKey='regEndDate'/>
                </div>
              </div>
              <div className='row'>
                <div className='col-xs-3' key='claimStart'>
                  <h4>Claim Start Date</h4>
                  <EditableDatePicker
                    value={config.claimStart}
                    label='Claim Start Date'
                    editMode={true}
                    allowEmpty
                    dateFormat='DD MMMM YYYY'
                    onChange={this.configChanged}
                    dataKey='claimStart'/>
                </div>
                <div className='col-xs-3' key='claimEnd'>
                  <h4>Claim End Date</h4>
                  <EditableDatePicker
                    value={config.claimEnd}
                    label='Claim End Date'
                    editMode={true}
                    allowEmpty
                    dateFormat='DD MMMM YYYY'
                    onChange={this.configChanged}
                    dataKey='claimEnd'/>
                </div>
              </div>
              <div className='row'>
                <div className='col-xs-3' key='dealerTruBlueResetDate'>
                  <h4>Dealer Tru•Blue Reset Date</h4>
                  <EditableDatePicker
                    value={config.dealerTruBlueResetDate}
                    label='Dealer Tru•Blue Reset Date'
                    editMode={true}
                    allowEmpty
                    dateFormat='DD MMMM YYYY'
                    onChange={this.configChanged}
                    dataKey='dealerTruBlueResetDate'/>
                </div>
              </div>
              <div className='row'>
                <div className='col-xs-3' key='customerTruBlueResetDate'>
                  <h4>Customer Tru•Blue Reset Date</h4>
                  <EditableDatePicker
                    value={config.customerTruBlueResetDate}
                    label='Customer Tru•Blue Reset Date'
                    editMode={true}
                    allowEmpty
                    dateFormat='DD MMMM YYYY'
                    onChange={this.configChanged}
                    dataKey='customerTruBlueResetDate'/>
                </div>
              </div>
            </IBox>
            <div className='row'>
              <div className='col-xs-12'>
                <button
                  disabled={!config.submittable()}
                  className='btn btn-primary'
                  onClick={this.save}>Save</button>
              </div>
            </div>
          </Loader>
        </div>
      </div>
    );
  }
}

let PageTruBlueConfigWrapper = requirePermissions('canViewTruBlueDashboard')(PageTruBlueConfig);

export default connectToToasts(PageTruBlueConfigWrapper);
