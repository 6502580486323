import React from 'react';
import PropTypes from 'prop-types';
import {debounce} from 'lodash';

import {
  Button,
  ProgressBar,
} from 'react-player-controls';

class PlayerControls extends React.Component {

  static propTypes = {
    connectedState: PropTypes.object,
    setStep: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      playing: false,
    };
  }

  playTimer = null;
  stepInterval = 500;

  selectNode = (index) => {
    this.props.setStep(index);
    return index;
  };

  selectNextNode = () => {
    let nextIndex = this.props.connectedState.currentStep || 0;
    const pathLength = this.props.connectedState.runResults.path.length;

    if(nextIndex < pathLength - 1) {
      nextIndex += 1;
    }
    return this.selectNode(nextIndex);
  };

  selectPreviousNode = () => {
    let nextIndex = this.props.connectedState.currentStep || 0;

    if (nextIndex > 0) {
      nextIndex -= 1;
    }
    return this.selectNode(nextIndex);
  };

  /**
   * Specifically debounce node selection when caused by seeking, it can happen alot.
   */
  seek = debounce((index) => {
    this.selectNode(this.getStep(index - 1));
  }, 500);

  showIntent = (index) => {
    this.setState({intendedStep: index});
  };

  start = () => {
    if (this.playTimer) {
      return;
    }

    this.setState({
      playing: true,
    });

    this.playTimer = setInterval(() => {
      const movedTo = this.selectNextNode();

      if (movedTo === this.props.connectedState.runResults.path.length - 1) {
        this.setState({
          playing: false,
        });
        clearInterval(this.playTimer);
        this.playTimer = null;
      }
    }, this.stepInterval);
  };

  pause = () => {
    clearInterval(this.playTimer);
    this.playTimer = null;
    this.setState({
      playing: false,
    });
  };

  goToStart = () => {
    this.selectNode(0);
  };

  goToEnd = () => {
    const {runResults: {path}} = this.props.connectedState;

    this.selectNode(path.length - 1);
  };

  getStep = (index) => {
    const {runResults: {path: {length}}} = this.props.connectedState;

    return Math.min(length, Math.round(index) + 1);
  };

  render() {
    const {currentStep, runResults: {path}} = this.props.connectedState;
    const {intendedStep, seeking} = this.state;

    const enableControls = !!path.length;

    const current = this.getStep(intendedStep);

    return <div className='player-controls'>
      <div className='player-controls__progress-wrapper'>
        <div
          className='player-controls__progress-container'
          onMouseLeave={() => this.showIntent(null)}
        >
          {!seeking && intendedStep !== null ?
            <div className='player-controls__progress-hover' style={{left: `${(intendedStep / (path.length - 1) * 100)}%`}}>
              {current && !isNaN(current) ? `${current}: ${this.props.connectedState.runResults.path[current - 1].id}` : null}
            </div> :
            null }
          <ProgressBar
            ref={pb => this.progressBar = pb}
            totalTime={path.length - 1}
            currentTime={!seeking ? currentStep : intendedStep || currentStep}
            isSeekable={enableControls}
            onSeek={index => {
              this.showIntent(index);
              this.seek(index);
            }}
            onSeekStart={() => this.setState({seeking: true})}
            onSeekEnd={(index) => {
              this.selectNode(this.getStep(index - 1));

              this.setState({seeking: false});
              this.showIntent(null);
            }}
            onIntent={this.showIntent}
          />
        </div>
        <div className='player-controls__step-indicator'>
          { path.length ?
            `${currentStep + 1} / ${path.length}` :
            null }
        </div>
      </div>
      <div className='player-controls__controls-container'>
        <Button onClick={this.goToStart} isEnabled={enableControls}>
          <i className='fa fa-step-backward' aria-hidden='true'></i>
        </Button>

        <Button onClick={this.selectPreviousNode} isEnabled={enableControls}>
          <i className='fa fa-backward' aria-hidden='true'></i>
        </Button>

        <Button onClick={this.state.playing ? this.pause : this.start} isEnabled={enableControls}>
          <i className={'fa fa-'.concat(this.state.playing ? 'pause' : 'play')} aria-hidden='true'></i>
        </Button>

        <Button onClick={this.selectNextNode} isEnabled={enableControls}>
          <i className='fa fa-forward' aria-hidden='true'></i>
        </Button>

        <Button onClick={this.goToEnd} isEnabled={enableControls}>
          <i className='fa fa-step-forward' aria-hidden='true'></i>
        </Button>
      </div>
    </div>;
  }
}

export default PlayerControls;
