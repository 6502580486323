import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import PageHeader from '../components/pageHeader.jsx';
import Loader from '../components/loader.jsx';
import IBox from '../components/iBox.jsx';
import {connectToToasts, TOAST_STATES} from '../components/toasts.jsx';
import {Link} from 'react-router';

import {getDoc} from '../../api/documentation.jsx';

// Does same thing as react-remarkable but transform local links to using Link for speed
import Remarkable from 'remarkable';

var md = new Remarkable();


function Markdown({doc}) {
  if (!doc) {
    return null;
  }

  const mdown = md.render(doc);

  const escaped = mdown.split(/(<a href="\/[a-zA-Z0-9-_\/]+">[a-zA-Z0-9 -+=_$\(\)]+<\/a>)/)
    .map((x, i) => {
      if (i % 2 === 0) { // Use split so even indices are not matches; Faster to return out early here like this
        return <span dangerouslySetInnerHTML={{__html: x}} />; // eslint-disable-line react/no-danger
      }
      const [match, to, label] = x.match(/<a href="(\/[a-zA-Z0-9-_\/]+)">([a-zA-Z0-9 -+=_$\(\)]+)<\/a>/); // eslint-disable-line no-unused-vars

      return <Link to={to}>{label}</Link>;
    });

  return <div>
    {escaped}
  </div>;
}
Markdown.propTypes = {
  doc: PropTypes.string,
};

class Documentation extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      doc: null,
    };
  }

  componentWillMount() {
    this.fetch(this.props.params.splat);
  }

  componentWillReceiveProps(nextProps) {
    this.fetch(nextProps.params.splat);
  }

  fetch = (tag = '') => {
    if (tag instanceof Array) {
      tag = tag.join('/');
    }
    if (this.state.loaded) {
      this.setState({
        loaded: false,
      });
    }
    getDoc(tag)
      .then(res => {
        this.setState({
          doc: res.data,
          loaded: true,
        });
      })
      .catch(err => {
        console.error(err);
        this.props.createToast({
          id: 'fetch-docs-failure',
          message: 'Failed to get documentation.',
          ttl: 6000,
          state: TOAST_STATES.ERROR,
        });
      });
  };

  render() {
    let body, title;

    if (this.state.doc) {
      const split = this.state.doc.replace(/\r\n/g, '\n').split('\n');

      title = split[0].replace(/[ ]*#[ ]*/, '');
      body = split.slice(1).join('\n');
    }
    return <div>
      <PageHeader title='Developer Documentation' icon='fa fa-file-text' breadcrumbs={[]} />
      <Loader loaded={this.state.loaded}>
        <IBox title={title}>
          <div className='row'>
            <div className='col-lg-2 col-md-3 col-sm-4'>
              <SideBar />
            </div>
            <div className='col-lg-10 col-md-9 col-sm-8'>
              <Markdown doc={body} />
            </div>
          </div>
        </IBox>
      </Loader>
    </div>;
  }
}

class SideBar extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      doc: null,
    };

    this.fetch = this.fetch.bind(this);
  }

  componentWillMount() {
    this.fetch();
  }

  fetch = () => {
    getDoc('sidebar')
      .then(res => {
        this.setState({
          doc: res.data,
        });
      })
      .catch(err => {
        console.error(err);
        this.props.createToast({
          id: 'fetch-docs-failure',
          message: 'Failed to get documentation.',
          ttl: 6000,
          state: TOAST_STATES.ERROR,
        });
      });
  };

  render() {
    return <Markdown doc={this.state.doc} />;
  }
}

export default connectToToasts(Documentation);
