import React from 'react';

import {browserHistory} from 'react-router';


// Page object
class GlobalSearch extends React.Component {
  goSearch = (e) => {
    e.preventDefault();

    let searchStr = this.topSearch.value;

    browserHistory.push('/search/' + searchStr);

    return false;
  };

  render() {

    return (
      <div className='row border-bottom'>
        <nav className='navbar navbar-static-top' role='navigation' style={{marginBottom: 0}}>
          <div className='navbar-header'>
            <form role='search' className='navbar-form-custom' onSubmit={this.goSearch}>
              <div className='form-group' style={{marginLeft: 15}}>
                <input type='text'
                ref={r => this.topSearch = r}
                autoComplete='off'
                placeholder='Search for anything...'
                className='form-control'
                name='top-search'
                id='top-search' />
              </div>
            </form>
          </div>
        </nav>
      </div>);

  }
}

export default GlobalSearch;
