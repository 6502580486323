import React from 'react';
import TruBlueApi from 'api/truBlueDashboard';
import Loader from 'components/loader';
import events from 'lib/events';
import {TOAST_STATES, connectToToasts} from 'components/toasts';
import PropTypes from 'prop-types';

class SystemSummaryStatics extends React.Component {
  static propTypes = {
    refName: PropTypes.string.isRequired,
    createToast: PropTypes.func.isRequired,
  };

  state = {
    loaded: true,
  };

  setLoading = () => {
    this.setState({loaded: false});
  };

  loadReport = (filters) => {
    this.setLoading();
    TruBlueApi.getSystemSummaryStatistics(filters.startDate, filters.endDate,
      filters.platinumStatus, filters.dsm, filters.rm, filters.states)
      .then(data => {
        this.setState({loaded: true, data});
        events.publish('trublue-block-loaded', this.props.refName);
      }).catch(e => {
        console.log(e);
        this.props.createToast({
          id: 'trublue-dashboard-error',
          state: TOAST_STATES.ERROR,
          message: 'Failed to load data for report',
          ttl: 4000,
        });
      });
  };

  render() {
    const {data} = this.state;

    return (
      <div>
        <div className='widget style1 lazur-bg'>
          <div className='row'>
            <div className='col-xs-4'>
              <i className='fa fa-tachometer fa-5x'/>
            </div>
            <div className='col-xs-8 text-right'>
              <h2 className='font-bold'>System Summary Statistics
              </h2>
            </div>
          </div>
        </div>
        <Loader loaded={this.state.loaded}>
          <table
            style={{width: '100%', textAlign: 'center', marginBottom: 30}}>
            <tbody>
              <tr>
                <td>
                  <div className='ibox-content'>
                    <h1 className='no-margins'>{data ? data.ActiveSaltPools : 0}</h1>
                    <small>Active Salt Pools</small>
                  </div>
                </td>
                <td>
                  <div className='ibox-content'>
                    <h1 className='no-margins'>{data ? data.ActiveChlorinePools : 0}</h1>
                    <small>Active Chlorine Pools</small>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className='ibox-content'>
                    <h1 className='no-margins'>{data ? data.ActiveMineralSpringsPools : 0}</h1>
                    <small>Active Mineral Springs Pools</small>
                  </div>
                </td>
                <td>
                  <div className='ibox-content'>
                    <h1 className='no-margins'>{data ? data.EligibleSystems : 0}</h1>
                    <small>Eligible Systems</small>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className='ibox-content'>
                    <h1 className='no-margins'>{data ? data.OpportunitySystems : 0}</h1>
                    <small>Opportunity Systems</small>
                  </div>
                </td>
                <td>
                  <div className='ibox-content'>
                    <h1 className='no-margins'>{data ? data.InactiveSystems : 0}</h1>
                    <small>Inactive Systems</small>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className='ibox-content'>
                    <h1 className='no-margins'>{data ? data.IneligibleSystems : 0}</h1>
                    <small>Ineligible Systems</small>
                  </div>
                </td>
                <td>
                  <div className='ibox-content'>
                    <h1 className='no-margins'>&nbsp;</h1>
                    <small>&nbsp;</small>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </Loader>
      </div>
    );
  }
}

export default connectToToasts(SystemSummaryStatics);
