export const unitsOfMeasure = {
  1: 'oz (ounces)',
  2: 'ml (milliliters)',
  3: 'Units',
  4: 'Quarts',
  5: 'Pounds',
  6: 'Gallons',
  7: 'Pint',
  8: 'Gram',
  9: 'Fluid ounce',
  10: 'Teaspoon',
  11: 'Tablespoon',
  12: 'Container: (Kit / Bottle/ Bag / Each)',
  13: 'Cups',
  14: 'Kilo Grams',
  15: 'Liters',
  99: 'Other',
};

export const salesProductCategory = {
  1: 'Saltwater Products',
  2: 'Sanitizer',
  3: 'Oxidizer',
  4: 'Biquanide',
  5: 'Algicide',
  6: 'Balancer',
  7: 'Specialty',
  8: 'Winter',
  9: 'Handheld',
  10: 'Testing',
  11: 'Spa Sanitizer',
  12: 'Spa Balancer',
  13: 'Cleaner',
  14: 'Spa Oxidizer',
  15: 'Spa Accessories',
  16: 'Water Enhancer',
  17: 'Biquanide System',
  18: 'Pool Tool',
  19: 'Off Season',
  20: 'Kit',
  99: 'Other',
};

export const truBlueStatus = {
  Active: 'ACTIVE',
  Inactive: 'INACTIVE',
  Eligible: 'ELIGIBLE',
  Ineligible: 'INELIGIBLE',
};

export const mediaType = {
  Liquid: 'Liquid',
  Powder: 'Powder',
  Solid: 'Solid',
  'Solid/Liquid/Powder': 'Solid/Liquid/Powder',
  'Liquid/Powder': 'Liquid/Powder',
};

export const containerType = {
  Bottle: 'Bottle',
  Bucket: 'Bucket',
  Bag: 'Bag',
  CaseBox: 'Case/Box',
};

export const countriesNames = {
  US: 'United States',
  CA: 'Canada',
};

export const printFilesLanguages = {
  'en-US': 'English',
  'fr-CA': 'French',
};

export const chemicalBaseTypes = {
  brominePool: 1,
  chlorinePool: 2,
  softSwimPool: 3,
  chlorineSpa: 5,
  bromineSpa: 6,
  mineralSpringsPool: 7,
  softSoakSpa: 8,
  saltWaterPool: 12,
  saltWaterSpa: 13,
  synergyPool: 14,
  synergySpa: 15,
};

export const chemicalBaseTypeCustomSortOrder = {
  [chemicalBaseTypes.brominePool]: 1,
  [chemicalBaseTypes.chlorinePool]: 2,
  [chemicalBaseTypes.softSwimPool]: 5,
  [chemicalBaseTypes.chlorineSpa]: 8,
  [chemicalBaseTypes.bromineSpa]: 7,
  [chemicalBaseTypes.mineralSpringsPool]: 3,
  [chemicalBaseTypes.softSoakSpa]: 11,
  [chemicalBaseTypes.saltWaterPool]: 4,
  [chemicalBaseTypes.saltWaterSpa]: 9,
  [chemicalBaseTypes.synergyPool]: 6,
  [chemicalBaseTypes.synergySpa]: 10,
};


export const chemicalBaseTypeNames = {
  [chemicalBaseTypes.brominePool]: 'Bromine Pool',
  [chemicalBaseTypes.chlorinePool]: 'Chlorine Pool',
  [chemicalBaseTypes.softSwimPool]: 'SoftSwim Pool',
  [chemicalBaseTypes.chlorineSpa]: 'Chlorine Spa',
  [chemicalBaseTypes.bromineSpa]: 'Bromine Spa',
  [chemicalBaseTypes.mineralSpringsPool]: 'Mineral Springs Pool',
  [chemicalBaseTypes.softSoakSpa]: 'SoftSoak Spa',
  [chemicalBaseTypes.saltWaterPool]: 'Saltwater Pool',
  [chemicalBaseTypes.saltWaterSpa]: 'Saltwater Spa',
  [chemicalBaseTypes.synergyPool]: 'Synergy Pool',
  [chemicalBaseTypes.synergySpa]: 'Synergy Spa',
};

export const productBrands = {
  spaGuard: 'SPAGUARD',
  bioGuard: 'BIOGUARD',
  proGuard: 'PROGUARD',
  naturalChemistry: 'NATURALCHEMISTRY',
};

export const productBrandNames = {
  [productBrands.spaGuard]: 'SpaGuard',
  [productBrands.bioGuard]: 'BioGuard',
  [productBrands.proGuard]: 'ProGuard',
  [productBrands.naturalChemistry]: 'Natural Chemistry',
};

export const productBrandsSortOrder = {
  [productBrands.bioGuard]: 1,
  [productBrands.spaGuard]: 2,
  [productBrands.naturalChemistry]: 3,
  [productBrands.proGuard]: 4,
};



export const productTypes = {
  sanitizer: 1,
  oxidizer: 2,
  maintOxidizer: 3,
  prevAlgicide: 4,
  pHAdjuster: 5,
  pHBuffer: 6,
  algaeProducts: 7,
  winterOxidizer: 8,
  winterAlgicide: 9,
  waterlineControl: 10,
  maintClarifier: 12,
  curativeClarifier: 13,
  smartPakProducts: 14,
  chlorineDemandProducts: 15,
  stabilizer: 16,
};


export const propertyTypes = {
  residential: 'R',
  commercial: 'C',
};

export const locationTypes = {
  indoor: 'I',
  outdoor: 'O',
};

export const systemTypes = {
  pool: 1,
  spa: 2,
  swimspa: 3,
};

export const systemTypeNames = {
  [systemTypes.pool]: 'Pool',
  [systemTypes.spa]: 'Spa',
  [systemTypes.swimspa]: 'Swim Spa',
};

export const systemTypeNamesFormat = (formatMessage) => {
  if (!isNull(formatMessage)) {
    return {
      1: formatMessage({id: 'general.pool'}),
      2: formatMessage({id: 'general.spa'}),
      3: formatMessage({id: 'general.swim.spa'}),
    };
  }
  return systemTypeNames;
};

export const countries = {
  US: 'US',
  CA: 'CA',
};

export const languages = {
  EN: 'English',
  FR: 'French',
};


/*
 * This templates are also maintained within RunReportsValueConstants.cs in the backend
 * Format
 * id: title
 */
export const reportTemplates = {
  blank: 'Blank',
  blanktitles: 'Blank with Titles',
  missingCustomers: 'Missing Customers Report',
  customerList: 'Customer List Report',
  poolSystemsList: 'All Systems Pools Report',
  spaSystemsList: 'All Systems Spas Report',
  swimSpaSystemsList: 'All Systems SwimSpas Report',
  missingInformation: 'Missing Information Report',
  productRecommendations: 'Product Recommendations Report',
  missedProductSales: 'Missed Product Sales Report',
  outboundEmails: 'Outbound Emails Report',
  wateranalysesaccounts: 'Water Analysis Accounts',
  optOutEmails: 'OptOut Emails Report',
};

export const notificationTypes = {
  'notification.types.update': 'Software Update',
  'notification.types.highlight.feature': 'Feature Highlight',
  'notification.types.annoucement': 'BioGuard Announcement',
  'notification.types.alert': 'System Alert',
};