import PropTypes from 'prop-types';
import React, {Component} from 'react';
import moment from 'moment';

import EditableDatePicker from '../components/form/editableDatePicker.jsx';
import ReactSelect from 'react-select';
import {browserHistory} from 'react-router';

import Loader from '../components/loader.jsx';
import PageHeader from '../components/pageHeader.jsx';
import IBox from '../components/iBox.jsx';
import {Input} from '../components/form/index.jsx';
import Pagination from '../components/Pagination.jsx';

import {getAll, getCSVUrl} from '../../api/truBlueClaims.jsx';
import dealerApi from '../../api/dealer.jsx';
import requirePermissions from '../helpers/requirePermissions';

const trublueClaimStatuses = {
  '': 'All',
  0: 'Open',
  1: 'Submitted',
  2: 'Approved',
  3: 'More Info Needed',
  4: 'Dismissed',
  5: 'Denied',
};

const statusValues = {
  open: 0,
  submitted: 1,
  approved: 2,
  moreInfo: 3,
  dismissed: 4,
  denied: 5,
};

const PAGE_LENGTH = 100;

class TruBlueClaimsListPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      claims: [],
      activePage: 1,
      total: 0,
      end: moment(),
      start: moment().startOf('year'),
      status: "1",
      dealers: [],
      dealersList: [],
      submittedCount: 0,
      moreInfoCount: 0,
      approvedCount: 0,
      dismissedCount: 0,
      deniedCount: 0,
    };
  }

  componentWillMount() {
    let apis = [
      getAll(this.state.start.format(), this.state.end.format(), this.state.activePage, PAGE_LENGTH, null, statusValues.submitted),
      dealerApi.getDealers(),
    ];

    Promise.all(apis)
      .then(([claimsResponse, dealerListing]) => {
        if (!claimsResponse.success) {
          throw new Error('Failed to download claims.');
        }

        this.setState({
          loaded: true,
          claims: claimsResponse.data.results,
          total: claimsResponse.data.total,
          dealersList: dealerListing.map((x) => ({
            label: x.Name + ' (' + x.Account + ')',
            value: x.ID,
          })),
          activePage: claimsResponse.data.page,
          search: '',
          submittedCount: claimsResponse.data.submittedCount,
          moreInfoCount: claimsResponse.data.moreInfoCount,
          approvedCount: claimsResponse.data.approvedCount,
          dismissedCount: claimsResponse.data.dismissedCount,
          deniedCount: claimsResponse.data.deniedCount,
        });
      })
      .catch(err => {
        console.error(err);
      });
  }

  search = (context, value) => {
    let cstate = this.state;

    if(context === 'start') {
      this.setState({
        start: value,
      });
      cstate.start = value;
    }else if(context === 'end') {
      this.setState({
        end: value,
      });
      cstate.end = value;
    }else if(context === 'status') {
      this.setState({
        status: value,
      });
      cstate.status = value;
    }else if(context === 'dealers') {
      this.setState({
        dealers: value,
      });
      cstate.dealers = value;
    }

    this.setState({loaded: false});

    getAll(cstate.start.format(), cstate.end.format(), cstate.activePage, PAGE_LENGTH, cstate.dealers.map(v => v.value), cstate.status).then((response) => {
      this.setState({
        loaded: true,
        claims: response.data.results,
        total: response.data.total,
        activePage: response.data.page,
        submittedCount: response.data.submittedCount,
        moreInfoCount: response.data.moreInfoCount,
        approvedCount: response.data.approvedCount,
        dismissedCount: response.data.dismissedCount,
        deniedCount: response.data.deniedCount,
      });
    });
  };

  edit = (id) => {
    browserHistory.push('/trublueclaim/' + id);
  };

  downloadCSV = (id) => {
    let cstate = this.state;

    window.open(getCSVUrl(cstate.start.format(), cstate.end.format(), cstate.dealers.map(v => v.value), cstate.status));
  };

  changePage = page => {
    this.setState({loaded: false});

    let cstate = this.state;

    getAll(cstate.start.format(), cstate.end.format(), page, PAGE_LENGTH, cstate.dealers.map(v => v.value), cstate.status).then((response) => {
      this.setState({
        loaded: true,
        claims: response.data.results,
        total: response.data.total,
        activePage: response.data.page,
        submittedCount: response.data.submittedCount,
        moreInfoCount: response.data.moreInfoCount,
        approvedCount: response.data.approvedCount,
        dismissedCount: response.data.dismissedCount,
        deniedCount: response.data.deniedCount,
      });
    });
  };


  render() {
    const {submittedCount,  moreInfoCount,  approvedCount,  dismissedCount,  deniedCount} = this.state;
    let rows = this.state.claims;

    let pages = Math.ceil(this.state.total / PAGE_LENGTH);
    let hasNextPage = this.state.activePage < pages;
    let hasPrevPage = this.state.activePage > 1;

    rows = rows
      .map(z => {
        let cc = '';

        if(z.Contact && z.Contact.NameFirst) {
          cc = z.Contact.NameFirst + ' ' + z.Contact.NameLast;
        }
        if(z.Contact && z.Contact.ContactOrganization) {
          cc = z.Contact.ContactOrganization;
        }

        return <tr key={z.ID} style={{cursor: 'pointer'}} onClick={() => {this.edit(z.ID);}}>
          <td>{z.ID}</td>
          <td>{cc || '-'}</td>
          <td>{z.DealerName}</td>
          <td>{z.SoldTo}</td>
          <td>{z.ShipTo}</td>
          <td>{moment(z.AnalysisCreated).format('YYYY-MM-DD')}</td>
          <td>{moment(z.Modified).format('YYYY-MM-DD')}</td>
          <td>{trublueClaimStatuses[z.StatusID]}</td>
          <td>{z.HasNotes ? 'Yes' : 'No'}</td>
        </tr>;
      });

    return <div id='page-tru-blue-claims'>
      <PageHeader title='Tru•Blue Claims' icon='fa fa-map-marker' breadcrumbs={[]} />

        <div>
          <IBox title='Claims'>
            <div className='row'>
              <div className='col-md-12'>
                <form ref={this.filterform}>
                  <div className='col-xs-3' key='start' style={{padding: '15px'}}>
                    <h5>Start Date</h5>
                    <EditableDatePicker value={this.state.start} label='Start Date' editMode={true}
                      dateFormat='DD MMMM YYYY' onChange={(e) => {this.search('start', e.value);}} dataKey='start'/>
                  </div>
                  <div className='col-xs-3' key='end' style={{padding: '15px'}}>
                    <h5>End Date</h5>
                    <EditableDatePicker value={this.state.end} label='End Date' editMode={true}
                      dateFormat='DD MMMM YYYY' onChange={(e) => {this.search('end', e.value);}} dataKey='end'/>
                  </div>
                  <div className='col-xs-3' key={'dealers'} style={{padding: '15px'}}>
                    <h4>Dealers</h4>
                    <ReactSelect name='dealers' multi={true}
                      options={this.state.dealersList}
                      value={this.state.dealers}
                      onChange={(e) => {this.search('dealers', e);}}/>
                  </div>
                  <div className='col-xs-3' key={'status'} style={{padding: '15px'}}>
                    <h4>Status</h4>
                    <select name='status' className='form-control' onChange={e => {this.search('status', e.target.value);}}>
                      <option value=''>All</option>
                      <option value='0'>Open</option>
                      <option value='1' selected="selected">Submitted</option>
                      <option value='2'>Approved</option>
                      <option value='3'>Needs More Info</option>
                      <option value='4'>Dismissed</option>
                      <option value='5'>Denied</option>
                    </select>
                  </div>
                </form>
              </div>
              <div className='col-md-12 claim-totals'>
                <div className='col-xs-2'>
                  <p className='strong'>Total Submissions: {this.state.total}</p>
                </div>
                <div className='col-xs-2'>
                  <p>Total Submitted: {submittedCount}</p>
                </div>
                <div className='col-xs-2'>
                  <p>Total More Info: {moreInfoCount}</p>
                </div>
                <div className='col-xs-2'>
                  <p>Total Approved: {approvedCount}</p>
                </div>
                <div className='col-xs-2'>
                  <p>Total Dismissed: {dismissedCount}</p>
                </div>
                <div className='col-xs-2'>
                  <p>Total Denied: {deniedCount}</p>
                </div>
              </div>
              <div className='col-md-12'>
                <Loader loaded={this.state.loaded}>
                  <table className='table table-striped table-hover'>
                    <thead>
                      <tr>
                        <th>Claim ID</th>
                        <th>Customer Name</th>
                        <th>Dealer Name</th>
                        <th>Sold To</th>
                        <th>Ship To</th>
                        <th>Date of Water Test</th>
                        <th>Date of Submission</th>
                        <th>Claim Status</th>
                        <th>Has Notes</th>
                      </tr>
                    </thead>
                    <tbody>
                    {rows}
                    </tbody>
                  </table>
                </Loader>
                <Pagination
                  onPageChange={this.changePage}
                  page={this.state.activePage}
                  count={PAGE_LENGTH}
                  total={this.state.total}
                  maxButtons={10} />
              </div>
            </div>
            <div className='col-md-12'>
              <button className="btn btn-primary" onClick={this.downloadCSV}>Download CSV</button>
            </div>
          </IBox>
        </div>
    </div>;
  }
};

let TruBlueClaimsListPageWrapper = requirePermissions('canViewTruBlueClaims')(TruBlueClaimsListPage);

export default TruBlueClaimsListPageWrapper;
