import moment from 'moment';

import cloneDeep from 'lodash/cloneDeep';

class Branch {
  constructor({
    id, name, address1, address2, city, state, country, modified, deleted, dealerID,
    bioGuard, proGuard, spaGuard, website, fax, logo, logoLink, postalCode, zip, hours,
    email, phone, soldTo, shipTo, overrideBranding, brandingName, useEmail, naturalChemistry,
  }) {
    this.id = id;
    this.name = name;
    this.address1 = address1;
    this.address2 = address2;
    this.city = city;
    this.state = state;
    this.country = country;
    this.modified = modified;
    this.deleted = deleted;
    this.dealerID = dealerID;
    this.bioGuard = bioGuard;
    this.proGuard = proGuard;
    this.spaGuard = spaGuard;
    this.naturalChemistry = naturalChemistry;
    this.website = website;
    this.fax = fax;
    this.logo = logo;
    this.logoLink = logoLink;
    this.postalCode = postalCode;
    this.zip = zip; // Some pages use this instead of postal code
    this.hours = cloneDeep(hours);
    this.email = email;
    this.phone = phone;
    this.shipTo = shipTo || '';
    this.soldTo = soldTo;
    this.overrideBranding = overrideBranding;
    this.brandingName = brandingName;
    this.useEmail = useEmail;
  }

  clone() {
    return new Branch(this);
  }

  // Filters a list of dealerUsers by those who are related to this branch by role
  relatedDealerUsers(dealerUsers) {
    return dealerUsers.filter((dealerUser) => {
      return !!dealerUser.roles.find((role) => {
        return role.branchId === this.id;
      });
    });
  }

  toApiFormat() {
    return {
      ID: this.id,
      Name: this.name,
      Address1: this.address1,
      Address2: this.address2,
      City: this.city,
      State: this.state,
      Country: this.country,
      Modified: moment(this.modified),
      Deleted: this.deleted,
      DealerID: this.dealerID,
      BioGuard: this.bioGuard,
      ProGuard: this.proGuard,
      SpaGuard: this.spaGuard,
      NaturalChemistry: this.naturalChemistry,
      Website: this.website,
      Fax: this.fax,
      Logo: this.logo,
      PostalCode: this.zip || this.postalCode,
      HoursMStart: this.hoursFor('Monday', 'start'),
      HoursMEnd: this.hoursFor('Monday', 'end'),
      HoursTStart: this.hoursFor('Tuesday', 'start'),
      HoursTEnd: this.hoursFor('Tuesday', 'end'),
      HoursWStart: this.hoursFor('Wednesday', 'start'),
      HoursWEnd: this.hoursFor('Wednesday', 'end'),
      HoursRStart: this.hoursFor('Thursday', 'start'),
      HoursREnd: this.hoursFor('Thursday', 'end'),
      HoursFStart: this.hoursFor('Friday', 'start'),
      HoursFEnd: this.hoursFor('Friday', 'end'),
      HoursSStart: this.hoursFor('Saturday', 'start'),
      HoursSEnd: this.hoursFor('Saturday', 'end'),
      HoursNStart: this.hoursFor('Sunday', 'start'),
      HoursNEnd: this.hoursFor('Sunday', 'end'),
      Email: this.email,
      UseEmail: this.useEmail,
      Phone: this.phone,
      SoldTo: this.soldTo,
      ShipTo: this.shipTo,
      BrandingName: this.brandingName,
      OverrideBranding: this.overrideBranding,
    };
  }

  hoursFor(day, time) {
    return this.hours && this.hours[day] && this.hours[day][time];
  }

  static fromApiFormat(api) {
    return new Branch({
      id: api.ID,
      name: api.Name,
      address1: api.Address1,
      address2: api.Address2,
      city: api.City,
      state: api.State,
      country: api.Country,
      modified: api.Modified,
      deleted: api.Deleted,
      dealerID: api.DealerID,
      bioGuard: api.BioGuard,
      proGuard: api.ProGuard,
      spaGuard: api.SpaGuard,
      naturalChemistry: api.NaturalChemistry,
      website: api.Website,
      fax: api.Fax,
      logo: api.Logo,
      logoLink: api.LogoLink,
      postalCode: api.PostalCode,
      zip: api.PostalCode,
      email: api.Email,
      useEmail: api.UseEmail,
      phone: api.Phone,
      soldTo: api.SoldTo,
      shipTo: api.ShipTo,
      overrideBranding: api.OverrideBranding,
      brandingName: api.BrandingName,
      hours: {
        Sunday: {
          start: api.HoursNStart,
          end: api.HoursNEnd,
        },
        Monday: {
          start: api.HoursMStart,
          end: api.HoursMEnd,
        },
        Tuesday: {
          start: api.HoursTStart,
          end: api.HoursTEnd,
        },
        Wednesday: {
          start: api.HoursWStart,
          end: api.HoursWEnd,
        },
        Thursday: {
          start: api.HoursRStart,
          end: api.HoursREnd,
        },
        Friday: {
          start: api.HoursFStart,
          end: api.HoursFEnd,
        },
        Saturday: {
          start: api.HoursSStart,
          end: api.HoursSEnd,
        },
      },
    });
  }
}

export default Branch;
