import ReportTask from '../models/reportTask';
import qwest from 'qwest';


let ReportTaskApi = {

  getAll(page, pageSize) {
    return qwest.get(`/api/ReportTask?page=${page}&pageSize=${pageSize}`)
            .then((xhr, response) => {
              if (response.success) {
                response.data.results = (response.data.results || []).map(x => ReportTask.fromApiFormat(x));
                return  response.data;
              }
              throw response;
            });

  },
  save(reportTask) {
    let apiReportTask = reportTask.toApiFormat();

    return qwest.post('/api/ReportTask/Save/', apiReportTask)
            .then((xhr, response) => {
              if (response.success) {
                return response;
              }
              throw response;
            });
  },
};


export default ReportTaskApi;
