import {getProductList} from 'api/product';
import {connectToToasts} from 'components/toasts';
import {cacheAndInjectData} from 'helpers/cacheAndInjectData';
import React from 'react';
import PropTypes from 'prop-types';
import LayoutInput from 'components/form/layoutInput';
import LayoutSelect from 'components/form/layoutSelect';
import LayoutCheckbox from 'components/form/layoutCheckbox';
import EditableDatePicker from 'components/form/editableDatePicker';
import Collapsible from 'components/collapsible';
import EditableTextField from 'components/form/editableTextField';
import EditableTextArea from 'components/form/editableTextArea';
import getProductDict from '../../../../../Canopy/Javascript/ui/page/customer/system/productDict';
import PoolModel from '../../../../../Canopy/Javascript/models/pool';
import CustomerModel from '../../../../../Canopy/Javascript/models/customer';
import DealerModel from '../../../../../Canopy/Javascript/models/dealer';
import AnalysisModel from '../../../../../Canopy/Javascript/models/analysis';
import ProductModel from '../../../../../Canopy/Javascript/models/product';
import moment from 'moment';

function generateMatchingObject(...values) {
  return values.reduce((acc, x) => {
    acc[x] = x;
    return acc;
  }, {});
}

function zipObject(xs = [], ys = []) {
  return xs
    .slice(0, Math.max(xs.length, ys.length))
    .reduce((acc, x, i) => {
      acc[x] = ys[i];
      return acc;
    }, {});
}

function sortOptions(optionsObject) {
  let keys = Object.keys(optionsObject);
  let options = Object.values(optionsObject);

  // If has empty option make sure it's first
  if (keys && (keys.includes('') || keys.includes(null) || keys.includes(undefined))) {
    let i = keys.findIndex(x => !x);

    keys = [keys[i]].concat(keys.slice(0, i)).concat(keys.slice(i + 1));
    options = [options[i]].concat(options.slice(0, i)).concat(options.slice(i + 1));
  }

  return {keys, options};
}

function get(obj, ...keys) {
  if (!obj || !keys.length) {
    return obj;
  }

  if (typeof obj !== 'object' && keys.length) {
    return undefined;
  }

  const key = keys.shift();

  return get(obj[key], ...keys);
}

function generateProductSelect(key, label) {
  return {
    key,
    label,
    type: 'select',
    options: ({productDict}) => zipObject(get(productDict, key, 'keys'), get(productDict, key, 'names')),
  };
}

const fields = {
  dealer: [
    {key: 'country', label: 'Country', type: 'select', value: 'US', options: {US: 'United States', CA: 'Canada'}},
    {key: 'state', label: 'State/Province', type: 'text', value: 'CA'},
    {
      key: 'inSeasonTABalanceTarget',
      label: 'In Season TA Balance Target',
      type: 'select',
      value: '135',
      options: generateMatchingObject(135, 150),
    },
    {key: 'saltScapes', label: 'SaltScapes', type: 'checkbox'},
    {key: 'spaSentry', label: 'Spa Sentry', type: 'checkbox'},
    {key: 'doNotTreatTraceMetals', label: 'Do Not Treat Trace Metals', type: 'checkbox'},
    {key: 'poolClosingComplete', label: 'Pool Closing Complete®', type: 'checkbox'},
    {key: 'overrideOxidizer', label: 'Override Oxidizer',type: 'select', options: {
      17: 'BurnOut 73',
      29: 'BurnOut 3',
      46: 'Chlorinating Liquid',
      187: 'All',
    },
    },
    {
      key: 'nonFoamingAlgicide',
      label: 'Non-Foaming Algicide',
      type: 'select',
      value: 'Banish',
      options: {Banish: 'Banish', 'Algae All 60': 'Algae All 60'},
    },
    {key: 'pHCalcLowOptimizer', label: 'pH Calc Low Optimizer', type: 'checkbox'},
    {key: 'poolScores', label: 'Show Pool Scores', type: 'checkbox'},
    {key: 'displaySpaProductsOunces', label: 'Display Spa Products in Ounces', type: 'checkbox'},
    {
      key: 'defaultHazyProduct', label: 'Default Product for Hazy Water', value: '', type: 'select',
      options: {'': '(disabled)', 'Natural Clarifier': 'Natural Clarifier', 'Polysheen Blue': 'Polysheen Blue'},
    },
    {
      key: 'oxysheenMaintClarifierDemand',
      label: 'Set Oxysheen for Maintenance for Clarifier Demand',
      type: 'checkbox',
    },
    {
      key: 'spaDrainFrequency', label: 'Spa Drain Frequency', type: 'select', value: 3,
      options: generateMatchingObject(1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
    },
    {
      key: 'balanceCHTo',
      label: 'Balance CH To',
      type: 'select',
      value: 'Use LSI',
      options: generateMatchingObject('Use LSI', 200, 250, 300, 350),
    },
    {
      key: 'smartShockMinSize',
      label: 'Smart Shock Minimum Size',
      value: '1',
      type: 'select',
      options: {'1': '1 lb bags', '2': '2 lb bottles'},
    },
    // {key: ''},
  ],
  pool: [
    {key: 'typeID', label: 'System Type', type: 'select', options: {1: 'Pool', 2: 'Spa', 3: 'Swim Spa'}, value: '1'},
    {key: 'volume', label: 'Volume', type: 'number'},
    {
      key: 'groundLevel',
      label: 'Ground Level',
      type: 'select',
      options: {A: 'Above Ground', I: 'In Ground', S: 'Splasher'},
    },
    {key: 'property', label: 'Residential / Commercial', type: 'select', options: {R: 'Residential', C: 'Commercial'}},
    {key: 'location', label: 'Location', type: 'select', options: {O: 'Outdoor', I: 'Indoor'}},
    {
      key: 'finish',
      label: 'Finish',
      type: 'select',
      options: {UV: 'Unfinished Vinyl', C: 'Coloured Plaster', W: 'White Plaster'},
    },

    generateProductSelect('sanitizer', 'Sanitizer'),
    generateProductSelect('maintOxidizer', 'Maint Oxidizer'),
    generateProductSelect('prevAlgicide', 'Prev Algicide'),
    generateProductSelect('oxidizer', 'Oxidizer'),
    generateProductSelect('maintClarifier', 'Maint Clarifier'),
    generateProductSelect('pHBuffer', 'pH Buffer', true),
    generateProductSelect('pHAdjuster', 'pH Adjuster'),
    generateProductSelect('winterOxidizer', 'Winterizer'),
    generateProductSelect('winterAlgicide', 'Winter Algicide'),
    generateProductSelect('stabilizer', 'Stabilizer'),

    {key: 'attachedSpa', label: 'Attached Spa', type: 'checkbox'},
    {key: 'borates', label: 'Using Optimizer Plus Borates', type: 'checkbox'},
    {key: 'enzymes', label: 'Using Enzymes', type: 'checkbox'},
    {key: 'cannotVacToWaste', label: 'Cannot Vac To Waste', type: 'checkbox'},
    {key: 'filterMedia', label: 'Filter Media', type: 'select', options: {Sand: 'Sand', DE: 'DE'}},
    {key: 'ionization', label: 'Ionization', type: 'checkbox'},
    {key: 'poolOpeningComplete', label: 'Pool Opening Complete®', type: 'checkbox'},
    {key: 'softSoakTRIO', label: 'SoftSoak TRIO', type: 'checkbox'},
    {key: 'truBlue', label: 'TruBlue', type: 'checkbox'},
    {key: 'usingNaturalResult', label: 'Using Natural Result', type: 'checkbox'},
    {key: 'usingOptimizerPlus', label: 'Using Optimizer Plus', type: 'checkbox'},
    {key: 'usingPoolTonic', label: 'Using Pool Tonic', type: 'checkbox'},
    {key: 'usingVanishingAct', label: 'Using Vanishing Act', type: 'checkbox'},
    {key: 'usingSmartShield', label: 'Using Smart Shield', type: 'checkbox'},
    {key: 'waterfall', label: 'Waterfall', type: 'checkbox'},
    {key: 'waterFountain', label: 'Water Fountain', type: 'checkbox'},
    {key: 'idealSaltPPM', label: 'Ideal Salt PPM', type: 'number'},
    {key: 'spaComplete', label: 'Spa Complete®', type: 'checkbox'},
  ],
  analysis: [
    {key: 'waterTester', label: 'Water Tester', type: 'text'},
    {
      key: 'season',
      label: 'Season',
      type: 'select',
      options: {in: 'In Season', springopening: 'Spring Opening', winterclosing: 'Winter Closing', out: 'Off Season'},
    },
    {key: 'temperature', label: 'Temperature', type: 'number'},
    {key: 'tDS', label: 'TDS', type: 'number'},
    {key: 'cYA', label: 'CYA', type: 'number'},
    {key: 'totalBromine', label: 'Total Bromine', type: 'number'},
    {key: 'totalChlorine', label: 'Total Chlorine', type: 'number'},
    {key: 'freeChlorine', label: 'Free Chlorine', type: 'number'},
    {key: 'pH', label: 'pH', type: 'number'},
    {key: 'alkalinity', label: 'TA', type: 'number'},
    {key: 'hardness', label: 'Total Hardness', type: 'number'},
    {key: 'optimizerPlusBorate', label: 'Optimizer Plus', type: 'number'},
    {key: 'initiatorLevel', label: 'Initiator Level', type: 'number'},
    {key: 'copper', label: 'Copper', type: 'number'},
    {key: 'copperFromProduct', label: 'Copper From Product', type: 'checkbox'},
    {key: 'iron', label: 'Iron', type: 'number'},
    {key: 'quat', label: 'Quat', type: 'number'},
    {key: 'softSoakSanitizer', label: 'SoftSoak Sanitizer', type: 'number'},
    {key: 'softSoakShock', label: 'SoftSoak Shock', type: 'number'},
    {key: 'softswimB', label: 'SoftSwim B', type: 'number'},
    {key: 'softswimC', label: 'SoftSwim C', type: 'number'},
    {key: 'salt', label: 'Salt', type: 'number'},
    {key: 'minerals', label: 'Minerals', type: 'number'},
    {key: 'cDDistStandard', label: 'CD Standard', type: 'number'},
    {key: 'cDSample', label: 'CD Sample', type: 'number'},
    {key: 'cDProduct', label: 'Chlorine Demand Product', type: 'select', options: {
      17: 'BurnOut 73',
      29: 'BurnOut 3',
      46: 'Chlorinating Liquid',
      191: 'ProGuard',
      187: 'Print All',
      45: 'Chlorinating Concentrate (Spa)',
    },
    },
    {key: 'startup', label: 'Startup', type: 'checkbox'},
    {key: 'freshFill', label: 'Fresh Fill', type: 'checkbox'},
    {key: 'spaSentryStartup', label: 'Spa Sentry Startup', type: 'checkbox'},
    {key: 'trioStartup', label: 'SoftSoak Trio Startup', type: 'checkbox'},
    {key: 'foaming', label: 'Foaming', type: 'checkbox'},
    {key: 'hazy', label: 'Hazy', type: 'checkbox'},
    {key: 'cloudy', label: 'Cloudy', type: 'checkbox'},
    {key: 'manganese', label: 'Manganese', type: 'checkbox'},
    {key: 'ironStaining', label: 'Iron Staining', type: 'checkbox'},
    {key: 'copperStaining', label: 'Copper Staining', type: 'select', options: {
      0: 'None',
      1: 'Mild',
      2: 'Severe',
    },
    },
    {key: 'organicStaining', label: 'Organic Staining', type: 'checkbox'},
    {key: 'unknownStaining', label: 'Unknown Staining', type: 'checkbox'},
    {key: 'partialDrain', label: 'User Accepted Partial Drain', type: 'checkbox'},
    {key: 'cleanAndDrain', label: 'Clean And Drain', type: 'checkbox'},
    {key: 'scaleBuildup', label: 'Scale Buildup', type: 'checkbox'},
    {key: 'waterBuildup', label: 'Waterline Buildup', type: 'checkbox'},
    {key: 'slimeMold', label: 'Slime/Mold', type: 'checkbox'},
    {key: 'greenAlgae', label: 'Green Algae', type: 'checkbox'},
    {key: 'mustardAlgae', label: 'Mustard Algae', type: 'checkbox'},
    {
      key: 'blackAlgae', label: 'Black Algae', type: 'select', options: {
        '': 'None',
        '1': 'Light',
        '2': 'Extensive',
      },
    },
    {key: 'swampy', label: 'Swampy', type: 'checkbox'},
    {key: 'spaAlgae', label: 'Spa Algae', type: 'checkbox'},
    {
      key: 'algaeProduct', label: 'Algae Product', type: 'select', options: {
        40: 'Banish',
        28: 'Algae Complete®',
        33: 'Algae All 60',
        39: 'Backup 2',
        101: 'Spot Kill',
        78: 'SaltScapes Algae Remover',
      },
    },
    {
      key: 'clarifier', label: 'Clarifier', type: 'select', options: {
        62: 'Polysheen Blue',
        60: 'Natural Clarifier',
      },
    },
    {key: 'chlorineDemandOnly', label: 'Chlorine Demand Only', type: 'checkbox'},
    {key: 'plasterLT12Months', label: 'Plaster < 12 Months', type: 'checkbox'},
    {key: 'plasterLT30Days', label: 'Plaster < 30 Days', type: 'checkbox'},
    {key: 'filterCleanedDate', label: 'Filter Cleaned Date', type: 'date', value: moment.utc().format()},
    {key: 'mSCellCleanedDate', label: 'Cell Cleaned Date', type: 'date', value: moment.utc().add(-43, 'days').format()},
    {key: 'lastCleanedDrained', label: 'Last Cleaned Drained', type: 'date', value: moment.utc().format()},
    {key: 'temperatureLT32', label: 'Temperature < 32', type: 'checkbox'},
  ],
};

const identity = () => () => {
};

function getFields(input, update, key) {
  const restrictedFields = fields[key] || [];

  update = update || identity;

  input = input || {dealer: {}, analysis: {}, pool: {}, customer: {}, productDict: {}};

  const props = input[key] || {};

  return <div className='row'>
    {restrictedFields.map(x => {
      let options = x.options;

      if (options && typeof options === 'function') {
        options = options(input);
      }

      return <div className='col-md-4' key={x.key}>
        {x.type === 'number' || x.type === 'text' ?
          <LayoutInput dataChanged={update(key)}
                       dataKey={x.key}
                       editMode={true}
                       label={x.label}
                       type={x.type}
                       value={props[x.key] || x.value}/> :
          x.type === 'select' ?
            <LayoutSelect dataChanged={update(key)}
                          dataKey={x.key}
                          editMode={true}
                          label={x.label}
                          value={props[x.key] || x.value}
                          {...sortOptions(options)} /> :
            x.type === 'checkbox' ?
              <LayoutCheckbox dataChanged={update(key)}
                              dataKey={x.key}
                              label={x.label}
                              editMode={true}
                              value={props[x.key]}/> :
              x.type === 'date' ?
                <div className='form-group'>
                  <label>{x.label}</label>
                  <EditableDatePicker dataKey={x.key}
                                      onChange={update(key)}
                                      editMode={true}
                                      value={props[x.key] || x.value}
                                      dateFormat={'YYYY-MM-DD'}/>
                </div> :
                null}
      </div>;
    })}
  </div>;
}

class TestInput extends React.Component {
  static propTypes = {
    dealer: PropTypes.object,
    customer: PropTypes.object,
    analysis: PropTypes.object,
    pool: PropTypes.object,
    onUnmount: PropTypes.func,
    createToast: PropTypes.func.isRequired,
    fromApi: PropTypes.bool.isRequired,
    name: PropTypes.string,
    reportType: PropTypes.number,
    description: PropTypes.string,
  };

  constructor(props) {
    super(props);

    const dealer = props.dealer && props.fromApi ? DealerModel.fromApiFormat(props.dealer) : new DealerModel(props.dealer || {});
    const customer = props.customer && props.fromApi && Object.keys(props.customer).length ?
      CustomerModel.fromApiFormat(props.customer) :
      new CustomerModel(props.customer || {});
    const pool = props.pool && props.fromApi ? PoolModel.fromApiFormat(props.pool) : new PoolModel(props.pool || {});
    const analysis = props.analysis && props.fromApi ? AnalysisModel.fromApiFormat(props.analysis) : new AnalysisModel(props.analysis || {});

    if (dealer && dealer.poolScores === undefined) {
      dealer.poolScores = true;
    }

    if (analysis && !analysis.filterCleanedDate) {
      analysis.filterCleanedDate = moment();
    }

    if (analysis && !analysis.mSCellCleanedDate) {
      analysis.mSCellCleanedDate = moment();
    }

    this.state = {
      name: props.name || '',
      description: '',
      productDict: {},
      analysis,
      pool,
      customer,
      dealer,
      reportType: props.reportType || 0,
    };
  }

  componentWillMount() {
    getProductList()
      .then(data => {
        const products = data.map(ProductModel.fromApiFormat);
        let {pool: _pool, dealer} = this.state;
        let {pool, productDict} = getProductDict({pool: _pool, dealer, products});

        this.setState({products, pool, productDict});
      })
      .catch(err => {
        console.error(err);

        this.props.createToast({
          id: 'products.list.error',
          message: 'Failed to load products list.',
          ttl: 4000,
        });
      });
  }

  componentWillReceiveProps(nextProps) {
    let keys = Object.keys(nextProps);
    let nextState = this.state;
    const {products, dealer} = this.state;

    for (let key of keys) {
      if (nextProps[key] === this.props[key]) {
        continue;
      }

      switch (key) {
        case 'dealer':
          nextState.dealer = nextProps.dealer && nextProps.fromApi ? DealerModel.fromApiFormat(nextProps.dealer) : new DealerModel(nextProps.dealer || {});

          if (nextState.dealer && nextState.dealer.poolScores === undefined) {
            nextState.dealer.poolScores = true;
          }
          break;

        case 'pool':
          const poolSetting = setInterval(() => {
            if (!!this.state.products && !!this.state.products.length) {
              let _pool = nextProps.pool && nextProps.fromApi ?
                PoolModel.fromApiFormat(nextProps.pool) :
                new PoolModel(nextProps.pool || {});

              let {productDict, pool} = getProductDict({pool: _pool, products: this.state.products, dealer});

              nextState.productDict = productDict;
              nextState.pool = pool;

              this.setState(nextState);

              clearInterval(poolSetting);
            }
          }, 100);

          break;

        case 'customer':
          nextState.customer = nextProps.customer && nextProps.fromApi ?
            CustomerModel.fromApiFormat(nextProps.customer) :
            new CustomerModel(nextProps.customer || {});
          break;

        case 'analysis':
          nextState.analysis = nextProps.analysis && nextProps.fromApi ?
            AnalysisModel.fromApiFormat(nextProps.analysis) :
            new AnalysisModel(nextProps.analysis || {});
          break;

        case 'name':
          nextState.name = nextProps.name;
          break;

        case 'description':
          nextState.description = nextProps.description;
          break;

        default:
          break;
      }
    }

    this.setState(nextState);
  }

  componentWillUnmount() {
    if (this.props.onUnmount) {
      this.props.onUnmount(this.state);
    }
  }

  updateState = baseKey => ({key, value}) => {
    const current = this.state[baseKey];

    current[key] = value;

    if (this.state.products && this.state.products.length && baseKey === 'pool') {
      const {dealer, products} = this.state;
      const {pool, productDict} = getProductDict({dealer, pool: current, products});

      this.setState({pool, productDict});
      return;
    }

    this.setState({[baseKey]: current}, () => {
      if (!this.state.products || !this.state.products.length) {return;}

      const {dealer, products} = this.state;
      const {pool, productDict} = getProductDict({dealer, pool: this.state.pool, products});

      this.setState({pool, productDict});
    });
  };

  get data() {
    return this.state;
  }

  render() {
    return <React.Fragment>
      <div className='row'>
        <div className='col-xs-12'>
          <EditableTextField placeholder='Test name'
                             editMode={true}
                             dataKey='name'
                             value={this.state.name}
                             onChange={({value}) => this.setState({name: value})}
                             style={{marginBottom: 10}}/>
          <EditableTextArea dataKey='description'
                            editMode={true}
                            value={this.state.description}
                            placeholder='Description'
                            onChange={({value}) => this.setState({description: value})}
                            style={{marginBottom: 30}}/>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-4'>
          <LayoutSelect dataChanged={({value}) => this.setState({reportType: value})}
                        label='Type'
                        options={['Water Test', 'Seasons Usage']}
                        keys={[0, 1]}
                        value={this.state.reportType}
                        dataKey='reportType'
                        editMode={true}/>
        </div>
        {this.state.reportType === 1 ?
          <div className='col-md-4'>
            <LayoutInput dataChanged={({value}) => {
              let seasonStart = moment().startOf('year');
              let seasonEnd = moment().startOf('year').add(value, 'weeks');

              let pool = this.state.pool;

              pool.seasonStart = seasonStart;
              pool.seasonEnd = seasonEnd;

              this.setState({pool});
            }}
                         value={this.state.pool.seasonEnd && this.state.pool.seasonStart ?
                           this.state.pool.seasonEnd.diff(this.state.pool.seasonStart, 'weeks') :
                           26}
                         dataKey='weeks'
                         label='Season Weeks'
                         type='number'
                         editMode={true}/>
          </div> :
          null}
      </div>
      <div className='row'>
        <div className='col-xs-12'>
          <h3>Dealer</h3>
        </div>
      </div>
      <Collapsible>
        {getFields(this.state, this.updateState, 'dealer')}
      </Collapsible>

      <div className='row'>
        <div className='col-xs-12'>
          <h3>System</h3>
        </div>
      </div>

      <Collapsible>
        {getFields(this.state, this.updateState, 'pool')}
      </Collapsible>

      {this.state.reportType !== 1 ?
        <React.Fragment>
          <div className='row'>
            <div className='col-xs-12'>
              <h3>Analysis</h3>
            </div>
          </div>
          <Collapsible>
            {getFields(this.state, this.updateState, 'analysis')}
          </Collapsible>
        </React.Fragment> :
        null}
    </React.Fragment>;
  }
}

export default cacheAndInjectData({
  products() {
    return getProductList()
      .then(data => data.map(ProductModel.fromApiFormat));
  },
})(connectToToasts(TestInput));
