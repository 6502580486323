import PropTypes from 'prop-types';
import React from 'react';
import PageHeader from '../components/pageHeader.jsx';
import DeleteFromElasticSearch from '../components/tools/DeleteFromElasticSearch.jsx';
import PerformanceFileEditor from '../components/tools/performanceFileEditor.jsx';
import ReindexAdminPages from '../components/tools/reindexAdminPages.jsx';
import ReindexCustomers from '../components/tools/reindexCustomer.jsx';
import ReindexDealers from '../components/tools/reindexDealer.jsx';
import ReindexPrintFiles from '../components/tools/reindexPrintFiles.jsx';
import TransferStores from '../components/tools/transferStores.jsx';
import {browserHistory} from 'react-router';


// Page object
class PageUsers extends React.Component {
  static contextTypes = {
    canUseTools: PropTypes.bool.isRequired,
  };

  componentWillMount() {
    if (!this.context.canUseTools) {
      browserHistory.push('/');
    }
  }

  render() {
    return (
      <div>
        <PageHeader title='Developer Tools' icon='fa fa-wrench' breadcrumbs={[]} />
        <div className='wrapper wrapper-content' id='page-tools'>
          <div className='row'>
            <div className='col-sm-6 col-lg-4'>
              <TransferStores />
            </div>
            <div className='col-sm-6 col-lg-4'>
              <ReindexCustomers />
            </div>
            <div className='col-sm-6 col-lg-4'>
              <ReindexDealers />
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-6 col-lg-4'>
              <ReindexAdminPages />
            </div>
            <div className='col-sm-6 col-lg-4'>
              <ReindexPrintFiles />
            </div>
            <div className='col-sm-6 col-lg-4'>
              <DeleteFromElasticSearch />
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-6 col-lg-4'>
              <PerformanceFileEditor />
            </div>
          </div>
        </div>
      </div>);
  }
}

export default PageUsers;
