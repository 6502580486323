import PropTypes from 'prop-types';
import React from 'react';
import PageHeader from '../components/pageHeader.jsx';
import DatabaseStatistics from '../components/health/databaseStatistics.jsx';
import Timeline from '../components/tasktimeline/timeline.jsx';
import JobStatus from 'ui/components/health/jobStatus';
import {taskTypes} from 'models/task';

// Page object
class PageDeveloperDashboard extends React.Component {
  render() {
    return <div id='page-developer-dashboard'>
      <PageHeader title='Developer Dashboard' icon='fa fa-tasks' breadcrumbs={[]} />
      <div className='wrapper wrapper-content'>
        <div className='row' id='page-dashboard'>
          <div className='col-md-6 col-lg-4'>
            <DatabaseStatistics columns={2} />
            <JobStatus />
          </div>
          <div className='col-md-6 col-lg-4'>
            <h2 className='text-center'>Tasks</h2>
            <Timeline excludeTypes={[taskTypes.reportsGeneration]} />
          </div>
          <div className='col-md-6 col-lg-4'>
            <h2 className='text-center'>Report Tasks</h2>
            <Timeline includeTypes={[taskTypes.reportsGeneration]} />
          </div>
        </div>
      </div>
    </div>;
  }
}

export default PageDeveloperDashboard;
