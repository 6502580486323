import React from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {getImagePath} from '../../helpers/image-helper';

const convertDataToTable = (parameters, size = 4) => {
  const rows = [];
  let cols = [];

  parameters.map((param, i) => {
    if (param) {
      cols.push(param);
    }

    if (cols.length === size) {
      rows.push(cols);
      cols = [];
    }
  });

  //Creates a new row for the remaining cols.
  if (cols.length > 0) {
    while (cols.length < size) {
      //Completes the row with empty cols, if it needs to.
      cols.push({});
    }
    rows.push(cols);
  }

  return rows;
};

function translateValue(val) {
  if (val === 'Not Tested') {
    return <FormattedMessage id='general.not.tested' />;
  }

  if (val === 'High') {
    return <FormattedMessage id='general.high' />;
  }

  if (val === 'Low') {
    return <FormattedMessage id='general.low' />;
  }

  if (val === 'Normal') {
    return <FormattedMessage id='general.normal' />;
  }

  if ((val || '').includes('Saturation Index')) {
    return <FormattedMessage id='report-viewer.si.key.param' />;
  }

  return val;
}

const KeyParameters = ({data, intl}) => {
  const {formatMessage} = intl;

  return <div className="key-parameters">
    <div className="row" key="title"><div className="col-12"><h3><FormattedMessage id='report-viewer.key-parameters.title'/></h3></div></div>
    {convertDataToTable(data).map((row, i) =>
      <div className="row"
           key={i}>
        {row.map((col, j) => <div className="col-3" key={j}>
            <div className={!col.title ? 'details' : ''}>
              {col.title ? formatMessage({id: col.title}) + ': ' : ''}
              {
                col.valid !== undefined && col.valid !== null &&
                <img src={getImagePath({
                  file: col.valid ? 'check.png' : 'triangle_alerts.png',
                  folder: '',
                  locale: intl.locale
                })}/>
              }
              {translateValue(col.value)}
            </div>
          </div>)}
      </div>)}
  </div>;
};

export default injectIntl(KeyParameters);
