import PropTypes from 'prop-types';
import React from 'react';
import {browserHistory} from 'react-router';
import IBox from '../components/IBox.jsx';
import PageHeader from '../components/PageHeader.jsx';

const REPORT_DESCRIPTIONS = [
  {
    type: 'Notifications',
    description: 'Graph of notifications over time.',
    link: 'reports/notifications-graph',
    permission: 'canViewReports',
  },
  {
    type: 'Emails Sent',
    description: 'Statistics on the number of emails sent and the CTR.',
    link: 'reports/emails-report',
    permission: 'canViewReports',
  },
];

// Page object
class ReportsPage extends React.Component {
  static contextTypes = {
    canViewReports: PropTypes.bool.isRequired,
    canViewPosReport: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    if (!this.context.canViewReports && !this.canViewPosReport) {
      browserHistory.push('/');
    }
  }

  render() {
    return (
      <div id='page-reports'>
        <PageHeader title='Reports' icon='fa fa-pie-chart' breadcrumbs={[{title: 'Reports', path: '/reports'}]} />
        <div className='wrapper wrapper-content'>
          <IBox title='Reports'>
            <table className='table table-striped table-hover hoverClick'>
              <thead>
                <tr>
                  <th>Report Type</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                {REPORT_DESCRIPTIONS
                  .filter(x => this.context[x.permission] === true)
                  .map((reportDescription, i) =>
                    <tr key={i} onClick={() => browserHistory.push(reportDescription.link)}>
                      <td>{reportDescription.type}</td>
                      <td>{reportDescription.description}</td>
                    </tr>
                  )}
              </tbody>
            </table>
          </IBox>
        </div>
      </div>
    );
  }
}

export default ReportsPage;
