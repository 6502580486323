import PropTypes from 'prop-types';
import React, {PureComponent, Component} from 'react';
import List from '../helpers/list.js';

export const TOAST_STATES = {
  SUCCESS: 'SUCCESS',
  WARNING: 'WARNING',
  ERROR: 'ERROR',
  INFO: 'INFO',
};

export class ToastManager extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      toasts: new List(),
    };
  }

  getChildContext() {
    return {
      createToast: this.create,
      clearToast: this.clear,
    };
  }

  create = toast => {
    let toasts = this.state.toasts.filter(t => !t.id || t.id !== toast.id).push(toast);


    if (toast.ttl && toast.id) {
      setTimeout(() => {
        this.clear(toast.id);
      }, toast.ttl);
    }

    if (toast.clears) {
      toasts = toasts.filter(x => x.id !== toast.clears);
    }

    this.setState({toasts});
  };

  clear = id => {
    let toasts = this.state.toasts.filter(x => x.id !== id);

    this.setState({toasts});
  };

  render() {
    return <div>
      <div id='toast-container'>
        {this.state.toasts.map(x => <Toast key={x.id}
                                           message={x.message}
                                           state={x.state}
                                           clear={(() => this.clear(x.id))}/>).toArray()}
      </div>
      {this.props.children}
    </div>;
  }
}

ToastManager.childContextTypes = {
  createToast: PropTypes.func.isRequired,
  clearToast: PropTypes.func.isRequired,
};


export class Toast extends PureComponent {
  render() {
    let className = 'toast';

    switch (this.props.state) {
      case TOAST_STATES.WARNING:
        className += ' toast-warning';
        break;
      case TOAST_STATES.ERROR:
        className += ' toast-error';
        break;
      case TOAST_STATES.INFO:
        className += ' toast-info';
        break;
      case TOAST_STATES.SUCCESS:
        className += ' toast-success';
        break;
      default:
        className += ' toast-info';
        break;
    }
    return <div className={className}>
      {this.props.message}
      <button className='btn toast-close-button' onClick={this.props.clear}><i className='fa fa-times fa-2x'/></button>
    </div>;
  }
}

export function connectToToasts(Element) {
  class _NewElement extends Component {
    getUnderlying = () => {
      if (!this._inner) {
        return;
      }

      return this._inner.getUnderlying ? this._inner.getUnderlying() : this._inner;
    };

    render() {
      return <Element {...this.props} ref={x => this._inner = x} createToast={this.context.createToast}
                      clearToast={this.context.clearToast}/>;
    }
  }

  _NewElement.displayName = 'connectToToasts(' + Element.displayName + ')';
  _NewElement.contextTypes = {
    createToast: PropTypes.func.isRequired,
    clearToast: PropTypes.func.isRequired,
  };
  return _NewElement;
}
