import React from 'react';
import {injectIntl} from 'react-intl';
import {getImagePath} from '../../helpers/image-helper.jsx';

const StarRating = ({intl, rating, of}) =>
  <div className="star-rating">
    { Array.from(Array(parseInt(of)).keys()).map((index, i) =>  //This line just generates an array of numbers from 0 to the "of" number then maps it.
      <img src={getImagePath({locale: intl.locale, folder: 'PoolScores', file: (index < rating ? 'filled' : 'empty') + '_star.png'})}
             key={i} />
    )}
  </div>;


export default injectIntl(StarRating);
