import SalesProduct from '../models/salesProduct.jsx';
import qwest from 'qwest';

let SalesProductApi = {
  getAll() {
    return qwest.get('/api/SalesProduct/All')
      .then((xhr, response) => {
        if (response.success) {
          return response.data.map(x => SalesProduct.fromApiFormat(x));
        }
        throw response;
      });
  },
  getProduct(upc, country) {
    return qwest.get(`/api/SalesProduct/Index/?upc=${upc}&country=${country}`)
      .then((xhr, response) => {
        if (response.success) {
          return SalesProduct.fromApiFormat(response.data);
        }
        throw response;
      });
  },

  addProduct(salesProduct) {
    let apiSalesProduct = salesProduct.toApiFormat();

    return qwest.post('/api/SalesProduct/Create/', apiSalesProduct)
      .then((xhr, response) => {
        if (response.success) {
          return response;
        }
        throw response;
      });
  },

  updateProduct(salesProduct) {
    let apiSalesProduct = salesProduct.toApiFormat();

    return qwest.put('/api/SalesProduct/Update/', apiSalesProduct)
      .then((xhr, response) => {
        if (response.success) {
          return response;
        }
        throw response;
      });
  },

  deleteProduct(id) {
    return qwest.delete(`/api/SalesProduct/Delete/?id=${id}`)
      .then((xhr, response) => {
        if (response.success) {
          return response;
        }
        throw response;
      });
  },
};

export default SalesProductApi;
