class SalesProduct {
  constructor({
    msrp, amount, caseUPC, salesCategory, name, productUPC, sku, isThreeStepProduct, stepNumber,
    unitOfMeasure,isActive,isTruBlue, unitsPerCase, mediaType, containerType, id, country,
  } = {}) {
    this.msrp = msrp;
    this.amount = amount;
    this.caseUPC = caseUPC;
    this.salesCategory = salesCategory;
    this.name = name;
    this.productUPC = productUPC;
    this.sku = sku;
    this.isThreeStepProduct = isThreeStepProduct;
    this.stepNumber = stepNumber;
    this.unitOfMeasure = unitOfMeasure;
    this.isActive = isActive;
    this.isTruBlue = isTruBlue;
    this.unitsPerCase = unitsPerCase;
    this.mediaType = mediaType;
    this.containerType = containerType;
    this.id = id;
    this.country = country;
  }

  clone() {
    return new SalesProduct(this);
  }

  toApiFormat() {
    return {
      MSRP: this.msrp,
      AmountInProduct: this.amount,
      CaseUPC: this.caseUPC,
      SalesCategory: this.salesCategory,
      Name: this.name,
      ProductUPC: this.productUPC,
      SKU: this.sku,
      IsThreeStepProduct: this.isThreeStepProduct,
      StepNumber: this.stepNumber,
      unitOfMeasure: this.unitOfMeasure,
      isActive: this.isActive,
      IsTruBlue: this.isTruBlue,
      UnitsPerCase: this.unitsPerCase,
      MediaType: this.mediaType,
      ContainerType: this.containerType,
      ID: this.id,
      Country: this.country,
    };
  }

  static fromApiFormat(api) {
    return new SalesProduct({
      msrp: api.MSRP,
      amount: api.Amount,
      caseUPC: api.CaseUPC,
      salesCategory: api.SalesCategory,
      name: api.Name,
      productUPC: api.ProductUPC,
      sku: api.SKU,
      isThreeStepProduct: api.IsThreeStepProduct,
      stepNumber: api.StepNumber,
      unitOfMeasure: api.UnitOfMeasure,
      isActive: api.IsActive,
      isTruBlue: api.IsTruBlue,
      unitsPerCase: api.UnitsPerCase,
      mediaType: api.MediaType,
      containerType: api.ContainerType,
      id: api.ID,
      country: api.Country,
    });
  }
}

export default SalesProduct;
