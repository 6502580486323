class DealerUser {
  constructor({id, email, name, username, dealerId, password, passwordReset,
      passwordResetLink, phoneNumber, privileges, deleted, canWaterTest, roles = [],
  }) {
    this.id = id;
    this.email = email;
    this.name = name;
    this.username = username;
    this.dealerId = dealerId;
    this.password = password;
    this.passwordReset = passwordReset;
    this.passwordResetLink = passwordResetLink;
    this.phoneNumber = phoneNumber;
    this.privileges = privileges;
    this.deleted = deleted;
    this.canWaterTest = canWaterTest;
    this.dealerId = dealerId;
    this.roles = roles;
  }

  clone() {
    return new DealerUser(Object.assign({}, this, {
      roles: this.roles.map((r) => r.clone()),
    }));
  }

  setRoleForBranch(branch, role = '') {
    let branchId = branch ? branch.id : branch;
    let index = this.roles.findIndex((r) => r.branchId === branchId);
    let branchRole;

    if (index > -1) {
      branchRole = this.roles[index];
      branchRole.branchId = branchId;
      branchRole.role = role;
    } else {
      branchRole = new DealerUser.Role({branchId: branchId, role});
      this.roles.push(branchRole);
    }
    return branchRole;
  }

  removeRoleForBranch(branch) {
    let branchId = branch ? branch.id : branch;
    let index = this.roles.findIndex(r => r.branchId === branchId);

    if (index > -1) {
      return this.roles.splice(index, 1)[0];
    }

    console.warn('Attempt to delete a nonexistent role from a dealer user');
    return this.roles;
  }

  hasRoleForBranch(branch, role) {
    let branchId = branch ? branch.id : branch;
    let index = this.roles.findIndex((r) => r.branchId === branchId && r.role === role);

    return index > -1;
  }

  toApiFormat() {
    return {
      ID: this.id,
      Email: this.email,
      Name: this.name,
      Username: this.username,
      DealerID: this.dealerId,
      Password: this.password,
      PasswordReset: this.passwordReset,
      PasswordResetLinks: this.passwordResetLink,
      PhoneNumber: this.phoneNumber,
      Privileges: this.privileges,
      Deleted: this.deleted,
      CanWaterTest: this.canWaterTest,
      Roles: this.roles ? this.roles.map((r) => r.toApiFormat()) : [],
    };
  }

  static fromApiFormat(apiUser) {
    return new DealerUser({
      id: apiUser.ID,
      email: apiUser.Email,
      name: apiUser.Name,
      username: apiUser.Username,
      dealerId: apiUser.DealerID,
      password: apiUser.Password,
      passwordReset: apiUser.PasswordReset,
      passwordResetLink: apiUser.PasswordResetLink,
      phoneNumber: apiUser.PhoneNumber,
      privileges: apiUser.Privileges,
      deleted: apiUser.Deleted,
      canWaterTest: apiUser.CanWaterTest,
      roles: apiUser.Roles ? apiUser.Roles.map(DealerUser.Role.fromApiFormat) : [],
    });
  }
}

DealerUser.Role = class DealerUserRole {
  constructor({
    id, role, branchId, dealerUserId,
  }) {
    this.id = id;
    this.dealerUserId = dealerUserId;
    this.role = role;
    this.branchId = branchId;
  }

  clone() {
    return new DealerUser.Role(this);
  }

  toApiFormat() {
    return {
      ID: this.id,
      DealerUserId: this.dealerUserId,
      Role: this.role,
      BranchID: this.branchId,
    };
  }

  static fromApiFormat(api) {
    return new DealerUser.Role({
      id: api.ID,
      dealerUserId: api.DealerUserId,
      role: api.Role,
      branchId: api.BranchID,
    });
  }
};

export default DealerUser;
