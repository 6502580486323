import React from 'react';
import moment from 'moment';
import AssetApi from '../../api/asset.jsx';
import ImageUpload from '../components/fileUpload.jsx';
import PageHeader from '../components/pageHeader.jsx';
import {FormControl} from 'react-bootstrap';

class ManageFiles extends React.Component {
  state = {
    assets: [],
    searchFilter: '',
    fileTypeFilter: [],
  };

  componentDidMount() {
    AssetApi.list()
      .then(assets => this.setState({assets: assets.sort(this.assetSort)}))
      .catch(e => console.error(e));
  }

  assetSort = (a, b) => {
    let aDate = moment(a.substring(0, 10), 'MM-DD-YYYY');
    let bDate = moment(b.substring(0, 10), 'MM-DD-YYYY');

    if (aDate.isBefore(bDate)) {
      return -1;
    }
    if (aDate.isAfter(bDate)) {
      return 1;
    }
    return 0;
  };

  downloadAsset = (filename) => {
    let xhr = new XMLHttpRequest();

    xhr.responseType = 'blob';
    xhr.onload = function() {
      let link = document.createElement('a');

      link.href = window.URL.createObjectURL(xhr.response);
      link.setAttribute('download', filename);
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };
    let CDN = document.getElementById('body').dataset.cdn;

    xhr.open('GET', CDN + filename);
    xhr.send();
  };

  fileBox = (assetName) => {
    let date = moment(assetName.substring(0, 10), 'MM-DD-YYYY').format('MMM Do, YY');
    let name = assetName.substring(11, assetName.length);
    let extension = name.substring(name.lastIndexOf('.') + 1).toLowerCase();

    // Apply search filter
    if (name.indexOf(this.state.searchFilter) === -1) {
      return null;
    }

    return (
      <div key={assetName} className='file-box'>
        <div className='file'
          onClick={() => this.downloadAsset(assetName)}>
          {this.fileIcon(extension, assetName)}
          <div className='file-name'>
            {name}
            <br />
            <small>Added: {date}</small>
          </div>
        </div>
      </div>
    );
  };

  fileIcon = (extension, assetName) => {
    let charts = ['csv', 'xls', 'xlsx', 'ods'];
    let images = ['jpg', 'jpeg', 'png', 'gif'];
    let music = ['mp3'];
    let pdf = ['pdf'];
    let powerpoint = ['ppt', 'pptx'];
    let videos = ['webm', 'mkv', 'mp4', 'avi'];
    let word = ['doc', 'docx'];
    let zip = ['rar', 'zip'];

    if (images.indexOf(extension) > -1) {
      let CDN = document.getElementById('body').dataset.cdn;

      return (
        <div className='image'>
          <img alt='image' className='img-responsive'
            src={CDN + assetName} />
        </div>
      );
    } else {
      let icon;

      if (word.indexOf(extension) > -1) {
        icon = <i className='fa fa-file-word-o'/>;
      } else if (charts.indexOf(extension) > -1) {
        icon = <i className='fa fa-file-excel-o'/>;
      } else if (pdf.indexOf(extension) > -1) {
        icon = <i className='fa fa-file-pdf-o'/>;
      } else if (zip.indexOf(extension) > -1) {
        icon = <i className='fa fa-file-zip-o'/>;
      } else if (powerpoint.indexOf(extension) > -1) {
        icon = <i className='fa fa-file-powerpoint-o'/>;
      } else if (music.indexOf(extension) > -1) {
        icon = <i className='fa fa-file-audio-o'/>;
      } else if (videos.indexOf(extension) > -1) {
        icon = <i className='fa fa-file-video-o'/>;
      } else {
        icon = <i className='fa fa-file-o'/>;
      }
      return (
        <div className='icon'>
          {icon}
        </div>
      );
    }
  };

  uploadAsset = (file) => {
    AssetApi.upload(file)
      .then(assets => this.setState({assets: assets.sort(this.assetSort)}))
      .catch(e => console.error(e));
  };

  render() {
    return (
      <div>
        <PageHeader title='Manage Files' icon='fa fa-file' breadcrumbs={[{title: 'Manage Files', path: '/manage-files'}]} />
        <div className='wrapper wrapper-content'>
          <div className='row'>
            <div className='col-xs-12'>
              <FormControl
                placeholder='Search assets...'
                value={this.state.searchFilter}
                onChange={e => this.setState({searchFilter: e.target.value})} />
            </div>
          </div>
          <div className='files-container'>
            <div className='file-box'>
              <div className='file'>
                <ImageUpload upload={this.uploadAsset} />
              </div>
            </div>
            { this.state.assets.map(asset => this.fileBox(asset)) }
          </div>
        </div>
      </div>
    );
  }
}

export default ManageFiles;
