import qwest from 'qwest';
import AnnouncementConsumer from '../models/announcementConsumer.jsx';
import AnnouncementDealer from '../models/announcementDealer.jsx';

let AnnouncementApi = {

  getAnnouncementsConsumer(page = 1) {
    return qwest.get('/api/AnnouncementConsumer/Index/', {
      page
    }, {dataType: 'post'}).then((xhr, response) => {
      if (response.success) {
        response.data.items = (response.data.items || []).map(announcement => AnnouncementConsumer.fromApiFormat(announcement));
        return response.data;
      }

      throw response;
    });
  },

  getAnnouncementsDealer(page = 1) {
    return qwest.get('/api/AnnouncementDealer/Index/', {
      page
    }, {dataType: 'post'}).then((xhr, response) => {
      if (response.success) {
        response.data.items = (response.data.items || []).map(announcement => AnnouncementDealer.fromApiFormat(announcement));
        return response.data;
      }

      throw response;
    });
  },

  getAnnouncementConsumer(announcementID) {
    return qwest.get(`/api/AnnouncementConsumer/Index/${announcementID}`).then((xhr, response) => {
      if (response.success) {
        return AnnouncementConsumer.fromApiFormat(response.data);
      }

      throw response;
    });
  },

  getAnnouncementDealer(announcementID) {
    return qwest.get(`/api/AnnouncementDealer/Index/${announcementID}`).then((xhr, response) => {
      if (response.success) {
        return AnnouncementDealer.fromApiFormat(response.data);
      }

      throw response;
    });
  },

  saveAnnouncementConsumer(announcement) {
    return qwest.post(`/api/AnnouncementConsumer/Index/${announcement.id}`, announcement).then((xhr, response) => {
      if (response.success) {
        return AnnouncementConsumer.fromApiFormat(response.data);
      }

      throw response;
    });
  },

  saveAnnouncementDealer(announcement) {
    return qwest.post(`/api/AnnouncementDealer/Index/${announcement.id}`, announcement).then((xhr, response) => {
      if (response.success) {
        return AnnouncementDealer.fromApiFormat(response.data);
      }

      throw response;
    });
  },

  createAnnouncementConsumer(announcement) {
    return qwest.post('/api/AnnouncementConsumer/Index', announcement).then((xhr, response) => {
      if (response.success) {
        return AnnouncementConsumer.fromApiFormat(response.data);
      }

      throw response;
    });
  },

  createAnnouncementDealer(announcement) {
    return qwest.post('/api/AnnouncementDealer/Index', announcement).then((xhr, response) => {
      if (response.success) {
        return AnnouncementDealer.fromApiFormat(response.data);
      }

      throw response;
    });
  }
};

export default AnnouncementApi;
