import qwest from 'qwest';

import Notification from '../models/notification.jsx';

let NotificationApi = {
  // Notification History calls
  getNotifications() {
    return qwest.get('/api/Notification/')
      .then((xhr, response) => {
        if (response.success) {
          return response.data;
        }
        throw response;
      });
  },

  getNotification(id) {
    return qwest.get(`/api/Notification/Index/${id}`)
      .then((xhr, response) => {
        if (response.success) {
          return Notification.fromApiFormat(response.data);
        }
        throw response;
      });
  },

  createNotification(notif) {
    return qwest.post('/api/Notification/', notif)
      .then((xhr, response) => {
        if (response.success) {
          return Notification.fromApiFormat(response.data);
        }
        throw response;
      });
  },

  saveNotification(notif) {
    return qwest.post('/api/Notification/Update', notif)
      .then((xhr, response) => {
        if (response.success) {
          return Notification.fromApiFormat(response.data);
        }
        throw response;
      });
  },

  // Sent Notification calls
  getSentNotifications(id) {
    return qwest.get(`/api/Notification/GetSent?id=${id}`)
      .then((xhr, response) => {
        if (response.success) {
          return response.data;
        }
        throw response;
      });
  },

  getReadNotifications(id) {
    return qwest.get(`/api/Notification/GetRead?id=${id}`)
      .then((xhr, response) => {
        if (response.success) {
          return response.data;
        }
        throw response;
      });
  },

  deleteNotification(id) {
    return qwest.delete(`/api/Notification/Index/${id}`)
      .then((xhr, response) => {
        if (response.success) {
          return response;
        }
        throw response;
      });
  }
};

export default NotificationApi;
