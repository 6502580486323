import PropTypes from 'prop-types';
import React, {Component} from 'react';
import IBox from 'components/iBox';
import {Input, Select, TextArea} from 'components/form';
import ReportTask from 'models/ReportTask';
import {reportTemplates} from 'helpers/constants.jsx';

class EditReportRun extends Component {
  static propTypes = {
    cancel: PropTypes.func.isRequired,
    save: PropTypes.func.isRequired,
  };

  state = {
    reportTask: new ReportTask(),
  };

  componentWillUnmount() {
    this.reset();
  }

  reset = () => {
    this.setState({
      reportTask: new ReportTask(),
    });
  }

  handleChange = e => {
    let state = this.state;

    if (e.key) {
      state.reportTask[e.key] = e.value;
    }
    this.setState(state);
  };

  save = () => {
    return this.props.save(this.state.reportTask);
  };

  cancel = () => {
    return this.props.cancel();
  };

  render() {
    const templateKeys = Object.keys(reportTemplates);
    const templateOptions = Object.values(reportTemplates);

    return <IBox title='Create new report task'>
      <div className='row'>
        <div className='col-md-12'>
          <Input value={this.state.reportTask.title}
            label='Title'
            dataKey='title'
            editMode={true}
            type='string'
            dataChanged={this.handleChange}
            required={true}/>

          <Select value={this.state.reportTask.template}
            label='Template' options={templateOptions}
            dataKey='template'
            keys={templateKeys}
            editMode={true}
            dataChanged={this.handleChange}
            required={true}/>

          <TextArea label='SQL'
            editMode={true}
            style={{height: 100}}
            dataKey='SQL'
            value={this.state.reportTask.SQL} onChange={this.handleChange}
            required={true}/>
        </div>

      </div>

      <div className='row'>
        <div className='col-md-12'>
          <div className='pull-right'>
            <button className='btn btn-cancel'
              onClick={this.props.cancel}
              style={{marginRight: 15}}>
              Cancel
            </button>
            <button className='btn btn-primary'
              onClick={this.save}>
              Save
            </button>
          </div>
        </div>
      </div>
    </IBox>;
  }
}

export default EditReportRun;
