import PropTypes from 'prop-types';

import React from 'react';

import Dropzone from 'react-dropzone';

import events from '../../lib/events.js';

class FileUpload extends React.Component {
  static propTypes = {
    upload: PropTypes.func,
  };

  state = {files: null};

  onDrop = (files) => {
    if (files.length !== 1) {
      events.publish('error.user', {message: 'Too many files'});
      return;
    }

    this.props.upload(files[0]);
  };

  render() {
    return (
      <Dropzone className='asset-upload' type='file' onDrop={this.onDrop}>
        <p className='hide-tablet'>Drag and drop a file here to upload it</p>
        <p className='show-tablet'> Click to Upload File</p>
      </Dropzone>
    );
  }
}

export default FileUpload;
