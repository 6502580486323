import PropTypes from 'prop-types';

import React from 'react';

// Page object
class PrintFile extends React.Component {
  static propTypes = {
    record: PropTypes.object,
  };

  render() {
    const url = this.props.record.Source.slug + '/' + this.props.record.Source.country;

    return (
      <div className='search-result'>
        <h3><a href={url}>{this.props.record.Source.referenceNumber} - {this.props.record.Source.problemTitle}</a></h3>
        <a href={url} className='search-link'>{url}</a>
        <p>Problem Description: {this.props.record.Source.problemDescription}</p>
        <p>Remedy: {this.props.record.Source.remedyTitle}</p>
      </div>
    );
  }
}

export default PrintFile;
