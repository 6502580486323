import PropTypes from 'prop-types';

import React from 'react';

import Modal from 'components/Modal';

import ErrorList from './errorList.jsx';

// Row object
class UserModal extends React.Component {
  static propTypes = {
    showState: PropTypes.oneOf(['closed','create', 'edit']).isRequired,
    user: PropTypes.object,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    enableSuperAdminControls: PropTypes.bool,
  };

  state = {user: null};

  componentWillReceiveProps(nextProps) {
    // If the modal is being opened...
    if (this.props.showState === 'closed' && nextProps.showState !== 'closed') {
      this.setState({
        user: nextProps.user.clone(),
      });
    }
  }

  changeSuperAdmin = () => {
    if (this.superuser.checked) {
      this.state.user.setRoleForBranch(null, 'sadmin');
    } else {
      this.state.user.removeRoleForBranch(null);
    }
  };

  closeModal = () => {
    let user = this.state.user.clone();

    user.name = this.name.value;
    user.username = this.username.value;
    user.email = this.email.value;
    user.password = this.password.value;

    this.props.onSubmit(user);
  };

  render() {
    let show = this.props.showState !== 'closed';
    let isEditing = this.props.showState === 'edit';

    if (show) {
      return (
        <Modal show={show} onHide={this.props.onCancel} id='userModal'>
          <Modal.Header closeButton>
            <Modal.Title>User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className='form-group required'>
                <label>Name</label>
                <input type='text' className='form-control' ref={r => this.name = r} defaultValue={this.state.user.name} />
              </div>
              <div className='form-group required'>
                <label>Username</label>
                <input type='text' className='form-control' ref={r => this.username = r} defaultValue={this.state.user.username} />
              </div>
              <div className='form-group required'>
                <label>Email</label>
                <input type='email' className='form-control' ref={r => this.email = r} defaultValue={this.state.user.email} />
              </div>
              <div className='form-group required'>
                <label>Password</label>
                <input type='password' className='form-control' ref={r => this.password = r}
                  defaultValue={this.state.user.password}
                  placeholder={isEditing ? '(unchanged)' : ''} />
              </div>
              {this.props.enableSuperAdminControls && isEditing ?
                <div className='form-group'>
                  <label>
                    <input type='checkbox' ref={r => this.superuser = r}
                      defaultChecked={this.state.user.hasRoleForBranch(null, 'sadmin')}
                      onChange={this.changeSuperAdmin} />&nbsp;
                    Super user
                  </label>
                </div> :
                null
              }
            </form>
            <ErrorList />
          </Modal.Body>
          <Modal.Footer>
            <button className='btn btn-secondary' onClick={this.props.onCancel}>Cancel</button>
            <button className='btn btn-primary' onClick={this.closeModal}>Save</button>
          </Modal.Footer>
        </Modal>
      );
    }

    return <div id='userModal'/>;
  }
}

export default UserModal;
