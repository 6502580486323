import qwest from 'qwest';

import moment from 'moment';

let HealthApi = {
  getClusterHealth() {
    return qwest.get('/api/Health/ClusterHealth/').then(this._process);
  },

  getClusterStats() {
    return qwest.get('/api/Health/ClusterStats/').then(this._process).then((response) => {
      response.timestamp = moment(response.timestamp).format('MMMM Do YYYY, h:mm:ss a');
      return response;
    });
  },

  getIndexStats() {
    return qwest.get('/api/Health/IndexStats/').then(this._process);
  },

  getDatabaseStats() {
    return qwest.get('/api/Health/DatabaseStats/').then(this._process);
  },

  getJobStatus() {
    return qwest.get('/api/Health/JobStatus').then(this._process);
  },

  _process(xhr, response) {
    return response;
  },
};

export default HealthApi;
