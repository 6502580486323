import React from "react";

export const truBlueStatus = {
  Active: "ACTIVE",
  Inactive: "INACTIVE",
  Eligible: "ELIGIBLE",
  Ineligible: "INELIGIBLE",
  Opportunity: "OPPORTUNITY"
};

export const reportTypes = {
  regular: 0,
  seasonsUsage: 1,
  chlorineDemand: 2,
  winterClosing: 3,
  springOpening: 4,
  drain: 5,
  optimizerPlus: 6,
  systemConversion: 7,
  qcSeasonsUsage: 8
};

export const KeyParameterTypes = {
  SaturationIndex: 0,
  SaturationCopy: 1,
  TDS: 2,
  Salt: 3,
  pH: 4,
  TotalAlkalinity: 5,
  TotalHardness: 6,
  Optimizer: 7,
  FreeChlorine: 8,
  TotalChlorine: 9,
  CYA: 10,
  Phosphate: 11,
  TotalBromine: 12
};

export const systemTypes = {
  pool: 1,
  spa: 2,
  swimspa: 3
};

export const systemTypeNames = {
  [systemTypes.pool]: "Pool",
  [systemTypes.spa]: "Spa",
  [systemTypes.swimspa]: "Swim Spa"
};

export const systemTypeNamesFormat = formatMessage => {
  return formatMessage
    ? {
        1: formatMessage({ id: "general.pool" }),
        2: formatMessage({ id: "general.spa" }),
        3: formatMessage({ id: "general.swim.spa" })
      }
    : systemTypeNames;
};

export const finishTypes = {
  B: "Pebbled",
  C: "Colored Plaster",
  F: "Fiberglass",
  P: "Painted",
  S: "Stainless Steel",
  U: "Unprinted Vinyl",
  V: "Printed Vinyl",
  W: "White Plaster"
};

export const finishTypesFormat = formatMessage => {
  return !formatMessage
    ? finishTypes
    : {
        A: formatMessage({ id: "general.finish.acrylic" }),
        B: formatMessage({ id: "general.pebbled" }),
        C: formatMessage({ id: "general.colored.plaster" }),
        F: formatMessage({ id: "general.fiberglass" }),
        P: formatMessage({ id: "general.painted" }),
        S: formatMessage({ id: "general.stainless.steel" }),
        U: formatMessage({ id: "general.unprinted.vinyl" }),
        V: formatMessage({ id: "general.printed.vinyl" }),
        W: formatMessage({ id: "general.white.plaster" })
      };
};

export const groundLevels = {
  A: "Above Ground",
  I: "In Ground",
  S: "Splasher"
};

export const groundLevelsFormat = formatMessage => {
  return formatMessage
    ? {
        A: formatMessage({ id: "general.above.ground" }),
        I: formatMessage({ id: "general.in.ground" }),
        S: formatMessage({ id: "general.splasher" })
      }
    : groundLevels;
};

export const unitsWithTranslation = {
  bag: "product.unit.bag",
  bottle: "product.unit.bottle",
  bucket: "product.unit.bucket",
  capful: "product.unit.capful",
  container: "product.unit.container",
  cup: "product.unit.cup",
  each: "product.unit.each",
  other: "product.unit.other",
  tube: "product.unit.tube",
  kit: "product.unit.kit",
  packet: "product.unit.packet",
  tbsp: "product.unit.tablespoon",
  tsp: "product.unit.teaspoon",
  qt: "product.unit.quart"
};

export const getUOMTranslation = (formatMessage, unit) => {
  return unitsWithTranslation[unit]
    ? formatMessage({ id: unitsWithTranslation[unit] })
    : unit;
};
