import moment from 'moment';

'use script';
export const taskTypes = {
  inactiveCustomers: 0,
  reindexCustomers: 1,
  reindexBranches: 2,
  reindexArticles: 3,
  reindexDealers: 4,
  reindexAdminPages: 5,
  reindexPrintFiles: 6,
  databaseExport: 7,
  seasonUsage: 8,
  loyalCustomer: 9,
  pastDueCustomer: 10,
  problemFollowUp: 11,
  winterClosing: 12,
  winterProductReminder: 13,
  truBlueReset: 14,
  tBBackdatingClaims: 15,
  reportsGeneration: 16,
  reportsBackdatingGeneration: 17,
};

const icons = {
  [taskTypes.reindexCustomers]: 'fa-refresh',
  [taskTypes.reindexBranches]: 'fa-refresh',
  [taskTypes.reindexArticles]: 'fa-refresh',
  [taskTypes.reindexDealers]: 'fa-refresh',
  [taskTypes.reindexAdminPages]: 'fa-refresh',
  [taskTypes.reindexPrintFiles]: 'fa-refresh',
  [taskTypes.databaseExport]: 'fa-gg',
};

const defaultIcon = 'fa-gg';

const titles = {
  [taskTypes.inactiveCustomers]: 'Inactive Customers',
  [taskTypes.reindexCustomers]: 'Re-Index Customers',
  [taskTypes.reindexBranches]: 'Re-Index Branches',
  [taskTypes.reindexArticles]: 'Re-Index Articles',
  [taskTypes.reindexDealers]: 'Re-Index Dealers',
  [taskTypes.reindexAdminPages]: 'Re-Index Admin Pages',
  [taskTypes.reindexPrintFiles]: 'Re-Index Print Files',
  [taskTypes.databaseExport]: 'Database Export',
  [taskTypes.seasonUsage]: 'Season\'s Usage Bulk PDF Generation',
  [taskTypes.loyalCustomer]: 'Loyal Customer Job',
  [taskTypes.pastDueCustomer]: 'Past Due customer Job',
  [taskTypes.problemFollowUp]: 'Problem Follow Ups Job',
  [taskTypes.winterClosing]: 'Winter Closing Job',
  [taskTypes.winterProductReminder]: 'Winter Product Reminder Job',
  [taskTypes.truBlueReset]: 'TruBlue Anual Reset Check',
  [taskTypes.tBBackdatingClaims]: 'TruBlue Claims Backdating Job',
  [taskTypes.reportsGeneration]: 'Report Generation Job',
  [taskTypes.reportsBackdatingGeneration]: 'Reports Backdating Generation Job',
};

class Task {
  constructor({
    id = 0,
    type = 0,
    status = 0,
    notificationTitle = '',
    body = '',
    data = '',
    link = '',
    created = moment(),
    completed = null,
  } = {}) {

    let completionStatus = status > 0 ?
    status === 100 && 'completed' || 'progress' :
    status === -100 && 'cancelled' || 'failed';

    this.id = id;
    this.type = type;
    this.status = status;
    this.notificationTitle = notificationTitle;
    this.body = body;
    this.data = data;
    this.link = link;
    this.created = created;
    this.completed = completed;
    this.metaData = {
      title: titles[type],
      icon: icons[type] || defaultIcon,
      completionStatus,
    };
  }

  clone() {
    return new Task(this);
  }

  toApiFormat() {
    return {
      ID: this.id,
      Type: this.type,
      Status: this.status,
      NotificationTitle: this.notificationTitle,
      Body: this.body,
      Data: this.data,
      Link: this.link,
      Created: this.created,
      Completed: this.completed,
    };
  }

  static fromApiFormat(api) {
    return new Task({
      id: api.ID,
      type: api.Type,
      status: api.Status,
      notificationTitle: api.NotificationTitle,
      body: api.Body,
      data: api.Data,
      link: api.Link,
      created: api.Created,
      completed: api.Completed,
    });
  }
}

export default Task;
