import PropTypes from 'prop-types';

import React from 'react';

import events from '../../lib/events.js';

class ErrorList extends React.Component {
  static propTypes = {
    errors: PropTypes.any,
  };

  state = {
    errors: [],
  };

  componentDidMount() {
    this._errorCaught = events.subscribe('error.user', (data) => {
      this.setState((prevState) => ({
        errors: [
          ...prevState.errors,
          {
            message: data.message,
          },
        ],
      }));
    });

    this._errorClear = events.subscribe('error.clear', () => {
      this.setState({errors: []});
    });
  }

  componentWillUnmount() {
    this._errorCaught.remove();
    this._errorClear.remove();
  }

  _errorCaught = null;
  _errorClear = null;

  render() {
    let errors = this.props.errors || this.state.errors;

    if (!errors || !errors.length) {
      return <div/>;
    }
    return (
      <div className='alert alert-danger'>
        <strong>Please resolve the following issues:</strong>
        <ul>
          {errors.map(({message}, index) =>
            <li key={index}>{message}</li>
          )}
        </ul>
      </div>
    );
  }
}

ErrorList.publishError = (message) => {
  events.publish('error.user', {
    message,
  });
};

ErrorList.publishErrors = (messages) => {
  messages.forEach(ErrorList.publishError);
};

ErrorList.clearErrors = () => {
  events.publish('error.clear');
};

export default ErrorList;
