import PropTypes from 'prop-types';

import React from 'react';

const spinners = {
  rotatingPlane() {
    return (
      <div className="sk-spinner sk-spinner-rotating-plane"/>
    );
  },
  doubleBounce() {
    return (
      <div className="sk-spinner sk-spinner-double-bounce">
        <div className="sk-double-bounce1"/>
        <div className="sk-double-bounce2"/>
      </div>
    );
  },
  wave() {
    return (
      <div className="sk-spinner sk-spinner-wave">
        <div className="sk-rect1"/>
        <div className="sk-rect2"/>
        <div className="sk-rect3"/>
        <div className="sk-rect4"/>
        <div className="sk-rect5"/>
      </div>
    );
  },
  wanderingCubes() {
    return (
      <div className="sk-spinner sk-spinner-wandering-cubes">
        <div className="sk-cube1"/>
        <div className="sk-cube2"/>
      </div>
    );
  },
  pulse() {
    return (
      <div className="sk-spinner sk-spinner-pulse"/>
    );
  },
  chasingDots() {
    return (
      <div className="sk-spinner sk-spinner-chasing-dots">
        <div className="sk-dot1"/>
        <div className="sk-dot2"/>
      </div>
    );
  },
  threeBounce() {
    return (
      <div className="sk-spinner sk-spinner-three-bounce">
        <div className="sk-bounce1"/>
        <div className="sk-bounce2"/>
        <div className="sk-bounce3"/>
      </div>
    );
  },
  spinnerCircle() {
    return (
      <div className="sk-spinner sk-spinner-circle">
        <div className="sk-circle1 sk-circle"/>
        <div className="sk-circle2 sk-circle"/>
        <div className="sk-circle3 sk-circle"/>
        <div className="sk-circle4 sk-circle"/>
        <div className="sk-circle5 sk-circle"/>
        <div className="sk-circle6 sk-circle"/>
        <div className="sk-circle7 sk-circle"/>
        <div className="sk-circle8 sk-circle"/>
        <div className="sk-circle9 sk-circle"/>
        <div className="sk-circle10 sk-circle"/>
        <div className="sk-circle11 sk-circle"/>
        <div className="sk-circle12 sk-circle"/>
      </div>
    );
  },
  cubeGrid() {
    return (
      <div className="sk-spinner sk-spinner-cube-grid">
        <div className="sk-cube"/>
        <div className="sk-cube"/>
        <div className="sk-cube"/>
        <div className="sk-cube"/>
        <div className="sk-cube"/>
        <div className="sk-cube"/>
        <div className="sk-cube"/>
        <div className="sk-cube"/>
        <div className="sk-cube"/>
      </div>
    );
  },
  wordpress() {
    return (
      <div className="sk-spinner sk-spinner-wordpress">
        <span className="sk-inner-circle"/>
      </div>
    );
  },
  fadingCircle() {
    return (
      <div className="sk-spinner sk-spinner-fading-circle">
        <div className="sk-circle1 sk-circle"/>
        <div className="sk-circle2 sk-circle"/>
        <div className="sk-circle3 sk-circle"/>
        <div className="sk-circle4 sk-circle"/>
        <div className="sk-circle5 sk-circle"/>
        <div className="sk-circle6 sk-circle"/>
        <div className="sk-circle7 sk-circle"/>
        <div className="sk-circle8 sk-circle"/>
        <div className="sk-circle9 sk-circle"/>
        <div className="sk-circle10 sk-circle"/>
        <div className="sk-circle11 sk-circle"/>
        <div className="sk-circle12 sk-circle"/>
      </div>
    );
  },
};

const Spinner = ({
  type = 'cubeGrid',
}) => {
  const Spin = spinners[type];

  return (
    <Spin />
  );
};

Spinner.propTypes = {
  type: PropTypes.string,
};

export default Spinner;
