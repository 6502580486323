import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {browserHistory} from 'react-router';

// Libs
import {getToken} from '../helpers/tokens.jsx';
import {ToastManager} from '../components/toasts.jsx';
import GlobalSearch from '../components/globalSearch.jsx';
import NavBar from '../components/layout/navBar.jsx';
import Header from '../components/layout/header.jsx';
import events from '../../lib/events.js';

class Layout extends Component {
  static childContextTypes = {
    canViewDashboard: PropTypes.bool.isRequired,
    canViewKpiDashboard: PropTypes.bool.isRequired,
    canEditDealers: PropTypes.bool.isRequired,
    canLoginAs: PropTypes.bool.isRequired,
    canEditSupport: PropTypes.bool.isRequired,
    canViewHealth: PropTypes.bool.isRequired,
    canViewReports: PropTypes.bool.isRequired,
    canEditPoolScores: PropTypes.bool.isRequired,
    canUseNotifications: PropTypes.bool.isRequired,
    canViewPrintfiles: PropTypes.bool.isRequired,
    canEditPrintfiles: PropTypes.bool.isRequired,
    canViewPosDashboard: PropTypes.bool.isRequired,
    canViewPosReport: PropTypes.bool.isRequired,
    canUseTools: PropTypes.bool.isRequired,
    canViewSupport: PropTypes.bool.isRequired,
    canViewProducts: PropTypes.bool.isRequired,
    canEditProducts: PropTypes.bool.isRequired,
    canViewTruBlueDashboard: PropTypes.bool.isRequired,
    canViewTruBlueClaims: PropTypes.bool.isRequired,
    role: PropTypes.string.isRequired,
    token: PropTypes.any.isRequired,
  };

  state = {
    title: 'Loading...',
    navigation: '#',
    user: {},
  };

  getChildContext() {
    let token = getToken();
    let permission = token && token.profile && token.profile.permission ? token.profile.permission : [];

    if (!(permission instanceof Array)) {
      permission = [permission];
    }
    let role = token && token.profile && token.profile.role ? token.profile.role : null;

    return {
      canViewDashboard: permission.includes('alex-admin-view-dashboard'),
      canViewKpiDashboard: permission.includes('alex-admin-full-kpi') || permission.includes('alex-admin-assigned-kpi'),
      canEditDealers: permission.includes('alex-admin-edit-dealers'),
      canLoginAs: permission.includes('alex-admin-login-as'),
      canEditSupport: permission.includes('alex-admin-edit-support'),
      canViewHealth: permission.includes('alex-admin-view-health'),
      canViewReports: permission.includes('alex-admin-full-reports') || permission.includes('alex-admin-assigned-reports'),
      canEditPoolScores: permission.includes('alex-admin-edit-poolscores'),
      canUseNotifications: permission.includes('alex-admin-notifications'),
      canViewPrintfiles: permission.includes('alex-admin-view-printfiles'),
      canEditPrintfiles: permission.includes('alex-admin-edit-printfiles'),
      canViewPosDashboard: permission.includes('alex-admin-view-full-pos-dashboard'),
      canViewPosReport: permission.includes('alex-admin-view-full-pos-reports'),
      canViewProducts: permission.includes('alex-admin-view-products'),
      canEditProducts: permission.includes('alex-admin-edit-products'),
      canUseTools: permission.includes('alex-admin-tools'),
      canViewSupport: permission.includes('alex-admin-view-support'),
      canViewTruBlueDashboard: permission.includes('alex-admin-full-trublue-dashboard'),
      canViewTruBlueClaims: permission.includes('alex-admin-trublue-claims'),
      token,
      role,
    };
  }

  componentDidMount() {
    this._eventHttpError = events.subscribe('http-error', this.onHttpError);


    let apps = [];
    let token = getToken();
    const {profile} = token;
    const region = profile && profile.address && profile.address.country ? profile.address.country : 'US';

    if (token && profile && profile.app) {
      apps = profile.app;
    }

    if (!(apps instanceof Array)) {
      apps = [apps];
    }

    if (window.insertAppSelector) {
      window.insertAppSelector(window.idm, apps, 'alex-admin', null, null, false, true, 'en-US', region, profile.given_name, profile.family_name, profile.email);
    }
  }

  componentWillUnmount() {
    this._eventHttpError.remove();
  }

  onHttpError = ({response}) => {
    if (response.status === 401) {
      console.error('User not authorized for that action.');
      browserHistory.push('/');
    }
  };

  render() {
    return (
      <div id='wrapper'>
        <Header />
        <NavBar />
        <div id='page-wrapper' className='gray-bg dashbard-1'>
          <GlobalSearch />
          <ToastManager>
            {this.props.children}
          </ToastManager>
        </div>
      </div>);
  }
}

Layout.propTypes = {
  children: PropTypes.any,
};

export default Layout;
