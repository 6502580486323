class PoolScoreOutput {
  constructor({
    testID,
    swimmerComfort, waterClarity, surfaceEquipmentProtection,
    expectedSwimmerComfortMatch, expectedWaterClarityMatch, expectedSurfaceEquipmentProtectionMatch,
    swimmerComfortIntermediate, waterClarityIntermediate, surfaceProtectionIntermediate,
  }) {
    this.testID = testID;

    this.swimmerComfort = swimmerComfort;
    this.waterClarity = waterClarity;
    this.surfaceEquipmentProtection = surfaceEquipmentProtection;

    this.expectedSwimmerComfortMatch = expectedSwimmerComfortMatch;
    this.expectedWaterClarityMatch = expectedWaterClarityMatch;
    this.expectedSurfaceEquipmentProtectionMatch = expectedSurfaceEquipmentProtectionMatch;

    this.swimmerComfortIntermediate = swimmerComfortIntermediate || {};
    this.waterClarityIntermediate = waterClarityIntermediate || {};
    this.surfaceProtectionIntermediate = surfaceProtectionIntermediate || {};
  }
}

export default PoolScoreOutput;
