import PropTypes from 'prop-types';

import React from 'react';

import _ from 'lodash';

import events from '../../../lib/events.js';

let stopEvent = e => e.preventDefault();

class EditableTextField extends React.Component {
  static defaultProps = {error: false, value: '', style: {}};

  static propTypes = {
    dataKey: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    editMode: PropTypes.bool.isRequired,
    error: PropTypes.bool,
    maxLength: PropTypes.string,
    nullText: PropTypes.node,
    onBlur: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    unit: PropTypes.string,
    autoFocus: PropTypes.bool,
    validate: PropTypes.func,
    validateMessage: PropTypes.string,
    value: PropTypes.any,
    formatted: PropTypes.node,
    style: PropTypes.object,
  };

  state = {
    correct: true,
    validate: null,
  };

  componentWillMount() {
    if (this.props.validate) {
      this.setState({
        validate: _.debounce(val => {
          if (this.props.validate) {
            if (!this.props.validate(val)) {
              this.setState({correct: false});
              if (val && this.props.validateMessage) { // Only display error message if outside of range
                events.publish('error.user', {message: this.props.validateMessage, key: this.props.dataKey});
              }
            } else {
              if (!this.state.correct) {
                this.setState({correct: true});
                events.publish('cancel.error', {key: this.props.dataKey});
              }
            }
          }
        }, 500),
      });
    }
  }

  componentDidMount() {
    if (this.props.dataKey) {
      let listener = events.subscribe('focus|' + this.props.dataKey, () => {
        this.input.focus();
        this.input.scrollIntoView(false);
      });

      if (this.input && this.props.type === 'number') {
        this.input.addEventListener('mousewheel', stopEvent);
      }
      this.setState({listener});
    }

    // Validate components on first load
    if (this.state.validate && this.props.editMode) {
      this.state.validate(this.input.value);
    }
  }

  componentWillReceiveProps(newProps) {
    // Validate when props change
    if (this.state.validate) {
      this.state.validate(newProps.value);
    }
  }

  componentWillUnmount() {
    if (this.state.listener) {
      this.state.listener.remove();
      if (this.input) {
        this.input.removeEventListener('mousewheel', stopEvent);
      }
    }
  }

  handleChange = (event) => {
    this.props.onChange({key: this.props.dataKey, value: event.target.value});
  };

  render() {
    let content;

    if (this.props.editMode) {
      content =
        <input id={this.props.dataKey} type={this.props.type ? this.props.type : 'text'}
               ref={r => this.input = r}
               maxLength={this.props.maxLength ? this.props.maxLength : null}
               className={'form-control ' + (this.props.error || !this.state.correct ? 'error' : '')}
               onBlur={this.props.onBlur ? this.props.onBlur : null}
               value={this.props.value !== undefined && this.props.value !== null ? this.props.value.toString() : ''}
               placeholder={this.props.placeholder}
               disabled={this.props.disabled}
               autoFocus={this.props.autoFocus}
               onChange={this.handleChange} />
      ;
    } else if (this.props.formatted) {
      content =
        <div className='form-control-static'>
          { this.props.formatted }
        </div>
      ;
    } else {
      content =
        <div className='form-control-static'>
          { !this.props.value && this.props.nullText ? this.props.nullText : this.props.value }
          { this.props.unit && this.props.value ? ' ' + this.props.unit : null }
        </div>
      ;
    }

    return (
      <div className='editable-textfield' style={this.props.style}>
        { content }
      </div>
    );
  }
}

export default EditableTextField;
