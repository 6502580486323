import PropTypes from 'prop-types';

import React from 'react';

class CheckboxList extends React.Component {
  static propTypes = {
    selected: PropTypes.array.isRequired,
    title: PropTypes.string,
    update: PropTypes.func.isRequired,
    values: PropTypes.array.isRequired,
  };

  updateVal = (val) => {
    if (this.props.update) {
      return e => {
        this.props.update(val)(e.target.checked);
      };
    }
    return () => {};
  };

  render() {
    let checkboxes = this.props.values.filter(val => val !== null && val !== undefined && val !== '').map((value, i) => {
      return (
        <div key={i} className='checkbox'>
          <label>
            <input type='checkbox' checked={this.props.selected.indexOf(value) !== -1} onChange={this.updateVal(value)} />
            {value}
          </label>
        </div>
      );
    });

    return (
      <div className='checkbox-list'>
        {checkboxes}
      </div>
    );
  }
}

export default CheckboxList;
