import React from 'react';

import _ from 'lodash';
// Renders any nested object
let ObjectTable = ({obj}) => {
  if (!obj) {
    return <p>Not loaded</p>;
  }

  let next = Object.keys(obj).map((k) => [0, '', k, obj[k]]);
  let rows = [];
  let tab = '\u2002'.repeat(4);
  let branch = '\u2516\u2501\u2002';

  while (next.length) {
    let [depth, prefix, key, value] = next.shift();
    let isObject = (_.isPlainObject(value) || Array.isArray(value)) && !React.isValidElement(value);
    let indent = (depth > 1 ? tab.repeat(depth - 1) : '') + (depth > 0 ? branch : '');

    rows.push(
      <tr key={prefix + key}>
        <td title={'' + prefix + key}><strong>{indent}{key.replace(/(\w)_/g, '$1 ').trim()}</strong></td>
        {(() => {
          if (!isObject) {
            if (value === 'yellow') {
              return <td><button type='button' className='btn btn-warning m-r-sm'>{value}</button></td>;
            }
            if (value === 'red') {
              return <td><button type='button' className='btn btn-danger m-r-sm'>{value}</button></td>;
            }
            return <td><button type='button' className='btn btn-success m-r-sm'>{value}</button></td>;
          } else {
            return <td/>;
          }
        })()}
      </tr>
    );

    if (isObject) {
      next.unshift(...Object.keys(value).map((k) => [depth + 1, prefix + key + '.', k, value[k]]));
    }
  }

  return (
    <table className='table table-striped'>
      <tbody>
        {rows}
      </tbody>
    </table>
  );
};

export default ObjectTable;
