import requirePermissions from '../../helpers/requirePermissions';
import Loader from '../../components/loader.jsx';

import StackedChart from '../../components/charts/stackedChart.jsx';
import IBox from '../../components/IBox.jsx';
import ReportFilter from '../../components/reportFilter.jsx';
import PageHeader from '../../components/pageHeader.jsx';
import reportApi from '../../../api/report.jsx';
import moment from 'moment';
import React from 'react';

const Counts = [
  {key: 'SentCount', title: 'Sent Count'},
  {key: 'ReadCount', title: 'Read Count'},
];

class NotificationsGraphPage extends React.Component {
  state = {
    data: [],
    loaded: true,
    showNoDataMessage: false,
  };

  updateReport = (filters) => {
    this.setState({
      data: [],
      loaded: false,
    });
    reportApi.getNotificationsGraph(
      filters.platinumStatus,
      filters.dsm,
      filters.rm,
      filters.states
    )
    .then(data => {
      if(data && data.length) {
        this.setState({
          showNoDataMessage: false,
          data: data.map(entry => {
            return {
              Created: moment(entry.Created).format('MM/DD/YYYY'),
              SentCount: entry.SentCount,
              ReadCount: entry.ReadCount,
              name: moment(entry.Created).format('MM/DD/YYYY'),
            };
          }),
        });
      } else {
        this.setState({
          showNoDataMessage: true,
        });
      }
      this.setState({loaded: true});
    })
    .catch(e => {
      this.setState({
        loaded: true,
        showNoDataMessage: true,
      });
      console.error(e);
    });
  };

  render() {
    let chart;
    let hasReportData = this.state.data.length > 0;

    if (hasReportData) {
      chart =
        <IBox title='Sent/Read Notifications Over Time Report'>
          <StackedChart
            data={this.state.data}
            fileName='notifications-over-time-report'
            height={800}
            valueFields={Counts.map(c => c.key)}
            valueTitles={Counts.map(c => c.title)}
            type={'column'} />
        </IBox>
      ;
    }
    return (
      <div>
        <PageHeader title='Number of Sent/Read Notifications Over Time' breadcrumbs={[]} />
        <div className='alert alert-warning'>
          <p>Warning! Do not run during peak hours. This report puts a lot of strain on the server.</p>
        </div>
        <ReportFilter onUpdate={this.updateReport} showDates={false}
          buttonText={hasReportData ? 'Update' : 'Run Report'}/>
        <Loader loaded={this.state.loaded}>
          <div>
            {!this.state.showNoDataMessage ? chart :
            <IBox title=''>
              <label className="no-data-report">No Notifications found under those parameters</label>
            </IBox>
            }
          </div>
        </Loader>
      </div>
    );
  }
}

export default requirePermissions('canViewReports')(NotificationsGraphPage);
