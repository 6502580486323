import React from 'react';

/*
 * Balance an array of React components into the specified number of columns.
 * @param  {Array of components} all     An array of components that need balancing.
 * @param  {number} numCols The number of columns to balance the components into.
 * @return {Array of components}         An array of rows balanced into numCols columns.
 */
export function balanceColumns(all, numCols) {
  let output = [];
  let currentRow = [];

  all = filterEmptySelect(all);

  for (let j = 0; j < all.length; ++j) {
    if (all[j] && all[j].props || !all[j] || !all[j].props || all[j].props.condition === undefined) {
      if (all[j].props.condition || all[j].props.condition === undefined) {
        currentRow.push(
          <div className={'col-xs-' + Math.round(12 / numCols)} key={all[0].key + 'row' + output.length + 'field' + currentRow.length}>
            {all[j]}
          </div>
        );
      } else if (!all[j].props.condition && all[j].props.leaveBlank) {
        currentRow.push(
          <div className={'col-xs-' + Math.round(12 / numCols)} key={all[0].key + 'row' + output.length + 'field' + currentRow.length} />
        );
      }
      if (currentRow.length === numCols) {
        output.push(<div className='row' key={all[0].key + 'row' + output.length}>{currentRow}</div>);
        currentRow = [];
      }
    }
  }
  if (currentRow.length > 0) {
    output.push(<div className='row' key={all[0].key + 'row' + all.length}>{currentRow}</div>);
  }

  return output;
}

function filterEmptySelect(all) {
  return all.filter(x => {
    let output = false;
    let isSelect = x && x.type && x.type.displayName && x.type.displayName === 'LayoutSelect';

    if (!isSelect || x.props.options.length > 0) {
      output = true;
    }
    return output;
  });
}

/*
 * Runs the validate function on a list of fields and returns true if all of them pass and false otherwise.
 * @param  {Array of components} fields An arrow of components to test their validation on.
 * @return {boolean}        True if and only if all components pass their validation function or don't have one.
 */
export function validateList(all) {
  let valid = true;

  for (let i = 0; i < all.length; ++i) {
    if (all[i].props.validate && (all[i].props.condition || all[i].props.condition === undefined)) {
      let isValid = all[i].props.validate(all[i].props.value);

      if (!isValid) {
        valid = false;
        break;
      }
    }
  }

  return valid;
}
