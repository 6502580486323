class PoolScoreTest {
  constructor({
    id, temperature, tds, freeChlorine, totalChlorine, hardness, optimizerPlusBorate, iron, copper, cya, pH, mangenese, alkalinity, hazy, cloudy,
    swampy, foaming, slimeMold, greenAlgae, mustardAlgae, blackAlgae, scaleBuildup, waterlineBuildup, ironStaining, copperStaining, organicStaining,
    unknownStaining, totalBromine, biguanide, peroxide, usingMaintClarifier, scaleInhibitor, chemicalBaseTypeID,
    expectedSwimmerComfort, expectedWaterClarity, expectedSurfaceEquipmentProtection, spaAlgae, description, vanishingAct, spaSentry,
  }) {
    this.id = id;
    this.chemicalBaseTypeID = chemicalBaseTypeID;
    this.temperature = temperature;
    this.tds = tds;
    this.freeChlorine = freeChlorine;
    this.totalBromine = totalBromine;
    this.hardness = hardness;
    this.totalChlorine = totalChlorine;
    this.optimizerPlusBorate = optimizerPlusBorate;
    this.iron = iron;
    this.copper = copper;
    this.cya = cya;
    this.pH = pH;
    this.mangenese = mangenese;
    this.alkalinity = alkalinity;
    this.hazy = hazy;
    this.cloudy = cloudy;
    this.swampy = swampy;
    this.foaming = foaming;
    this.slimeMold = slimeMold;
    this.greenAlgae = greenAlgae;
    this.mustardAlgae = mustardAlgae;
    this.blackAlgae = blackAlgae;
    this.scaleBuildup = scaleBuildup;
    this.waterlineBuildup = waterlineBuildup;
    this.ironStaining = ironStaining;
    this.copperStaining = copperStaining;
    this.organicStaining = organicStaining;
    this.unknownStaining = unknownStaining;
    this.biguanide = biguanide;
    this.peroxide = peroxide;
    this.usingMaintClarifier = usingMaintClarifier;
    this.scaleInhibitor = scaleInhibitor;
    this.spaAlgae = spaAlgae;
    this.swampy = swampy;

    this.expectedWaterClarity = expectedWaterClarity;
    this.expectedSwimmerComfort = expectedSwimmerComfort;
    this.expectedSurfaceEquipmentProtection = expectedSurfaceEquipmentProtection;

    this.description = description;

    this.vanishingAct = vanishingAct;
    this.spaSentry = spaSentry;
  }

  clone() {
    return new PoolScoreTest(this);
  }

  toApiFormat() {
    return {
      ID: this.id,
      Temperature: this.temperature,
      TDS: this.tds,
      FreeChlorine: this.freeChlorine,
      TotalBromine: this.totalBromine,
      Hardness: this.hardness,
      TotalChlorine: this.totalChlorine,
      OptimizerPlusBorate: this.optimizerPlusBorate,
      Iron: this.iron,
      Copper: this.copper,
      CYA: this.cya,
      pH: this.pH,
      Mangenese: this.mangenese,
      Alkalinity: this.alkalinity,
      Hazy: this.hazy,
      Cloudy: this.cloudy,
      Swampy: this.swampy,
      Foaming: this.foaming,
      SlimeMold: this.slimeMold,
      GreenAlgae: this.greenAlgae,
      MustardAlgae: this.mustardAlgae,
      BlackAlgae: this.blackAlgae,
      ScaleBuildup: this.scaleBuildup,
      WaterlineBuildup: this.waterlineBuildup,
      IronStaining: this.ironStaining,
      CopperStaining: this.copperStaining,
      OrganicStaining: this.organicStaining,
      UnknownStaining: this.unknownStaining,
      Biguanide: this.biguanide,
      Peroxide: this.peroxide,
      UsingMaintClarifier: this.usingMaintClarifier,
      ScaleInhibitor: this.scaleInhibitor,
      SpaAlgae: this.spaAlgae,

      ChemicalBaseTypeID: this.chemicalBaseTypeID,

      ExpectedWaterClarity: this.expectedWaterClarity,
      ExpectedSwimmerComfort: this.expectedSwimmerComfort,
      ExpectedSurfaceEquipmentProtection: this.expectedSurfaceEquipmentProtection,

      Description: this.description,

      VanishingAct: this.vanishingAct,
      SpaSentry: this.spaSentry,
    };
  }

  static fromApiFormat({
    ID, Temperature, TDS, FreeChlorine, TotalBromine, Hardness, TotalChlorine, OptimizerPlusBorate, Iron, Copper, CYA, pH, Mangenese, Alkalinity, Hazy, Cloudy,
    Swampy, Foaming, SlimeMold, GreenAlgae, MustardAlgae, BlackAlgae, ScaleBuildup, WaterlineBuildup, IronStaining, CopperStaining, OrganicStaining,
    UnknownStaining, Biguanide, Peroxide, UsingMaintClarifier, ScaleInhibitor, ChemicalBaseTypeID, ExpectedWaterClarity, ExpectedSwimmerComfort,
    ExpectedSurfaceEquipmentProtection, SpaAlgae, Description, SpaSentry, VanishingAct,
  }) {
    return new PoolScoreTest({
      id: ID,
      temperature: Temperature,
      tds: TDS,
      freeChlorine: FreeChlorine,
      totalBromine: TotalBromine,
      hardness: Hardness,
      totalChlorine: TotalChlorine,
      optimizerPlusBorate: OptimizerPlusBorate,
      iron: Iron,
      copper: Copper,
      cya: CYA,
      pH: pH,
      mangenese: Mangenese,
      alkalinity: Alkalinity,
      hazy: Hazy,
      cloudy: Cloudy,
      swampy: Swampy,
      foaming: Foaming,
      slimeMold: SlimeMold,
      greenAlgae: GreenAlgae,
      mustardAlgae: MustardAlgae,
      blackAlgae: BlackAlgae,
      scaleBuildup: ScaleBuildup,
      waterlineBuildup: WaterlineBuildup,
      ironStaining: IronStaining,
      copperStaining: CopperStaining,
      organicStaining: OrganicStaining,
      unknownStaining: UnknownStaining,
      biguanide: Biguanide,
      peroxide: Peroxide,
      usingMaintClarifier: UsingMaintClarifier,
      scaleInhibitor: ScaleInhibitor,
      spaAlgae: SpaAlgae,

      chemicalBaseTypeID: ChemicalBaseTypeID,

      expectedWaterClarity: ExpectedWaterClarity,
      expectedSwimmerComfort: ExpectedSwimmerComfort,
      expectedSurfaceEquipmentProtection: ExpectedSurfaceEquipmentProtection,

      description: Description,

      vanishingAct: VanishingAct,
      spaSentry: SpaSentry,
    });
  }
}

export default PoolScoreTest;
