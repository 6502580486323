import qwest from 'qwest';

let PrintFileAPI = {

  getFiles() {
    return qwest.post('/api/PrintFile/Index').then(this._process);
  },

  getFile(id, country) {
    return qwest.post('/api/PrintFile/Index', {id: id, country: country}).then(this._process);
  },

  create(file){
    return qwest.post('/api/PrintFile/Create', {file: file}).then(this._process);
  },

  save(file) {
    return qwest.post('/api/PrintFile/Save', {file: file}).then(this._process);
  },

  getLinkedFiles(problemID, remedyID, country) {
    return qwest.get(`/api/PrintFile/GetLinkedItems?problemID=${problemID}&remedyID=${remedyID}&country=${country}`).then(this._process);
  },

  getExportJSON(token) {
    window.open(`/api/PrintFile/Export?token=${token}`);
  },

  getExportCSV(token) {
    window.open(`/api/PrintFile/ExportToCSV?token=${token}`);
  },

  import(data) {
    return qwest.post('/api/PrintFile/Import', {
      json: data,
    })
      .then((xhr, res) => res);
  },

  _process(xhr, response) {
    if (response.success) {
      return response;
    }
    throw response;
  },

  toggleFlag(id) {
    return qwest.post('/api/PrintFile/ToggleFlag', {printFileID: id}).then(this._process);
  },

};

export default PrintFileAPI;
