import moment from 'moment';
import branchApi from '../../api/branch.jsx';
import BranchModal from '../components/branchModal.jsx';
import dealerApi from '../../api/dealer.jsx';
import DealerInfoModal from '../components/dealerInfoModal.jsx';
import dealerUserApi from '../../api/dealerUser.jsx';
import ErrorList from '../components/errorList.jsx';
import PageHeader from '../components/pageHeader.jsx';
import PropTypes from 'prop-types';
import React from 'react';
import ToolApi from '../../api/tools.jsx';
import UserModal from '../components/userModal.jsx';
import {connectToToasts, TOAST_STATES} from '../components/toasts.jsx';
import {countriesNames} from '../helpers/constants.jsx';
import {getToken} from '../helpers/tokens.jsx';
import Modal from 'components/Modal';
import EditableCheckbox from 'components/form/editableCheckbox.jsx';

// Page object
class PageDealerDetails extends React.Component {
  static contextTypes = {
    canEditDealers: PropTypes.bool.isRequired,
    canLoginAs: PropTypes.bool.isRequired,
    canUseTools: PropTypes.bool.isRequired,
  };

  static propTypes = {
    createToast: PropTypes.func.isRequired,
  };

  state = {
    showDealerInfoModalState: 'closed',
    dealer: null,
    dealerUsers: [],
    dealerBranches: [],
    selectedUser: null,
    selectedBranch: null,
    selectedBranchUsers: [],
    showBranchModalState: 'closed',
    showUserModalState: 'closed',
    showCloseBranchModal: false,
    ssoDeletedBranchSanityCheck: false,
  };

  componentDidMount() {
    this.refreshDealer();
  }

  onCancelBranchModal = () => {
    this.setState({showBranchModalState: 'closed', selectedBranch: null, selectedBranchUsers: null});
  };

  onCancelDealerInfoModal = () => {
    this.setState({showDealerInfoModalState: 'closed'});
    ErrorList.clearErrors();
  };

  onCancelUserModal = () => {
    this.setState({showUserModalState: 'closed', selectedUser: null});
  };

  onSubmitBranchModal = (branch) => {
    let promise;

    if (this.state.showBranchModalState === 'edit') {
      promise = branchApi.saveBranch(branch);
    } else {
      console.error(`Unknown showBranchModalState when submitting modal: ${this.state.showBranchModalState}`);
      return;
    }

    let savedUsers = new Set();

    branch.relatedDealerUsers(this.state.dealerUsers || []).forEach((dealerUser) => {
      savedUsers.add(dealerUser.id);
      promise = Promise.all([promise, dealerUserApi.saveDealerUser(dealerUser)]);
    });
    this.state.selectedBranchUsers.forEach((dealerUser) => {
      if (savedUsers.has(dealerUser.id)) {
        return;
      }
      promise = Promise.all([promise, dealerUserApi.saveDealerUser(dealerUser)]);
    });

    this.props.createToast({
      id: 'saving-branch',
      message: 'Saving...',
      ttl: 4000,
      state: TOAST_STATES.INFO,
    });

    ErrorList.clearErrors();
    promise.then(() => {
      this.refreshBranches();

      this.props.createToast({
        id: 'saving-branch-success',
        message: 'Saved branch.',
        ttl: 4000,
        state: TOAST_STATES.SUCCESS,
        clears: 'saving-branch',
      });

      this.setState({
        showBranchModalState: 'closed',
        selectedBranch: null,
        selectedBranchUsers: null,
      });
    }).catch((...args) => {
      this.handleErrors(...args);
      this.props.createToast({
        id: 'saving-branch-failure',
        message: 'Failed to save branch.',
        ttl: 6000,
        state: TOAST_STATES.ERROR,
        clears: 'saving-branch',
      });
    });
  };

  onSubmitDealerInfoModal = (newDealer) => {
    ErrorList.clearErrors();

    this.props.createToast({
      id: 'saving-dealer',
      message: 'Saving...',
      ttl: 4000,
      state: TOAST_STATES.INFO,
    });

    dealerApi.saveDealer(newDealer)
    .then(savedDealer => {
      this.setState({
        showDealerInfoModalState: 'closed',
        dealer: savedDealer,
      });

      this.props.createToast({
        id: 'saving-dealer-success',
        message: 'Saved dealer.',
        ttl: 4000,
        state: TOAST_STATES.SUCCESS,
        clears: 'saving-dealer',
      });
    }).catch((...args) => {
      this.handleErrors(...args);
      this.props.createToast({
        id: 'saving-dealer-failure',
        message: 'Failed to save dealer',
        ttl: 6000,
        state: TOAST_STATES.ERROR,
        clears: 'saving-dealer',
      });
    });
  };

  onSubmitUserModal = (user) => {
    let promise;

    if (this.state.showUserModalState === 'edit') {
      promise = dealerUserApi.saveDealerUser(user);
    } else {
      console.error(`Unknown showUserModalState when submitting modal: ${this.state.showUserModalState}`);
      return;
    }

    ErrorList.clearErrors();
    promise.then(() => {
      this.refreshDealerUsers();

      this.setState({
        showUserModalState: 'closed',
        selectedUser: null,
      });
    }).catch(this.handleErrors);
  };

  deleteUser = (user) => {
    dealerUserApi.deleteDealerUser(user).then(() => {
      let dealerUsers = this.state.dealerUsers;
      let index = dealerUsers.findIndex((u) => u.id === user.id);

      if (index > -1) {
        this.setState({
          dealerUsers: [
            ...dealerUsers.slice(0, index),
            ...dealerUsers.slice(index + 1),
          ],
        });
      } else {
        console.warn('DealerUser was removed from dealerUsers before it was finished being deleted');
      }
    }).catch(this.handleErrors);
  };

  editBranch = (branch) => {
    branchApi.getBranch(branch.id).then((fullBranch) => {
      this.setState({showBranchModalState: 'edit', selectedBranch: fullBranch, selectedBranchUsers: fullBranch.relatedDealerUsers(this.state.dealerUsers)});
    }).catch(this.handleErrors);
  };

  editDealerInfo = () => {
    this.setState({showDealerInfoModalState: 'edit'});
    ErrorList.clearErrors();
  };

  editUser = (user) => {
    dealerUserApi.getDealerUser(user.id).then((dealerUser) => {
      this.setState({showUserModalState: 'edit', selectedUser: dealerUser});
    }).catch(this.handleErrors);
  };

  handleErrors = (error) => {
    ErrorList.publishErrors(error.message.split('\n'));
  };

  refreshBranches = () => {
    return branchApi.getBranches(this.state.dealer.id).then(branches => {
      this.setState({dealerBranches: branches});
    }).catch(this.handleErrors);
  };

  refreshDealer = () => {
    return dealerApi.getDealer(this.props.params.dealerId).then(dealer => {
      this.setState({dealer: dealer});
      return this.refreshDealerUsers().then(() => {
        return this.refreshBranches().catch(this.handleErrors);
      }).catch(this.handleErrors);
    }).catch(this.handleErrors);
  };

  refreshDealerUsers = () => {
    return dealerUserApi.getDealerUsers(this.state.dealer.id).then(dealerUsers => {
      this.setState({dealerUsers: dealerUsers});
    }).catch(this.handleErrors);
  };

  reindex = () => {
    if (!this.context.canUseTools || !this.state.dealer || !this.state.dealer.id) {
      return;
    }

    ToolApi.reindexCustomersInDealer(this.state.dealer.id)
      .then(x => {
        this.props.createToast({
          id: 'reindex-dealers-customers',
          message: 'Started reindex.',
          ttl: 4000,
          state: TOAST_STATES.SUCCESS,
        });
      })
      .catch(err => {
        console.error(err);
        this.props.createToast({
          id: 'reindex-dealers-customers-failure',
          message: 'Couldn\' start reindex.',
          ttl: 6000,
          state: TOAST_STATES.ERROR,
        });
      });
  };

  closeBranchStore = () => {
    const {selectedBranch} = this.state;

    this.props.createToast({
      id: 'closing-branch',
      message: `Closing branch: ${selectedBranch.name}, inactivating all customers...`,
      ttl: 4000,
      state: TOAST_STATES.INFO,
    });

    ErrorList.clearErrors();
    branchApi.deleteBranch(selectedBranch).then(() => {
      this.refreshBranches();
      this.props.createToast({
        id: 'closing-branch-success',
        message: `Closed branch ${selectedBranch.name} successfully.`,
        ttl: 4000,
        state: TOAST_STATES.SUCCESS,
        clears: 'closing-branch',
      });
      this.resetCloseBranch();

    }).catch((...args) => {
      this.handleErrors(...args);
      this.props.createToast({
        id: 'closing-branch-failure',
        message: `Failed to close branch ${selectedBranch.name}.`,
        ttl: 6000,
        state: TOAST_STATES.ERROR,
        clears: 'closing-branch',
      });
      this.resetCloseBranch();
    });
  };

  renderBranches = (branches) => {
    return branches.map((branch, index) => {
      return (
        <div className='panel panel-success' key={index}>
          <div className='panel-heading'>
            <span>{branch.name}</span>
            <span className='modify-buttons'>
              <button className='btn btn-xs btn-secondary' onClick={this.editBranch.bind(this, branch)}>
                <i className='fa fa-pencil'/>
              </button>
            </span>
          </div>
          <div className='panel-body'>
            <div className='row'>
              <div className='col-sm-6'>
                <strong>Admin Users</strong>
                <div className='admin-table-container'>
                  <table className='table table-striped'>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                      </tr>
                    </thead>
                    <tbody>
                      {branch.relatedDealerUsers(this.state.dealerUsers || []).map((user, userIndex) => {
                        return (
                          <tr key={userIndex}>
                            <td>{user.name}</td>
                            <td>{user.email}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className='col-sm-4'>
                <strong>Address</strong>
                <div>
                  {branch.address1}
                </div>
                <div>
                  {branch.address2}
                </div>
                <div>
                  {branch.city}
                </div>
                <div>
                  {branch.postalCode}
                </div>
                <div>
                  {branch.state}
                </div>
              </div>

            </div>
            { this.context.canEditDealers ? <div className="row">
              <div className='col-sm-2 pull-right'>
                <button type="button" className="btn btn-primary pull-right" onClick={() => this.toggleCloseBranchModal(branch)}>Close store</button>
              </div>
            </div> : null}
          </div>
        </div>
      );
    });
  };

  renderBranchesList = () => {
    return (
      <div>
        <h3>Branches</h3>
        <div className='branches-list'>
          {this.renderBranches(this.state.dealerBranches)}
        </div>
      </div>
    );
  };

  renderDealerInfo = () => {
    const {dealer} = this.state;

    return (
      <div className='dealer-info'>
        <h3 className='bottom-spacing'>
          Dealer Info
          {this.context.canEditDealers ? <button className='btn btn-xs btn-default edit-dealer-info-btn' onClick={this.editDealerInfo}>
            <i className='fa fa-pencil'/>
          </button> : null}
          {this.context.canUseTools ? <button className='btn btn-primary pull-right'
            onClick={this.reindex}><i className='fa fa-database' />&nbsp;Reindex
          </button> : null}
        </h3>
        <div className='row bottom-spacing'>
          <div className='col-sm-3'><strong>Name:</strong><br />{dealer.name}</div>
          <div className='col-sm-3'><strong>Sold To:</strong><br />{dealer.soldTo}</div>
          <div className='col-sm-3'><strong>DSM Name:</strong><br />{dealer.dsmName}</div>
          <div className='col-sm-3'><strong>DSM Number:</strong><br />{dealer.dsmNumber}</div>
        </div>
        <div className='row bottom-spacing'>
          <div className='col-sm-3'><strong>RM Name:</strong><br />{dealer.rmName}</div>
          <div className='col-sm-3'><strong>RM Number:</strong><br />{dealer.rmNumber}</div>
          <div className='col-sm-3'><strong>Administrator Name:</strong><br />{dealer.ownerName}</div>
          <div className='col-sm-3'><strong>Administrator Phone:</strong><br />{dealer.ownerPhone}</div>
        </div>
        <div className='row bottom-spacing'>
          <div className='col-sm-3'><strong>Administrator Email:</strong><br />{dealer.ownerEmail}</div>
          <div className='col-sm-3'><strong>Fax:</strong><br />{dealer.fax}</div>
          <div className='col-sm-3'><strong>Address Line 1:</strong><br />{dealer.address1}</div>
          <div className='col-sm-3'><strong>Address Line 2:</strong><br />{dealer.address2}</div>
        </div>
        <div className='row bottom-spacing' >
          <div className='col-sm-3'><strong>City:</strong><br />{dealer.city}</div>
          <div className='col-sm-3'><strong>State:</strong><br />{dealer.state}</div>
          <div className='col-sm-3'><strong>Zip:</strong><br />{dealer.zip}</div>
          <div className='col-sm-3'><strong>Country:</strong><br />{countriesNames[dealer.country]}</div>
        </div>
        <div className='row bottom-spacing'>
          <div className='col-sm-3'><strong>Platinum Status:</strong><br />{dealer.platinumStatus}</div>
          {/* ALEX-2590 - Turn off beta flag for new rules engine */ }
          {/* <div className='col-sm-3'><strong>Opted into beta:</strong><br />{dealer.beta ? 'Yes' : 'No'}</div> */}
          <div className='col-sm-3'><strong>Tru•Blue Enrollment Date:</strong><br />{dealer.truBlueActive ? dealer.truBlueActive.format('ll') : 'N/A'}</div>
          <div className='col-sm-3'><strong>Tru•Blue Signed Up By:</strong><br />{dealer.truBlueSignedUp || ''}</div>
        </div>
        <div className='row bottom-spacing'>
          <div className='col-sm-3'><strong>Show Water Quality Score</strong><br />{dealer.poolScores ? 'Yes' : 'No'}</div>
          <div className='col-sm-3'><strong>Show New Key Parameters Section</strong><br />{dealer.keyParametersRanges ? 'Yes' : 'No'}</div>
        </div>
        <div className='row bottom-spacing'>
          <div className='col-sm-3'><strong>Test Connect</strong><br />{dealer.testConnectVersion || 'None'}</div>
          <div className='col-sm-3'><strong>Using SmartLab</strong><br />{dealer.usingSmartLab &&
          moment(dealer.usingSmartLab).isAfter(moment().add(-60, 'days')) ? 'Yes' : 'No'}</div>
          <div className='col-sm-3'><strong>Using AccuScan</strong><br />{dealer.usingAccuScan &&
          moment(dealer.usingAccuScan).isAfter(moment().add(-60, 'days')) ? 'Yes' : 'No'}</div>
        </div>
        <div className='row bottom-spacing'>
          <div className='col-sm-3'><strong>Is Defunct?</strong><br />{dealer.isDefunct ? 'Yes' : 'No'}</div>
          <div className='col-sm-3'><strong>Is Demo?</strong><br />{dealer.isDemo ? 'Yes' : 'No'}</div>
        </div>
      </div>
    );
  };

  renderDealerUsers = (dealerUsers) => {
    return dealerUsers.map((user, index) => {
      return (
        <tr key={index}>
          <td>{user.name}</td>
          <td>{user.email}</td>
          <td className='modify-buttons'>
            {(() => {
              if (!this.context.canLoginAs) {
                return null;
              }
              return <a href={'/api/User/LoginAs?token=' + getToken()['id_token'] + '&id=' + user.id} target='_NEW' className='btn btn-xs btn-success'>
                <i className='fa fa-sign-in'/>
              </a>;
            })()}
          </td>
        </tr>
      );
    });
  };

  renderDealerUsersList = () => {
    return (
      <div>
        <h3>Dealer Users</h3>
        <table className='table table-striped user-table'>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th/>
            </tr>
          </thead>
          <tbody>
            {this.renderDealerUsers(this.state.dealerUsers)}
          </tbody>
        </table>
        <div>
        </div>
      </div>
    );
  };

  toggleCloseBranchModal = (branch) => {
    this.setState({
      showCloseBranchModal: !!branch,
      selectedBranch: branch,
    });
  }

  resetCloseBranch = () => {
    this.setState({
      showCloseBranchModal: false,
      selectedBranch: null,
      ssoDeletedBranchSanityCheck: false,
    });
  }

  getBranchDeleteConfirmationModal = () => {
    const {selectedBranch, showCloseBranchModal, ssoDeletedBranchSanityCheck} = this.state;

    if(!this.context.canEditDealers || !showCloseBranchModal || !selectedBranch) {
      return <div id='branch-confirm-modal'/>;
    }
    return (
      <Modal show={showCloseBranchModal} onHide={this.resetCloseBranch} id='branch-confirm-modal'>
        <Modal.Header closeButton>
          <Modal.Title>Confirm <b>{selectedBranch.name}</b> branch closing</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='alert alert-warning'><b>* Please make sure you have removed this branch from SSO admin first or deletion will not work correctly</b></div>
          <div>The following actions will be performed as part of closing store</div>
          <ul>
            <li>Hide store (Ship To location) from Alex store preferences</li>
            <li>Inactive customers for store that is being closed</li>
            <li>Delete water testers from inactived store</li>
          </ul>
          <EditableCheckbox
            checked={ssoDeletedBranchSanityCheck}
            dataKey={'ssoDeletedBranchSanityCheck'}
            editMode={true}
            label={'Please confirm you have removed this branch from SSO User admin first in order to continue '}
            onChange={({value}) => {
              this.setState({ssoDeletedBranchSanityCheck: value});
            }}
            value={ssoDeletedBranchSanityCheck}
        />
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-danger' type='submit' onClick={this.resetCloseBranch}>Cancel</button>
          <button className='btn btn-primary' disabled={!ssoDeletedBranchSanityCheck} type='submit' onClick={this.closeBranchStore}>Confirm</button>
        </Modal.Footer>
      </Modal>);
  }

  render() {
    if (!this.state.dealer) {
      return <div>Loading...</div>;
    }

    return (
      <div>
        <PageHeader title='Dealer Info' icon='fa fa-truck' breadcrumbs={[{title: 'Dealer', path: '/dealers'}, {title: 'Dealer', path: '/dealer'}]} />
        <div id='page-dealer-details'>
          {this.renderDealerInfo()}
          {this.renderDealerUsersList()}
          {this.renderBranchesList()}
          {this.getBranchDeleteConfirmationModal()}

        </div>

        <BranchModal showState={this.state.showBranchModalState} branch={this.state.selectedBranch}
                     onCancel={this.onCancelBranchModal} onSubmit={this.onSubmitBranchModal} dealerUsers={this.state.dealerUsers}/>

        <UserModal showState={this.state.showUserModalState} user={this.state.selectedUser}
                   onCancel={this.onCancelUserModal} onSubmit={this.onSubmitUserModal} enableSuperAdminControls={true} />

        <DealerInfoModal showState={this.state.showDealerInfoModalState} dealer={this.state.dealer}
                   onCancel={this.onCancelDealerInfoModal} onSubmit={this.onSubmitDealerInfoModal}/>

      </div>
    );
  }
}

export default connectToToasts(PageDealerDetails);
