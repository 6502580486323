import React from 'react';

import toolsApi from '../../../api/tools.jsx';

// Page object
class ReindexPrintFiles extends React.Component {
  state = {
    status: 'load',
    percent: 0,
  };

  _checkStatus = null;

  reindex = () => {
    this.setState({
      status: 'run',
      percent: 0,
    });

    toolsApi.reindexPrintFiles();
  };

  render() {
    let action = '';

    if (this.state.status === 'load') {
      action = <div>
        <h4 className='media-heading'>Index</h4>
        <div className='tc'>
          <button className='btn btn-primary' onClick={this.reindex}>Reindex</button>
        </div>
      </div>;
    } else {
      action = <div>
        <h4 className='media-heading'>Indexing...</h4>
      </div>;
    }

    return (
      <div>
        <div className='widget-head-color-box navy-bg p-lg text-center'>
          <div className='m-b-md'>
            <h2 className='font-bold no-margins'>Reindex Print Files</h2>
            <small>This will take 1-2 minutes</small>
          </div>
          <i className='fa fa-cloud fa-5x' />
        </div>
        <div className='widget-text-box'>
          {action}
        </div>
      </div>
    );
  }
}

export default ReindexPrintFiles;
