import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
class PathLogger extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    currentStep: PropTypes.number,
    rulesMap: PropTypes.object,
    runResults: PropTypes.object,
    update: PropTypes.func.isRequired,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.currentStep !== this.props.currentStep) {
      // Check if currently visible and if not scroll into view
      const containerBox = this.container.getBoundingClientRect();
      const boundingBox = this.active.getBoundingClientRect();

      const topVisible = boundingBox.top - containerBox.top > 0;
      const bottomVisible = boundingBox.bottom - containerBox.bottom < 0;
      const isVisible = topVisible && bottomVisible;

      if (!isVisible) {
        // Only stick to top if scrolling up
        this.active.scrollIntoView(prevProps.currentStep > this.props.currentStep);
      }
    }
  }

  render() {
    const {
      currentStep,
      rulesMap,
      runResults: {path},
      update,
    } = this.props;

    return <div className={classNames('path-logger', 'path-logger--dark')}>
      <div className='path-logger__header component-header'>
        <h2>Steps</h2>
      </div>
      <ul className='path-logger__list' ref={r => this.container = r}>
        {
          path.map((step, index) => {
            const id = step.id;
            const rule = rulesMap.find(x => x.id === id);
            const description = rule && rule.description;

            const isActive = currentStep === index;

            return <li className={`path-logger__list-item ${currentStep === index ? 'path-logger__list-item--selected' : ''}`}
                       ref={r => isActive ? this.active = r : undefined}
                       key={index}
                       onClick={() => update({currentStep: index})}>
              <div className='path-logger__list-item-wrapper'>
                <div className='path-logger__list-item-num'>{index + 1}.</div>
                <div className='path-logger__list-item-id'>{id}: </div>
                {description && <div className='path-logger__list-item-description'>{description}</div>}
              </div>
              {isActive && step && step.notes ?
                <div className='path-logger__list-item-notes'>
                  {step.notes.map((x, i) => <p className='path-logger__list-item-note' key={i}>{i}) {x}</p>)}
                </div> :
                null}
            </li>;
          })
        }
      </ul>
    </div>;
  }
}

export default PathLogger;
