import PropTypes from 'prop-types';

import React from 'react';

import healthApi from '../../../api/health.jsx';

import IBoxes from '../iBoxes.jsx';

class DatabaseStatistics extends React.Component {
  static propTypes = {
    columns: PropTypes.number,
  };

  state = {
    databaseStats: null,
    metadataVersion: null,
  };

  componentDidMount() {

    healthApi.getDatabaseStats().then((databaseStats) => {
      let metadataVersion = databaseStats.metadata_version;

      delete databaseStats.metadata_version;
      this.setState({databaseStats, metadataVersion});
    });
  }

  partitionObjectRows = (object, columns) => {
    const keys = Object.keys(object);
    const rowsSize = Math.ceil(keys.length / columns);

    const rows = [];

    for (let i = 0; i < rowsSize; i++) {
      rows.push(keys.slice(i * columns, (i + 1) * columns).map((key) => ({
        label: key.replace(/(\w)_/g, '$1 ').trim(),
        value: object[key],
      })));
    }

    return rows;
  };

  toggle = (event) => {
    event.preventDefault();
    this.setState({collapsed: !this.state.collapsed});
  };

  render() {
    const databaseStats = this.state.databaseStats || {};
    const columns = this.props.columns || 6;
    const databaseStatsRows = this.partitionObjectRows(databaseStats, columns);

    return (
      <IBoxes title={<span>Database Statistics <span className='label label-secondary pull-right'>Database Version: {this.state.metadataVersion}</span></span>}>
        {databaseStatsRows.map((row, i) =>
          <div className='row' key={i}>
            {row.map(({label, value}) =>
              <div key={label} className={'col-sm-' + 12 / columns}>
                <span className='label label-success pull-right'>{value}</span>
                {label}
              </div>
            )}
          </div>
        )}
      </IBoxes>
    );
  }
}

export default DatabaseStatistics;
