import {getProductNames} from 'api/product';
import {cacheAndInjectData} from 'helpers/cacheAndInjectData';
import React from 'react';
import {TabContainer, TabItem} from 'components/tabs';
import TestInput from 'page/Testing/TestInput';
import {browserHistory} from 'react-router';
import IBox from 'components/iBox';
import PropTypes from 'prop-types';
import {loadTestDataFromSystem, loadTestDataFromAnalysis, runTestAnalysis, saveTest} from 'api/testing';
import Trace from 'page/Testing/Trace';
import {connectToToasts, TOAST_STATES} from 'components/toasts';
import ReportWrapper from '../../../../../Canopy/Javascript/ui/components/ReportWrapper';
import ReportViewer from '../../../../../ReportViewer/layout.jsx';
import RawResult from 'page/Testing/RawResult';
import DiffResults from './Diff';
import JsonInput from './JsonInput';
import {get} from 'helpers/objectHelpers';

class View extends React.Component {
  static propTypes = {
    params: PropTypes.object.isRequired,
    createToast: PropTypes.func.isRequired,
    productNames: PropTypes.array,
  };

  state = {
    ran: false,
    saved: false,
    loaded: false,
    dealer: {},
    fromApi: false,
    customer: {},
    analysis: {},
    pool: {},
    expected: [],
    logId: '',
    reportType: 0,
    raw: {
      alexMill: [],
      warden: [],
    },
  };

  componentWillMount() {
    if (this.props.params && this.props.params.id && this.props.params.type === 'system') {
      console.log('Loading from system: ' + this.props.params.id);

      loadTestDataFromSystem(this.props.params.id)
        .then(data => {
          let d = Object.keys(data)
            .filter(x => data[x] !== null)
            .reduce((acc, x) => {
              acc[x] = data[x];
              return acc;
            }, {});


          this.setState(Object.assign({loaded: true, fromApi: true}, d));
        })
        .catch(err => {
          console.error(err);
        });
    } else if (this.props.params && this.props.params.id && this.props.params.type === 'analysis') {
      console.log('Loading from analysis: ' + this.props.params.id);

      loadTestDataFromAnalysis(this.props.params.id)
        .then(data => {
          let d = Object.keys(data)
            .filter(x => data[x] !== null)
            .reduce((acc, x) => {
              acc[x] = data[x];
              return acc;
            }, {});


          this.setState(Object.assign({loaded: true, fromApi: true}, d));
        })
        .catch(err => {
          console.error(err);
        });
    } else {
      this.setState({loaded: true});
    }
  }

  save = expectedResult => () => {
    const {dealer, customer, analysis, pool, name, description, reportType} = this.state;

    if (!name || !description) {
      this.props.createToast({
        id: 'testing.validation',
        message: 'Please add a test name and description.',
        ttl: 4000,
        state: TOAST_STATES.WARNING,
      });

      return;
    }

    saveTest({
      dealer,
      customer,
      analysis,
      pool,
      expectedResult,
      name,
      description,
      reportType,
    })
      .then(() => {
        browserHistory.push('/testing');

        this.props.createToast({
          id: 'test.save',
          message: 'Test saved.',
          ttl: 4000,
          state: TOAST_STATES.SUCCESS,
        });
      })
      .catch(err => {
        console.error(err);

        this.props.createToast({
          id: 'test.save',
          message: 'Failed to save test.',
          ttl: 4000,
          state: TOAST_STATES.ERROR,
        });
      });
  };

  isCanada = (dealer = this.state.dealer) => dealer && dealer.country && dealer.country === 'CA';

  search = () => {
    browserHistory.push('/testing/search');
  };

  run = () => {
    let dealer, customer, pool, analysis, name, description, reportType;

    if (this.testInput) {
      ({dealer, customer, pool, analysis, name, description, reportType} = this.testInput.getUnderlying().data);
    } else {
      ({dealer, customer, pool, analysis, name, description, reportType} = this.state);
    }

    runTestAnalysis({dealer, customer, pool, analysis, reportType})
      .then(({alexMill = {}, warden, raw}) => {
        this.setState({
          ran: true,
          alexMillAnalysisObj: this.isCanada(dealer) ? null : alexMill.analysisObj,
          wardenAnalysisObj: warden.analysisObj,
          raw,
          dealer,
          customer,
          pool,
          analysis,
          name,
          description,
          reportType,
          fromApi: false,
          logId: get(warden, 'wardenOutput', 'id'),
        }, () => {
          this.tabs.select('printouts')();
        });
      })
      .catch(err => {
        console.error(err);

        this.props.createToast({
          id: 'run-analysis-error',
          message: 'Failed to run analysis.',
          ttl: 4000,
        });
      });
  };

  render() {
    return <div className='testing-view'>
      <TabContainer ibox
                    ref={r => this.tabs = r}
                    right={<button className='btn btn-primary' disabled={!this.state.loaded}
                                   onClick={this.run}>Preview</button>}>

        <TabItem tabKey='input' label='Input'>
          <IBox title='Input'>
            <TestInput {...this.state} ref={r => this.testInput = r} fromApi={this.state.fromApi} />
          </IBox>
        </TabItem>

        <TabItem tabKey='printouts' label='Printouts' disabled={!this.state.ran}>
          <div className='row'>
            {!this.isCanada() ? <div className='col-md-6'>
              <IBox title='Alex Mill Rules' rightTitle={<button className='btn btn-primary'
                                                                   onClick={this.save(this.state.raw.alexMill)}>Use As Expected</button>}>
                <ReportWrapper><ReportViewer showControls={false}
                                             data={this.state.alexMillAnalysisObj}/></ReportWrapper>
              </IBox>
            </div> : null}
            <div className={this.isCanada() ? 'col-md-12' : 'col-md-6'}>
              <IBox title='Alex Pro Rules' rightTitle={<button className='btn btn-primary'
                                                                  onClick={this.save(this.state.raw.warden)}>Use As Expected</button>}>
                <ReportWrapper><ReportViewer showControls={false} data={this.state.wardenAnalysisObj}/></ReportWrapper>
              </IBox>
            </div>
          </div>
        </TabItem>

        <TabItem tabKey='raw' label='Raw' disabled={!this.state.ran}>
          <div className='row'>
            {!this.isCanada() ? <div className='col-md-6'>
              <IBox title='Alex Mill Raw Results'>
                {this.state.raw.alexMill.map((x, i) => <RawResult productNames={this.props.productNames}
                                                                  key={i + x.printFile} {...x} />)}
              </IBox>
            </div> : null}
            <div className={this.isCanada() ? 'col-md-12' : 'col-md-6'}>
              <IBox title='Alex Pro Raw Results'>
                {this.state.raw.warden.map((x, i) => <RawResult productNames={this.props.productNames}
                                                                key={i + x.printFile} {...x} />)}
              </IBox>
            </div>
          </div>
        </TabItem>

        {!this.isCanada() ? <TabItem tabKey='diff' label='Diff' disabled={!this.state.ran}>
          <IBox title='Diff'>
            <DiffResults alexMill={this.state.raw.alexMill} warden={this.state.raw.warden} productNames={this.props.productNames} />
          </IBox>
        </TabItem> : null}

        <TabItem tabKey='expected' label='Expected' disabled={!this.state.ran}>
          <IBox title='Expected' rightTitle={
            <button className='btn btn-primary' onClick={() => this.save(this.expectedJson.value)()}>
              Use As Expected &amp; Save
            </button>
          }>
            <JsonInput ref={r => this.expectedJson = r}
                       defaultValue={this.state.raw.warden}
                       placeholder='Expected result (JSON)'
                       style={{width: '100%', height: 400}} />
          </IBox>
        </TabItem>

        <TabItem tabKey='trace' label='Trace' disabled={!this.state.ran}>
          <IBox title='Trace'>
            <Trace id={this.state.logId}/>
          </IBox>
        </TabItem>

      </TabContainer>
    </div>;
  }
}

export default cacheAndInjectData({
  productNames() {
    return getProductNames();
  },
})(connectToToasts(View));
