import React from 'react';
import moment from 'moment';
import {frCA} from '../../helpers/locale';
import {intlShape, injectIntl, FormattedMessage} from 'react-intl';
import PropTypes from 'prop-types';

function getDays(locale) {
  const output = [];
  let i = 1;

  while(i < 8) {
    output.push(moment().locale(locale).day(i).format('ddd'));

    i += 1;
  }

  return output;
}

const Schedule = ({data, intl}) => {
  const days = getDays(intl.locale);

  return <div className="row schedule">
    {data.map((hours, i) =>
      <div className="col" key={days[i]}>
        <h5>{days[i]}</h5>
        <div>{hours === 'CLOSED' ? <FormattedMessage id='general.closed' /> : hours}</div>
      </div>
    )}
  </div>;
};

Schedule.propTypes = {
  intl: intlShape.isRequired,
  data: PropTypes.array,
};

export default injectIntl(Schedule);
