import PropTypes from 'prop-types';

import React from 'react';

import {Link} from 'react-router';


class PageHeader extends React.Component {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  static propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.string,
    iconAfter: PropTypes.string,
    breadcrumbs: PropTypes.array.isRequired,
  };

  render() {
    let icon = '';

    if (this.props.icon) {
      icon = <i className={this.props.icon} /> ;
    }

    let iconAfter = '';

    if (this.props.icon) {
      iconAfter = <i className={this.props.iconAfter} /> ;
    }

    return (
      <div className='row wrapper border-bottom white-bg page-heading'>
        <div className='col-lg-12'>
          <h2 className='page-heading-h2'>{icon}{this.props.title}{iconAfter}</h2>
          <ol className='breadcrumb'>
            {this.props.breadcrumbs.map(({title, path = ''}) => {
              let Item = Link;

              if (!path) {
                Item = 'span';
              }
              if (this.context.router.isActive(path)) {
                Item = 'strong';
              }
              return (
                <li key={path || title}>
                  <Item to={path}>{title}</Item>
                </li>
              );
            })}
          </ol>

          {this.props.children}
        </div>
      </div>
    );
  }
}

export default PageHeader;
