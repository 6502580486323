import React from 'react';
import ToolsApi from 'api/tools';
import moment from 'moment';
import Loader from 'components/loader';

export const performanceKeys = {
  dashboard: 'Dashboard',
  adminReports: 'AdminReports',
  reports: 'Reports',
};

const __cache = {
  performance: undefined,
  expires: undefined,
};

function setCache(performance, expires = moment().add(5, 'minutes')) {
  __cache.performance = performance;
  __cache.expires = performance ? expires : undefined;
}

function clearCache() {
  return setCache(undefined, undefined);
}

export function connectToPerformanceFile(key, C) {
  class WrappedComponent extends React.Component {
    static displayName = `Performance(${C.displayName || C.name})`;

    state = {
      visible: false,
      loaded: false,
    };

    componentWillMount() {
      this.getPerformance()
        .then(performance => {
          this.setState({
            loaded: true,
            visible: (performance || {})[key],
          });
        });
    }

    getPerformance = () => {
      if (__cache.performance && __cache.expires && __cache.expires.isAfter(moment())) {
        return Promise.resolve(__cache.performance);
      }

      return ToolsApi.getPerformanceFileVariables()
        .then(x => {
          if (x.success) {
            setCache(x.data || {});

            return __cache.performance;
          }

          clearCache();
          return undefined;
        })
        .catch(e => {
          console.error(e);
          clearCache();
          return undefined;
        });
    };

    render() {
      return <Loader loaded={this.state.loaded}>
        {this.state.visible ?
          <C {...this.props} /> :
          <div className='blocked-page-error'>
            <p>We cannot process your request because the reporting database is under unusually high load right now. We suggest running this report outside of peak business hours.</p>
          </div>}
      </Loader>;
    }
  }

  return WrappedComponent;
}
