import React from 'react';

import toolsApi from '../../../api/tools.jsx';

import Spinner from '../spinner.jsx';

// Page object
class TransferStores extends React.Component {
  render() {

    return (
      <div>
        <div className='widget-head-color-box navy-bg p-lg text-center'>
          <div className='m-b-md'>
            <h2 className='font-bold no-margins'>Transfer Stores</h2>
            <small>This may take up to half an hour per transfer.</small>
          </div>
          <i className='fa fa-cloud fa-5x' />
        </div>
        <div className='widget-text-box'>
          <div className='tc' style={{marginTop: 15}}>
            <a href='tools/transfer' className='btn btn-primary'>Transfer</a>
          </div>
        </div>
      </div>
    );
  }
}

export default TransferStores;
