import React from 'react';

const __cache = {};

const _subscriptions = {};

function subscribe(component, config) {
  const keys = Object.keys(config);

  for (let key of keys) {
    _subscriptions[key] = _subscriptions[key] || [];
    _subscriptions[key].push(component);

    let res = config[key](__cache);

    if (!(res instanceof Promise)) {
      res = Promise.resolve(res);
    }

    res
      .then(output => {
        __cache[key] = output;
        _subscriptions[key].forEach(c => c.refresh());
      })
      .catch(err => {
        console.error(err);
      });
  }
}

function unsubscribe(component, config) {
  const keys = Object.keys(config);

  for (let key of keys) {
    if (_subscriptions[key]) {
      _subscriptions[key] = _subscriptions[key].filter(c => c !== component);
    }
  }
}

function getProps(config) {
  return Object.keys(config).reduce((acc, key) => {
    acc[key] = __cache[key];
    return acc;
  }, {});
}

export const cacheAndInjectData = config => C => {
  return class Wrapped extends React.Component {
    static displayName = `cacheAndInjectData(${C.displayName || C.name})`;

    state = {
      version: 0,
    };

    componentWillMount() {
      subscribe(this, config);
    }

    componentWillUnmount() {
      unsubscribe(this, config);
    }

    refresh = () => {
      this.setState(state => ({version: state.version + 1}));
    };

    getUnderlying = () => {
      if (!this._inner) {
        return;
      }

      return this._inner.getUnderlying ? this._inner.getUnderlying() : this._inner;
    };

    render() {
      const data = getProps(config);

      return <C {...this.props} {...data} ref={r => this._inner = r} />;
    }
  };
};
