import React from 'react';
import PropTypes from 'prop-types';

function ensureArray(x) {
  return x instanceof Array ? x : [x];
}

const RawResult = ({printFile, days, amount, unit, product, period, maintenance, productNames = {}}) => {
  product = ensureArray(product);

  return <div>
    <p><strong>{printFile}</strong></p>
    {product && product.length ?
      product.map((p, i) => <p key={i}>
        {rawResultAsString({i, days, amount, unit, product, maintenance, period, productNames, p})}
      </p>) :
      null}
  </div>;
};

export function rawResultAsString({i, days, amount, unit, maintenance, p, period, productNames = {}}) {
  days = ensureArray(days);
  amount = ensureArray(amount);
  unit = ensureArray(unit);

  p = productNames[p];

  const a = amount && amount.length > i ? amount[i] : undefined;
  const u = unit && unit.length > i ? unit[i] : undefined;
  const d = days && days.length > i ? days[i] : undefined;

  return (a || '') +
    (a && u ? ` ${u} ` : '') +
    (p && a ? ` of ${p}` : p ? p : '') +
    (a && d ? ` for ${d} days` : '') +
    (maintenance ? ` (maintenance)` : '') +
    (period ? ` (${period})` : '');
}

RawResult.propTypes = {
  amount: PropTypes.number,
  days: PropTypes.array,
  printFile: PropTypes.string.isRequired,
  product: PropTypes.arrayOf(PropTypes.number),
  unit: PropTypes.arrayOf(PropTypes.string),
  productNames: PropTypes.object,
  maintenance: PropTypes.bool,
  period: PropTypes.string,
};



export default RawResult;
