import React from 'react';
import {IndexRedirect, IndexRoute, Redirect, Route, Router} from 'react-router';
import {ReportContainer} from 'layout/reportsContainer';
import ZipCodes from './ui/page/zipcodes.jsx';
import SalesProductsPage from './ui/page/salesProduct.jsx';
import DocumentationPage from './ui/page/documentation.jsx';
import Token from './ui/page/token.jsx';
import PagePrintFile from './ui/page/printFile.jsx';
import PagePrintFileList from './ui/page/printfileList.jsx';
import PageSearch from './ui/page/searchResult.jsx';
import PageTools from './ui/page/tools.jsx';
import PageTransfer from './ui/page/transfer/transfer.jsx';
import PageDashboard from './ui/page/dashboard.jsx';
import PageManageFiles from './ui/page/manageFiles.jsx';
import PageNotifications from './ui/page/notifications.jsx';
import PagePoolScore from './ui/page/poolScore.jsx';
import PageNotificationsGraph from './ui/page/reports/notificationGraph.jsx';
import PageEmailsReport from './ui/page/reports/emailsReport.jsx';
import PageTruBlueDashboard from './ui/page/trublueDashboard/trublueDashboard.jsx';
import PageReports from './ui/page/reports.jsx';
import PageHealth from './ui/page/health.jsx';
import PageAnnouncementsAdmin from './ui/page/announcements/announcements-admin.jsx';
import PageAnnouncements from './ui/page/announcements/announcements.jsx';
import PageDealerDetails from './ui/page/dealerDetails.jsx';
import PageDealers from './ui/page/dealers.jsx';
import PageSignIn from './ui/page/signIn.jsx';
import Container from './ui/layout/container.jsx';
import {requireAuth} from './util/routeUtils.jsx';
import PageTruBlueClaims from 'page/truBlueClaims';
import PageTruBlueClaimItem from 'page/truBlueClaimItem';
import PageTruBlueConfig from 'page/truBlueConfig';

import TestingLanding from './ui/page/Testing';
import TestingView from './ui/page/Testing/View';
import TestingList from './ui/page/Testing/Test';
import TestingSearch from './ui/page/Testing/Search';
import Historical from './ui/page/Testing/Historical';
import PageRunReports from './ui/page/runReports';
import PageDeveloperDashboard from './ui/page/developerDashboard';

const createRouter = (history) =>
  <Router history={history}>
    <Route path='token' component={Token} />
    <Route path='/signIn' component={PageSignIn} />
    <Route path='/' component={Container} onEnter={requireAuth}>
      <IndexRedirect to='/dashboard' />
      <Route path='dashboard' component={PageDashboard} />
      <Route path='trublue-dasboard' component={PageTruBlueDashboard} />
      <Route path='trublue-dashboard' component={PageTruBlueDashboard} />
      <Route path='dealers' component={PageDealers} />
      <Route path='dealer/:dealerId' component={PageDealerDetails} />
      <Route path='announcements-admin' component={PageAnnouncementsAdmin} />
      <Route path='announcements' component={PageAnnouncements}/>
      <Route path='health' component={PageHealth} />
      <Route path='tools'>
        <IndexRoute component={PageTools} />
        <Route path='transfer' component={PageTransfer} />
      </Route>
      <Route path='zipcodes' component={ZipCodes} />
      <Route path='sales-products' component={SalesProductsPage} />
      <Route path='search(/:search)' component={PageSearch} />
      <Route path='reports' component={ReportContainer}>
        <IndexRoute component={PageReports} />
        <Route path='notifications-graph' component={PageNotificationsGraph} />
        <Route path='emails-report' component={PageEmailsReport} />
      </Route>
      <Route path='run-reports' component={PageRunReports}/>
      <Route path='manage-files' component={PageManageFiles} />
      <Route path='notifications' component={PageNotifications} />
      <Route path='claims' component={PageTruBlueClaims} />
      <Route path='trublueclaim/:id' component={PageTruBlueClaimItem} />
      <Redirect from='pool-score' to='pool-score/edit' />
      <Route path='pool-score/:mode' component={PagePoolScore} />
      <Route path='print-files' component={PagePrintFileList} />
      <Route path='print-file/new' component={PagePrintFile} />
      <Route path='print-file/:id/:country' component={PagePrintFile} />
      <Route path='testing'>
        <IndexRoute component={TestingLanding} />
        <Route path='new' component={TestingView} />
        <Route path='search/:query' component={TestingSearch} />
        <Route path='test(/:id)' component={TestingList} />
        <Route path='historical' component={Historical} />
        <Route path=':type/:id' component={TestingView} />
      </Route>
      <Route path='documentation'>
        <IndexRoute component={DocumentationPage} />
        <Route path='**/*' component={DocumentationPage} />
        <Route path='*' component={DocumentationPage} />
      </Route>
      <Route path='trublueconfig' component={PageTruBlueConfig} />
      <Route path='developer-dashboard' component={PageDeveloperDashboard} />
    </Route>
    {/* Fallback non valid routes to home dashboard */}
    <Redirect from='*' to='/' />
  </Router>;

export default createRouter;
