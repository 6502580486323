import PropTypes from 'prop-types';
import React from 'react';
import PageHeader from '../components/pageHeader.jsx';
import CustomerSearch from '../components/search/customer';

// Page object
class PageDashboard extends React.Component {
  static contextTypes = {
    canViewDashboard: PropTypes.bool.isRequired,
  };

  render() {
    return <div>
      <PageHeader title='Dashboard' icon='fa fa-tachometer' breadcrumbs={[]}/>
      <div className='wrapper wrapper-content'>
        {this.context.canViewDashboard ? <div className='row' id='page-dashboard'>
          <div className='col-md-6 col-lg-5'>
            <CustomerSearch />
          </div>
        </div> :
        <div className='row' id='page-dashboard'>
          <div className='col-xs-12'>
            <p style={{
              textAlign: 'center',
              maxWidth: '50%',
              fontSize: '2em',
              marginRight: 'auto',
              marginLeft: 'auto',
              marginTop: 30,
            }}>You Are Currently Not Authorized to View This Dashboard.
                Use the Left Hand Navigation to Access The Other Tools on This Site.
            </p>
          </div>
        </div>}
      </div>
    </div>;
  }
}

export default PageDashboard;
