import PropTypes from 'prop-types';
import React from 'react';
import Pagination from '../../components/pagination.jsx';
import Loader from '../../components/loader.jsx';
import announcementApi from '../../../api/announcement.jsx';
import {browserHistory} from 'react-router';
import AnnouncementInfoModal from '../../components/announcementInfoModal.jsx';
import ErrorList from '../../components/errorList.jsx';
import moment from 'moment';
import {chemicalBaseTypeNames, chemicalBaseTypeCustomSortOrder, countriesNames, productBrandNames, productBrandsSortOrder, printFilesLanguages} from '../../helpers/constants.jsx';

class PageAnnouncementManagerList extends React.Component {
  static contextTypes = {
    canViewSupport: PropTypes.bool.isRequired,
    canEditSupport: PropTypes.bool.isRequired,
    adminMode: PropTypes.bool.isRequired,
  };

  state = {
    loaded: false,
    announcements: [],
    page: 1,
    pageSize: 1,
    announcementsCount: 0,
    showAnnouncementInfoModalState: 'closed',
    announcementSelected: {},
    tabSelected: 0,
    imageHash: moment().valueOf(),
  };

  componentWillMount() {
    if (!this.context.canViewSupport) {
      browserHistory.push('/');
    }
  }

  componentDidMount() {
    this.refresh();
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.page !== this.state.page) {
      this.refresh(nextState.page);
    }
  }

  onPageChange = (page) => {
    console.log('page', page);
    this.setState({page});
  };

  onCancelAnnouncementInfoModal = () => {
    this.setState({showAnnouncementInfoModalState: 'closed', imageHash: moment().valueOf()});
    this.refresh();
    ErrorList.clearErrors();
  };

  refresh = (page = this.state.page) => {
    this.setState({loaded: false});

    if (this.props.tabSelected === 1) { // Consumer
      announcementApi.getAnnouncementsConsumer(page).then(({items, count, pageSize, page}) => {
        this.setState({
          pageSize: pageSize,
          page: page,
          announcementsCount: count,
          announcements: items,
          loaded: true,
        });
      }).catch((e) => console.error(e));
    } else { // Dealer
      announcementApi.getAnnouncementsDealer(page).then(({items, count, pageSize, page}) => {
        this.setState({
          pageSize: pageSize,
          page: page,
          announcementsCount: count,
          announcements: items,
          loaded: true,
        });
      }).catch((e) => console.error(e));
    }
  };

  editAnnouncementInfo = (message) => () => {
    this.setState({showAnnouncementInfoModalState: 'edit', announcementSelected: message});
    ErrorList.clearErrors();
  };

  createAnnouncement = () => () => {
    this.setState({showAnnouncementInfoModalState: 'create', announcementSelected: {}});
    ErrorList.clearErrors();
  };

  formatDate(startDate, endDate) {
    let year = moment(startDate).year() + ' - ' + moment(endDate).year();
    let monthDay = moment(startDate).format('MMMM') + ' ' + moment(startDate).format('DD') + ' - ' + moment(endDate).format('MMMM') + ' ' + moment(endDate).format('DD');

    return monthDay + ', ' + year;
  }

  getAssetUrl = (filename) => {
    let CDN = document.getElementById('body').dataset.cdn;
    let url = CDN + filename;

    return url;
  };

  renderAnnouncements = (announcements) => {
    if (!announcements.length) {
      return <p>No announcements to display</p>;
    }
    return (
      announcements.map((announcement) =>
        <div key={announcement.id} className='card' onClick={this.editAnnouncementInfo(announcement)}>
          <div className='card-horizontal'>
            {
              announcement.contentURL ? <img className='card-img' src={`${announcement.contentURL}?${this.state.imageHash}`} alt='Announcement Image' /> : null
            }
            <div className='card-body'>
              <div className='card-text'>
                <span className='card-title'>{announcement.title ? announcement.title : null} </span>
                <span>{announcement.startDate && announcement.endDate ? this.formatDate(announcement.startDate, announcement.endDate) : null} </span>
              </div>
              <div className='card-text'>
                <i className='fa fa-angle-double-up' aria-hidden='true'></i> <span>Priority {announcement.priority} - {announcement.country ? announcement.country.split(',').map(x => countriesNames[x] + ' ') : null } - {announcement.language ? announcement.language.split(',').map(x => printFilesLanguages[x] + ' ') : null } </span>
              </div>
              <div className='card-text'>
                {
                  this.props.tabSelected === 1 ?
                    announcement.sanitizingGroup ?
                    announcement.sanitizingGroup.split(',').sort((a, b) => chemicalBaseTypeCustomSortOrder[a] - chemicalBaseTypeCustomSortOrder[b]).map((x, i) => <span key={i}><span key={`b-${i}`} className='badge'>{chemicalBaseTypeNames[x]}</span> </span>) :
                    null :
                  announcement.productsCarried ?
                    announcement.productsCarried.split(',').sort((a, b) => productBrandsSortOrder[a] - productBrandsSortOrder[b]).map((x, i) => <span key={i}><span key={`b-${i}`} className='badge'>{productBrandNames[x]}</span> </span>) :
                    null
                }
              </div>
            </div>
          </div>
        </div>
      )
    );
  };

  render() {
    return (
      <div className='row'>
        <div className='col-md-12'>
          {this.context.canEditSupport ? <div className='tab-content-wrapper'>
            { this.props.adminMode ? <button className='btn btn-primary create-marketing-message' onClick={this.createAnnouncement()}>Create an Announcement</button> : null}
          </div> : null}
          <div className='card-container'>
            <Loader loaded={this.state.loaded}>
              {this.renderAnnouncements(this.state.announcements)}
            </Loader>
          </div>
          {this.state.announcementsCount ?
            <Pagination
              onPageChange={this.onPageChange}
              page={this.state.page}
              count={this.state.pageSize}
              total={this.state.announcementsCount} /> : null
          }
        </div>
        <AnnouncementInfoModal
          showState={this.state.showAnnouncementInfoModalState}
          announcement={this.state.announcementSelected}
          onCancel={this.onCancelAnnouncementInfoModal}
          tabSelected={this.props.tabSelected}
          adminMode={this.props.adminMode} />
      </div>);
  }
}

export default PageAnnouncementManagerList;