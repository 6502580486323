import qwest from 'qwest';

import Branch from '../../../Canopy/Javascript/models/branch.jsx';

let BranchApi = {
  getBranches(dealerId) {
    return qwest.get('/api/Branch/Index', {dealer: dealerId}, {dataType: 'post'})
      .then((xhr, response) => {
        if (response.success) {
          return response.data.map(Branch.fromApiFormat.bind(Branch));
        }
        throw response;
      });
  },

  getBranchesByDealerIDs(dealerIds) {
    return qwest.post('/api/Branch/GetBranchesByDealerIds', dealerIds)
      .then((xhr, response) => {
        if (response.success) {
          return response.data.map(Branch.fromApiFormat.bind(Branch));
        }
        throw response;
      });
  },

  getBranch(id) {
    return qwest.get(`/api/Branch/Index/${id}`)
      .then((xhr, response) => {
        if (response.success) {
          return Branch.fromApiFormat(response.data);
        }
        throw response;
      });
  },

  createBranch(branch) {
    let apiBranch = branch.toApiFormat();

    return qwest.post('/api/Branch/Index', apiBranch)
      .then((xhr, response) => {
        if (response.success) {
          return Branch.fromApiFormat(response.data);
        }
        throw response;
      });
  },

  saveBranch(branch) {
    let apiBranch = branch.toApiFormat();

    return qwest.post(`/api/Branch/Index/${branch.id}`, apiBranch)
      .then((xhr, response) => {
        if (response.success) {
          return Branch.fromApiFormat(response.data);
        }
        throw response;
      });
  },

  deleteBranch(branch) {
    return qwest.delete(`/api/Branch/Index/${branch.id}`)
      .then((xhr, response) => {
        if (response.success) {
          return response;
        }
        throw response;
      });
  },
};

export default BranchApi;
