import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage, injectIntl, intlShape} from 'react-intl';
import {getProducts} from '../../../Canopy/Javascript/ui/helpers/suggestedProductList';
import SpecialCharacterHelper from '../../helpers/special-character-helper';
import {getImagePath} from '../../helpers/image-helper';
import classNames from 'classnames';
import {getUOMTranslation} from '../../helpers/constants-helper';

const props = {
  suggestedProducts: PropTypes.array,
  intl: intlShape.isRequired,
  country: PropTypes.string,
  version: PropTypes.number,
  chemicalBaseTypeID: PropTypes.number,
  volume: PropTypes.number,
};
const SuggestedProducts = ({country, intl, suggestedProducts, chemicalBaseTypeID, version, volume}) => {
  if (!version || version < 3) {
    return null;
  }

  const products = getProducts(country, chemicalBaseTypeID, true) || [];
  let resolvedProducts = (suggestedProducts || []).map(x => products.find(y => y.id === x));

  resolvedProducts = resolvedProducts || [];

  const {formatMessage} = intl;

  return <div className='suggested-products container'>
    <div className='suggested-products__header'>
      <div className='suggested-products__header-inner'>
        <h2 className='suggested-products__title'>{formatMessage({id: 'report-viewer.suggested-products.title'}).toUpperCase()}</h2>
        <p className='suggested-products__details'><FormattedMessage id='report-viewer.suggested-products.details'/></p>
      </div>
    </div>
    <div className='suggested-products__body'>
      {resolvedProducts.map(product => <SuggestedProduct {...product} key={product.id}
                                                         volume={volume}
                                                         country= {country}
                                                         count={resolvedProducts.length}
                                                         multiple={resolvedProducts.length > 1} />)}
    </div>
  </div>;
};

SuggestedProducts.propTypes = props;

const spProps = {
  name: PropTypes.string.isRequired,
  tag: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  protection: PropTypes.bool,
  clarity: PropTypes.bool,
  comfort: PropTypes.bool,
  initialDose: PropTypes.func,
  initialDoseText: PropTypes.string,
  maintenanceDose: PropTypes.func,
  maintenanceDoseText: PropTypes.string,
  intl: intlShape,
  country: PropTypes.string,
  volume: PropTypes.number,
  multiple: PropTypes.bool,
  count: PropTypes.number,
};
const _SuggestedProduct = ({name, tag, protection, clarity, comfort, initialDose,
                             maintenanceDose, intl, volume, multiple, count, country = 'US',
                             initialDoseText, maintenanceDoseText}) => {
  const {formatMessage} = intl;

  const initial = initialDose && typeof initialDose === 'function' ? initialDose(volume) : null;

  // All products should have an minimum of 1 if recommended and in integers only
  if (initial) {
    initial.number = Math.max(1, Math.round(initial.number));
  }

  const maintenance = maintenanceDose && typeof maintenanceDose === 'function' ? maintenanceDose(volume) : null;

  if (maintenance) {
    maintenance.number = Math.max(1, Math.round(maintenance.number));
  }

  const addDefaultImageSrc = (e) => {
    e.target.src = '/Content/img/suggested-products/fallback.svg';
  };

  return <div className={classNames('suggested-product', {
    'suggested-product--multiple': multiple,
    'suggested-product--2': count === 2,
    'suggested-product--3': count === 3,
  })}>
    <div className='suggested-product__image-wrapper'>
      <img src={getImagePath({locale: intl.locale, folder: `Products/${country}`, file: tag.replace(/-/g, '') + '.png'})}
         height={80}
         width={80}
         className='suggested-product__image'
         onError = {addDefaultImageSrc}
         alt={name}/>
    </div>

    <div className='suggested-product__inner'>
      <h3 className='suggested-product__name'>
        {name}
        {clarity ? <img alt='Clarity'
                        height={20}
                        src={getImagePath({locale: intl.locale, folder: 'TitleImages', file: 'clarity.png'})}/> : null}
        {protection ? <img alt='Protection'
                           height={20}
                           src={getImagePath({
                             locale: intl.locale,
                             folder: 'TitleImages',
                             file: 'protection.png',
                           })}/> : null}
        {comfort ? <img alt='Comfort'
                        height={20}
                        src={getImagePath({locale: intl.locale, folder: 'TitleImages', file: 'comfort.png'})}/> : null}
      </h3>
      <div className='suggested-product__description'>
        {initial || maintenance ?
          <p>
            {initial ?
              <FormattedMessage id='report-viewer.suggested-products.dose.initial'
                                values={{
                                  amount: initial.number,
                                  unit: getUOMTranslation(formatMessage, initial.unit),
                                }}/> :
              null}
            {initial && maintenance ? <br /> : null}
            {maintenance ?
              <FormattedMessage id='report-viewer.suggested-products.dose.maintenance'
                                values={{
                                  amount: maintenance.number,
                                  unit: getUOMTranslation(formatMessage, maintenance.unit)}}
                                /> :
              null}
          </p> :
          null}
        {initialDoseText || maintenanceDoseText ?
          <p>
            {initialDoseText ?
              <FormattedMessage id={initialDoseText}/> :
              null}
            {initialDoseText && maintenanceDoseText ? <br /> : null}
            {maintenanceDoseText ?
              <FormattedMessage id={maintenanceDoseText}/> :
              null}
          </p> :
          null}
        <div>
          {SpecialCharacterHelper.formatBullets(formatMessage({id: `suggested-products.${country.toLowerCase()}.${tag}`}))}
        </div>
      </div>
    </div>
  </div>;
};

_SuggestedProduct.propTypes = spProps;

const SuggestedProduct = injectIntl(_SuggestedProduct);

export default injectIntl(SuggestedProducts);
