import React from 'react';
import TruBlueApi from 'api/truBlueDashboard';
import Loader from 'components/loader';
import events from 'lib/events';
import {connectToToasts, TOAST_STATES} from 'components/toasts';
import PropTypes from 'prop-types';

class SummaryStatics extends React.Component {
  static propTypes = {
    createToast: PropTypes.func.isRequired,
    refName: PropTypes.string,
  };

  state = {
    loaded: true,
  };

  setLoading = () => {
    this.setState({loaded: false});
  };

  loadReport = (filters) => {
    this.setLoading();
    TruBlueApi.getDealerSummary(filters.startDate, filters.endDate, filters.platinumStatus,
      filters.dsm, filters.rm, filters.states)
      .then(data => {
        this.setState({loaded: true, data});
        events.publish('trublue-block-loaded', this.props.refName);
      }).catch(e => {
        console.log(e);
        this.props.createToast({
          id: 'trublue-dashboard-error',
          state: TOAST_STATES.ERROR,
          message: 'Failed to load data for report',
          ttl: 4000,
        });
      });
  };

  render() {
    const {data} = this.state;

    return (
      <div>
        <div className='widget style1 blue-bg'>
          <div className='row'>
            <div className='col-xs-4'>
              <i className='fa fa-users fa-5x'/>
            </div>
            <div className='col-xs-8 text-right'>
              <h2 className='font-bold'>Dealer Summary Statistics
              </h2>
            </div>
          </div>
        </div>
        <Loader loaded={this.state.loaded}>
          <table
            style={{width: '100%', textAlign: 'center', marginBottom: 30}}>
            <tbody>
              <tr>
                <td>
                  <div className='ibox-content'>
                    <h1 className='no-margins'>{data ? data.TotalPlatinumDealers : 0}</h1>
                    <small>Platinum Dealers</small>
                  </div>
                </td>
                <td>
                  <div className='ibox-content'>
                    <h1 className='no-margins'>{data ? data.TotalTruBlueOptinDealers : 0}</h1>
                    <small>Tru•Blue Opt In Dealers</small>
                  </div>
                </td>
                <td>
                  <div className='ibox-content'>
                    <h1 className='no-margins'>{data ? data.TotalStoresOptin : 0}</h1>
                    <small>Stores Across Opted in Dealers</small>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </Loader>
      </div>
    );
  }
}

export default connectToToasts(SummaryStatics);
