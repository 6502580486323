import qwest from 'qwest';
import {trublueMemberIDEnabled} from '../features';

export function getClaim(id) {

  return qwest.get(`/api/TruBlueClaims/claim?claimID=${id}`)
    .then((xhr, res) => res);
}

export function saveClaim(claim) {
  return qwest.post('/api/TruBlueClaims/Save', {claim: claim});
}

export function getAll(start, end, page, pageSize, dealers, status) {
  let dealersList = '';

  if(dealers && dealers.length > 0) {
    dealers.forEach(v => {
      dealersList += `&dealer=${v}`;
    });
  }
  return qwest.get(`/api/TruBlueClaims?start=${start}&end=${end}&page=${page}&pageSize=${pageSize}&status=${status}${dealersList}`)
    .then((xhr, res) => res);
}

export function getCSVUrl(start, end, dealers, status) {
  let dealersList = '';

  if(dealers && dealers.length > 0) {
    dealers.forEach(v => {
      dealersList += `&dealer=${v}`;
    });
  }
  return `/api/TruBlueClaims/CSVDownload?start=${start}&end=${end}&status=${status}&isTruBlueMemberIDEnabled=${trublueMemberIDEnabled}${dealersList}`;
}
