import PropTypes from 'prop-types';
import React from 'react';

import EditableCheckbox from './editableCheckbox.jsx';

class LayoutCheckbox extends React.Component {
  static defaultProps = {
    condition: true,
    disabled: false,
    label: '',
  };

  static propTypes = {
    condition: PropTypes.bool,
    dataChanged: PropTypes.func.isRequired,
    dataKey: PropTypes.string.isRequired,
    disabled: PropTypes.any,
    editMode: PropTypes.bool.isRequired,
    hideLabel: PropTypes.bool,
    label: PropTypes.string,
    topLabel: PropTypes.string,
    value: PropTypes.bool,
    checked: PropTypes.bool,
  };

  render() {
    let disabled = this.props.disabled;

    if (disabled instanceof Array) {
      disabled = disabled.indexOf(this.props.dataKey) !== -1;
    }
    if (this.props.condition) {
      return <div className='form-group'>
        {this.props.topLabel ? <label>{this.props.topLabel}</label> : null}
        <EditableCheckbox
          dataKey={this.props.dataKey}
          disabled={disabled}
          editMode={this.props.editMode}
          hideLabel={this.props.hideLabel}
          label={this.props.label}
          onChange={this.props.dataChanged}
          data-event-category={this.props['data-event-category']}
          data-event-action={this.props['data-event-action']}
          value={this.props.value || this.props.checked}
          checked={this.props.checked || this.props.value}
        />
      </div>;
    }

    return null;
  }
}

export default LayoutCheckbox;
