import React from 'react';

function hideNode(e) {
  if (window.displayBrokenImages === 'False') {
    e.target.style.display = 'none';
  }
}

const Highlight = ({imageUrl, title, children}) =>
  <div className="highlight row">
    <div className="col image">
      <img src={imageUrl} onError={hideNode} />
    </div>
    <div className="col">
      <h3>{title}</h3>
      {children}
    </div>
  </div>;

export default Highlight;
