import qwest from 'qwest';

import DealerUser from '../models/dealerUser.jsx';

let DealerUserApi = {
  getDealerUsers(dealerId) {
    return qwest.get('/api/DealerUser/Index', {dealer: dealerId}, {dataType: 'post'})
      .then((xhr, response) => {
        if (response.success) {
          return response.data.map(DealerUser.fromApiFormat.bind(DealerUser));
        }
        throw response;
      });
  },

  getDealerUser(id) {
    return qwest.get(`/api/DealerUser/Index/${id}`)
      .then((xhr, response) => {
        if (response.success) {
          return DealerUser.fromApiFormat(response.data);
        }
        throw response;
      });
  },

  createDealerUser(dealerUser) {
    let apiDealerUser = dealerUser.toApiFormat();

    return qwest.post('/api/DealerUser/Index', apiDealerUser)
      .then((xhr, response) => {
        if (response.success) {
          return DealerUser.fromApiFormat(response.data);
        }
        throw response;
      });
  },

  saveDealerUser(dealerUser) {
    let apiDealerUser = dealerUser.toApiFormat();

    return qwest.post(`/api/DealerUser/Index/${dealerUser.id}`, apiDealerUser)
      .then((xhr, response) => {
        if (response.success) {
          return DealerUser.fromApiFormat(response.data);
        }
        throw response;
      });
  },

  deleteDealerUser(dealerUser) {
    return qwest.delete(`/api/DealerUser/Index/${dealerUser.id}`);
  },
};

export default DealerUserApi;
