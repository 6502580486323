import moment from 'moment';

class ReportTask {
  constructor({id, reportId, dealerId, template, SQL,
        title, status, initiatedBy, timeToComplete,
        finalResultsXLSX, finalResultsCSV, created, archived, generated, finalResultsXLSX_FR, finalResultsCSV_FR} = {}) {
    this.id = id;
    this.reportId = reportId;
    this.dealerId = dealerId;
    this.template = template;
    this.SQL = SQL;
    this.title = title;
    this.status = status;
    this.initiatedBy = initiatedBy;
    this.timeToComplete = timeToComplete;
    this.finalResultsXLSX = finalResultsXLSX;
    this.finalResultsCSV = finalResultsCSV;
    this.created = created;
    this.archived = archived;
    this.generated = generated;
    this.finalResultsXLSX_FR = finalResultsXLSX_FR;
    this.finalResultsCSV_FR = finalResultsCSV_FR;
  }

  clone() {
    return new ReportTask(this);
  }

  toApiFormat() {
    return {
      ID: this.id,
      ReportID: this.reportId,
      DealerID: this.dealerId,
      Template: this.template,
      SQL: this.SQL,
      Title: this.title,
      Status: this.status,
      InitiatedBy: this.initiatedBy,
      TimeToComplete: this.timeToComplete,
      FinalResultsXLSX: this.finalResultsXLSX,
      FinalResultsCSV: this.finalResultsCSV,
      Created: this.created ? moment(this.created) : null,
      Archived: this.archived ? moment(this.archived) : null,
      Generated: this.generated ? moment(this.generated) : null,
    };
  }

  static fromApiFormat(api) {
    return new ReportTask({
      id: api.ID,
      reportId: api.ReportID,
      dealerId: api.DealerID,
      template: api.Template,
      SQL: api.SQL,
      title: api.Title,
      status: api.Status,
      initiatedBy: api.InitiatedBy,
      timeToComplete: api.TimeToComplete,
      finalResultsXLSX: api.FinalResultsXLSX,
      finalResultsCSV: api.FinalResultsCSV,
      finalResultsXLSX_FR: api.FinalResultsXLSX_FR,
      finalResultsCSV_FR: api.FinalResultsCSV_FR,
      created: api.Created ? moment(api.Created) : null,
      archived: api.Archived ? moment(api.Archived) : null,
      generated: api.Generated ? moment(api.Generated) : null,
    });
  }
}

export default ReportTask;
