import PropTypes from 'prop-types';
import React, {Component} from 'react';

import Loader from '../components/loader.jsx';
import PageHeader from '../components/pageHeader.jsx';
import IBox from '../components/iBox.jsx';
import {Input} from '../components/form/index.jsx';

import {connectToToasts, TOAST_STATES} from '../components/toasts.jsx';

import {getAll, getMissing, save} from '../../api/zipcode.jsx';
import EditZipCodes from './EditZipCodes.jsx';

import Pagination from '../components/Pagination.jsx';

class ZipCodesPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      missing: [],
      zips: [],
      activePage: 0,
    };
  }

  componentWillMount() {
    let apis = [
      getAll(),
      getMissing(),
    ];

    Promise.all(apis)
      .then(([all, missing]) => {
        if (!all.success || !missing.success) {
          throw new Error('Failed to download zip codes.');
        }

        this.setState({
          loaded: true,
          missing: missing.data.sort(),
          zips: all.data.sort((a, b) => a.zipcode < b.zipcode ? -1 : 1),
          activePage: 1,
          search: '',
          editting: null,
        });
      })
      .catch(err => {
        console.error(err);
        this.props.createToast({
          id: 'get-zips-failure',
          message: 'Failed to get zip codes.',
          ttl: 6000,
          state: TOAST_STATES.ERROR,
        });
      });
  }

  cancelEdit = () => {
    this.setState({editting: null});
  };

  changePage = page => {
    this.setState({
      activePage: page,
    });
  };

  createNew = () => {
    this.setState({
      editting: {
        id: null,
        zipcode: '',
        latitude: '',
        longitude: '',
      },
    });
  };

  edit = zip => {
    return () => {
      let rec = this.state.zips.find(x => x.zipcode === zip);

      if (!rec) {
        rec = {
          id: null,
          zipcode: zip,
          latitude: '',
          longitude: '',
        };
      }
      this.setState({
        editting: rec,
      });
    };
  };

  save = ({zipcode, latitude, longitude}) => {
    this.props.createToast({
      id: 'saving-zipcode',
      message: 'Saving...',
      clears: 10000,
      state: TOAST_STATES.INFO,
    });
    save(zipcode, latitude, longitude)
      .then(x => {
        if (!x.success) {
          throw new Error('Failed to save zip code.');
        }

        this.props.createToast({
          id: 'saving-zipcode-success',
          message: 'Saved.',
          ttl: 4000,
          clears: 'saving-zipcode',
          state: TOAST_STATES.SUCCESS,
        });

        let zips = this.state.zips.filter(y => y.zipcode !== zipcode);

        zips.push(x.data);
        zips = zips.sort((a, b) => a.zipcode < b.zipcode ? -1 : 1);

        let missing = this.state.missing.filter(x => x.zipcode !== zipcode).sort((a, b) => a.zipcode < b.zipcode ? -1 : 1);

        this.setState({
          editting: null,
          zips,
          missing,
        });
      })
      .catch(err => {
        console.error(err);
        this.props.createToast({
          id: 'saving-zipcode-failure',
          message: 'Failed to save.',
          ttl: 6000,
          clears: 'saving-zipcode',
          state: TOAST_STATES.ERROR,
        });
      });
  };

  search = ({value}) => {
    this.setState({
      search: value,
    });
  };

  render() {
    let rows = this.state.zips;

    if (this.state.search) { // Searching in memory since there's isn't that much data and it isn't worth involving elasticsearch for this
      rows = rows.filter(x => x.zipcode.includes(this.state.search));
    }

    const pageSize = 10;
    const total = rows.length;

    rows = rows
      .slice((this.state.activePage - 1) * pageSize, this.state.activePage * pageSize)
      .map(z => <tr key={z.zipcode} style={{cursor: 'pointer'}}
          onClick={this.edit(z.zipcode)}><td>{z.zipcode}</td><td>{z.latitude}</td><td>{z.longitude}</td>
      </tr>);

    return <div>
      <PageHeader title='Zip Codes' icon='fa fa-map-marker' breadcrumbs={[]} />

      <Loader loaded={this.state.loaded}>
        <div>
          {this.state.editting ? <EditZipCodes {...this.state.editting} cancel={this.cancelEdit} save={this.save} /> : null}

          <IBox title='Missing Zip Codes'>
            <div>
              {this.state.missing && this.state.missing.length > 0 ? <table className='table table-striped table-hover'>
                <thead><tr><th>Zip Code</th></tr></thead>
                <tbody>
                  {this.state.missing.map(x => <tr key={x} style={{cursor: 'pointer'}} onClick={this.edit(x)}><td>{x}</td></tr>)}
                </tbody>
              </table> : 'No zip codes in use by dealers are currently missing.'}
            </div>
          </IBox>

          <IBox title='Current Zip Codes'>
            <div className='row'>
              <div className='col-md-11'>
                <Input value={this.state.search} dataChanged={this.search} placeholder='Search' editMode={true} type='text' />
              </div>
              <div className='col-md-1'>
                <button className='btn btn-primary' style={{marginTop: 18}} onClick={this.createNew}>Create</button>
              </div>
              <div className='col-md-12'>
                <table className='table table-striped table-hover'>
                  <thead>
                    <tr>
                      <th>Zip Code</th><th>Latitude</th><th>Longitude</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rows}
                  </tbody>
                </table>
                <Pagination
                  onPageChange={this.changePage}
                  page={this.state.activePage}
                  count={pageSize}
                  total={total}
                  maxButtons={10}/>
              </div>
            </div>
          </IBox>
        </div>
      </Loader>
    </div>;
  }
}

ZipCodesPage.propTypes = {
  createToast: PropTypes.func.isRequired,
};

export default connectToToasts(ZipCodesPage);
