import PropTypes from 'prop-types';
import LoadingButton from './loadingBtn.jsx';
import React from 'react';
import Modal from 'components/Modal';
import assetApi from '../../api/asset.jsx';
import notificationApi from '../../api/notification.jsx';
import reportApi from '../../api/report.jsx';
import ImageUpload from '../components/fileUpload.jsx';
import ReactSelect from 'react-select';
import ErrorList from './errorList.jsx';
import {connectToToasts, TOAST_STATES} from './toasts.jsx';
import {notificationTypes} from 'ui/helpers/constants';
import _ from 'lodash';

const ALL = {value: 'All', label: 'All'};

// Row object
class NotifModal extends React.Component {
  static propTypes = {
    showState: PropTypes.oneOf(['closed', 'edit', 'create']).isRequired,
    hideModal: PropTypes.func.isRequired,
    createToast: PropTypes.func.isRequired,
    notificationToEdit: PropTypes.object,
  };

  state = {
    isSaving: false,
    options: {
      platinumStatus: [],
      dsm: [],
      rm: [],
      states: [],
      language: [
        ALL,
        {value: 'en-US', label: 'English'}, 
        {value: 'fr-CA', label: 'French'},
      ],
      country: [
        ALL,
        {value: 'US', label: 'United States'},
        {value: 'CA', label: 'Canada'},
      ],
    },
    selected: {
      platinumStatus: [],
      dsm: [],
      states: [],
      language: [ALL],
      country: [ALL],
    },
    selectedRM: 'All',
  };

  isEditMode = () => {
    return this.props.showState === 'edit';
  }

  componentWillMount() {
    let apis = [];

    apis.push(reportApi.getPlatinumStatusList());
    apis.push(reportApi.getDSMList());
    apis.push(reportApi.getRMList());
    apis.push(reportApi.getStateList());

    Promise.all(apis)
      .then(([platinumStatus, dsm, rm, states]) => {

        dsm = dsm.filter(x => x.DSMNumber);
        rm = rm.filter(x => x.RMNumber);

        let options = {
          platinumStatus: [ALL].concat(platinumStatus.map((x) => ({label: x, value: x}))),
          states: [ALL].concat(states.map((x) => ({label: x, value: x}))),
          dsm: [ALL].concat(dsm.map(x => ({
            label: x.DSMName ? x.DSMNumber + ' - ' + x.DSMName : x.DSMNumber,
            value: x.DSMNumber,
          }))),
          rm: [ALL].concat(rm.map(x => ({
            label: x.RMName ? x.RMName + ' (' + x.RMNumber + ')' : x.RMNumber,
            value: x.RMNumber,
          }))),
          language: this.state.options.language,
          country: this.state.options.country,
        };

        let selected = {
          platinumStatus: [ALL],
          dsm: [ALL],
          rm: ALL,
          states: [ALL],
          country: [ALL],
          language: [ALL],
        };

        if(this.isEditMode()){
          selected = {
            platinumStatus: [this.state.notificationToEdit.platinumStatus],
            dsm: [this.state.notificationToEdit.dsm],
            rm: this.state.notificationToEdit.rm,
            states: [this.state.notificationToEdit.states],
            country: [this.state.notificationToEdit.country],
            language: [this.state.notificationToEdit.language],
          }
        }

        this.setState({options, selected});
      })
      .catch(err => console.error(err));

    this.save = _.debounce(this._save, 500, {'leading': true, 'trailing': false});
    this.send = _.debounce(this._send, 500, {'leading': true, 'trailing': false});
  }

  getFileName = (list, filename) => { //List can be out of order
    for (var key in list) {
      if (list[key].indexOf(filename) !== -1) {
        return list[key];
      }
    }

    return list[list.length - 1]; //Default is last element
  };

  setLink = (assets, file) => {
    // Set the link URL, by simply getting the last asset we uploaded
    let filename = this.getFileName(assets, file.name);
    let CDN = document.getElementById('body').dataset.cdn;
    let url = CDN + filename;
    let markdown = `${url}`;

    this.link.value = markdown;
  };

  _getStatesForAPI(){
    let states = '';

      if (this.state.selected.states) {
        if (this.state.selected.states[0].value === 'All') {
          states = '';
        } else {
          let statesArray = this.state.selected.states.map(x => x.value);

          for (let ind1 in statesArray) {
            states += statesArray[ind1];
            states += ',';
          }
          states = states.slice(0, -1);
        }
      }
  }

  _save = () => {
    if(this.state.isSaving) return;
    this.setState({isSaving: true});

    let updatedNotif = {
      NotificationTitle: this.notificationTitle.value,
      NotificationTitleFr: this.notificationTitleFr.value,
      Body: this.body.value,
      BodyFr: this.bodyFr.value,
      URLLink: this.link.value,
      Type: this.type.value,
      UserType: this.props.notificationToEdit.UserType,
      SendEmail: this.props.notificationToEdit.SendEmail,
      Country: this.props.notificationToEdit.Country,
      PlatinumStatus: this.props.notificationToEdit.PlatinumStatus,
      DSM: this.props.notificationToEdit.DSM,
      RM: this.props.notificationToEdit.RM,
      States: this.props.notificationToEdit.States,
      ID: this.props.notificationToEdit.ID,
    };

    this.props.createToast({
      id: 'saving-notification',
      message: 'Saving...',
      ttl: 10000,
      state: TOAST_STATES.INFO,
    });

    notificationApi.saveNotification(updatedNotif)
    .then(() => {
      // Hide modal and toggle 'isSaving' flag
      this.setState({isSaving: false});
      this.props.hideModal();

      this.props.createToast({
        id: 'saving-notification-success',
        clears: 'saving-notification',
        message: 'Saved notification.',
        ttl: 4000,
        state: TOAST_STATES.SUCCESS,
      });

    })
    .catch(error => {
      // Toggle isSaving flag regardless of success
      this.setState({isSaving: false});
      console.error(error);

      this.props.createToast({
        id: 'saving-notification-failure',
        message: 'Failed saving.',
        clears: 'saving-notification',
        ttl: 6000,
        state: TOAST_STATES.ERROR,
      });
    });
  }

  _send = () => {
    if (!this.state.isSaving) {
      this.setState({isSaving: true});
      // Create new notif object and use this.setState (instead of setting directly)
      let states = this._getStatesForAPI();

      if (this.state.selected.states) {
        if (this.state.selected.states[0].value === 'All') {
          states = '';
        } else {
          let statesArray = this.state.selected.states.map(x => x.value);

          for (let ind1 in statesArray) {
            states += statesArray[ind1];
            states += ',';
          }
          states = states.slice(0, -1);
        }
      }

      let dsm = '';

      if (this.state.selected.dsm) {
        if (this.state.selected.dsm[0].value === 'All') {
          dsm = '';
        } else {
          let dsmArray = this.state.selected.dsm.map(x => x.value);

          for (let ind2 in dsmArray) {
            dsm += dsmArray[ind2];
            dsm += ',';
          }
          dsm = dsm.slice(0, -1);
        }
      }

      let rm = !this.state.selected.rm || this.state.selected.rm.value === 'All' ?
        '' : this.state.selected.rm.value;

      let platinumStatus = '';

      if (this.state.selected.platinumStatus) {
        if (this.state.selected.platinumStatus[0].value === 'All') {
          platinumStatus = '';
        } else {
          let platinumStatusArray = this.state.selected.platinumStatus.map(x => x.value);

          for (let ind3 in platinumStatusArray) {
            platinumStatus += platinumStatusArray[ind3];
            platinumStatus += ',';
          }
          platinumStatus = platinumStatus.slice(0, -1);
        }
      }
      let newNotif = {
        NotificationTitle: this.notificationTitle.value,
        NotificationTitleFr: this.notificationTitleFr.value,
        Body: this.body.value,
        BodyFr: this.bodyFr.value,
        URLLink: this.link.value,
        Type: this.type.value,
        UserType: 'all',
        SendEmail: this.sendEmail.checked,
        Country: this.state.selected.country.map(x => x.value),
        PlatinumStatus: platinumStatus,
        DSM: dsm,
        RM: rm,
        States: states,
      };

      this.props.createToast({
        id: 'sending-notification',
        message: 'Sending...',
        ttl: 10000,
        state: TOAST_STATES.INFO,
      });

      notificationApi.createNotification(newNotif)
        .then(() => {
          // Hide modal and toggle 'isSaving' flag
          this.setState({isSaving: false});
          this.props.hideModal();

          this.props.createToast({
            id: 'sending-notification-success',
            clears: 'sending-notification',
            message: 'Sent notification.',
            ttl: 4000,
            state: TOAST_STATES.SUCCESS,
          });

        })
        .catch(error => {
          // Toggle isSaving flag regardless of success
          this.setState({isSaving: false});
          console.error(error);

          this.props.createToast({
            id: 'sending-notification-failure',
            message: 'Failed sending.',
            clears: 'sending-notification',
            ttl: 6000,
            state: TOAST_STATES.ERROR,
          });
        });
    }
  };

  closeModal = () => {
    this.props.hideModal();
  };

  updateFilter = (key) => {
    return selectedOptions => {
      // Ensure that 'All' cannot be selected along with other values in a multiselect
      if (Array.isArray(selectedOptions) && Array.isArray(this.state.selected[key])) {
        let prevIndexOfAll = this.state.selected[key].indexOf(ALL);
        let currentIdxOfAll = selectedOptions.indexOf(ALL);

        // If 'ALL' is being added to the selected options, then force it to be the only option selected
        if (currentIdxOfAll !== -1 && prevIndexOfAll === -1) {
          selectedOptions = [selectedOptions[currentIdxOfAll]];
          // Remove 'ALL', when the user is trying to add another option
        } else if (currentIdxOfAll !== -1 && selectedOptions.length > 1) {
          selectedOptions.splice(currentIdxOfAll, 1);
        }
      }

      let selected = Object.assign({}, this.state.selected);

      selected[key] = selectedOptions;
      this.setState({selected: selected}, () => {
        if (key !== 'language') {return;}

        // Empty allow all
        if (this.state.selected.language.includes(ALL) || !this.state.selected.language.length) {
          return;
        }

        if (this.state.selected.language.every(x => !x.value.includes('en'))) {
          this.body.value = '';
          this.notificationTitle.value = '';
        }

        if (this.state.selected.language.every(x => !x.value.includes('fr'))) {
          this.bodyFr.value = '';
          this.notificationTitleFr.value = '';
        }
      });
    };
  };

  uploadAsset = (file) => {
    assetApi.upload(file)
      .then(assets => this.setLink(assets, file))
      .catch(e => console.error(e));
  };

  render() {
    let show = this.props.showState !== 'closed';
    const isEditMode = this.isEditMode();

    let editOrSaveButton;
    if (isEditMode){
      editOrSaveButton = <LoadingButton className='btn btn-primary' onClick={this.save}
                           loading={this.state.isSaving}>Save</LoadingButton>
    }else{
      editOrSaveButton = <LoadingButton className='btn btn-primary' onClick={this.send}
                           loading={this.state.isSaving}>Send</LoadingButton>
    }

    if (show) {
      return (
        <Modal show={show} id='notifModal' onHide={this.props.hideModal}>
          <Modal.Header closeButton>
            <Modal.Title>Notification</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className='form-group'>
                <label>Title (English)</label>
                <input type='text'
                       className='form-control'
                       maxLength='100'
                       defaultValue={isEditMode ? this.props.notificationToEdit.NotificationTitle : ""}
                       required={this.state.selected.language.some(x => x.value.includes('en')) || this.state.selected.language.includes(ALL)}
                       disabled={!this.state.selected.language.some(x => x.value.includes('en')) && !this.state.selected.language.includes(ALL)}
                       ref={r => this.notificationTitle = r}/>
              </div>
              <div className='form-group'>
                <label>Title (French)</label>
                <input type='text'
                       className='form-control'
                       maxLength='100'
                       defaultValue={isEditMode ? this.props.notificationToEdit.NotificationTitleFr : ""}
                       required={this.state.selected.language.some(x => x.value.includes('fr')) || this.state.selected.language.includes(ALL)}
                       disabled={!this.state.selected.language.some(x => x.value.includes('fr')) && !this.state.selected.language.includes(ALL)}
                       ref={r => this.notificationTitleFr = r}/>
              </div>
              <div className='form-group'>
                <label>Body (English)</label>
                <textarea rows='3'
                          className='form-control'
                          maxLength='50000'
                          defaultValue={isEditMode ? this.props.notificationToEdit.Body : ""}
                          required={this.state.selected.language.some(x => x.value.includes('en')) || this.state.selected.language.includes(ALL)}
                          disabled={!this.state.selected.language.some(x => x.value.includes('en')) && !this.state.selected.language.includes(ALL)}
                          ref={r => this.body = r}/>
              </div>
              <div className='form-group'>
                <label>Body (French)</label>
                <textarea rows='3'
                          className='form-control'
                          defaultValue={isEditMode ? this.props.notificationToEdit.BodyFr : ""}
                          required={this.state.selected.language.some(x => x.value.includes('fr')) || this.state.selected.language.includes(ALL)}
                          disabled={!this.state.selected.language.some(x => x.value.includes('fr')) && !this.state.selected.language.includes(ALL)}
                          maxLength='50000'
                          ref={r => this.bodyFr = r}/>
              </div>
              <div className='form-group'>
                <label>Link</label>
                <input type='text' className='form-control' ref={r => this.link = r} defaultValue={isEditMode ? this.props.notificationToEdit.URLLink : ""}/>
                <div className='col-xs-12'>
                  <div className='file-box'>
                    <ImageUpload upload={this.uploadAsset}/>
                  </div>
                </div>
              </div>
              <div className='form-group'>
                <h2>Notification Type</h2>
                <select ref={r => this.type = r} className='form-control' defaultValue={isEditMode ? this.props.notificationToEdit.Type : ""}>
                  <option value='notification.types.update'>{notificationTypes['notification.types.update']}</option>
                  <option value='notification.types.highlight.feature'>{notificationTypes['notification.types.highlight.feature']}</option>
                  <option value='notification.types.annoucement'>{notificationTypes['notification.types.annoucement']}</option>
                  <option value='notification.types.alert'>{notificationTypes['notification.types.alert']}</option>
                </select>
              </div>
              <div className='form-group'>
                <label style={{paddingRight: 10}}>Send Email?</label>
                <input type='checkbox' ref={r => this.sendEmail = r} id='sendEmail' disabled={isEditMode}/>
              </div>
              <div className='row'>
                <div className='col-xs-6'>
                  <h4>States</h4>
                  <ReactSelect name='states' multi={true}
                               options={this.state.options.states}
                               value={this.state.selected.states}
                               onChange={this.updateFilter('states')}
                               disabled={isEditMode}/>
                </div>
                <div className='col-xs-6'>
                  <h4>DSM</h4>
                  <ReactSelect name='dsm' multi={true}
                               options={this.state.options.dsm}
                               value={this.state.selected.dsm}
                               onChange={this.updateFilter('dsm')}
                               disabled={isEditMode}/>
                </div>
              </div>

              <div className='row'>
                <div className='col-xs-6'>
                  <h4>RM</h4>
                  <ReactSelect name='rm'
                               options={this.state.options.rm}
                               value={this.state.selected.rm}
                               onChange={this.updateFilter('rm')}
                               disabled={isEditMode}/>
                </div>

                <div className='col-xs-6' key='platinumStatus'>
                  <h4>Platinum Status</h4>
                  <ReactSelect name='platinumStatus' multi={true}
                               options={this.state.options.platinumStatus}
                               value={this.state.selected.platinumStatus}
                               onChange={this.updateFilter('platinumStatus')}
                               disabled={isEditMode}/>
                </div>
              </div>

              <div className='row'>
                <div className='col-xs-6'>
                  <h4>Country</h4>
                  <ReactSelect name='country'
                               multi
                               value={this.state.selected.country}
                               options={this.state.options.country}
                               onChange={this.updateFilter('country')}
                               disabled={isEditMode}/>
                </div>

                <div className='col-xs-6'>
                  <h4>Language</h4>
                  <ReactSelect name='language'
                               multi
                               value={this.state.selected.language}
                               options={this.state.options.language}
                               onChange={this.updateFilter('language')}
                               disabled={isEditMode}/>
                </div>
              </div>
            </form>
            <ErrorList/>
          </Modal.Body>
          <Modal.Footer>
            <button className='btn btn-secondary' onClick={this.closeModal}>Cancel</button>
            {editOrSaveButton}
          </Modal.Footer>
        </Modal>
      );
    }

    return <div id='notifModal'/>;
  }
}

export default connectToToasts(NotifModal);
