import PropTypes from 'prop-types';

import React from 'react';

const maxPagedTotalResults = 10000;

// Row object
class Pagination extends React.Component {
  static propTypes = {
    baseUrl: PropTypes.string,
    count: PropTypes.number,
    maxButtons: PropTypes.number,
    onPageChange: PropTypes.func,
    page: PropTypes.number,
    total: PropTypes.number,
  };

  getValidPagesCount = () => {
    return this.props.total >= maxPagedTotalResults ?
     Math.floor(maxPagedTotalResults / this.props.count) : Math.ceil(this.props.total / this.props.count);
  };

  render() {
    let pageCount = this.getValidPagesCount();

    let prev;
    let next;
    let first;
    let last;
    let current = <li className='active'><span>{this.props.page}</span></li>;
    let prevPages = [];
    let nextPages = [];

    let maxButtons = this.props.maxButtons || 11;
    // Subtract 1 for the current button

    maxButtons -= 1;
    let buttonsPerSide = maxButtons / 2;

    // Ensure that the number of buttons always adds up to maxButtons
    let numButtonsOnRight = maxButtons - Math.min(buttonsPerSide, this.props.page - 1);
    let numButtonsOnLeft = maxButtons - Math.min(buttonsPerSide, pageCount - this.props.page);

    let onPageChange =  (page, event) => {
      event.preventDefault();
      if (this.props.onPageChange) {
        this.props.onPageChange(page, event);
      } else {
        window.location.hash = this.props.baseUrl + page;
      }
    };

    if (this.props.page > 1) {
      prev =
        <li>
          <a href='#' onClick={onPageChange.bind(null, this.props.page - 1)}
            data-event-category='User' data-event-action='paginate-prev' aria-label='Previous' className='previous'>
            <span aria-hidden='true'>&lt;</span>
          </a>
        </li>
      ;
    } else {
      prev = <li className='disabled'><span aria-hidden='true' className='previous'>&lt;</span></li>;
    }

    if (this.props.page < pageCount) {
      next =
        <li>
          <a href='#' onClick={onPageChange.bind(null, this.props.page + 1)}
            data-event-category='User' data-event-action='paginate-next' aria-label='Next' className='next'>
            <span aria-hidden='true'>&gt;</span>
          </a>
        </li>
      ;
    } else {
      next = <li className='disabled'><span aria-hidden='true' className='next'>&gt;</span></li>;
    }

    if (numButtonsOnLeft < this.props.page - 1) {
      first = [
        <li key='1.1'>
          <a onClick={onPageChange.bind(null, 1)} data-event-category='User'
            data-event-action='paginate-num' className="pagination-num">1
          </a>
        </li>,
        <li key='1.2'>
          <span>...</span>
        </li>,
      ];
      numButtonsOnLeft -= 2;
    } else {
      first = null;
    }

    if (numButtonsOnRight < pageCount - this.props.page) {
      last = [
        <li key='last.1'>
          <span>...</span>
        </li>,
        <li key='last.2'>
          <a onClick={onPageChange.bind(null, pageCount)} data-event-category='User'
            data-event-action='paginate-num' className="pagination-num">{pageCount}
          </a>
        </li>,
      ];
      numButtonsOnRight -= 2;
    } else {
      last = null;
    }

    for (let i = 1; i < numButtonsOnLeft + 1 && this.props.page - i > 0; i++) {
      prevPages.unshift(
        <li key={this.props.page - i}>
          <a onClick={onPageChange.bind(null, this.props.page - i)} data-event-category='User'
            data-event-action='paginate-num' className="pagination-num">{this.props.page - i}
          </a>
        </li>
      );
    }

    for (let x = 1; x < numButtonsOnRight + 1 && this.props.page + x <= pageCount; x++) {
      nextPages.push(
        <li key={this.props.page + x}>
          <a onClick={onPageChange.bind(null, this.props.page + x)} data-event-category='User'
            data-event-action='paginate-num' className="pagination-num">{this.props.page + x}
          </a>
        </li>
      );
    }

    /* To show record counts:
    let to = this.props.page * this.props.count;
    if (to > this.props.total) {
      to = this.props.total;
    }
        records {(this.props.page - 1) * this.props.count + 1} to {to} of {this.props.total}
    */

    return (
      <ul className='pagination'>
        {prev}
        {first}
        {prevPages}
        {current}
        {nextPages}
        {last}
        {next}
      </ul>
    );
  }
}

export default Pagination;
