import React from 'react';
import Schedule from '../schedule/schedule.jsx';
import {FormattedMessage} from 'react-intl';

function hideNode(e) {
  if (window.displayBrokenImages === 'False') {
    e.target.style.display = 'none';
  }
}

const Footer = ({data, children}) =>
  <div className="container footer" id="footer">
    <div className="row fine-print">
      <div className="col">
        {data.country === 'CA' ?
          <FormattedMessage id='report-viewer.legal.disclaimer.ca' /> :
          <FormattedMessage id='report-viewer.legal.disclaimer' />}
      </div>
    </div>
    <div className="row dividers">
      <div className="col-4">
        <img onError={hideNode} src={data.branchLogo} />
      </div>
      <div className="col-8">
        <Schedule data={data.storeHours} />

        <div className="row">
          <div className="col contact-info">
            {data.storeAddress ? data.storeAddress + ' | ' : ''}
            {data.storeTelephone ? data.storeTelephone + ' | ' : ''}
            {data.storeWebsite ? data.storeWebsite + ' | ' : ''}
            {data.storeEmail ? data.storeEmail : ''}
          </div>
        </div>
      </div>
    </div>
  </div>

;

export default Footer;
