class Locale {
  constructor({short, long, name}) {
    this.short = short;
    this.long = long;
    this.name = name;
  }
}

export const enUS = new Locale({short: 'en', long: 'en-US', name: 'English'});
export const frCA = new Locale({short: 'fr', long: 'fr-CA', name: 'French'});
