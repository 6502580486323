import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  title: PropTypes.node,
  rightTitle: PropTypes.node,
  leftTitle: PropTypes.any,
  children: PropTypes.any.isRequired,
  style: PropTypes.object,
  tools: PropTypes.node,
};

function IBox({title, children, style, tools, rightTitle, leftTitle}) {
  let iboxtitle = null;

  if (title != null) {
    iboxtitle = <div className='ibox-title ibox-title--flex'>
      {leftTitle ? <span className='ibox-left-title--flex'>{leftTitle}</span> : null}
      <h5 className='ibox-main-title--flex'>{title}</h5>
      {rightTitle ? <h6 className='ibox-right-title--flex'>{rightTitle}</h6> : null}
      {tools ? <div className='ibox-tools'>{tools}</div> : null}
    </div>;
  }
  return <div className='ibox ibox--flex' style={style}>
    {iboxtitle}
    <div className='ibox-content ibox-content--flex'>
      {children}
    </div>
  </div>;
}
IBox.propTypes = propTypes;

export default IBox;
