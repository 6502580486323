import React from 'react';
import PageHeader from '../../components/pageHeader.jsx';
import IBox from '../../components/iBox.jsx';
import {browserHistory} from 'react-router';
import Tab from 'react-bootstrap/lib/Tab';
import Tabs from 'react-bootstrap/lib/Tabs'
import PropTypes from 'prop-types';
import PageAnnouncementManagerList from './announcementsList.jsx';

class PageAnnouncementManager extends React.Component {
  static contextTypes = {
    canViewSupport: PropTypes.bool.isRequired,
    canEditSupport: PropTypes.bool.isRequired,
  };

  constructor(props){
    super(props);
    this.handleSelect = this.handleSelect.bind(this);

    this.state = {
      activeTab: 1
    };
  }

  state = {
    activeTab: 1
  };

  componentWillMount() {
    if (!this.context.canViewSupport) {
      browserHistory.push('/');
    }
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.filter !== this.state.filter || nextState.page !== this.state.page) {
      this.refresh(nextState.filter, nextState.page);
    }
  }

  handleSelect(key) {
    this.setState({ activeTab: key });
  }

  render() {
    return (
      <div>
        <PageHeader title='Announcement Manager' icon='fa fa-newspaper-o' breadcrumbs={[{title: 'Announcement Manager', path: '/announcements'}]} />
        <div className='wrapper wrapper-content'>
          <div className='row' id='page-announcement-manager'>
            <div className='col-md-12'>
              <IBox title='Announcement Manager'>
                <Tabs id="announcement-tab" className='tab-announcement' defaultActiveKey={1} onSelect={k => this.handleSelect(k)}>
                  <Tab eventKey={1} title='Consumer'>
                    {
                      this.state.activeTab == 1 ?
                        <PageAnnouncementManagerList 
                        tabSelected={this.state.activeTab} 
                        adminMode={false} />
                      : null
                    }
                  </Tab>
                  <Tab eventKey={2} title='Dealer'>
                    {
                      this.state.activeTab == 2 ?
                        <PageAnnouncementManagerList 
                        tabSelected={this.state.activeTab} 
                        adminMode={false} />
                      : null
                    }
                  </Tab>
                </Tabs> 
              </IBox>
            </div>
          </div>
        </div>
      </div>); 
  }
}

export default PageAnnouncementManager;
