import qwest from 'qwest';

function _process(xhr, response) {
  if (response.success) {
    return response;
  }
  throw response;
}

let ToolsAPI = {
  reindexCustomer() {
    return qwest.post('/api/Tools/ReindexCustomer').then(_process);
  },

  reindexCustomerCancel() {
    return qwest.post('/api/Tools/ReindexCustomerCancel').then(_process);
  },

  reindexCustomerStatus() {
    return qwest.get('/api/Tools/ReindexCustomerStatus').then(_process);
  },

  reindexDealer() {
    return qwest.post('/api/Tools/ReindexDealer').then(_process);
  },

  reindexAdminPages() {
    return qwest.post('/api/Tools/ReindexAdminPages').then(_process);
  },

  reindexPrintFiles() {
    return qwest.post('/api/Tools/ReindexPrintFiles').then(_process);
  },

  reindexCustomersInDealer(dealerID) {
    return qwest.post('/api/Tools/ReindexCustomersInDealer?dealerID=' + dealerID).then(_process);
  },

  elasticSearchDelete(index, ids) {
    return qwest.post('/api/Tools/ElasticSearchDelete', {
      index,
      ids,
    }).then(_process);
  },

  getPerformanceFileVariables() {
    return qwest.get('/api/performanceFile').then(_process);
  },

  setPerformanceFileVariables(adminReports, dashboard, reports) {
    return qwest.post('/api/performanceFile',
      {
        AdminReports: adminReports,
        Dashboard: dashboard,
        Reports: reports,
      }).then(_process);
  },

  transferBranches(requests) {
    return qwest.post('/api/Tools/Transfer', {
      transferRequests: requests,
    }, {timeout: 600000 * (requests || []).length}) // Give it 10 minutes per request.
    .then(_process);
  },
};

export default ToolsAPI;
