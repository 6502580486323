import AnalysisModel from '../../../../../Canopy/Javascript/models/analysis';
import Collapsible from 'components/collapsible';
import CustomerModel from '../../../../../Canopy/Javascript/models/customer';
import DealerModel from '../../../../../Canopy/Javascript/models/dealer';
import DiffResults from './Diff';
import IBox from 'components/iBox';
import PoolModel from '../../../../../Canopy/Javascript/models/pool';
import PropTypes from 'prop-types';
import React from 'react';
import ReportViewer from '../../../../../ReportViewer/layout.jsx';
import ReportWrapper from '../../../../../Canopy/Javascript/ui/components/ReportWrapper';
import {TabContainer, TabItem} from 'components/tabs';
import {cacheAndInjectData} from 'helpers/cacheAndInjectData';
import {connectToToasts} from 'components/toasts';
import {getAllAnalysesWithComparations, getTestByRuleAndID, deleteTestByRuleAndID, saveTest} from 'api/testing';
import {getProductNames} from 'api/product';
import {getToken} from 'helpers/tokens';

const ListSummary = ({subset, label, selectedTest, setSelectedTest}) => {
  return <div>
    <h3>{label}</h3>
    <Collapsible>
      <ul className={'tests'}>
        {subset.map(t => <li key={t.id}>
          <input type="radio" value={t.id} checked={selectedTest.id === t.id} onClick={() => setSelectedTest(t.rule, t.id)} />
          {t.id}
        </li>)}
      </ul>
    </Collapsible>
  </div>;
};

class Historical extends React.Component {

  static propTypes = {
    createToast: PropTypes.func.isRequired,
  };

  state = {
    tests: [],
    running: true,
    filter: '',
    selectedTest: {},
  };

  componentWillMount() {
    this.fetchData();
  }

  deleteTestFromList = () => {
    let {id} = this.state.selectedTest;
    let tempTests = this.state.tests;

    let deletedTest = tempTests.splice(tempTests.findIndex(i => i.id === id), 1);

    this.setState({
      alexMillAnalysisObj: null,
      raw: null,
      selectedTest: {},
      tests: tempTests,
      wardenAnalysisObj: null,
    });

    return deletedTest;
  };

  save = expectedResult => {
    let {rule, id} = this.state.selectedTest;
    let {dealer, customer, analysis, pool} = this.state;

    customer = new CustomerModel(customer || {}).toApiFormat();
    dealer = new DealerModel(dealer || {}).toApiFormat();
    analysis = new AnalysisModel(analysis || {}).toApiFormat();
    pool = new PoolModel(pool || {}).toApiFormat();

    let savedTest = this.deleteTestFromList();

    saveTest({
      dealer,
      customer,
      analysis,
      pool,
      name: rule,
      description: '',
      id,
      expectedResult,
      saveAsTests: true,
    })
    .then(result => {
      this.props.createToast({
        id: 'test.saved.successfully',
        message: `Test ${rule} saved.`,
        ttl: 4000,
      });
    })
    .catch(err => {
      console.log(err);

      this.setState({tests: this.state.tests.concat(savedTest)});

      this.props.createToast({
        id: 'test.dismissed.error',
        message: 'Failed to save test.',
        ttl: 4000,
      });
    });
  };

  dismiss = () => {
    let {rule, id} = this.state.selectedTest;

    let deletedTest = this.deleteTestFromList();

    deleteTestByRuleAndID(rule, id, true)
    .then(result => {
      this.props.createToast({
        id: 'test.dismiss.success',
        message: `Test ${rule} dismissed.`,
        ttl: 4000,
      });
    })
    .catch(err => {
      console.log(err);

      this.setState({tests: this.state.tests.concat(deletedTest)});

      this.props.createToast({
        id: 'test.dismiss.error',
        message: 'Failed to dismiss test.',
        ttl: 4000,
      });
    });
  }

  setSelectedTest = (rule, id) => {

    let test = {rule: rule, id: id};

    this.setState({selectedTest: test});
  }

  fetchData = () => {
    return getAllAnalysesWithComparations()
      .then(data => {
        this.setState({tests: data});
      })
      .catch(err => {
        console.error(err);

        this.props.createToast({
          id: 'test.case.error',
          message: 'Failed to load test case.',
          ttl: 4000,
        });
      });
  };

  run = () => {
    let {rule, id} = this.state.selectedTest;

    this.setState({
      alexMillAnalysisObj: null,
      wardenAnalysisObj: null,
      raw: null,
    });

    getTestByRuleAndID(rule, id)
    .then(result => {
      this.setState({
        alexMillAnalysisObj: result.alexMill ? result.alexMill.analysisObj : {},
        analysis: result.analysis,
        customer: result.customer,
        dealer: result.dealer,
        pool: result.pool,
        raw: result.raw,
        wardenAnalysisObj: result.warden.analysisObj,
      });
    })
    .catch(err => console.log(err));
  };

  export = () => {
    window.open('/api/testing/ExportHistoryTests?token=' + getToken()['id_token']);
  };

  render() {

    let {tests, filter, alexMillAnalysisObj, wardenAnalysisObj, selectedTest, raw} = this.state;

    const totalTests = tests;

    const listTests = !filter ? totalTests : totalTests.filter(x => x.id && x.id.toLowerCase().includes(filter.toLowerCase()));

    const existData = alexMillAnalysisObj && wardenAnalysisObj;

    // Lists
    let subsets = listTests.reduce((r, a) => {
      r[a.rule] = r[a.rule] || [];
      r[a.rule].push(a);
      return r;
    }, Object.create(null));

    let lists = Object.keys(subsets).map(key => {
      return {subset: subsets[key], label: key};
    });

    // Buttons
    let buttons = [];

    if(selectedTest.id) {
      buttons.push(<button key={0} onClick={this.run} className='btn btn-primary'>View Test</button>);
      buttons.push(<button key={1} onClick={() => this.dismiss()} className='btn btn-secondary'>Dismiss</button>);
    }

    buttons.push(<button key={2} disabled={!lists || !lists.length} onClick={this.export} className='btn btn-secondary'>Export</button>);

    return <div className="test-componentes">

      <div className="container-fluid">
        <div className='row'>
          <div className='col-md-2'>
            <div className={'left-menu'}>
              <div className='warden-test__sidebar'>
                <h2>Historical Tests</h2>
                <input
              type='text'
              className='form-control'
              placeholder='Filter'
              onChange={e => this.setState({filter: e.target.value})}
              style={{
                marginBottom: 30,
              }}/>
                <div className='lists'>
                  <div>
                    {lists.map(x => <ListSummary
                  key={x.label}
                  label={x.label}
                  selectedTest={selectedTest}
                  setSelectedTest={this.setSelectedTest}
                  subset={x.subset}/>)}
                  </div>
                </div>
                {buttons}
              </div>
            </div>
          </div>
          {existData ?
            <div className='col-md-10'>
              <TabContainer ibox ref={r => this.tabs = r} >
                <TabItem tabKey='printouts' label='Printouts'>
                  <div className='container-fluid'>
                    <div className='row'>
                      <div className='col-md-6'>
                        <IBox title='Alex Mill Printout'
                      rightTitle={< button className = 'btn btn-primary' onClick = {
                      () => this.save(raw.alexMill)} > Accept & Save </button>}>
                          <ReportWrapper><ReportViewer showControls={false} data={alexMillAnalysisObj}/></ReportWrapper>
                        </IBox>
                      </div>
                      <div className='col-md-6'>
                        <IBox title='Alex Pro Printout'
                      rightTitle={< button className = 'btn btn-primary' onClick = {
                      () => this.save(raw.warden)
                    } > Accept & Save </button>}>
                          <ReportWrapper><ReportViewer showControls={false} data={wardenAnalysisObj}/></ReportWrapper>
                        </IBox>
                      </div>
                    </div>
                  </div>
                </TabItem>
                <TabItem tabKey='diff' label='Diff' disabled={!existData}>
                  <div className='container-fluid'>
                    <DiffResults alexMill={raw.alexMill} warden={raw.warden} productNames={this.props.productNames} />
                  </div>
                </TabItem>
              </TabContainer>
            </div> :
      null}
        </div>
      </div>
    </div>;
  }
}

export default cacheAndInjectData({
  productNames() {
    return getProductNames();
  },
})(connectToToasts(Historical));
