import PropTypes from 'prop-types';
import React, {Component} from 'react';

import IBox from '../components/iBox.jsx';
import {Input} from '../components/form/index.jsx';

class EditZipCodes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      latitude: props.latitude,
      longitude: props.longitude,
      zipcode: props.zipcode,
    };
  }

  handleChange = e => {
    let state = this.state;

    state[e.key] = e.value;
    this.setState(state);
  };

  save = () => {
    return this.props.save(this.state);
  };

  render() {
    return <IBox title={this.props.id ? 'Edit' : 'Create'}>
      <div className='row'>
        <div className='col-md-4'>
          <Input value={this.state.zipcode} label='Zip Code' dataKey='zipcode' editMode={true} type='text' dataChanged={this.handleChange} />
        </div>
        <div className='col-md-4'>
          <Input value={this.state.latitude} label='Latitude' dataKey='latitude' editMode={true} type='number' dataChanged={this.handleChange} />
        </div>
        <div className='col-md-4'>
          <Input value={this.state.longitude} label='Longitude' dataKey='longitude' editMode={true} type='number' dataChanged={this.handleChange} />
        </div>
      </div>
      <div className='row'>
        <div className='col-md-12'>
          <div className='pull-right'>
            <button className='btn btn-cancel' onClick={this.props.cancel} style={{marginRight: 15}}>Cancel</button>
            <button className='btn btn-primary' onClick={this.save}>Save</button>
          </div>
        </div>
      </div>
    </IBox>;
  }
}
EditZipCodes.propTypes = {
  cancel: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  zipcode: PropTypes.number,
  id: PropTypes.number,
};

export default EditZipCodes;
