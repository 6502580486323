import qwest from 'qwest';

import events from '../lib/events.js';

qwest.setDefaultDataType('json');
qwest.setDefaultOptions({
  timeout: 120000,
});

qwest.raw = {
  get: qwest.get.bind(qwest),
  post: qwest.post.bind(qwest),
  put: qwest.put.bind(qwest),
  delete: qwest.delete.bind(qwest),
};

function wrapQwestFunc(qwestFunc) {
  return (...args) => {
    let qwestPromise = qwestFunc(...args);

    return qwestPromise.catch((xhr, response, error) => {
      events.publish('http-error', {xhr, response, error});
      return qwestPromise;
    });
  };
}

qwest.get = wrapQwestFunc(qwest.raw.get);
qwest.post = wrapQwestFunc(qwest.raw.post);
qwest.put = wrapQwestFunc(qwest.raw.put);
qwest.delete = wrapQwestFunc(qwest.raw.delete);
