import removeMd from 'remove-markdown';
import moment from 'moment';

class AnnouncementConsumer {

  constructor({
    id = 0,
    title = '',
    startDate = null,
    endDate = null,
    country = '',
    language = '',
    systemType = '',
    sanitizingGroup = '',
    priority = 0,
    contentURL = ''
  } = {}) {
    this.id = id;
    this.title = title;
    this.startDate = startDate;
    this.endDate = endDate;
    this.country = country;
    this.language = language;
    this.systemType = systemType;
    this.sanitizingGroup = sanitizingGroup;
    this.priority = priority;
    this.contentURL = contentURL;
  }

  toApiFormat() {
    return {
      ID: this.id,
      Title: this.title,
      StartDate: this.startDate,
      EndDate: this.endDate,
      Country: this.country,
      Language: this.language,
      SystemType: this.systemType,
      SanitizingGroup: this.sanitizingGroup,
      Priority: this.priority,
      ContentURL: this.ContentURL
    };
  }

  static fromApiFormat(api) {
    return new AnnouncementConsumer({
      id: api.ID,
      title: api.Title,
      startDate: api.StartDate,
      endDate: api.EndDate,
      country: api.Country,
      language: api.Language,
      systemType: api.SystemType,
      sanitizingGroup: api.SanitizingGroup,
      priority: api.Priority,
      contentURL: api.ContentURL
    });
  }
}

export default AnnouncementConsumer;
