import PropTypes from 'prop-types';
import React from 'react';
import toolsApi from 'api/tools';
import {Link} from 'react-router';

class NavBar extends React.Component {
  static contextTypes = {
    router: PropTypes.object.isRequired,
    canViewHealth: PropTypes.bool.isRequired,
    canUseTools: PropTypes.bool.isRequired,
    canViewReports: PropTypes.bool.isRequired,
    canUseNotifications: PropTypes.bool.isRequired,
    canViewPrintfiles: PropTypes.bool.isRequired,
    canViewSupport: PropTypes.bool.isRequired,
    canEditPoolScores: PropTypes.bool.isRequired,
    canViewProducts: PropTypes.bool.isRequired,
    canViewKpiDashboard: PropTypes.bool.isRequired,
    canViewPosDashboard: PropTypes.bool.isRequired,
    canViewTruBlueDashboard: PropTypes.bool.isRequired,
    canViewTruBlueClaims: PropTypes.bool.isRequired,
  };

  state = {
    user: null,
    profileOpen: '',
    navItems: [
      {title: 'Dashboard', link: '/dashboard', icon: 'fa fa-tachometer'},
      {title: 'Dealers', link: '/dealers', icon: 'fa fa-truck'},
      {title: 'Announcement Manager', link: '/announcements', icon: 'fa fa-newspaper-o', permission: 'canViewSupport'},
      {title: 'Health & Statistics', link: '/health', icon: 'fa fa-line-chart', permission: 'canViewHealth'},
      {title: 'Pool Score', link: '/pool-score/edit', icon: 'fa fa-calculator', permission: 'canEditPoolScores'},
      {title: 'Notifications', link: '/notifications', icon: 'fa fa-flag', permission: 'canUseNotifications'},
      {title: 'Manage Files', link: '/manage-files', icon: 'fa fa-file'},
      {title: 'Print Files', link: '/print-files', icon: 'fa fa-print', permission: 'canViewPrintfiles'},
      {title: 'Zip Codes', link: '/zipcodes', icon: 'fa fa-map-marker'},
      {title: 'Tru•Blue Claims', link: '/claims', icon: 'fa fa-map-marker', permission: 'canViewTruBlueClaims'},
      {title: 'Tru•Blue Config', link: '/trublueconfig', icon: 'fa fa-cog', permission: 'canViewTruBlueDashboard'},
      {title: 'Product Table', link: '/sales-products', icon: 'fa fa-credit-card', permission: 'canViewProducts'},
      {title: 'Testing', link: '/testing', icon: 'fa fa-flask'},
      //Developer section
      {title: 'Development', sectionDivider: true},
      {title: 'Dashboard', link: '/developer-dashboard', icon: 'fa fa-tasks'},
      {title: 'Tools', link: '/tools', icon: 'fa fa-wrench', permission: 'canUseTools'},
      {title: 'Documentation', link: '/documentation', icon: 'fa fa-file-text'},

    ],
  };

  componentDidMount() {
    toolsApi.getPerformanceFileVariables().then(x => {
      if (x.success) {

        var arr = this.state.navItems;

        if (x.data.Dashboard) {
          arr.splice(3, 0, {
            title: 'Tru•Blue Dashboard',
            link: '/trublue-dasboard',
            icon: 'fa fa-tachometer',
            permission: 'canViewTruBlueDashboard',
          });
        }

        if (x.data.AdminReports) {
          arr.splice(7, 0, {title: 'Reports', link: '/reports', icon: 'fa fa-pie-chart', permission: 'canViewReports'});
        }

        this.setState({
          navItems: arr,
        });

      }
    });
  }

  render() {
    let navItems = this.state.navItems
      .filter(nav => !nav.permission || this.context[nav.permission])
      .map((item, key) => {
        if(item.sectionDivider === true) {
          return (
            <div key={key} className='nav-section'>{item.title}</div>
          );
        }
        return (
          <li className='nav' key={key}>
            <Link to={item.link} activeClassName='active'><i className={item.icon}/> {item.title}</Link>
          </li>
        );
      });

    return (
      <nav className='navbar-default navbar-static-side' role='navigation'>
        <div className='sidebar-collapse'>
          <ul className='nav metismenu' id='side-menu'>
            {navItems}
          </ul>
        </div>
      </nav>);
  }
}

export default NavBar;
