import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { getImagePath } from "../../helpers/image-helper";
import IdealRangeParameter from "../ideal-range-parameter/ideal-range-parameter.jsx";
import classNames from "classnames";

const convertDataToTable = (parameters, size = 4) => {
  const rows = [];
  let cols = [];

  parameters.map((param, i) => {
    if (param) {
      cols.push(param);
    }

    if (cols.length === size) {
      rows.push(cols);
      cols = [];
    }
  });

  //Creates a new row for the remaining cols.
  if (cols.length > 0) {
    while (cols.length < size) {
      //Completes the row with empty cols, if it needs to.
      cols.push({});
    }
    rows.push(cols);
  }

  return rows;
};

function translateValue(val) {
  if (val === "Not Tested") {
    return <FormattedMessage id="general.not.tested" />;
  }

  if (val === "High") {
    return <FormattedMessage id="general.high" />;
  }

  if (val === "Low") {
    return <FormattedMessage id="general.low" />;
  }

  if (val === "Normal") {
    return <FormattedMessage id="general.normal" />;
  }

  if ((val || "").includes("Saturation Index")) {
    return (
      <span>
        (<FormattedMessage id="report-viewer.si.key.param" />)
      </span>
    );
  }

  return val;
}

function getColumnDetails(col, formatMessage, intl, singleRow = false) {
  return (
    <div
      className={classNames("param-container", {
        details: !col.title,
        inline: singleRow
      })}
    >
      <div>{col.title ? formatMessage({ id: col.title }) + ": " : ""}</div>
      <div className={classNames({ "param-value": !singleRow })}>
        {col.valid !== undefined && col.valid !== null && (
          <img
            src={getImagePath({
              file: col.valid ? "check.png" : "triangle_alerts.png",
              folder: "",
              locale: intl.locale
            })}
          />
        )}
        {translateValue(col.value)}
        {!!col.additionalValue ? (
          <span className="gray">
            {" "}
            | {translateValue(col.additionalValue)}{" "}
          </span>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

const KeyParametersRanges = ({ data, intl }) => {
  const { formatMessage } = intl;

  return (
    <div className="key-parameters-ranges">
      <div className="row" key="title">
        <div className="col-12">
          <h3>
            <FormattedMessage id="report-viewer.key-parameters.title" />{" "}
          </h3>
          <span className="gray">
            <FormattedMessage id="report-viewer.key-parameters.details" />
          </span>
        </div>
      </div>
      {convertDataToTable(data).map((row, i) => (
        <div className="row" key={i}>
          {row.map((col, j) => {
            const siParameter = ["Low", "Normal", "High"].includes(col.value);
            const siDescription = (col.value || "").includes(
              "Saturation Index"
            );
            const idealRangeTested =
              isFinite(col.value) && col.scale && col.ideal;
            const firstCell = i === 0 && j === 0;

            return (
              <div
                className={classNames({
                  "col-2": siParameter,
                  "endless-column": siParameter,
                  "col-4": siDescription,
                  gray: siDescription,
                  "col-3": !siParameter && !siDescription,
                  "col-range": idealRangeTested
                })}
                key={j}
              >
                {getColumnDetails(
                  col,
                  formatMessage,
                  intl,
                  !siParameter && !siDescription && !idealRangeTested
                )}
                {idealRangeTested ? (
                  <IdealRangeParameter
                    scale={col.scale}
                    ideal={col.ideal}
                    value={col.value}
                    valid={col.valid}
                    greatherThan={col.greatherThan}
                    lessThan={col.lessThan}
                  />
                ) : null}
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};

export default injectIntl(KeyParametersRanges);
