
import {getToken} from 'helpers/tokens';
import PropTypes from 'prop-types';
import React from 'react';
import IBox from 'components/IBox.jsx';
import PageHeader from 'components/PageHeader.jsx';
import EditReportRun from 'page/runReports/editReportRun';
import ReportTaskApi from 'api/reportTask';
import moment from 'moment';
import {connectToToasts, TOAST_STATES} from 'components/toasts';
import {browserHistory} from 'react-router';
import {reportTemplates} from 'helpers/constants.jsx';
import Pagination from 'components/Pagination.jsx';
import Loader from 'components/loader.jsx';

const PAGE_LENGTH = 100;

class PageRunReports extends React.Component {
  static contextTypes = {
    canViewReports: PropTypes.bool.isRequired,
  };

  static propTypes = {
    createToast: PropTypes.func.isRequired,
  };

  state = {
    reportTasks: [],
    editing: false,
    user: null,
    activePage: 1,
    total: 0,
    loaded: true,
  };

  componentWillMount() {
    let token = getToken();

    if (token) {
      this.setState({
        user: {
          email: token.profile.email,
          name: token.profile.name,
        },
      });
    }

    if (!this.context.canViewReports) {
      browserHistory.push('/');
      console.warn('Not authed.');
    }
  }

  componentDidMount() {
    this.fecthData();
  }

  fecthData = (page = null) => {
    this.setState({loaded: false});
    ReportTaskApi.getAll(page || this.state.activePage, PAGE_LENGTH)
      .then(data => {
        this.setState({
          loaded: true,
          activePage: data.page,
          reportTasks: data.results,
          total: data.total,
        });
      })
      .catch(err => {
        console.error(err);
      });
  }

  save = reportTask => {

    if (reportTask) {
      reportTask.initiatedBy = `${this.state.user.name} (${this.state.user.email})`;
    }

    ReportTaskApi.save(reportTask).then(() => {
      this.setState({
        editing: false,
      });
      this.fecthData();
    })
      .catch(err => {
        console.error(err);
        this.props.createToast({
          id: 'save-report-task-failure',
          message: err.message,
          ttl: 3000,
          state: TOAST_STATES.ERROR,
        });
      });
  };

  cancel = () => {
    this.setState({
      editing: false,
    });
  }

  getFinalResultFileName = (url) => {
    const chunks = url.split('/');

    return chunks[chunks.length - 1];
  }


  changePage = page => {
    this.fecthData(page);
  };


  render() {
    const {reportTasks, editing, loaded} = this.state;

    const rows = (reportTasks || []).map(reportTask =>
      <tr key={reportTask.id}>
        <td>{reportTask.title}</td>
        <td>{reportTemplates[reportTask.template]}</td>
        <td>{reportTask.status}</td>
        <td>{reportTask.reportId || '-'}</td>
        <td>{reportTask.dealerId || '-'}</td>
        <td>{reportTask.initiatedBy || '-'}</td>
        <td>{reportTask.timeToComplete ? `${reportTask.timeToComplete} ms` : '-'}</td>
        <td>{reportTask.finalResultsXLSX ?
          <a href={reportTask.finalResultsXLSX} target='_blank'>{this.getFinalResultFileName(reportTask.finalResultsXLSX)}</a> :
          '-'}</td>
        <td>{reportTask.finalResultsXLSX_FR ?
          <a href={reportTask.finalResultsXLSX_FR} target='_blank'>{this.getFinalResultFileName(reportTask.finalResultsXLSX_FR)}</a> :
          '-'}</td>
        <td>{reportTask.finalResultsCSV ?
          <a href={reportTask.finalResultsCSV} target='_blank'>{this.getFinalResultFileName(reportTask.finalResultsCSV)}</a> :
          '-'}</td>
        <td>{reportTask.finalResultsCSV_FR ?
          <a href={reportTask.finalResultsCSV_FR} target='_blank'>{this.getFinalResultFileName(reportTask.finalResultsCSV_FR)}</a> :
          '-'}</td>
        <td>{reportTask.created ? moment(reportTask.created).format('YYYY-MM-DD') : '-'}</td>
        <td>{reportTask.generated ? moment(reportTask.generated).format('YYYY-MM-DD') : '-'}</td>
        <td>{reportTask.archived ? moment(reportTask.archived).format('YYYY-MM-DD') : '-'}</td>
      </tr>
    );

    return (
      <div id='page-reports'>
        <PageHeader title='Run Reports' icon='fa fa-pie-chart' breadcrumbs={[]} />
        <div className='wrapper wrapper-content'>
          {editing ? <EditReportRun cancel={this.cancel} save={this.save} /> : null}
          <IBox title='Run Reports'>
            <div className='btn-group-padding'>
              <button className='btn btn-primary' disabled={editing} onClick={() => { this.setState({editing: true}); }}>Queue Report</button>
            </div>
            <Loader loaded={this.state.loaded}>
              <div className="row">
                <table className='table table-striped'>
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Template</th>
                      <th>Status</th>
                      <th>Report ID</th>
                      <th>Dealer ID</th>
                      <th>Initiated By</th>
                      <th>Time To Complete</th>
                      <th>Final Results XSLX</th>
                      <th>Final Results XSLX (French)</th>
                      <th>Final Results CSV</th>
                      <th>Final Results CSV (French)</th>
                      <th>Created</th>
                      <th>Generated</th>
                      <th>Archived</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rows}
                  </tbody>
                </table>
              </div>
              <div className="row">
                <Pagination
                onPageChange={this.changePage}
                page={this.state.activePage}
                count={PAGE_LENGTH}
                total={this.state.total}
                maxButtons={10} />
              </div>
            </Loader>
          </IBox>
        </div>
      </div>
    );
  }
}

export default connectToToasts(PageRunReports);
