function makeCancelable(promise) {
  let shouldResolve = true;
  let output = {
    cancel: function() {
      shouldResolve = false;
    },
    promise: function() {
      return new Promise((resolve, reject) => {
        promise.then(response => {
          if (shouldResolve) {
            resolve(response);
          } else {
            reject({canceled: true});
          }
        })
        .catch(err => {
          if (shouldResolve) {
            resolve(err);
          } else {
            reject({canceled: true});
          }
        });
      });
    },
  };

  return output;
}

export default makeCancelable;
