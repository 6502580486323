import PropTypes from 'prop-types';

import React from 'react';

let IBoxes = ({
  title,
  children,
}) =>
  <div className='ibox'>
    <div className='ibox-title'>
      <h5>{title}</h5>
    </div>
    {children ? React.Children.toArray(children).filter(x => x !== null && x !== undefined).map((child, i) =>
      <div className='ibox-content' key={i}>
        {child}
      </div>
    ) : null}
  </div>

;

IBoxes.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.any.isRequired,
};

export default IBoxes;
