import PropTypes from 'prop-types';

import React from 'react';
import Modal from 'components/Modal';
import {DropdownButton, MenuItem} from 'react-bootstrap';
import ErrorList from './errorList.jsx';

// Row object
class BranchModal extends React.Component {
  static propTypes = {
    showState: PropTypes.oneOf(['closed', 'create', 'edit']).isRequired,
    branch: PropTypes.object,
    dealerUsers: PropTypes.array.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  state = {
    branch: {},
  };

  componentWillReceiveProps(nextProps) {
    // If the modal is being opened...
    if (this.props.showState === 'closed' && nextProps.showState !== 'closed') {
      this.setState({
        branch: nextProps.branch.clone(),
      });
    }
  }

  addUser = (user) => {
    user.setRoleForBranch(this.state.branch, 'badmin');
    this.forceUpdate();
  };

  closeModal = () => {
    let branch = this.state.branch.clone();

    branch.name = this.name.value;
    branch.address1 = this.address1.value;
    branch.address2 = this.address2.value;
    branch.city = this.city.value;
    branch.postalCode = this.postalCode.value;
    branch.state = this.stateInput.value;
    branch.shipTo = this.shipTo.value;

    this.props.onSubmit(branch);
  };

  removeUser = (user) => {
    user.removeRoleForBranch(this.state.branch);
    this.forceUpdate();
  };

  renderDealerUserDropdownItems = (dealerUsers) => {
    return dealerUsers.map((user, index) => {
      return (
        <MenuItem key={index} onClick={this.addUser.bind(this, user)}>
          {user.name}
        </MenuItem>
      );
    });
  };

  renderDealerUsers = (dealerUsers) => {
    return dealerUsers.map((user, index) => {
      return (
        <tr key={index}>
          <td>{user.name}</td>
          <td>{user.email}</td>
          <td className='modify-buttons'>
            <button className='btn btn-xs btn-default' onClick={this.removeUser.bind(this, user)}>
              <i className='fa fa-minus'/>
            </button>
          </td>
        </tr>
      );
    });
  };

  render() {
    let show = this.props.showState !== 'closed';

    if (!show) {
      return <div/>;
    }
    let isCreating = this.props.showState === 'create';

    return (
      <Modal show={show} onHide={this.props.onCancel} id='branch-modal'>
        <Modal.Header closeButton>
          <Modal.Title>Branch</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className='form-group'>
              <label>Name</label>
              <input className='form-control' ref={r => this.name = r} defaultValue={this.state.branch.name}/>
            </div>
            <div className='form-group'>
              <label>Address Line 1</label>
              <input className='form-control' ref={r => this.address1 = r} defaultValue={this.state.branch.address1}/>
            </div>
            <div className='form-group'>
              <label>Address Line 2</label>
              <input className='form-control' ref={r => this.address2 = r} defaultValue={this.state.branch.address2}/>
            </div>
            <div className='form-group'>
              <label>City</label>
              <input className='form-control' ref={r => this.city = r} defaultValue={this.state.branch.city}/>
            </div>
            <div className='form-group'>
              <label>Zip</label>
              <input className='form-control' ref={r => this.postalCode = r} defaultValue={this.state.branch.postalCode}/>
            </div>
            <div className='form-group'>
              <label>State</label>
              <input className='form-control' maxLength='2' ref={r => this.stateInput = r} defaultValue={this.state.branch.state}/>
            </div>
            <div className='form-group'>
              <label>Ship To</label>
              <input className='form-control' ref={r => this.shipTo = r} defaultValue={this.state.branch.shipTo} />
            </div>
            {(() => {
              // Can't show this while creating because users can't be linked until there
              // is an ID
              if (!isCreating) {
                return (
                  <div className='form-group'>
                    <label>Admin Users</label>
                    <div className='add-admin-container'>
                      <DropdownButton bsStyle='default' bsSize='sm' title='Add User' id='add-admin'>
                        {this.renderDealerUserDropdownItems(this.props.dealerUsers)}
                      </DropdownButton>
                    </div>
                    <div className='user-table-container'>
                      <table className='table table-striped user-table'>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th/>
                          </tr>
                        </thead>
                        <tbody>
                          {this.renderDealerUsers(this.state.branch.relatedDealerUsers(this.props.dealerUsers))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                );
              }
              return null;
            })()}
          </form>
          <ErrorList />
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-secondary' onClick={this.props.onCancel}>Cancel</button>
          <button className='btn btn-primary' onClick={this.closeModal}>Save</button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default BranchModal;
