import EditableDatePicker from 'components/form/editableDatePicker';
import moment from 'moment';
import PropTypes from 'prop-types';

import React from 'react';

import Modal from 'components/Modal';

import _ from 'lodash';

import ErrorList from './errorList.jsx';

// Row object
class DealerInfoModal extends React.Component {
  static propTypes = {
    showState: PropTypes.oneOf(['closed', 'create', 'edit']).isRequired,
    dealer: PropTypes.object,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    validate: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      expiry: null,
      truBlueActive: props.dealer && props.dealer.truBlueActive && moment(props.dealer.truBlueActive).isValid() ?
        props.dealer.truBlueActive :
        null,
    };
  }

  componentDidMount() {
    this.closeModal = _.throttle(this._closeModal, 2000, {trailing: false});
  }

  componentWillReceiveProps(nextProps) {
    // If the modal is being opened...
    if (this.props.showState === 'closed' && nextProps.showState !== 'closed') {
      this.setState({
        invalidFields: {},
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state) {
      // Update the errorList
      let errors = Object.keys(this.state.invalidFields).map(key => this.state.invalidFields[key]);

      ErrorList.clearErrors();
      ErrorList.publishErrors(errors);
    }
  }

  onDateChange = newDate => {
    this.setState({expiry: newDate});
  };

  _closeModal = event => {
    if (event) {
      event.preventDefault();
    }
    let dealer = this.props.dealer.clone();

    dealer.name = this.name.value;
    dealer.ownerName = this.ownerName.value;
    dealer.platinumStatus = this.platinumStatus.value;
    dealer.ownerPhone = this.ownerPhone.value;
    dealer.ownerEmail = this.ownerEmail.value;
    dealer.soldTo = this.soldTo.value;
    dealer.dsmName = this.dsmName.value;
    dealer.dsmNumber = this.dsmNumber.value;
    dealer.rmName = this.rmName.value;
    dealer.rmNumber = this.rmNumber.value;
    dealer.fax = this.fax.value;
    dealer.address1 = this.address1.value;
    dealer.address2 = this.address2.value;
    dealer.city = this.city.value;
    dealer.state = this.stateInput.value;
    dealer.zip = this.zip.value;
    dealer.country = this.country.value;
    dealer.truBlueSignedUp = this.truBlueSignedUp.value;
    //ALEX-2590 - Turn off beta flag for new rules engine
    // dealer.beta = this.beta.value;
    dealer.poolScores = this.poolScores.checked;
    // ALEX ADMIN 2019 updates
    // dealer.keyParametersRanges = this.keyParametersRanges.checked;
    dealer.expiry = this.state.expiry;
    //ALEX-2590 - Turn off beta flag for new rules engine
    // dealer.beta = this.beta.checked;
    dealer.truBlueActive = this.state.truBlueActive;
    dealer.isDefunct = this.isDefunct.checked;
    dealer.isDemo = this.isDemo.checked;

    let validation = this._validate(dealer);

    if (!Object.keys(this._validate(dealer)).length) {
      this.props.onSubmit(dealer);
    } else {
      this.setState({invalidFields: validation});
    }
  };

  _validate = dealer => {
    let validation = {};

    if (!dealer.name) {
      validation.name = 'A dealership name must be provided';
    }

    if (!dealer.city) {
      validation.city = 'A city must be provided';
    }

    if (!dealer.state) {
      validation.state = 'A state must be provided';
    } else if (dealer.state.length > 2) {
      validation.state = 'State must be two characters or less';
    }

    return validation;
  };

  render() {
    let show = this.props.showState !== 'closed';
    let isCreating = this.props.showState === 'create';

    let title = isCreating ? 'Create New Dealer' : 'Dealer';

    if (show) {
      return <Modal show={show} onHide={this.props.onCancel} id='dealer-info-modal'>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <form onSubmit={this.closeModal}>
          <Modal.Body>
            <fieldset>
              <legend>Dealership Information</legend>
              <div className='row'>
                <div className='col-sm-12'>
                  <div className={'form-group required ' + (this.state.invalidFields.name ? 'has-error' : '')}>
                    <label>Dealership Name</label>
                    <input type='text' className='form-control' ref={r => this.name = r} defaultValue={this.props.dealer.name}/>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-6'>
                  <div className='form-group'>
                    <label>Administrator Name</label>
                    <input type='text' className='form-control' ref={r => this.ownerName = r}
                           defaultValue={this.props.dealer.ownerName}/>
                  </div>
                </div>
                <div className='col-sm-6'>
                  <div className='form-group'>
                    <div className='dropdown'>
                      <label>Dealer Platinum Status</label>
                      <select className='form-control' ref={r => this.platinumStatus = r}
                              defaultValue={this.props.dealer.platinumStatus}>
                        <option value='NP'>Non-Platinum</option>
                        <option value='PL'>Platinum</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-12'>
                  <div className='form-group'>
                    <label>Administrator Phone Number</label>
                    <input type='text' className='form-control' ref={r => this.ownerPhone = r}
                           defaultValue={this.props.dealer.ownerPhone}
                           placeholder='000-000-0000'/>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-12'>
                  <div className='form-group'>
                    <label>Administrator Email</label>
                    <input type='text' className='form-control' ref={r => this.ownerEmail = r}
                           defaultValue={this.props.dealer.ownerEmail}
                           placeholder='name@example.com'/>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-6'>
                  <div className='form-group text-center'>
                    <label>Defunct</label>
                    <input type='checkbox' className='form-control' ref={r => this.isDefunct = r}
                           defaultChecked={this.props.dealer.isDefunct} />
                  </div>
                </div>
                <div className='col-sm-6'>
                  <div className='form-group text-center'>
                    <label>Demo</label>
                    <input type='checkbox' className='form-control' ref={r => this.isDemo = r}
                           defaultChecked={this.props.dealer.isDemo} />
                  </div>
                </div>
              </div>
            </fieldset>
            <fieldset>
              <legend>Sales Information</legend>
              <div className='row'>
                <div className='col-sm-12'>
                  <div className='form-group'>
                    <label>Sold To</label>
                    <input className='form-control'
                           ref={r => this.soldTo = r} type='text' defaultValue={this.props.dealer.soldTo}
                           placeholder='000000'/>
                  </div>
                </div>
              </div>
            </fieldset>
            <fieldset>
              <legend>DSM Information</legend>
              <div className='row'>
                <div className='col-sm-6'>
                  <div className='form-group'>
                    <label>DSM Name</label>
                    <input type='text' className='form-control' ref={r => this.dsmName = r} defaultValue={this.props.dealer.dsmName}/>
                  </div>
                </div>
                <div className='col-sm-6'>
                  <div className='form-group'>
                    <label>DSM Number</label>
                    <input type='text' className='form-control'
                           ref={r => this.dsmNumber = r} defaultValue={this.props.dealer.dsmNumber}
                           placeholder='000'/>
                  </div>
                </div>
              </div>
            </fieldset>
            <fieldset>
              <legend>RM Information</legend>
              <div className='row'>
                <div className='col-sm-6'>
                  <div className='form-group'>
                    <label>RM Name</label>
                    <input type='text' className='form-control' ref={r => this.rmName = r} defaultValue={this.props.dealer.rmName}/>
                  </div>
                </div>
                <div className='col-sm-6'>
                  <div className='form-group'>
                    <label>RM Number</label>
                    <input type='number' min='0' step='1' className='form-control'
                           ref={r => this.rmNumber = r} defaultValue={this.props.dealer.rmNumber}
                           placeholder='000'/>
                  </div>
                </div>
              </div>
            </fieldset>
            <fieldset>
              <legend>Additional Information</legend>
              <div className='row'>
                <div className='col-sm-12'>
                  <div className='form-group'>
                    <label>Fax</label>
                    <input type='text' className='form-control' ref={r => this.fax = r}
                           defaultValue={this.props.dealer.fax}
                           placeholder='000-000-0000'/>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-12'>
                  <div className='form-group'>
                    <label>Address Line 1</label>
                    <input type='text' className='form-control' ref={r => this.address1 = r}
                           defaultValue={this.props.dealer.address1}/>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-12'>
                  <div className='form-group'>
                    <label>Address Line 2</label>
                    <input type='text' className='form-control' ref={r => this.address2 = r}
                           defaultValue={this.props.dealer.address2}/>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-6'>
                  <div className={'form-group ' + (this.state.invalidFields.city ? 'has-error' : '')}>
                    <label>City</label>
                    <input type='text' className='form-control' ref={r => this.city = r}
                           defaultValue={this.props.dealer.city}/>
                  </div>
                </div>
                <div className='col-sm-6'>
                  <div className={'form-group ' + (this.state.invalidFields.state ? 'has-error' : '')}>
                    <label>State</label>
                    <input type='text' className='form-control' ref={r => this.stateInput = r}
                           defaultValue={this.props.dealer.state}/>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-6'>
                  <div className='form-group'>
                    <label>Zip</label>
                    <input type='text' className='form-control' ref={r => this.zip = r}
                           defaultValue={this.props.dealer.zip}/>
                  </div>
                </div>
                <div className='col-sm-6'>
                  <div className='form-group'>
                    <label>Country</label>
                    <select className='form-control' ref={r => this.country = r} defaultValue={this.props.dealer.country}>
                      <option value='US'>United States</option>
                      <option value='CA'>Canada</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-6'>
                  <div className='form-group'>
                    <label>Tru•Blue Active</label>
                    <EditableDatePicker dataKey={'truBlueActive'}
                                        onChange={({key, value}) => this.setState({[key]: value})}
                                        editMode={true}
                                        allowEmpty={true}
                                        value={this.state.truBlueActive}
                                        dateFormat={'YYYY-MM-DD'}/>
                  </div>
                </div>
                <div className='col-sm-6'>
                  <div className='form-group'>
                    <label>Tru•Blue Signed Up By</label>
                    <input type='text' className='form-control' ref={r => this.truBlueSignedUp = r}
                           defaultValue={this.props.dealer.truBlueSignedUp}/>
                  </div>
                </div>
              </div>
              {/* ALEX-2590 - Turn off beta flag for new rules engine */ }
              {/* <div className='row'>
                <div className='col-sm-6'>
                  <div className='form-group'>
                    <label>Opt Into Beta</label>
                    <input type='checkbox' className='form-control' ref={r => this.beta = r}
                           defaultChecked={this.props.dealer.beta}/>
                  </div>
                </div>
              </div> */}
            </fieldset>
            <fieldset>
              <legend>Water Analysis Preferences</legend>
              <div className='row'>
                <div className='col-sm-6'>
                  <div className='form-group'>
                    <label>Show Water Quality Score</label>
                    <input type='checkbox' className='form-control' ref={r => this.poolScores = r}
                           defaultChecked={this.props.dealer.poolScores} />
                  </div>
                </div>
                {/* <div className='col-sm-6'>
                  <div className='form-group'>
                    <label>Show New Key Parameters Section</label>
                    <input type='checkbox' className='form-control' ref={r => this.keyParametersRanges = r}
                           defaultChecked={this.props.dealer.keyParametersRanges} />
                  </div>
                </div> */}
              </div>
            </fieldset>
            <ErrorList/>
          </Modal.Body>
          <Modal.Footer>
            <button className='btn btn-secondary pull-left' type='button' onClick={this.props.onCancel}>Cancel</button>
            <button className='btn btn-primary' type='submit'>Save</button>
          </Modal.Footer>
        </form>
      </Modal>;
    }

    return <div id='dealer-info-modal'/>;

  }
}

export default DealerInfoModal;
