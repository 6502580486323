import qwest from 'qwest';

import reportOptions from 'helpers/reportOptions';

let resolveSuccessful = promise => {
  return new Promise((resolve, reject) => {
    promise
    .then((xhr, response) => {
      if (response.success) {
        resolve(response.data);
      } else {
        reject(response);
      }
    });
  });
};

let TruBlueDashboard = {
  getDealerSummary(startDate, endDate, platinumStatus, dsm, rm, state) {
    return resolveSuccessful(qwest.post('/api/TruBlueDashboard/GetDealerSummary', {
      start: startDate,
      end: endDate,
      DSM: dsm,
      RM: rm,
      PlatinumStatus: platinumStatus,
      State: state,
    }, reportOptions));
  },

  getAccountSummary(startDate, endDate, platinumStatus, dsm, rm, state) {
    return resolveSuccessful(qwest.post('/api/TruBlueDashboard/GetAccountSummary', {
      start: startDate,
      end: endDate,
      DSM: dsm,
      RM: rm,
      PlatinumStatus: platinumStatus,
      State: state,
    }, reportOptions));
  },

  getSystemSummaryStatistics(startDate, endDate, platinumStatus, dsm, rm, state) {
    return resolveSuccessful(qwest.post('/api/TruBlueDashboard/GetSystemSummaryStatistics', {
      start: startDate,
      end: endDate,
      DSM: dsm,
      RM: rm,
      PlatinumStatus: platinumStatus,
      State: state,
    }, reportOptions));
  },

  getProducts(startDate, endDate, platinumStatus, dsm, rm, state) {
    return resolveSuccessful(qwest.post('/api/TruBlueDashboard/GetProducts', {
      start: startDate,
      end: endDate,
      DSM: dsm,
      RM: rm,
      PlatinumStatus: platinumStatus,
      State: state,
    }, reportOptions));
  },
};

export default TruBlueDashboard;
