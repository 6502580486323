import PropTypes from 'prop-types';

import React from 'react';

import DatePicker from 'react-datepicker';

import moment from 'moment';

class EditableDatePicker extends React.Component {
  static propTypes = {
    dataKey: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    editMode: PropTypes.bool.isRequired,
    dateFormat: PropTypes.string.isRequired,
    value: PropTypes.any,
    style: PropTypes.object,
    ['data-event-category']: PropTypes.any,
    ['data-event-action']: PropTypes.any,
    allowEmpty: PropTypes.bool,
  };

  handleChange = (e) => {
    if (this.props.onChange) {
      let newVal = moment(e);

      if (newVal.isValid()) {
        this.props.onChange({key: this.props.dataKey, value: newVal});
      } else {
        this.props.onChange({key: this.props.dataKey, value: null});
      }
    }
  };

  render() {
    let content;
    let displayDate = this.props.value ? moment(this.props.value) : this.props.value !== null || !this.props.allowEmpty ? moment() : null;
    let displayText = this.props.value ? moment(this.props.value).utc().format(this.props.dateFormat) : '(none)';

    if (this.props.editMode) {
      content =
        <DatePicker selected={displayDate} dateFormat={this.props.dateFormat} dateFormatCalendar={this.props.dateFormat}
                    onChange={this.handleChange}
                    data-event-category={this.props['data-event-category']}
                    data-event-action={this.props['data-event-action']}/>;
    } else {
      content = displayText;
    }

    let wrapperClass = 'editable-datepicker';

    if (!this.props.editMode) {
      wrapperClass += ' form-control-static';
    }

    return (
      <div className={wrapperClass} style={this.props.style}>
        {content}
      </div>
    );
  }
}

export default EditableDatePicker;
