import PropTypes from 'prop-types';

import React from 'react';

import events from '../../../lib/events.js';

class EditableTextArea extends React.Component {
  static defaultProps = {
    value: '',
    visible: true,
    required: false,
  };

  static propTypes = {
    dataKey: PropTypes.string.isRequired,
    editMode: PropTypes.bool.isRequired,
    maxLength: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    validate: PropTypes.func,
    value: PropTypes.string,
    required: PropTypes.bool,
    validateMessage: PropTypes.string,
    visible: PropTypes.bool,
    group: PropTypes.object,
    beforeFocus: PropTypes.func,
    rows: PropTypes.number,
    cols: PropTypes.number,
    style: PropTypes.object,
  };

  state = {
    correct: true,
  };

  componentWillMount() {
    if (this.props.dataKey) {
      let listener = events.subscribe('focus|' + this.props.dataKey, () => {
        this.underlying.focus();
        this.underlying.scrollIntoView(false);
      });

      this.listener = listener;
    }
  }

  componentDidMount() {
    if (this.props.dataKey && this.underlying) {
      let listener = events.subscribe('focus|' + this.props.dataKey, () => {
        this.underlying.focus();
        this.underlying.scrollIntoView(false);
      });

      this.listener = listener;
    }

    if (this.props.group) {
      this.props.group.addComponent(this, false);
    }
  }

  componentWillReceiveProps(newProps) {
    // Validate when props change
    if (this.state.validate) {
      this.state.validate(newProps.value);
    }
  }

  componentWillUnmount() {

    if (this.listener) {
      this.listener.remove();
    }

    if (this.props.group) {
      this.props.group.removeComponent(this);
    }
  }

  focus = () => {
    if (this.underlying) {
      if (this.props.beforeFocus) {
        this.props.beforeFocus();
      }
      this.underlying.focus();
      this.underlying.scrollIntoView();
    }
  };

  handleChange = (e) => {
    let val = e.target.value;

    this.props.onChange({
      key: this.props.dataKey,
      value: val,
    });
    let correct = this.state.correct;

    if (!correct) {
      this.validate(val);
    }
  };

  validate = (val = this.props.value) => {
    if (!this.props.visible) {
      return true;
    }

    if (this.props.required && !val) {
      this.setState({
        correct: false,
      });
      return false;
    }

    if (!this.state.correct && this.props.required && val && !this.props.value) {
      this.setState({
        correct: true,
      });
      return true;
    }

    if (!this.props.validate || !this.props.required) {
      this.setState({
        correct: true,
      });
      return true;
    }

    let validateResult = this.props.validate(val);

    if (validateResult === false) {
      this.setState({
        correct: false,
      });

      if (val && this.props.validateMessage) {
        events.publish('error.user', {
          message: this.props.validateMessage,
          key: this.props.dataKey,
        });
      }
      return false;
    }

    this.setState({correct: true});
    events.publish('cancel.error', {key: this.props.dataKey});
    return true;
  };

  render() {
    let content;
    let style = Object.assign({}, {maxWidth: '100%'}, this.props.style);

    if (this.props.editMode) {
      content =
        <textarea
          style={style}
          className={'form-control ' + (!this.state.correct ? 'error' : '') + (this.state.required ? ' bold-placeholder' : '')}
          ref={r => this.underlying = r}
          maxLength={this.props.maxLength}
          onChange={this.handleChange}
          placeholder={this.props.placeholder}
          rows={this.props.rows}
          cols={this.props.cols}
          value={this.props.value} />
      ;
    } else {
      content =
        <div className='form-control-static'>{this.props.value}</div>
      ;
    }

    return (
      <div>
        {content}
      </div>
    );
  }
}

export default EditableTextArea;
