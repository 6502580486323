import removeMd from 'remove-markdown';
import moment from 'moment';

class AnnouncementDealer {

  constructor({
    id = 0,
    title = '',
    startDate = null,
    endDate = null,
    country = '',
    language = '',
    productsCarried = '',
    priority = 0,
    contentURL = ''
  } = {}) {
    this.id = id;
    this.title = title;
    this.startDate = startDate;
    this.endDate = endDate;
    this.country = country;
    this.language = language;
    this.productsCarried = productsCarried;
    this.priority = priority;
    this.contentURL = contentURL;
  }

  toApiFormat() {
    return {
      ID: this.id,
      Title: this.title,
      StartDate: this.startDate,
      EndDate: this.endDate,
      Country: this.country,
      Language: this.language,
      ProductsCarried: this.productsCarried,
      Priority: this.priority,
      ContentURL: this.ContentURL
    };
  }

  static fromApiFormat(api) {
    return new AnnouncementDealer({
      id: api.ID,
      title: api.Title,
      startDate: api.StartDate,
      endDate: api.EndDate,
      country: api.Country,
      language: api.Language,
      productsCarried: api.ProductsCarried,
      priority: api.Priority,
      contentURL: api.ContentURL
    });
  }
}

export default AnnouncementDealer;
