import moment from 'moment';

class TruBlueConfig {
  constructor({
    regStartDate, regEndDate,
    claimStart, claimEnd,
    dealerTruBlueResetDate, customerTruBlueResetDate,
  }) {
    this.regStartDate = regStartDate ? moment(regStartDate) : null;
    this.regEndDate = regEndDate ? moment(regEndDate) : null;
    this.claimStart = claimStart ? moment(claimStart) : null;
    this.claimEnd = claimEnd ? moment(claimEnd) : null;
    this.dealerTruBlueResetDate = dealerTruBlueResetDate ? moment(dealerTruBlueResetDate) : null;
    this.customerTruBlueResetDate = customerTruBlueResetDate ? moment(customerTruBlueResetDate) : null;
  }

  clone() {
    return new TruBlueConfig(this);
  }

  submittable() {
    return moment.isMoment(this.regStartDate) && this.regStartDate.isValid() &&
    moment.isMoment(this.regEndDate) && this.regEndDate.isValid() &&
    moment.isMoment(this.claimStart) && this.claimStart.isValid() &&
    moment.isMoment(this.claimEnd) && this.claimEnd.isValid() &&
    moment.isMoment(this.dealerTruBlueResetDate) && this.dealerTruBlueResetDate.isValid() &&
    moment.isMoment(this.customerTruBlueResetDate) && this.customerTruBlueResetDate.isValid();
  }

  toApiFormat() {
    return JSON.stringify({
      regStartDate: this.regStartDate,
      regEndDate: this.regEndDate,
      claimStart: this.claimStart,
      claimEnd: this.claimEnd,
      dealerTruBlueResetDate: this.dealerTruBlueResetDate,
      customerTruBlueResetDate: this.customerTruBlueResetDate,
    });
  }

  static fromApiFormat(config) {
    return new TruBlueConfig(JSON.parse(config));
  }
}

export default TruBlueConfig;
