import PropTypes from 'prop-types';
import React, {Component} from 'react';

import Loader from '../components/loader.jsx';
import PageHeader from '../components/pageHeader.jsx';
import IBox from '../components/iBox.jsx';
import {browserHistory} from 'react-router';

import EditSalesProduct from './editSalesProduct.jsx';
import {Input} from '../components/form/index.jsx';
import {connectToToasts, TOAST_STATES} from '../components/toasts.jsx';

import SalesProductApi from '../../api/salesProduct.jsx';
import SalesProduct from '../../models/salesProduct.jsx';

import {containerType, mediaType, salesProductCategory, unitsOfMeasure, countriesNames} from '../helpers/constants.jsx';

import Pagination from '../components/Pagination.jsx';

import Modal from 'components/Modal';

class SalesProductPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activePage: 0,
      editting: null,
      loaded: false,
      missing: [],
      newProduct: new SalesProduct(),
      productToDelete: null,
      products: [],
      showModal: false,
    };
  }

  componentWillMount() {
    this.getInitial();

    if (!this.context.canViewProducts && !this.context.canEditProducts) {
      browserHistory.push('/');
    }
  }

  onCancel() {
    this.setState({
      showModal: false,
      productToDelete: null,
    });
  }

  getInitial = () => {
    SalesProductApi.getAll().then((products) => {
      this.setState({
        activePage: 1,
        editProduct: null,
        editting: null,
        updateProductMode: false,
        loaded: true,
        products: products.sort((a, b) => a.name < b.name ? -1 : 1),
        search: '',
      });
    })
    .catch(err => {
      console.error(err);
      this.props.createToast({
        id: 'get-products-failure',
        message: 'Failed to get sale products.',
        state: TOAST_STATES.ERROR,
        ttl: 6000,
      });
    });
  };

  cancelEdit = () => {
    this.setState({
      editProduct: null,
      editting: null,
      updateProductMode: false,
    });
  };

  changePage = page => {
    this.setState({
      activePage: page,
    });
  };

  confirmProductDelete(product) {
    this.setState({
      showModal: true,
      productToDelete: product,
    });
  }

  deleteProduct() {
    SalesProductApi.deleteProduct(this.state.productToDelete.id).then(() => {
      this.getInitial();
      this.setState({productToDelete: null, showModal: false});
    }).catch(err=>{
      console.error(err);
      this.props.createToast({
        id: 'delete-product-failure',
        message: err.message,
        ttl: 6000,
        state: TOAST_STATES.ERROR,
      });
    });
  }

  editItem = product => {
    this.setState({
      editProduct: product,
      editting: true,
      updateProductMode: true,
    });
  };

  save = product => {
    SalesProductApi.addProduct(product).then(() => {
      this.getInitial();
    })
    .catch(err => {
      console.error(err);
      this.props.createToast({
        id: 'save-product-failure',
        message: err.message,
        ttl: 6000,
        state: TOAST_STATES.ERROR,
      });
    });
  };

  search = ({value}) => {
    this.setState({
      search: value,
      activePage: 1,
    });
  };

  update = product => {
    SalesProductApi.updateProduct(product).then(() => {
      this.getInitial();
    })
    .catch(err => {
      console.error(err);
      this.props.createToast({
        id: 'save-product-failure',
        message: err.message,
        ttl: 6000,
        state: TOAST_STATES.ERROR,
      });
    });
  };

  render() {
    let rows = this.state.products;

    if (this.state.search) { // Searching in memory since there's isn't that much data and it isn't worth involving elasticsearch for this
      rows = rows.filter(x => (x.sku || '').toLowerCase().includes(this.state.search.toLowerCase()) ||
                              (x.name || '').toLowerCase().includes(this.state.search.toLowerCase()) ||
                              salesProductCategory[x.salesCategory].toLowerCase().includes(this.state.search.toLowerCase())
                        );
    }

    const pageSize = 10;
    const total = rows.length;

    rows = rows
      .slice((this.state.activePage - 1) * pageSize, this.state.activePage * pageSize)
      .map(z =>
        <tr key={z.id}
            onClick={this.context.canEditProducts ? this.editItem.bind(this,z) : () => {}}
            style={{cursor: 'pointer'}}>
          <td>{z.name}</td>
          <td>{salesProductCategory[z.salesCategory]}</td>
          <td>{z.sku}</td>
          <td>{z.caseUPC}</td>
          <td>{z.productUPC}</td>
          <td>{z.msrp}</td>
          <td>{z.amount}</td>
          <td>{unitsOfMeasure[z.unitOfMeasure]}</td>
          <td>{mediaType[z.mediaType] ? mediaType[z.mediaType] : '-'}</td>
          <td>{containerType[z.containerType] ? containerType[z.containerType] : '-'}</td>
          <td>{countriesNames[z.country]}</td>
          <td>
            {this.context.canEditProducts ?
              <button className='btn btn-danger' onClick={this.confirmProductDelete.bind(this,z)}>Delete</button> :
            null}
          </td>
        </tr>);

    return <div>
      <PageHeader title='Product Table' icon='fa fa-credit-card' breadcrumbs={[]} />
      <Modal show={this.state.showModal} onHide={this.onCancel.bind(this)} id='confirm-modal'>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete of the product Below</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>{this.state.productToDelete ? 'Name: ' + this.state.productToDelete.name : ''}</div>
          <div>{this.state.productToDelete ? 'Code: ' + this.state.productToDelete.sku : ''}</div>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-danger' type='submit' onClick={this.onCancel.bind(this)}>Cancel</button>
          <button className='btn btn-primary' type='submit' onClick={this.deleteProduct.bind(this)}>Confirm</button>
        </Modal.Footer>
      </Modal>

      <Loader loaded={this.state.loaded}>
        <div>
          {this.state.editting ? <EditSalesProduct
                                    cancel={this.cancelEdit}
                                    save={this.save}
                                    product={this.state.editProduct}
                                    update={this.update}
                                    updateProductMode={this.state.updateProductMode}
                                  /> : null }

          <IBox title='Current Products'>
            <div className='row'>
              <div className='col-md-11'>
                <Input value={this.state.search} dataChanged={this.search} placeholder='Search' editMode={true} />
              </div>
              {this.context.canEditProducts ?
                <div className='col-md-1'>
                  <button className='btn btn-primary'
                          style={{marginTop: 18}}
                          onClick={() => this.setState({editting: true})}>
                    Create
                  </button>
                </div> :
                null}
              <div className='col-md-12'>
                <table className='table table-striped table-hover'>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Sales Category</th>
                      <th>Code</th>
                      <th>Case UPC</th>
                      <th>Product UPC</th>
                      <th>MSRP</th>
                      <th>Amount (grams, ml, units)</th>
                      <th>Unit of Measure</th>
                      <th>Media Type</th>
                      <th>Container Type</th>
                      <th>Country</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rows}
                  </tbody>
                </table>
                <Pagination
                  onPageChange={this.changePage}
                  page={this.state.activePage}
                  count={pageSize}
                  total={total}
                  maxButtons={10}/>
              </div>
            </div>
          </IBox>
        </div>
      </Loader>
    </div>;
  }
}

SalesProductPage.propTypes = {
  createToast: PropTypes.func.isRequired,
};

SalesProductPage.contextTypes = {
  canEditProducts: PropTypes.bool.isRequired,
  canViewProducts: PropTypes.bool.isRequired,
};

export default connectToToasts(SalesProductPage);
