import PropTypes from 'prop-types';

import React from 'react';

class EditableCheckbox extends React.Component {
  static propTypes = {
    class: PropTypes.string,
    dataKey: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    editMode: PropTypes.bool.isRequired,
    hideLabel: PropTypes.bool,
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.bool,
    checked: PropTypes.bool,
  };

  handleChange = () => {
    this.props.onChange({key: this.props.dataKey, value: !this.props.value});
  };

  render() {
    let content;

    if (this.props.editMode) {
      content = <label>
        <input type='checkbox' className='checkbox' value={this.props.value} onChange={this.handleChange} disabled={this.props.disabled} checked={this.props.checked} />
        {this.props.hideLabel ? null : this.props.label}
      </label>;
    } else {
      content = <i className={this.props.value ? 'bl bl-checkmark' : 'bl bl-x'} />;
    }

    let wrapperClass = 'editable-checkbox';

    if (this.props.class) {
      wrapperClass += ' ' + this.props.class;
    }

    return (
      <div className={wrapperClass}>
        <div className='form-control-static'>
          {content}
        </div>
      </div>
    );
  }
}

export default EditableCheckbox;
