const escapeName = (obj, name) => obj.hasOwnProperty(name) ? '_' + name : name;
const unescapeName = (obj, name) => obj.hasOwnProperty(name.slice(1)) && name[0] === '_' ? name.slice(1) : name;

function createModel({name, properties}) {
  const keys = Object.keys(properties);

  class _Model {
    constructor(p, ...ps) {
      let props = p;

      if (ps && ps.length) {
        props = [p].concat(ps);
      }

      if (props instanceof Array) {
        for (let i = 0; i < keys.length; ++i) {
          const key = keys[i];

          this[escapeName(this, key)] = props.length > i ? props[i] : properties[key];
        }
      } else {
        for (let key of keys) {
          this[escapeName(this, key)] = props.hasOwnProperty(key) ? props[key] : properties[key];
        }
      }
    }

    static instanceOf(obj) {
      return obj instanceof _Model;
    }
  }

  for (let key of keys) {
    _Model[escapeName(_Model, key)] = obj => obj instanceof _Model ? obj[key] : obj;
  }

  _Model.displayName = name;

  return _Model;
}

export default createModel;
