import React from 'react';
import {reportTypes} from './constants-helper';

var helper = {
  showHeader(reportType, specialReportType) {
    return specialReportType !== 0;
  },
  showFooter(reportType, specialReportType) {
    return specialReportType !== 0;
  },
  showPoolScores(reportType) {
    return reportType !== reportTypes.winterClosing &&
      reportType !== reportTypes.optimizerPlus &&
      reportType !== reportTypes.systemConversion;
  },
  showRegularMaintenance(reportType, specialReportType, hideOnWinterClosing) {
    return (
      reportType !== reportTypes.chlorineDemand &&
      reportType !== reportTypes.winterClosing &&
      reportType !== reportTypes.seasonsUsage &&
      reportType !== reportTypes.qcSeasonsUsage &&
      reportType !== reportTypes.systemConversion &&
      reportType !== reportTypes.optimizerPlus &&
      specialReportType !== 0 && !hideOnWinterClosing
    );
  },
  showWaterQualityScore(reportType, specialReportType) {
    return (
      reportType !== reportTypes.chlorineDemand &&
      reportType !== reportTypes.seasonsUsage &&
      reportType !== reportTypes.qcSeasonsUsage &&
      reportType !== reportTypes.optimizerPlus &&
      reportType !== reportTypes.systemConversion &&
      specialReportType !== 0
    );
  },
  showProblemSolutions(reportType, problemAreas) {
    return reportType !== reportTypes.systemConversion &&
      reportType !== reportTypes.optimizerPlus &&
      reportType !== reportTypes.seasonsUsage &&
      reportType !== reportTypes.qcSeasonsUsage &&
      problemAreas &&
      problemAreas.length > 0;
  },
  showCongratulations(reportType, problemAreas) {
    return reportType !== reportTypes.seasonsUsage &&
      reportType !== reportTypes.qcSeasonsUsage &&
      problemAreas &&
      problemAreas.length <= 0;
  },
  showMaintenanceSchedule(reportType) {
    return reportType === reportTypes.seasonsUsage ||
      reportType === reportTypes.qcSeasonsUsage ||
      reportType === reportTypes.systemConversion ||
      reportType === reportTypes.optimizerPlus;
  },
  showShoppingList(reportType) {
    return reportType === reportTypes.seasonsUsage ||
      reportType === reportTypes.qcSeasonsUsage;
  },
  showTruBlueBanner(isDealerTruBlue, hideTruBlueBanner) {
    return isDealerTruBlue && !hideTruBlueBanner;
  },
  showSuggestedProducts(reportType, suggestedProducts) {
    return reportType !== reportTypes.systemConversion &&
      reportType !== reportTypes.optimizerPlus &&
      reportType !== reportTypes.seasonsUsage &&
      reportType !== reportTypes.qcSeasonsUsage &&
      suggestedProducts &&
      suggestedProducts.length > 0;
  },
};

export default helper;
