import PropTypes from 'prop-types';

import React from 'react';

import _ from 'lodash';

import events from '../../../lib/events.js';

const intOrString = val => {
  let integral = parseInt(val);
  let newVal = isNaN(integral) || integral.toString().length !== val.length ? val : integral;

  return newVal;
};

class EditableSelectField extends React.Component {
  static defaultProps = {
    optionsChange: false,
  };

  static propTypes = {
    dataKey: PropTypes.string.isRequired,
    editMode: PropTypes.bool.isRequired,
    keys: PropTypes.array,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    optionsChange: PropTypes.bool,
    value: PropTypes.any,
    'data-event-action': PropTypes.string,
    'data-event-category': PropTypes.string,
  };

  state = {
    listener: null,
  };

  componentWillMount() {
    if (this.props.dataKey) {
      let listener = events.subscribe('focus|' + this.props.dataKey, () => {
        this.underlying.focus();
        this.underlying.scrollIntoView(false);
      });

      this.setState({listener});
    }

    if (this.props.onChange && this.props.dataKey) {
      let value = this.props.value;

      if (!value && value !== 0 && this.props.keys && this.props.keys.length) {
        value = this.props.keys[0];
      }

      if (!value && value !== 0 && this.props.options && this.props.options.length) {
        value = this.props.options[0];
      }

      this.props.onChange({key: this.props.dataKey, value});
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.optionsChange && nextProps.optionsChange && !_.isEqual(nextProps.options, this.props.options)) {
      let val = nextProps.value;

      if (nextProps.keys && nextProps.keys.length > 0 && nextProps.keys.indexOf(nextProps.value) === -1) {
        val = nextProps.keys[0];
      } else if (nextProps.options && nextProps.options.length > 0 && nextProps.options.indexOf(nextProps.value) === -1) {
        val = nextProps.options[0];
      } else if (nextProps.options && nextProps.options.length === 0) {
        val = null;
      }

      if (this.props.onChange && this.props.dataKey) {
        this.props.onChange({key: this.props.dataKey, value: intOrString(val)});
      }
    }
  }

  componentWillUnmount() {
    if (this.state.listener) {
      this.state.listener.remove();
    }
  }

  handleChange = (e) => {
    if (this.props.onChange) {
      let val = parseInt(e.target.value);

      val = isNaN(val) || val.toString().length !== e.target.value.length ? e.target.value : val;
      this.props.onChange({key: this.props.dataKey, value: val});
    }
  };

  render() {
    let content;

    let displayText;

    if (this.props.keys && this.props.keys.length > 0) {
      displayText = this.props.options[this.props.keys.indexOf(this.props.value)];
    } else {
      displayText = this.props.value;
    }

    if (this.props.editMode) {

      let optionsList = [];

      for (let i = 0; i < this.props.options.length; ++i) {
        let item = this.props.options[i];
        let key = this.props.options[i];

        if (this.props.keys && this.props.keys.length > 0) {
          key = this.props.keys[i];
        }
        optionsList.push(<option key={key} value={key}>{item}</option>);
      }

      content =
        <select value={this.props.value} className='form-control' onChange={this.handleChange} ref={r => this.underlying = r}
          data-event-category={this.props['data-event-category']} data-event-action={this.props['data-event-action']} >
          {optionsList}
        </select>
      ;
    } else {
      content = <div className='form-control-static'>{displayText}</div>;
    }

    return (
      <div className='editable-selectfield'>{content}</div>
    );
  }
}

export default EditableSelectField;
