import qwest from 'qwest';

let CustomerApi = {
  GetCustomerBasicInfo(id) {
    return qwest.get(`/api/customer/GetCustomerBasicInfo?id=${id}`)
            .then((xhr, response) => {
              if (response.success) {
                return response.data;
              }
              throw response;
            });
  },
};

export default CustomerApi;
