import qwest from 'qwest';

import TaskModel from '../models/task.jsx';

let TaskApi = {
  getCompletedTasks(includes = null, excludes = null) {
    return qwest.get(`/api/Task/Index/?includeTypes=${includes}&excludeTypes=${excludes}`)
      .then((xhr, response) => {
        return response.data.map(task => TaskModel.fromApiFormat(task));
      });
  },
};

export default TaskApi;
