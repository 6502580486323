import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import {chemicalBaseTypes, systemTypes} from 'helpers/constants';

class Pool {
  constructor({
    id, customerID, typeID = 1, volume, chemicalBaseTypeID, finish, property, location, groundLevel, ionization, ozonator, jettedReturns, sanitizer,
    maintOxidizer, prevAlgicide, pHAdjuster, pHBuffer, winterOxidizer, winterAlgicide, enzymes, spaSilken, borates, softswimAssist, dateBuilt,
    infloorCleaner, mainDrain, other1, other2, other3, other4, other5, detailsActiveInd, useMaintClarifier,oxysheenTreatment, usingNaturalResult,
    ecoKleanConvertedInd, filterMedia, usingVanishingAct, idealSaltPPM, maintClarifier, feedMethod, smartPAK, cannotVacToWaste, usingPoolTonic, uv,
    usingGoodbyeDry, usingAngryEgg, softSoakTRIO, stainScale, created, modified, imported, deleted, usingOptimizerPlus, seasonStart, seasonEnd, oxidizer,
    waterlineControl, waterfall, waterFountain, attachedSpa, filterCleanedDate, mSCellCleanedDate, prevPossibleCD, sourceConcern,
    winterMetalStaining, nextAnalysisDate, prevAnalysisDate, flag, installedByDealer, truBlue, truBlueExpiry, stabilizer,
    poolOpeningComplete, lastCleanedDrained, truBlueStatus, scanCount, nickName, hasAllTruBlueProducts, isInMaintenance, spaComplete, usingSmartShield,
    synergyClean, optimizerProductID, balanceComplete,
  }) {
    this.id = id;
    this.customerID = customerID;
    this.typeID = typeID;
    this.volume = volume;
    this.chemicalBaseTypeID = chemicalBaseTypeID;
    this.finish = finish;
    this.property = property;
    this.location = location;
    this.groundLevel = groundLevel;
    this.ionization = ionization;
    this.ozonator = ozonator;
    this.jettedReturns = jettedReturns;
    this.sanitizer = sanitizer;
    this.oxidizer = oxidizer;
    this.maintOxidizer = maintOxidizer;
    this.prevAlgicide = prevAlgicide;
    this.pHAdjuster = pHAdjuster;
    this.pHBuffer = pHBuffer;
    this.winterOxidizer = winterOxidizer;
    this.winterAlgicide = winterAlgicide;
    this.enzymes = enzymes;
    this.spaSilken = spaSilken;
    this.borates = borates;
    this.softswimAssist = softswimAssist;
    this.dateBuilt = dateBuilt;
    this.infloorCleaner = infloorCleaner;
    this.mainDrain = mainDrain;
    this.other1 = other1;
    this.other2 = other2;
    this.other3 = other3;
    this.other4 = other4;
    this.other5 = other5;
    this.detailsActiveInd = detailsActiveInd;
    this.useMaintClarifier = useMaintClarifier;
    this.oxysheenTreatment = oxysheenTreatment;
    this.usingNaturalResult = usingNaturalResult;
    this.ecoKleanConvertedInd = ecoKleanConvertedInd;
    this.filterMedia = filterMedia;
    this.usingVanishingAct = usingVanishingAct;
    this.idealSaltPPM = idealSaltPPM;
    this.maintClarifier = maintClarifier;
    this.feedMethod = feedMethod;
    this.smartPAK = smartPAK;
    this.cannotVacToWaste = cannotVacToWaste;
    this.usingPoolTonic = usingPoolTonic;
    this.uv = uv;
    this.usingGoodbyeDry = usingGoodbyeDry;
    this.usingAngryEgg = usingAngryEgg;
    this.softSoakTRIO = softSoakTRIO;
    this.stainScale = stainScale;
    this.created = moment(created);
    this.modified = moment(modified);
    this.imported = imported;
    this.deleted = deleted;
    this.usingOptimizerPlus = usingOptimizerPlus;
    this.seasonEnd = seasonEnd ? moment(seasonEnd) : moment('10 01', 'MM DD');
    this.seasonStart = seasonStart ? moment(seasonStart) : moment('04 01', 'MM DD');
    this.waterlineControl = waterlineControl;
    this.waterfall = waterfall;
    this.waterFountain = waterFountain;
    this.attachedSpa = attachedSpa;
    this.prevPossibleCD = prevPossibleCD;
    this.filterCleanedDate = filterCleanedDate ? moment(filterCleanedDate) : filterCleanedDate;
    this.mSCellCleanedDate = mSCellCleanedDate ? moment(mSCellCleanedDate) : mSCellCleanedDate;
    this.sourceConcern = sourceConcern;
    this.winterMetalStaining = winterMetalStaining;
    this.nextAnalysisDate = nextAnalysisDate ? moment(nextAnalysisDate) : nextAnalysisDate;
    this.prevAnalysisDate = prevAnalysisDate ? moment(prevAnalysisDate) : prevAnalysisDate;
    this.flag = flag;
    this.installedByDealer = installedByDealer;
    this.truBlue = truBlue;
    this.truBlueExpiry = truBlueExpiry;
    this.stabilizer = stabilizer;
    this.poolOpeningComplete = poolOpeningComplete;
    this.lastCleanedDrained = lastCleanedDrained ? moment(lastCleanedDrained) : lastCleanedDrained;
    this.truBlueStatus = truBlueStatus;
    this.scanCount = scanCount;
    this.nickName = nickName;
    this.hasAllTruBlueProducts = hasAllTruBlueProducts;
    this.isInMaintenance = isInMaintenance;
    this.spaComplete = spaComplete;
    this.usingSmartShield = usingSmartShield;
    this.synergyClean = synergyClean;
    this.optimizerProductID = optimizerProductID;
    this.balanceComplete = balanceComplete;
  }

  clone() {
    return cloneDeep(this);
  }

  get isTruBlueActive() {
    return this.truBlue && moment.utc().isBefore(this.truBlueExpiry);
  }

  get isTruBlueIneligible() {
    return this.typeID !== systemTypes.pool || this.chemicalBaseTypeID !== chemicalBaseTypes.chlorinePool &&
      this.chemicalBaseTypeID !== chemicalBaseTypes.synergyPool &&
      this.chemicalBaseTypeID !== chemicalBaseTypes.mineralSpringsPool &&
      this.chemicalBaseTypeID !== chemicalBaseTypes.saltWaterPool;
  }

  toApiFormat() {
    return {
      ID: this.id,
      CustomerID: this.customerID,
      TypeID: this.typeID,
      Volume: this.volume,
      ChemicalBaseTypeID: this.chemicalBaseTypeID,
      Finish: this.finish,
      Property: this.property,
      Location: this.location,
      GroundLevel: this.groundLevel,
      Ionization: this.ionization,
      Ozonator: this.ozonator,
      JettedReturns: this.jettedReturns,
      Sanitizer: this.sanitizer,
      Oxidizer: this.oxidizer,
      MaintOxidizer: this.maintOxidizer,
      PrevAlgicide: this.prevAlgicide,
      pHAdjuster: this.pHAdjuster,
      pHBuffer: this.pHBuffer,
      WinterOxidizer: this.winterOxidizer,
      WinterAlgicide: this.winterAlgicide,
      Enzymes: this.enzymes,
      SpaSilken: this.spaSilken,
      Borates: this.borates,
      SoftswimAssist: this.softswimAssist,
      DateBuilt: this.dateBuilt,
      InfloorCleaner: this.infloorCleaner,
      MainDrain: this.mainDrain,
      Other1: this.other1,
      Other2: this.other2,
      Other3: this.other3,
      Other4: this.other4,
      Other5: this.other5,
      DetailsActiveInd: this.detailsActiveInd,
      UseMaintClarifier: this.useMaintClarifier,
      OxysheenTreatment: this.oxysheenTreatment,
      UsingNaturalResult: this.usingNaturalResult,
      EcoKleanConvertedInd: this.ecoKleanConvertedInd,
      FilterMedia: this.filterMedia,
      UsingVanishingAct: this.usingVanishingAct,
      IdealSaltPPM: this.idealSaltPPM,
      MaintClarifier: this.maintClarifier,
      FeedMethod: this.feedMethod,
      SmartPAK: this.smartPAK,
      CannotVacToWaste: this.cannotVacToWaste,
      UsingPoolTonic: this.usingPoolTonic,
      UV: this.uv,
      UsingGoodbyeDry: this.usingGoodbyeDry,
      UsingAngryEgg: this.usingAngryEgg,
      SoftSoakTRIO: this.softSoakTRIO,
      StainScale: this.stainScale,
      Created: moment(this.created),
      Modified: moment(this.modified),
      Imported: moment(this.imported),
      Deleted: this.deleted,
      UsingOptimizerPlus: this.usingOptimizerPlus,
      SeasonStart: moment(this.seasonStart),
      SeasonEnd: moment(this.seasonEnd),
      WaterlineControl: this.waterlineControl,
      Waterfall: this.waterfall,
      WaterFountain: this.waterFountain,
      AttachedSpa: this.attachedSpa,
      FilterCleanedDate: this.filterCleanedDate ? moment(this.filterCleanedDate) : this.filterCleanedDate,
      MSCellCleanedDate: this.mSCellCleanedDate ? moment(this.mSCellCleanedDate) : this.mSCellCleanedDate,
      SourceConcern: this.sourceConcern,
      WinterMetalStaining: this.winterMetalStaining,
      NextAnalysisDate: this.nextAnalysisDate ? moment(this.nextAnalysisDate) : this.nextAnalysisDate,
      PrevAnalysisDate: this.prevAnalysisDate ? moment(this.prevAnalysisDate) : this.prevAnalysisDate,
      Flag: this.flag,
      InstalledByDealer: this.installedByDealer,
      TruBlue: this.truBlue ? this.truBlue.format() : this.truBlue,
      TruBlueExpiry: this.truBlueExpiry ? this.truBlueExpiry.format() : this.truBlueExpiry,
      Stabilizer: this.stabilizer,
      PoolOpeningComplete: this.poolOpeningComplete,
      LastCleanedDrained: this.lastCleanedDrained ? moment(this.lastCleanedDrained) : this.lastCleanedDrained,
      TruBlueStatus: this.truBlueStatus,
      ScanCount: this.scanCount,
      NickName: this.nickName,
      HasAllTruBlueProducts: this.hasAllTruBlueProducts,
      IsInMaintenance: this.isInMaintenance,
      SpaComplete: this.spaComplete,
      UsingSmartShield: this.usingSmartShield,
      SynergyClean: this.synergyClean,
      OptimizerProductID: this.optimizerProductID,
      BalanceComplete: this.balanceComplete,
    };
  }

  static fromApiFormat(api) {
    return new Pool({
      id: api.ID,
      customerID: api.CustomerID,
      typeID: api.TypeID,
      volume: api.Volume,
      chemicalBaseTypeID: api.ChemicalBaseTypeID,
      finish: api.Finish,
      property: api.Property,
      location: api.Location,
      groundLevel: api.GroundLevel,
      ionization: api.Ionization,
      ozonator: api.Ozonator,
      jettedReturns: api.JettedReturns,
      sanitizer: api.Sanitizer,
      oxidizer: api.Oxidizer,
      maintOxidizer: api.MaintOxidizer,
      prevAlgicide: api.PrevAlgicide,
      pHAdjuster: api.pHAdjuster,
      pHBuffer: api.pHBuffer,
      winterOxidizer: api.WinterOxidizer,
      winterAlgicide: api.WinterAlgicide,
      enzymes: api.Enzymes,
      spaSilken: api.SpaSilken,
      borates: api.Borates,
      softswimAssist: api.SoftswimAssist,
      dateBuilt: api.DateBuilt,
      infloorCleaner: api.InfloorCleaner,
      mainDrain: api.MainDrain,
      other1: api.Other1,
      other2: api.Other2,
      other3: api.Other3,
      other4: api.Other4,
      other5: api.Other5,
      detailsActiveInd: api.DetailsActiveInd,
      useMaintClarifier: api.UseMaintClarifier,
      oxysheenTreatment: api.OxysheenTreatment,
      usingNaturalResult: api.UsingNaturalResult,
      ecoKleanConvertedInd: api.EcoKleanConvertedInd,
      filterMedia: api.FilterMedia,
      usingVanishingAct: api.UsingVanishingAct,
      idealSaltPPM: api.IdealSaltPPM,
      maintClarifier: api.MaintClarifier,
      feedMethod: api.FeedMethod,
      smartPAK: api.SmartPAK,
      cannotVacToWaste: api.CannotVacToWaste,
      usingPoolTonic: api.UsingPoolTonic,
      uv: api.UV,
      usingGoodbyeDry: api.UsingGoodbyeDry,
      usingAngryEgg: api.UsingAngryEgg,
      softSoakTRIO: api.SoftSoakTRIO,
      stainScale: api.StainScale,
      created: api.Created,
      modified: api.Modified,
      imported: api.Imported,
      deleted: api.Deleted,
      usingOptimizerPlus: api.UsingOptimizerPlus,
      seasonStart: api.SeasonStart,
      seasonEnd: api.SeasonEnd,
      waterlineControl: api.WaterlineControl,
      waterfall: api.Waterfall,
      waterFountain: api.WaterFountain,
      attachedSpa: api.AttachedSpa,
      prevPossibleCD: api.PrevPossibleCD,
      filterCleanedDate: api.FilterCleanedDate,
      mSCellCleanedDate: api.MSCellCleanedDate,
      sourceConcern: api.SourceConcern,
      winterMetalStaining: api.WinterMetalStaining,
      prevAnalysisDate: api.PrevAnalysisDate,
      nextAnalysisDate: api.NextAnalysisDate,
      flag: api.Flag,
      installedByDealer: api.InstalledByDealer,
      truBlue: api.TruBlue ? moment(api.TruBlue) : api.TruBlue,
      truBlueExpiry: api.TruBlueExpiry ? moment(api.TruBlueExpiry) : api.TruBlueExpiry,
      stabilizer: api.Stabilizer,
      poolOpeningComplete: api.PoolOpeningComplete,
      lastCleanedDrained: api.LastCleanedDrained,
      truBlueStatus: api.TruBlueStatus,
      scanCount: api.ScanCount,
      nickName: api.NickName,
      hasAllTruBlueProducts: api.HasAllTruBlueProducts,
      isInMaintenance: api.IsInMaintenance,
      spaComplete: api.SpaComplete,
      usingSmartShield: api.UsingSmartShield,
      synergyClean: api.SynergyClean,
      optimizerProductID: api.OptimizerProductID,
      balanceComplete: api.BalanceComplete,
    });
  }
}

export default Pool;
