import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'components/Modal';
import _ from 'lodash';
import ErrorList from './errorList.jsx';
import announcementApi from '../../api/announcement.jsx';
import AssetList from '../components/assetList.jsx';
import CheckboxList from './form/checkboxList.jsx';
import LoadingButton from './loadingBtn.jsx';
import DatePicker from 'react-datepicker';
import AssetApi from '../../api/asset.jsx';
import {chemicalBaseTypeNames, printFilesLanguages, productBrandNames, productBrands, systemTypeNames, countriesNames, languages} from '../helpers/constants.jsx';

class AnnouncementInfoModal extends React.Component {
  static propTypes = {
    showState: PropTypes.oneOf(['closed', 'create', 'edit']).isRequired,
    announcement: PropTypes.object,
    onCancel: PropTypes.func.isRequired,
    validate: PropTypes.func,
    adminMode: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      countrySelected: [],
      languageSelected: [],
      systemSelected: [],
      sanitizingSelected: [],
      productsSelected: [],
      startDate: null,
      endDate: null,
      assetSelected: '',
      assets: [],
      imageHash: moment().valueOf()
    };
  }

  componentWillMount() {
    this.save = _.debounce(this._save, 500, {'leading': true, 'trailing': false});
  }

  componentDidMount() {
    AssetApi.list().then(assets => 
      this.setState({
        assets: assets.sort(this.assetSort)
      })
    )

    this.closeModal = _.throttle(this._closeModal, 2000, {trailing: false});
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.showState === 'closed' && nextProps.showState !== 'closed' && nextProps.showState === 'edit') {
      this.setState({
        invalidFields: {},
        countrySelected: nextProps.announcement.country ? nextProps.announcement.country.split(',').map(x => countriesNames[x]) : [],
        languageSelected: nextProps.announcement.language ? nextProps.announcement.language.split(',').map(x => printFilesLanguages[x]) : [],
        systemSelected: nextProps.tabSelected === 1 && nextProps.announcement.systemType ? nextProps.announcement.systemType.split(',').map(x => systemTypeNames[x]) : [],
        sanitizingSelected: nextProps.tabSelected === 1 && nextProps.announcement.sanitizingGroup ? nextProps.announcement.sanitizingGroup.split(',').map(x => chemicalBaseTypeNames[x]) : [],
        productsSelected: nextProps.tabSelected === 2 && nextProps.announcement.productsCarried ? nextProps.announcement.productsCarried.split(',').map(x => productBrandNames[x]) : [],
        assetSelected: nextProps.announcement.contentURL ? nextProps.announcement.contentURL : '',
        startDate: nextProps.announcement.startDate ? moment(nextProps.announcement.startDate, 'YYYY-MM-DD') : null,
        endDate: nextProps.announcement.endDate ? moment(nextProps.announcement.endDate, 'YYYY-MM-DD') : null,
      });
    } else {
      this.setState({
        invalidFields: {},
        countrySelected: [],
        languageSelected: [],
        systemSelected: [],
        sanitizingSelected: [],
        productsSelected: [],
        startDate: null,
        endDate: null,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state) {
      // Update the errorList
      let errors = Object.keys(this.state.invalidFields).map(key => this.state.invalidFields[key]);

      ErrorList.clearErrors();
      ErrorList.publishErrors(errors);
    }
  }

  assetSort = (a, b) => {
    let aDate = moment(a.substring(0, 10), 'MM-DD-YYYY');
    let bDate = moment(b.substring(0, 10), 'MM-DD-YYYY');

    if (aDate.isBefore(bDate)) {
      return -1;
    }
    if (aDate.isAfter(bDate)) {
      return 1;
    }
    return 0;
  };

  uploadAsset = (file) => {
    AssetApi.upload(file).then(assets => 
      this.setState({
        assets: assets.sort(this.assetSort),
        imageHash: moment().valueOf()
      })
    )
    .catch(e => console.error(e));
  };

  onDateChange = newDate => {
    this.setState({expiry: newDate});
  };

  _closeModal = event => {
    if (event) {
      event.preventDefault();
    }
  };

  cancel = () => {
    this.props.onCancel();
  };

  getKey(myEnum, enumValue) {
    let keys = Object.keys(myEnum).filter(x => myEnum[x] == enumValue);

    return keys.length > 0 ? keys[0] : null;
  }

  saveConsumer() {
    let newAnnouncement = {};

    if (this.props.adminMode) {
      newAnnouncement.Title = this.title.value;
      newAnnouncement.Priority = this.priority.value ? parseInt(this.priority.value, 10) : 0;
      newAnnouncement.Country = this.state.countrySelected.map(x => this.getKey(countriesNames, x)).join();
      newAnnouncement.Language = this.state.languageSelected.map(x => this.getKey(printFilesLanguages, x)).join();
      newAnnouncement.SystemType = this.state.systemSelected.map(x => this.getKey(systemTypeNames, x)).join();
      newAnnouncement.SanitizingGroup = this.state.sanitizingSelected.map(x => this.getKey(chemicalBaseTypeNames, x)).join();
      newAnnouncement.StartDate = moment(this.state.startDate).format('YYYY-MM-DD HH:mm:ss');
      newAnnouncement.EndDate = moment(this.state.endDate).format('YYYY-MM-DD HH:mm:ss');
      newAnnouncement.ContentURL = this.state.assetSelected ? this.state.assetSelected : '';
    } else {
      newAnnouncement.Title = this.props.announcement.title;
      newAnnouncement.Priority = this.props.announcement.priority;
      newAnnouncement.Country = this.props.announcement.country;
      newAnnouncement.Language = this.props.announcement.language;
      newAnnouncement.SystemType = this.props.announcement.systemType;
      newAnnouncement.SanitizingGroup = this.props.announcement.sanitizingGroup;
      newAnnouncement.StartDate = this.props.announcement.startDate;
      newAnnouncement.EndDate = this.props.announcement.endDate;
      newAnnouncement.ContentURL = this.state.assetSelected ? this.state.assetSelected : '';
    }

    if (this.props.showState == 'edit') {
      newAnnouncement.ID = this.props.announcement.id;
      announcementApi.saveAnnouncementConsumer(newAnnouncement)
        .then(() => {
          this.setState({isSaving: false});
          this.props.onCancel();
        })
        .catch(error => {
          this.setState({isSaving: false});
          console.error(error);
        });
    } else {
      announcementApi.createAnnouncementConsumer(newAnnouncement)
        .then(() => {
          this.setState({isSaving: false});
          this.props.onCancel();
        })
        .catch(error => {
          this.setState({isSaving: false});
          console.error(error);
        });
    }
  }

  getAssetURL = (filename) => {
    let CDN = document.getElementById('body').dataset.cdn;
    let url = CDN + filename;

    return url;
  };

  saveDealer() {
    let newAnnouncement = {};

    if (this.props.adminMode) {
      newAnnouncement.Title = this.title.value;
      newAnnouncement.Priority = this.priority.value ? parseInt(this.priority.value, 10) : 0;
      newAnnouncement.Country = this.state.countrySelected.map(x => this.getKey(countriesNames, x)).join();
      newAnnouncement.Language = this.state.languageSelected.map(x => this.getKey(printFilesLanguages, x)).join();
      newAnnouncement.ProductsCarried = this.state.productsSelected.map(x => this.getKey(productBrandNames, x)).join();
      newAnnouncement.StartDate = moment(this.state.startDate).format('YYYY-MM-DD HH:mm:ss');
      newAnnouncement.EndDate = moment(this.state.endDate).format('YYYY-MM-DD HH:mm:ss');
      newAnnouncement.ContentURL = this.state.assetSelected ? this.state.assetSelected : '';
    } else {
      newAnnouncement.Title = this.props.announcement.title;
      newAnnouncement.Priority = this.props.announcement.priority;
      newAnnouncement.Country = this.props.announcement.country;
      newAnnouncement.Language = this.props.announcement.language;
      newAnnouncement.ProductsCarried = this.props.announcement.productsCarried;
      newAnnouncement.StartDate = this.props.announcement.startDate;
      newAnnouncement.EndDate = this.props.announcement.endDate;
      newAnnouncement.ContentURL = this.state.assetSelected ? this.state.assetSelected : '';
    }

    if (this.props.showState == 'edit') {
      newAnnouncement.ID = this.props.announcement.id;
      announcementApi.saveAnnouncementDealer(newAnnouncement)
        .then(() => {
          this.setState({isSaving: false});
          this.props.onCancel();
        })
        .catch(error => {
          this.setState({isSaving: false});
          console.error(error);
        });
    } else {
      announcementApi.createAnnouncementDealer(newAnnouncement)
        .then(() => {
          this.setState({isSaving: false});
          this.props.onCancel();
        })
        .catch(error => {
          this.setState({isSaving: false});
          console.error(error);
        });
    }
  }

  _save = () => {
    if (!this.state.isSaving) {
      this.setState({isSaving: true});
      this.props.tabSelected == 1 ? this.saveConsumer() : this.saveDealer();
    }
  };

  updateSelectedCountry = () => {
    return (val => {
      return (newVal => {
        if (newVal && this.state.countrySelected.indexOf(val) === -1) {
          this.state.countrySelected.push(val);
          this.setState({countrySelected: this.state.countrySelected});
        } else if (!newVal && this.state.countrySelected.indexOf(val) !== -1) {
          this.state.countrySelected.splice(this.state.countrySelected.indexOf(val), 1);
          this.setState({countrySelected: this.state.countrySelected});
        }
      }).bind(this);
    }).bind(this);
  }

  updateSelectedLanguage = () => {
    return (val => {
      return (newVal => {
        if (newVal && this.state.languageSelected.indexOf(val) === -1) {
          this.state.languageSelected.push(val);
          this.setState({languageSelected: this.state.languageSelected});
        } else if (!newVal && this.state.languageSelected.indexOf(val) !== -1) {
          this.state.languageSelected.splice(this.state.languageSelected.indexOf(val), 1);
          this.setState({languageSelected: this.state.languageSelected});
        }
      }).bind(this);
    }).bind(this);
  }

  updateSelectedSystemType = () => {
    return (val => {
      return (newVal => {
        if (newVal && this.state.systemSelected.indexOf(val) === -1) {
          this.state.systemSelected.push(val);
          this.setState({systemSelected: this.state.systemSelected});
        } else if (!newVal && this.state.systemSelected.indexOf(val) !== -1) {
          this.state.systemSelected.splice(this.state.systemSelected.indexOf(val), 1);
          this.setState({systemSelected: this.state.systemSelected});
        }
      }).bind(this);
    }).bind(this);
  }

  updateSelectedSanizitingGroup = () => {
    return (val => {
      return (newVal => {
        if (newVal && this.state.sanitizingSelected.indexOf(val) === -1) {
          this.state.sanitizingSelected.push(val);
          this.setState({sanitizingSelected: this.state.sanitizingSelected});
        } else if (!newVal && this.state.sanitizingSelected.indexOf(val) !== -1) {
          this.state.sanitizingSelected.splice(this.state.sanitizingSelected.indexOf(val), 1);
          this.setState({sanitizingSelected: this.state.sanitizingSelected});
        }
      }).bind(this);
    }).bind(this);
  }

  updateSelectedProducts = () => {
    return (val => {
      return (newVal => {
        if (newVal && this.state.productsSelected.indexOf(val) === -1) {
          this.state.productsSelected.push(val);
          this.setState({productsSelected: this.state.productsSelected});
        } else if (!newVal && this.state.productsSelected.indexOf(val) !== -1) {
          this.state.productsSelected.splice(this.state.productsSelected.indexOf(val), 1);
          this.setState({productsSelected: this.state.productsSelected});
        }
      }).bind(this);
    }).bind(this);
  }

  appendAssetUrl = (filename) => {
    let CDN = document.getElementById('body').dataset.cdn;
    let url = CDN + filename;

    this.setState({assetSelected: url});
  };

  render() {
    let show = this.props.showState !== 'closed';
    let isCreating = this.props.showState === 'create';
    let title = isCreating ? 'Create New Announcement' : this.props.announcement.title || 'Announcement';

    if (show) {
      return <Modal show={show} onHide={this.props.onCancel} id='announcement-info-modal'>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <form onSubmit={this.closeModal}>
          <Modal.Body>
            {
              this.props.adminMode ?
                <div>
                  <div className='row'>
                    <div className='col-sm-6'>
                      <div className='form-group'>
                        <label>Campaign</label>
                        <input type='text' className='form-control' ref={r => this.title = r}
                                defaultValue={this.props.announcement.title} />
                      </div>
                    </div>
                    <div className='col-sm-6'>
                      <div className='form-group'>
                        <div className='dropdown'>
                          <label>Priority</label>
                          <select className='form-control' ref={r => this.priority = r}
                                  defaultValue={this.props.announcement.priority}>
                            <option value='0'>0</option>
                            <option value='1'>1</option>
                            <option value='2'>2</option>
                            <option value='3'>3</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-6'>
                      <div className='form-group'>
                        <label>Start Date</label>
                        <DatePicker
                          selected={this.state.startDate}
                          dateFormat='YYYY-MM-DD'
                          onChange={(value, e) => this.setState({startDate: value})}
                          placeholderText='Select a start date' />
                      </div>
                    </div>
                    <div className='col-sm-6'>
                      <div className='form-group'>
                        <div className='dropdown'>
                          <label>End Date</label>
                          <DatePicker
                            selected={this.state.endDate}
                            dateFormat='YYYY-MM-DD'
                            minDate={this.state.startDate}
                            onChange={(value, e) => this.setState({endDate: value})}
                            placeholderText='Select an end date' />
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className='row'>
                    <div className='col-sm-6'>
                      <div className='form-group'>
                        <label>Country</label>
                        <CheckboxList values={[countriesNames['CA'], countriesNames['US']]}
                          title={'Country'}
                          selected={this.state.countrySelected}
                          update={this.updateSelectedCountry()} />
                      </div>
                    </div>
                    <div className='col-sm-6'>
                      <div className='form-group'>
                        <label>Language</label>
                        <CheckboxList values={[languages['EN'], languages['FR']]}
                          title={'Language'}
                          selected={this.state.languageSelected}
                          update={this.updateSelectedLanguage()} />
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className='row'>
                    {this.props.tabSelected === 1 ?
                      <div className='col-sm-6'>
                        <div className='form-group'>
                          <label>System Type</label>
                          <CheckboxList values={[systemTypeNames[1], systemTypeNames[2], systemTypeNames[3]]}
                            title={'System Type'}
                            selected={this.state.systemSelected}
                            update={this.updateSelectedSystemType()} />
                        </div>
                      </div> :
                    null}
                    {this.props.tabSelected === 1 ?
                      <div className='col-sm-6'>
                        <div className='form-group'>
                          <label>Sanitizing Group</label>
                          <CheckboxList values={[chemicalBaseTypeNames[1],
                            chemicalBaseTypeNames[2], chemicalBaseTypeNames[3],
                            chemicalBaseTypeNames[5], chemicalBaseTypeNames[6],
                            chemicalBaseTypeNames[7], chemicalBaseTypeNames[8],
                            chemicalBaseTypeNames[12], chemicalBaseTypeNames[13],
                            chemicalBaseTypeNames[14], chemicalBaseTypeNames[15]]}
                            title={'Sanitizing Group'}
                            selected={this.state.sanitizingSelected}
                            update={this.updateSelectedSanizitingGroup()} />
                        </div>
                      </div> :
                    <div className='col-sm-6'>
                      <div className='form-group'>
                          <label>Products Carried</label>
                          <CheckboxList values={[productBrandNames[productBrands.spaGuard], productBrandNames[productBrands.bioGuard], productBrandNames[productBrands.proGuard], productBrandNames[productBrands.naturalChemistry]]}
                            title={'Products Carried'}
                            selected={this.state.productsSelected}
                            update={this.updateSelectedProducts()} />
                        </div>
                    </div>
                    }
                  </div>
                  <hr />
                </div> :
              null
            }
            <AssetList title={'Announcement Image'}
              fileSelected={this.state.assetSelected}
              addLink={this.appendAssetUrl}
              addAsset={this.uploadAsset}
              assets={this.state.assets} 
              imageHash={this.state.imageHash} />
            <ErrorList />
          </Modal.Body>
          <Modal.Footer>
            <button className='btn btn-secondary pull-left' type='button' onClick={this.cancel}>Cancel</button>
            <button type="button" onClick={() => this.setState({assetSelected: ''})} className="btn btn-link">Clear Image</button>
            <LoadingButton className='btn btn-primary'
              onClick={this.save}
              loading={this.state.isSaving}>
              Save
            </LoadingButton>
          </Modal.Footer>
        </form>
      </Modal>;
    }

    return <div id='announcement-info-modal'/>;
  }
}

export default AnnouncementInfoModal;

AnnouncementInfoModal.defaultProps = {
  announcement: {},
};